import {
  Dialog,
  Grid,
  InfoIcon,
  Input,
  InputType,
  InputVariant,
  Option,
  Select,
  SelectOption,
  SelectVariant,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { useState } from 'react'
import { ProjectType } from '../../../data/gql-gen/dashboard/graphql'
import { isMultiMarketFeatureEnabled } from '../../../utils/HelperFunctions'
import { isFocaldataUser } from '../../../utils/authUtils'

const shouldHaveMultiMarketOption =
  isFocaldataUser() && isMultiMarketFeatureEnabled()

const fdChatProjectOption = {
  id: ProjectType.FdChat,
  name: 'FD chat',
  value: ProjectType.FdChat
}

const projectTypeOptions = shouldHaveMultiMarketOption
  ? [
      {
        id: ProjectType.AdHoc,
        name: 'FD core single-market',
        value: ProjectType.AdHoc
      },
      {
        id: ProjectType.MultiMarketV2,
        name: 'FD core multi-market',
        value: ProjectType.MultiMarketV2
      },
      fdChatProjectOption
    ]
  : [
      {
        id: ProjectType.AdHoc,
        name: 'FD core',
        value: ProjectType.AdHoc
      },
      fdChatProjectOption
    ]

interface Props {
  title?: string
  completes?: number
  country?: Option
  defaultOption?: Option
  open: boolean
  options?: Option[]
  primaryButtonClick: (projectTitle: string) => void
  setCompletes?: (completes: number) => void
  setProjectType?: (projectType: ProjectType) => void
  setCountry?: (selectedCountry: Option) => void
  setOpen: (open: boolean) => void
  suggestedOptions?: Option[]
  loadingCreateProject?: boolean
  dataDdActionNamePrimaryButton?: string
}

const getCountryByValue: (
  options: SelectOption[],
  value: string
) => SelectOption | undefined = (options, value) => {
  const country = options.find((option) => option.value === value)
  return country
}

const CreateProjectDialog = ({
  title = 'Create new project',
  defaultOption,
  completes,
  country,
  open,
  options,
  primaryButtonClick,
  setCompletes,
  setProjectType,
  setCountry,
  setOpen,
  suggestedOptions,
  loadingCreateProject,
  dataDdActionNamePrimaryButton
}: Props) => {
  const [projectTitle, setProjectTitle] = useState('')

  const showProjectType = setProjectType
  const showCountry = defaultOption && options && setCountry
  const showCompletes = setCompletes

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      primaryButtonClick={() => primaryButtonClick(projectTitle)}
      primaryButtonDisabled={
        projectTitle === '' ||
        (showCompletes && !completes) ||
        (showCountry && !country?.countryCode) ||
        loadingCreateProject
      }
      primaryButtonText="Create new project"
      title={title}
      onClickOutside={() => setOpen(false)}
      dataCyPrimaryButton="dialog-primary-button"
      primaryButtonLoading={loadingCreateProject}
      dataDdActionNamePrimaryButton={dataDdActionNamePrimaryButton}
    >
      <Grid container direction="column" justifyContent="space-between">
        <Grid item xs={12}>
          <Text gutterBottom size={TextSize.m} weight={TextWeight.SemiBold}>
            Project title
          </Text>

          <Input
            ariaLabel="Project title"
            fullWidth
            inputType={InputType.Text}
            onChange={(event) => setProjectTitle(event.target.value)}
            variant={InputVariant.Filled}
            dataCy="dialog-project-title"
          />
        </Grid>
        {showProjectType && (
          <Grid item xs={12}>
            <Text gutterBottom size={TextSize.m} weight={TextWeight.SemiBold}>
              Choose project type
            </Text>
            <Select
              fullWidth
              defaultOptionValue={ProjectType.AdHoc}
              onChange={(event) => {
                setProjectType(event.target.value as ProjectType)
              }}
              options={projectTypeOptions}
              variant={SelectVariant.Filled}
            />
          </Grid>
        )}
        {showCountry && (
          <Grid item xs={12}>
            <Text gutterBottom size={TextSize.m} weight={TextWeight.SemiBold}>
              Country
            </Text>
            <Select
              fullWidth
              defaultOptionValue={defaultOption.value}
              onChange={(event) => {
                const selectedCountry =
                  getCountryByValue(options, event.target.value) ||
                  defaultOption

                setCountry(selectedCountry)
              }}
              options={options.length === 0 ? [defaultOption] : options}
              suggestedOptions={suggestedOptions}
              variant={SelectVariant.Filled}
            />
          </Grid>
        )}
        {showCompletes && (
          <Grid item xs={12}>
            <Text gutterBottom size={TextSize.m} weight={TextWeight.SemiBold}>
              Number of completes
            </Text>
            <Grid item xs={12} container alignItems="center">
              <Input
                ariaLabel="Completes"
                defaultValue={completes}
                variant={InputVariant.Filled}
                onChange={(event) => {
                  setCompletes(parseInt(event.target.value, 10))
                }}
                inputType={InputType.Number}
                dataCy="dialog-project-wanted-completes"
              />
              <InfoIcon text="Number of people who will complete your survey" />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export default CreateProjectDialog
