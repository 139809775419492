import React, { memo } from 'react'
import { BottomNav as BottomNavComponent } from '../../../../components/BottomNav'
import useForkMutations from '../../../../hooks/questionnaire/useForkMutations'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useGetFeasibilityEstimateData } from '../../../../hooks/useGetFeasibilityEstimateData'

interface Props {
  overrideDisableButtons?: boolean
  middleButtonText?: string
  rightButtonDisabled?: boolean
  rightButtonText?: string
  secondaryRightButtonDisabled?: boolean
  secondaryRightButtonText?: string
  middleButtonControl?: React.ReactNode
  width?: string
  onClickMiddleButton?: () => void
  onClickRightButton?: () => void
  onClickSecondaryRightButton?: () => void
}

// @todo Legacy eslint violation – fix this when editing
// eslint-disable-next-line react/display-name
const BottomNavComponentMemoized = memo(
  (
    props: Omit<Props, 'overrideDisableButtons'> & {
      middleButtonDisabled?: boolean
    }
  ) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <BottomNavComponent {...props} />
  }
)

const BottomNav: React.FC<Props> = (props: Props) => {
  const {
    overrideDisableButtons,
    middleButtonText,
    rightButtonDisabled,
    rightButtonText,
    secondaryRightButtonDisabled,
    secondaryRightButtonText,
    middleButtonControl,
    width,
    onClickMiddleButton,
    onClickRightButton,
    onClickSecondaryRightButton
  } = props

  const { draftQuestionnaire } = useGetDraftQuestionnaire()

  const { isFeasible, isAudienceValid } = useGetFeasibilityEstimateData()
  const { uniqueForkNameError } = useForkMutations()
  const hasEntries = draftQuestionnaire && draftQuestionnaire.entries.length > 0
  const isRightButtonDisabled =
    (!hasEntries && (!isFeasible || !isAudienceValid)) ||
    overrideDisableButtons ||
    uniqueForkNameError.hasError ||
    rightButtonDisabled

  return (
    <BottomNavComponentMemoized
      width={width}
      middleButtonControl={middleButtonControl}
      middleButtonText={middleButtonText}
      onClickMiddleButton={onClickMiddleButton}
      middleButtonDisabled={overrideDisableButtons}
      onClickRightButton={onClickRightButton}
      rightButtonText={rightButtonText}
      rightButtonDisabled={isRightButtonDisabled}
      secondaryRightButtonDisabled={secondaryRightButtonDisabled}
      secondaryRightButtonText={secondaryRightButtonText}
      onClickSecondaryRightButton={onClickSecondaryRightButton}
    />
  )
}

export default BottomNav
