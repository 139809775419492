import { ApolloLink, HttpLink } from '@apollo/client'
import services, { GqlService } from '../data/gql/services'
import { authLink, refreshLink, retryLink } from './apolloAuth'

const debug = localStorage.getItem('debug')

const httpLinks: HttpLink[] = services.map((service: GqlService) => {
  const customFetch = (uri: string, options: any) => {
    const { operationName, variables } = JSON.parse(options.body)
    const debugAll = debug?.includes('all')

    const uriParams = new URLSearchParams({
      opname: operationName
    })

    if (debugAll || debug?.includes('vars')) {
      for (const [varKey, varValue] of Object.entries(variables)) {
        uriParams.append(varKey, `${varValue}`)
      }
    }

    return fetch(`${uri}?${uriParams}`, options)
  }

  return new HttpLink({
    uri: `${service.uri.includes('http') ? '' : process.env.REACT_APP_DOMAIN}${
      service.uri
    }`,
    fetch: customFetch
  })
})

const serviceLinks: ApolloLink[] = []

// link each service to the next one in the list,
// except for the last one because it has no next
services.forEach((service: GqlService, index: number) => {
  serviceLinks.push(
    ApolloLink.split(
      (operation) => operation.getContext().clientName === service.name,
      httpLinks[index],
      index === 0 ? httpLinks[index] : serviceLinks[index - 1]
    )
  )
})

export const link = ApolloLink.from([
  authLink,
  refreshLink,
  retryLink,
  serviceLinks[serviceLinks.length - 1]
])
