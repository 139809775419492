import { gql } from '@apollo/client/core'
import { DraftTextCardItem } from '../../../model/questionnaire'

export interface UpdateTextCardData {
  updateTextCard: {
    entryItem: DraftTextCardItem
    number: number
    position: number
  }
}

export const UPDATE_TEXT_CARD = gql`
  mutation updateTextCard(
    $questionnaireId: ID!
    $textCardLk: ID!
    $title: String!
    $titleStyling: String!
    $subtitle: String!
    $body: String!
  ) {
    updateTextCard(
      questionnaireId: $questionnaireId
      textCardLk: $textCardLk
      title: $title
      titleStyling: $titleStyling
      subtitle: $subtitle
      body: $body
    ) {
      position
      number
      entryItem {
        __typename
        ... on DraftTextCardItem {
          textCardLk
          textCard {
            ...TextCard
          }
          __typename
        }
      }
    }
  }
`
