import { gql } from '@apollo/client'
import { DraftCustomAudienceCriterionOption } from '../../../model/audience'
import DRAFT_CUSTOM_CRITERION_OPTION from '../fragments/draftCustomCriterionOption'

export interface AddCustomCriterionOptionData {
  addCustomCriterionOption: DraftCustomAudienceCriterionOption
}

const ADD_CUSTOM_CRITERION_OPTION = gql`
  mutation addCustomCriterionOption(
    $surveyId: ID!
    $questionLk: ID!
    $position: Int!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    addCustomCriterionOption(
      surveyId: $surveyId
      questionLk: $questionLk
      position: $position
    ) {
      ...DraftCustomCriterionOption
    }
  }
  ${DRAFT_CUSTOM_CRITERION_OPTION}
`

export default ADD_CUSTOM_CRITERION_OPTION
