import { ApolloCache } from '@apollo/client'
import {
  AudienceChangedSinceLastSlackIrsNotificationQueryQuery,
  AudienceChangedSinceLastSlackIrsNotificationQueryQueryVariables
} from '../../data/gql-gen/local/graphql'
import { AUDIENCE_CHANGED_SINCE_LAST_SLACK_IRS_NOTIFICATION_QUERY } from './audienceChangedSinceLastSlackIRSNotification'

export const writeAudienceChangedSinceLastSlackIRSNotification = (
  cache: ApolloCache<unknown>,
  audienceChangedSinceLastIRSNotification: boolean
) => {
  cache.writeQuery<
    AudienceChangedSinceLastSlackIrsNotificationQueryQuery,
    AudienceChangedSinceLastSlackIrsNotificationQueryQueryVariables
  >({
    query: AUDIENCE_CHANGED_SINCE_LAST_SLACK_IRS_NOTIFICATION_QUERY,
    data: {
      __typename: 'Query',
      audienceChangedSinceLastIRSNotification
    }
  })
}
