import React from 'react'
import { QuestionCardDefaultOptions } from '.'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import {
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import {
  WithEntry,
  getQuestionEnabledSettings
} from '../../../utils/questionnaireUtils'
import {
  selectSettingsByQuestionId,
  setQuestionSetting
} from '../Questionnaire.slice'
import { getEntryId } from '../Questionnaire.utils'

const DefaultOptions: React.FC<WithEntry> = (props: WithEntry) => {
  const { entry } = props
  const entryId = getEntryId(entry)
  const handleSettingChange = useSetQuestionnaireSetting(entry)

  const dispatch = useAppDispatch()
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) => selectSettingsByQuestionId(state, entryId)) || []

  const changeSettingValue = async (
    code: QuestionSettingCode,
    value: SettingValue
  ) => {
    dispatch(
      setQuestionSetting({
        questionLk: entryId,
        code,
        value
      })
    )
    await handleSettingChange(code, value, 'no-cache')
  }

  const enabledSettings = getQuestionEnabledSettings(settingValues)

  const shouldShowDontKnow = enabledSettings.has(QuestionSettingCode.DontKnow)
  const shouldShowPeferNotToSay = enabledSettings.has(
    QuestionSettingCode.PreferNotToSay
  )
  const shouldShowNoneOfThese = enabledSettings.has(
    QuestionSettingCode.NoneOfThese
  )

  return (
    <QuestionCardDefaultOptions
      entry={entry}
      shouldShowDontKnow={shouldShowDontKnow}
      shouldShowPeferNotToSay={shouldShowPeferNotToSay}
      shouldShowNoneOfThese={shouldShowNoneOfThese}
      onChangeDontKnow={(
        settingValue: SettingValue.Enabled | SettingValue.Disabled
      ) => {
        changeSettingValue(QuestionSettingCode.DontKnow, settingValue)
      }}
      onChangeNoneOfThese={(
        settingValue: SettingValue.Enabled | SettingValue.Disabled
      ) => {
        changeSettingValue(QuestionSettingCode.NoneOfThese, settingValue)
      }}
      onChangePreferNotToSay={(
        settingValue: SettingValue.Enabled | SettingValue.Disabled
      ) => {
        changeSettingValue(QuestionSettingCode.PreferNotToSay, settingValue)
      }}
    />
  )
}

export default DefaultOptions
