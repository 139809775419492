import {
  ButtonType,
  LinkButton,
  Logo,
  PasswordInput,
  SpinnerButton,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import useStyles from '../Auth.styles'

import PasswordChecklist from './PasswordChecklist'

interface Props {
  disabled?: boolean
  onCreatePassword: (password: string) => Promise<boolean | Error>
}
const CreatePassword: React.FC<Props> = (props: Props) => {
  const { disabled, onCreatePassword } = props
  const [loading, onLoading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const minimumLengthCheck: (password: string) => boolean = (password) => {
    const minPasswordLen = 8
    const isValid = password.length >= minPasswordLen

    return isValid
  }
  const useOfNumbersCheck: (password: string) => boolean = (password) => {
    const isValid = /\d/.test(password)

    return isValid
  }
  const usingCapitalsCheck: (password: string) => boolean = (password) => {
    const isValid = password.toLowerCase() !== password

    return isValid
  }

  const handleCreatePassword: (event: React.SyntheticEvent) => void = async (
    event
  ) => {
    event.preventDefault()

    onLoading(true)
    const createPasswordResponse = await onCreatePassword(password)

    if (createPasswordResponse) {
      onLoading(false)
    }
    if (createPasswordResponse instanceof Error) {
      onLoading(false)
    }
  }

  const isLengthValid = minimumLengthCheck(password)
  const isUsingNumbers = useOfNumbersCheck(password)
  const isUsingCapitals = usingCapitalsCheck(password)

  const isDisabled =
    !isLengthValid || !isUsingNumbers || !isUsingCapitals || disabled

  return (
    <Grid
      className={classes.page}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.maxWidth}>
        <form onSubmit={handleCreatePassword}>
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            className={classes.container}
          >
            <Logo width={180} />
            <Grid
              item
              className={classes.gridItem}
              style={{ marginTop: '16px' }}
            >
              <p className={classNames(textClasses.default, textClasses.sizeL)}>
                Set your password for your account
              </p>
            </Grid>
            <Grid item container className={classes.gridItem}>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.weightSemiBold
                )}
              >
                Password
              </p>
              <PasswordInput
                fullWidth
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value)
                }}
              />
            </Grid>
            <PasswordChecklist
              isLengthValid={isLengthValid}
              isUsingNumbers={isUsingNumbers}
              isUsingCapitals={isUsingCapitals}
            />
            <Grid item container className={classes.gridItem}>
              <SpinnerButton
                onClick={handleCreatePassword}
                loading={loading}
                className={classes.button}
                fullWidth
                buttonType={ButtonType.Submit}
                disabled={isDisabled}
              >
                Create password
              </SpinnerButton>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.helperText}
            >
              <Grid className={classes.paddingRight}>
                <p className={classNames(textClasses.default)}>
                  Need help? Contact
                </p>
              </Grid>
              <LinkButton
                size={TextSize.m}
                weight={TextWeight.Bold}
                noPadding
                underlined
              >
                support@focaldata.com
              </LinkButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default CreatePassword
