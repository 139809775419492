import { gql } from '@apollo/client/core'
import { EntrySettingValue } from '../../../model/questionnaire'
import ENTRY_SETTING_VALUE from '../fragments/entrySettingValue'

export interface SetQuestionnaireMatrixSettingValueData {
  setQuestionnaireMatrixSettingValue: EntrySettingValue
}

const SET_QUESTIONNAIRE_MATRIX_SETTING_VALUE = gql`
  mutation setQuestionnaireMatrixSettingValue(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $setting: SettingInputInput!
  ) {
    setQuestionnaireMatrixSettingValue(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      setting: $setting
    ) {
      number
      entryItem {
        ... on DraftMatrixItem {
          matrixTitleLk
          settingValues {
            ...EntrySettingValue
          }
        }
      }
    }
  }
  ${ENTRY_SETTING_VALUE}
`

export default SET_QUESTIONNAIRE_MATRIX_SETTING_VALUE
