import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  LogicPropositionType,
  ReplaceQuestionLogicInputLogicInput,
  ReplaceQuestionLogicInputPropositionInput
} from '../../../data/gql-gen/questionnaire/graphql'
import {
  DraftQuestionnaireEntry,
  EntryType
} from '../../../data/model/questionnaire'
import {
  ClauseBlock,
  Operator,
  hasQuestionLogic
} from '../../../utils/questionLogic'
import { entryAsUnion } from '../../../utils/questionnaireUtils'
import { useSurveyId } from '../../useSurveyId'
import { REPLACE_QUESTION_LOGIC } from './useQuestionLogic.questionnaire.gql'

interface UseQuestionLogic {
  setQuestionLogic: (
    entry: DraftQuestionnaireEntry,
    clauseBlocks: ClauseBlock[],
    operator: Operator
  ) => Promise<void>
  removeLogic: (entry: DraftQuestionnaireEntry) => Promise<void>
  loading: boolean
}

const buildQuestionLogic = (
  clauseBlocks: ClauseBlock[],
  operator: Operator
) => {
  const logic: ReplaceQuestionLogicInputLogicInput[][] = []

  for (const block of clauseBlocks) {
    if (block.questionLk && block.propositionEntry) {
      let group: ReplaceQuestionLogicInputLogicInput[]

      const propositionEntry = entryAsUnion(block.propositionEntry)

      switch (operator) {
        case Operator.Or:
          group = logic.at(-1) ?? []
          logic[Math.max(0, logic.length - 1)] = group
          break

        case Operator.And:
          group = []
          logic.push(group)
      }

      for (const responseOptionLk of block.propositionResponseOptionLks) {
        let propositionType: LogicPropositionType
        let proposition: ReplaceQuestionLogicInputPropositionInput

        switch (propositionEntry.entryType) {
          case EntryType.QuestionEntryType: {
            propositionType = LogicPropositionType.QuestionResponseOptionType
            proposition = {
              questionResponseOption: {
                questionLk: propositionEntry.entryItem.questionLk,
                responseOptionLk
              }
            }
            break
          }

          case EntryType.MatrixEntryType:
            {
              propositionType = LogicPropositionType.MatrixResponseOptionType
              proposition = {
                matrixResponseOption: {
                  matrixTitleLk: propositionEntry.entryItem.matrixTitleLk,
                  questionLk: block.questionLk,
                  responseOptionLk
                }
              }
            }
            break

          default:
            throw new Error(
              `Cannot build proposition for entry type ${propositionEntry.entryType}`
            )
        }

        group.push({
          propositionType,
          proposition,
          negated: block.negated,
          propositionRef: {
            propositionId: uuidv4(),
            clauseNumber: logic.length - 1
          }
        })
      }
    }
  }

  return logic
}

const useSetQuestionLogic = () => {
  const [replaceQuestionLogic] = useMutation(REPLACE_QUESTION_LOGIC, {
    context: {
      clientName: 'questionnaire'
    }
  })

  const questionnaireId = useSurveyId()

  const setQuestionLogic = async (
    entry: DraftQuestionnaireEntry,
    logic: ReplaceQuestionLogicInputLogicInput[][]
  ) => {
    await replaceQuestionLogic({
      variables: {
        entryNumber: entry.number,
        questionnaireId,
        logic
      }
    })
  }

  return setQuestionLogic
}

const useQuestionLogic = (): UseQuestionLogic => {
  const [loading, setLoading] = useState(false)

  const setLogic = useSetQuestionLogic()

  const removeLogic = async (entry: DraftQuestionnaireEntry) => {
    if (hasQuestionLogic(entry)) {
      await setLogic(entry, [])
    }
  }

  const setQuestionLogic = async (
    entry: DraftQuestionnaireEntry,
    clauseBlocks: ClauseBlock[],
    operator: Operator
  ) => {
    setLoading(true)

    await setLogic(entry, buildQuestionLogic(clauseBlocks, operator))

    setLoading(false)
  }
  return {
    setQuestionLogic,
    removeLogic,
    loading
  }
}

export default useQuestionLogic
