import { questionnaireGql } from '../../data/gql-gen/questionnaire'

export const SECTION_ITEM_LOOPING_CONFIG = questionnaireGql(/* GraphQL */ `
  fragment SectionItemLoopingConfig on DraftSectionItem {
    sectionId
    isLoopingEnabled @client
    loopingConfig {
      maxIterations
      loopBasis
      sourceEntryNumber
      loopRandomisationEnabled
      prioritizedResponseOptionIds
      matrixLoopingConfig {
        qualifyingResponseOptionIds
        sourceMatrixRowQuotas {
          questionId
          quotaTarget
        }
      }
    }
  }
`)
