import { ScrollTo } from '@focaldata/cin-ui-components'
import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  CustomCriterionValidationError,
  SetCustomMemberSettingValueMutationVariables
} from '../../../data/gql-gen/fieldwork/graphql'
import {
  DraftCustomAudience,
  FieldworkAudienceType,
  MemberSettingCode,
  SettingValue
} from '../../../data/model/audience'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import useCustomAudienceMutations from '../../../hooks/audience/useCustomAudienceMutations'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useUpdateFieldwork } from '../../../hooks/audience/useUpdateFieldwork'
import { useGetFeasibilityEstimateData } from '../../../hooks/useGetFeasibilityEstimateData'
import useGetLoi from '../../../hooks/useGetLoi'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  ResponseOrderingOptions,
  getOrdering
} from '../../../utils/questionnaireUtils'
import { useProjectType } from '../../Project/Project.hooks'
import AudienceContext, {
  setAudienceMembers,
  setNewAudienceQuestionLk
} from '../Audience.context'
import { AUDIENCE_SCROLL_CONTAINER_ID, SCROLL_ID_PREFIX } from '../constants'
import {
  getCustomAudienceValidationErrors,
  getIsEnabledInSettings
} from '../utils'
import AudienceCustomQuestionCardControl from './CustomAudienceQuestionCard.control'

const CustomAudienceQuestionCardContainer = ({
  questionLk
}: {
  questionLk: string
}) => {
  const surveyId = useSurveyId()
  const { isFdChat } = useProjectType()
  const loi = useGetLoi()
  const { dispatch, audienceState } = useContext(AudienceContext)
  const { refetchAudience } = useGetDraftAudience()
  const { refetchFeasibilityEstimateData } = useGetFeasibilityEstimateData()
  const audience = audienceState.audienceMembers.find(
    (member) =>
      member.memberType === FieldworkAudienceType.Custom &&
      (member.memberItem as DraftCustomAudience).questionLk === questionLk
  )

  const customAudience = audience?.memberItem as DraftCustomAudience

  const {
    detachCustomAudienceQuestion,
    updateCustomCriterion,
    addCustomCriterionOption,
    setCustomMemberSettingValue,
    removeCustomMemberSettingValue,
    batchSetCustomQuota
  } = useCustomAudienceMutations()

  const questionMutationVariable = useMemo(
    () => ({
      surveyId,
      questionLk: customAudience.questionLk
    }),
    [customAudience.questionLk, surveyId]
  )
  const { updateFieldwork } = useUpdateFieldwork()

  useEffect(() => {
    if (questionLk === audienceState.newAudienceQuestionLk) {
      ScrollTo(
        `${SCROLL_ID_PREFIX}${
          (audience?.memberItem as DraftCustomAudience).questionLk
        }`,
        AUDIENCE_SCROLL_CONTAINER_ID
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationErrors = getCustomAudienceValidationErrors(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    audienceState?.validationErrors,
    customAudience.questionLk
  )

  const { isAudienceInvalid } = useAudienceValidation({
    validationErrors: validationErrors ? [validationErrors] : undefined
  })

  const noQualificationsError =
    isAudienceInvalid &&
    validationErrors?.customCriterionErrors.some(
      (err) =>
        err === CustomCriterionValidationError.MissingCustomQualificationError
    )

  const handleDeleteCustomAudienceQuestion = useCallback(() => {
    dispatch(
      setAudienceMembers(
        audienceState.audienceMembers.filter(
          (member) => member.number !== audience?.number
        )
      )
    )
    detachCustomAudienceQuestion({
      variables: {
        surveyId,
        number: audience?.number || 0
      }
    })
    if (
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !audienceState?.audienceMembers
        .filter((a) => a.number !== audience?.number)
        .some((a) => a.memberType !== FieldworkAudienceType.Standard)
    ) {
      updateFieldwork({
        variables: { surveyId, incidenceRate: 100, panelSupplierCode: 'cint' }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleUpdateTitle = useCallback((newTitle: string) => {
    updateCustomCriterion({
      variables: {
        ...questionMutationVariable,
        text: newTitle
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddOption = useCallback(() => {
    addCustomCriterionOption({
      variables: {
        ...questionMutationVariable,
        position: customAudience.criterionOptions.length
      }
    })

    dispatch(setNewAudienceQuestionLk(customAudience.questionLk))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAudience.criterionOptions])

  const handleToggleSettingValue = useCallback(() => {
    const code = MemberSettingCode.Quotas
    const variables: SetCustomMemberSettingValueMutationVariables = {
      ...questionMutationVariable,
      setting: {
        memberSettingCode: code,
        settingValue: SettingValue.Disabled
      }
    }
    if (!getIsEnabledInSettings(customAudience.settingValues, code)) {
      variables.setting.settingValue = SettingValue.Enabled
    }

    setCustomMemberSettingValue({ variables })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAudience.settingValues])

  const handleSumQuotas = useCallback(async () => {
    dispatch(setNewAudienceQuestionLk(customAudience.questionLk))
    const quotaSum = customAudience.criterionOptions
      .filter((co) => co.qualification)
      .reduce<number>(
        (sum, criterionOption) =>
          sum +
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (criterionOption.quota ? criterionOption.quota.percent : 0),
        0
      )

    const toDistribute = 1 - quotaSum
    // get all options that are qualifying
    const qualifyingOptions = customAudience.criterionOptions.filter(
      (criterionOption) => criterionOption.qualification
    )
    const valueToAdd = toDistribute / qualifyingOptions.length

    Promise.all(
      qualifyingOptions.map(async (qualifyingOption) => {
        return batchSetCustomQuota({
          variables: {
            surveyId,
            questionLk: customAudience.questionLk,
            responseOptionLk: qualifyingOption.responseOptionLk,
            percent:
              toDistribute < 0
                ? (qualifyingOption.quota?.percent ?? 0) / quotaSum
                : (qualifyingOption.quota?.percent ?? 0) + valueToAdd
          }
        })
      })
    ).then(() => {
      refetchAudience({ surveyId })
      refetchFeasibilityEstimateData({
        surveyId,
        lengthOfInterview: loi
      })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAudience.criterionOptions, surveyId, loi])

  const handleSwitchQuestionType = useCallback(
    (isSingleChoice: boolean) => {
      if (isSingleChoice) {
        // set setting to single choice
        setCustomMemberSettingValue({
          variables: {
            surveyId,
            questionLk: customAudience.questionLk,
            setting: {
              memberSettingCode: MemberSettingCode.CustomAudienceChoice,
              settingValue: SettingValue.SingleChoice
            }
          }
        })
      } else {
        // set setting to multiple choice
        setCustomMemberSettingValue({
          variables: {
            surveyId,
            questionLk: customAudience.questionLk,
            setting: {
              memberSettingCode: MemberSettingCode.CustomAudienceChoice,
              settingValue: SettingValue.MultipleChoice
            }
          }
        })
        if (
          getIsEnabledInSettings(
            customAudience.settingValues,
            MemberSettingCode.Quotas
          )
        ) {
          setCustomMemberSettingValue({
            variables: {
              surveyId,
              questionLk: customAudience.questionLk,
              setting: {
                memberSettingCode: MemberSettingCode.Quotas,
                settingValue: SettingValue.Disabled
              }
            }
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customAudience.settingValues, customAudience.questionLk]
  )

  const handleLeavingCard = useCallback(() => {
    if (audienceState.newAudienceQuestionLk !== customAudience.questionLk) {
      dispatch(setNewAudienceQuestionLk(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceState.newAudienceQuestionLk, customAudience.questionLk])

  const handleOnOrderingChange = useCallback(
    async (ordering: ResponseOrderingOptions) => {
      switch (ordering) {
        case ResponseOrderingOptions.Random: {
          await setCustomMemberSettingValue({
            variables: {
              ...questionMutationVariable,
              setting: {
                memberSettingCode: MemberSettingCode.RandomiseOptions,
                settingValue: SettingValue.Enabled
              }
            }
          })

          LogAmplitudeEvent(EventType.RandomisedOrderForScreeningQuestion, {
            ...questionMutationVariable
          })

          break
        }
        default: {
          await setCustomMemberSettingValue({
            variables: {
              ...questionMutationVariable,
              setting: {
                memberSettingCode: MemberSettingCode.RandomiseOptions,
                settingValue: SettingValue.Disabled
              }
            }
          })

          break
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const isRandomiseOn = getIsEnabledInSettings(
    customAudience.settingValues,
    MemberSettingCode.RandomiseOptions
  )

  const handleScreeningQuestionLimitChanged = useCallback(
    (limit: number) => {
      setCustomMemberSettingValue({
        variables: {
          surveyId,
          questionLk,
          setting: {
            memberSettingCode: MemberSettingCode.ScreeningQuestionResponseLimit,
            settingValue: limit.toString()
          }
        }
      })
      LogAmplitudeEvent(EventType.SetScreeningQuestionResponseOptionsLimit, {
        surveyId,
        questionLk,
        settingValue: limit
      })
    },
    [questionLk, setCustomMemberSettingValue, surveyId]
  )

  const handleToggleOffScreeningQuestionResponseLimit = useCallback(() => {
    removeCustomMemberSettingValue({
      variables: {
        surveyId,
        questionLk,
        memberSettingCode: MemberSettingCode.ScreeningQuestionResponseLimit
      }
    })
    LogAmplitudeEvent(EventType.RemovedScreeningQuestionResponseOptionsLimit, {
      surveyId,
      questionLk
    })
  }, [questionLk, removeCustomMemberSettingValue, surveyId])

  const isHardDisqualification = getIsEnabledInSettings(
    (audience?.memberItem as DraftCustomAudience).settingValues,
    MemberSettingCode.HardDisqualification
  )

  const onIsHardDisqualificationChange = useCallback(
    (nextIsHardDisqualification: boolean) => {
      const settingValue = nextIsHardDisqualification
        ? SettingValue.Enabled
        : SettingValue.Disabled

      setCustomMemberSettingValue({
        variables: {
          surveyId,
          questionLk,
          setting: {
            memberSettingCode: MemberSettingCode.HardDisqualification,
            settingValue
          }
        }
      })
      LogAmplitudeEvent(EventType.SetScreeningQuestionResponseOptionsLimit, {
        surveyId,
        questionLk,
        settingValue
      })
    },
    [questionLk, setCustomMemberSettingValue, surveyId]
  )

  return (
    <AudienceCustomQuestionCardControl
      audience={audience}
      ordering={getOrdering(isRandomiseOn, false)}
      isAudienceInvalid={isAudienceInvalid}
      noQualificationsError={noQualificationsError}
      shouldTitleInputFocus={questionLk === audienceState.newAudienceQuestionLk}
      onUpdateTitle={handleUpdateTitle}
      onToggleQuotas={handleToggleSettingValue}
      onDelete={handleDeleteCustomAudienceQuestion}
      onAddOption={handleAddOption}
      onLeavingCard={handleLeavingCard}
      onSumQuotas={handleSumQuotas}
      onSwitchQuestionType={handleSwitchQuestionType}
      onOrderingChange={handleOnOrderingChange}
      onSetScreeningQuestionResponseLimit={handleScreeningQuestionLimitChanged}
      onToggleOffScreeningQuestionResponseLimit={
        handleToggleOffScreeningQuestionResponseLimit
      }
      isHardDisqualification={isHardDisqualification}
      onIsHardDisqualificationChange={onIsHardDisqualificationChange}
      isFdchatProject={isFdChat}
    />
  )
}

export default memo(CustomAudienceQuestionCardContainer)
