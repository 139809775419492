import {
  FetchResult,
  MutationFunctionOptions,
  useMutation
} from '@apollo/client'
import defer from 'lodash/defer'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import {
  AddEntryToSectionMutationVariables,
  AddForkMutationVariables,
  AddMatrixQuestionMutationVariables,
  AddQuestionMutationVariables,
  AddTextCardMutationVariables,
  SetQuestionScaleMutationVariables,
  SetQuestionnaireSettingValueMutationVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import {
  ADD_ENTRY_TO_SECTION,
  AddEntryToSectionData
} from '../../../data/gql/questionnaire/mutations/addEntryToSection'
import ADD_FORK, {
  AddForkData
} from '../../../data/gql/questionnaire/mutations/addFork'
import ADD_MATRIX_QUESTION, {
  AddMatrixQuestionData
} from '../../../data/gql/questionnaire/mutations/addMatrixQuestion'
import {
  ADD_QUESTION,
  AddQuestionData
} from '../../../data/gql/questionnaire/mutations/addQuestion'
import {
  ADD_TEXT_CARD,
  AddTextCardData
} from '../../../data/gql/questionnaire/mutations/addTextCard'
import {
  SET_QUESTIONNAIRE_SETTING_VALUE,
  SetQuestionnaireSettingValueData
} from '../../../data/gql/questionnaire/mutations/setQuesitionnaireSettingValue'
import { SET_QUESTION_SCALE } from '../../../data/gql/questionnaire/mutations/setQuestionScale'
import { draftQuestionnaireRefetchQuery } from '../../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../../data/logger'
import {
  DraftForkItem,
  DraftTextCardItem,
  QuestionSettingCode,
  QuestionTypeCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { useProjectId } from '../../../hooks/useProjectId'
import { questionNewlyCreatedNumber } from '../../../hooks/useResetNewlyCreatedEntry'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { newEntryId } from '../../../utils/questionnaireUtils'
import { scrollToNewInBetweenQuestionCard } from './AddQuestionMenu.utils'

interface AddQuestionMenuMutationsData {
  addMatrixQuestion: (
    options?: MutationFunctionOptions<
      AddMatrixQuestionData,
      AddMatrixQuestionMutationVariables
    >
  ) => Promise<FetchResult<AddMatrixQuestionData>>
  setQuestionnaireSettingValue: (
    options?: MutationFunctionOptions<
      SetQuestionnaireSettingValueData,
      SetQuestionnaireSettingValueMutationVariables
    >
  ) => Promise<FetchResult<SetQuestionnaireSettingValueData>>
  setQuestionScale: (
    options?: MutationFunctionOptions<void, SetQuestionScaleMutationVariables>
  ) => Promise<FetchResult<void>>
  addQuestion: (
    options?: MutationFunctionOptions<
      AddQuestionData,
      AddQuestionMutationVariables
    >
  ) => Promise<FetchResult<AddQuestionData>>
  addEntryToSection: (
    options?: MutationFunctionOptions<
      AddEntryToSectionData,
      AddEntryToSectionMutationVariables
    >
  ) => Promise<FetchResult<AddEntryToSectionData>>
  addTextCard: (
    options?: MutationFunctionOptions<
      AddTextCardData,
      AddTextCardMutationVariables
    >
  ) => Promise<FetchResult<AddTextCardData>>
  addFork: (
    options?: MutationFunctionOptions<AddForkData, AddForkMutationVariables>
  ) => Promise<FetchResult<AddForkData>>
}

const useAddQuestionMenuHook = (
  questionnaireId: string
): AddQuestionMenuMutationsData => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [addMatrixQuestion] = useMutation<
    AddMatrixQuestionData,
    AddMatrixQuestionMutationVariables
  >(ADD_MATRIX_QUESTION, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onCompleted: ({ addMatrixQuestion }) => {
      questionBeingEditedNumber(undefined)
      questionNewlyCreatedNumber(addMatrixQuestion.number)
      newEntryId(addMatrixQuestion.entryItem.matrixTitleLk)
      scrollToNewInBetweenQuestionCard(addMatrixQuestion.number)
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addMatrixQuestion',
        error
      )
    }
  })

  const [setQuestionnaireSettingValue] = useMutation<
    SetQuestionnaireSettingValueData,
    SetQuestionnaireSettingValueMutationVariables
  >(SET_QUESTIONNAIRE_SETTING_VALUE, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setQuestionnaireSettingValue',
        error
      )
    }
  })

  const [setQuestionScale] = useMutation<
    void,
    SetQuestionScaleMutationVariables
  >(SET_QUESTION_SCALE, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setQuestionScale',
        error
      )
    }
  })

  const [addQuestion] = useMutation<
    AddQuestionData,
    AddQuestionMutationVariables
  >(ADD_QUESTION, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onCompleted: ({ addQuestion }) => {
      if (
        addQuestion.entryItem.questionTypeCode === QuestionTypeCode.FreeText
      ) {
        setQuestionnaireSettingValue({
          variables: {
            questionnaireId,
            questionLk: addQuestion.entryItem.questionLk,
            setting: {
              questionSettingCode: QuestionSettingCode.CharacterLimit,
              settingValue: SettingValue.TwoHundred
            }
          }
        })
      }
      questionBeingEditedNumber(undefined)
      questionNewlyCreatedNumber(addQuestion.number)
      newEntryId(addQuestion.entryItem.questionLk)
      defer(() => {
        scrollToNewInBetweenQuestionCard(addQuestion.number)
      })
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'addQuestion', error)
    }
  })

  const [addEntryToSection] = useMutation<
    AddEntryToSectionData,
    AddEntryToSectionMutationVariables
  >(ADD_ENTRY_TO_SECTION, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addEntryToSection',
        error
      )
    }
  })

  const [addTextCard] = useMutation<
    AddTextCardData,
    AddTextCardMutationVariables
  >(ADD_TEXT_CARD, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onCompleted: ({ addTextCard }) => {
      questionBeingEditedNumber(undefined)
      questionNewlyCreatedNumber(addTextCard.number)
      newEntryId((addTextCard.entryItem as DraftTextCardItem).textCardLk)
      scrollToNewInBetweenQuestionCard(addTextCard.number)
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'addTextCard', error)
    }
  })

  const [addFork] = useMutation<AddForkData, AddForkMutationVariables>(
    ADD_FORK,
    {
      context: { clientName: 'questionnaire' },
      refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
      onCompleted: ({ addFork }) => {
        questionBeingEditedNumber(undefined)
        questionNewlyCreatedNumber(addFork.number)
        newEntryId((addFork.entryItem as DraftForkItem).fork.forkId)
        scrollToNewInBetweenQuestionCard(addFork.number)
      },
      onError: (error) => {
        captureApolloError(LoggerErrorType.ApolloMutation, 'addFork', error)
      }
    }
  )

  return {
    addMatrixQuestion,
    setQuestionnaireSettingValue,
    setQuestionScale,
    addQuestion,
    addEntryToSection,
    addTextCard,
    addFork
  }
}

export default useAddQuestionMenuHook
