import {
  Checkbox,
  Grid,
  IconButton,
  IconColor,
  IconName,
  IconSize,
  Select,
  SelectOption,
  SelectVariant,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { useAppSelector } from '../../../App.store'
import {
  DraftEntryResponseOption,
  DraftLogicClauseProposition,
  DraftMatrixResponseOption,
  DraftQuestionResponseOption,
  DraftQuestionnaireEntry,
  EntryType,
  LogicPropositionType,
  QuestionKind
} from '../../../data/model/questionnaire'
import {
  composeSelectedQuestionLk,
  getIdsFromSelectedQuestionLk
} from '../../../utils/HelperFunctions'
import { ClauseBlock } from '../../../utils/questionLogic'
import {
  checkIfMultipleChoice,
  checkIfSingleChoice,
  truncateText
} from '../../../utils/questionnaireUtils'
import { MaskingRuleNegation } from '../Masking/MaskingButton'
import { selectResponseOptionsByQuestion } from '../Questionnaire.slice'
import useStyles from './DialogAddDisplayLogic.styles'

export interface DisplayLogicForkTag {
  forkId: string
  branchNumbers: number[]
}

interface Props {
  entries: DraftQuestionnaireEntry[] | undefined
  entry: DraftQuestionnaireEntry | undefined
  clauseId?: string
  clauseBlock?: ClauseBlock
  showForks?: boolean
  displayLogicForkTags?: DisplayLogicForkTag
  negated: boolean
  onDelete: (clauseId: string, clauseNumber: number | undefined) => void
  onChangeResponseOptions?: (
    clauseId: string,
    checkedResponseLks: string[],
    questionLk: string | undefined,
    matrixTitleLk?: string
  ) => void
  onSelectedQuestionChange?: (
    clauseId: string,
    entry: DraftQuestionnaireEntry | undefined,
    questionLk: string,
    matrixTitleLk?: string
  ) => void
  onChangeForkBranches?: (forkId: string, checkedBranches: number[]) => void
  onSelectedForkChange?: (forkId: string) => void
  onChangeNegation?: (clauseId: string, negated: boolean) => void
}

const ClauseBlockControl: React.FC<Props> = (props: Props) => {
  const {
    entries,
    entry,
    clauseId,
    clauseBlock,
    showForks = false,
    displayLogicForkTags,
    negated = false,
    onChangeResponseOptions,
    onDelete,
    onSelectedQuestionChange,
    onChangeForkBranches,
    onSelectedForkChange,
    onChangeNegation
  } = props
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const getProposition: (
    proposition: DraftLogicClauseProposition
  ) => DraftMatrixResponseOption | DraftQuestionResponseOption = (
    proposition
  ) => {
    switch (proposition.propositionType) {
      case LogicPropositionType.MatrixResponseOptionType:
        return proposition.proposition as DraftMatrixResponseOption
      default:
        return proposition.proposition as DraftQuestionResponseOption
    }
  }
  const getDefaultCheckedResponseOptionLks: () => string[] | undefined = () => {
    if (clauseBlock?.clause) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return clauseBlock?.clause.map((proposition) => {
        return getProposition(proposition).responseOptionLk
      })
    }
    if (clauseBlock?.propositionResponseOptionLks) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return clauseBlock?.propositionResponseOptionLks
    }

    return undefined
  }

  const getDefaultSelectedQuestionId: () => string | undefined = () => {
    if (clauseBlock?.clause) {
      // if questionLk and matrixtitlelk is not empty, set them both. If not, just one
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const proposition = clauseBlock?.clause[0]
        .proposition as DraftMatrixResponseOption
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (proposition.matrixTitleLk !== undefined) {
        return composeSelectedQuestionLk(
          proposition.questionLk,
          proposition.matrixTitleLk
        )
      }
      return proposition.questionLk
    }
    if (clauseBlock?.questionLk) {
      if (clauseBlock.matrixTitleLk !== undefined) {
        return composeSelectedQuestionLk(
          clauseBlock.questionLk,
          clauseBlock.matrixTitleLk
        )
      }
      return clauseBlock.questionLk
    }
    if (displayLogicForkTags) {
      return displayLogicForkTags.forkId
    }

    return undefined
  }

  const getDefaultSelectedForkBranches: () => number[] = () => {
    if (displayLogicForkTags) {
      return displayLogicForkTags.branchNumbers
    }

    return []
  }

  const [checkedResponseLks, setCheckedResponseLks] = useState<
    string[] | undefined
  >(getDefaultCheckedResponseOptionLks())
  const [selectedQuestionLk, setSelectedQuestionLk] = useState<
    string | undefined
  >(getDefaultSelectedQuestionId())
  const [checkedForkBranchNumbers, setCheckedForkBranchNumbers] = useState<
    number[]
  >(getDefaultSelectedForkBranches())

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const filteredEntries = entries?.reduce<DraftQuestionnaireEntry[]>(
    (acc, filteredEntry) => {
      const isPreviousQuestion =
        entry?.position !== undefined
          ? // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            filteredEntry.position < entry?.position ||
            filteredEntry.questionKind === QuestionKind.AudienceKind
          : false
      if (
        filteredEntry.entryType === EntryType.QuestionEntryType &&
        isPreviousQuestion
      ) {
        const entryItem = filteredEntry.entryItem
        if (
          checkIfSingleChoice(entryItem) ||
          checkIfMultipleChoice(entryItem.settingValues) ||
          filteredEntry.questionKind === QuestionKind.AudienceKind
        ) {
          acc.push(filteredEntry)
        }
      }
      if (
        filteredEntry.entryType === EntryType.MatrixEntryType &&
        isPreviousQuestion
      ) {
        acc.push(filteredEntry)
      }
      if (
        filteredEntry.entryType === EntryType.ForkEntryType &&
        isPreviousQuestion
      ) {
        acc.push(filteredEntry)
      }
      return acc
    },
    []
  )

  const options =
    filteredEntries?.reduce<SelectOption[]>((acc, filteredEntry) => {
      if (filteredEntry.entryType === EntryType.QuestionEntryType) {
        const entryItem = filteredEntry.entryItem
        const prefix =
          filteredEntry.questionKind === QuestionKind.QuestionnaireKind
            ? 'Q'
            : 'A'
        const item = {
          id: entryItem.questionLk,
          name: `${prefix}${filteredEntry.contextPosition + 1} ${truncateText(
            150,
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            entryItem.question?.text
          )}`,
          value: entryItem.questionLk
        }
        acc.push(item)
      }
      if (filteredEntry.entryType === EntryType.MatrixEntryType) {
        const entryItem = filteredEntry.entryItem
        entryItem.matrixRows.forEach((row, index) => {
          const item = {
            id: composeSelectedQuestionLk(
              row.questionLk,
              entryItem.matrixTitleLk
            ),
            name: `Q${filteredEntry.contextPosition + 1}.${
              index + 1
            } ${truncateText(
              150,
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              row.question?.text
            )}`,
            value: composeSelectedQuestionLk(
              row.questionLk,
              entryItem.matrixTitleLk
            )
          }
          acc.push(item)
        })
      }
      if (filteredEntry.entryType === EntryType.ForkEntryType && showForks) {
        const { fork } = filteredEntry.entryItem
        const item = {
          id: `A/B ${fork.forkId}`,
          name: fork.name,
          value: fork.forkId
        }
        acc.push(item)
      }
      return acc
    }, []) || []

  const handleCheckboxChange: (
    responseOptionLk: string,
    checked: boolean
  ) => void = (responseOptionLk, checked) => {
    const [questionLk, matrixTitleLk] = getIdsFromSelectedQuestionLk(
      selectedQuestionLk || ''
    )
    if (checked) {
      const newCheckeResponseLks = checkedResponseLks?.slice() || []
      newCheckeResponseLks.push(responseOptionLk)
      setCheckedResponseLks(newCheckeResponseLks)
      if (onChangeResponseOptions) {
        onChangeResponseOptions(
          clauseId || '',
          newCheckeResponseLks,
          questionLk,
          matrixTitleLk
        )
      }
    } else {
      const newCheckeResponseLks = checkedResponseLks?.slice() || []
      const filteredResponsLks = newCheckeResponseLks.filter(
        (lk) => lk !== responseOptionLk
      )
      setCheckedResponseLks(filteredResponsLks)
      if (onChangeResponseOptions) {
        onChangeResponseOptions(
          clauseId || '',
          filteredResponsLks,
          questionLk,
          matrixTitleLk
        )
      }
    }
  }

  const handleChangeForkBranch: (
    branchNumber: number,
    checked: boolean
  ) => void = (branchNumber, checked) => {
    if (checked) {
      const newCheckedBranches = [...checkedForkBranchNumbers, branchNumber]
      setCheckedForkBranchNumbers(newCheckedBranches)
      if (onChangeForkBranches && displayLogicForkTags) {
        onChangeForkBranches(displayLogicForkTags.forkId, newCheckedBranches)
      }
    } else {
      const newCheckedBranches = checkedForkBranchNumbers.filter(
        (cfbn) => cfbn !== branchNumber
      )
      setCheckedForkBranchNumbers(newCheckedBranches)
      if (onChangeForkBranches && displayLogicForkTags) {
        onChangeForkBranches(displayLogicForkTags.forkId, newCheckedBranches)
      }
    }
  }

  const getSelectedEntry: (
    selectedQuestionLk: string | undefined,
    filteredEntries: DraftQuestionnaireEntry[] | undefined
  ) => DraftQuestionnaireEntry | undefined = (
    selectedQuestionLk,
    filteredEntries
  ) => {
    return filteredEntries?.find((filteredEntry: DraftQuestionnaireEntry) => {
      if (
        filteredEntry.entryType === EntryType.QuestionEntryType &&
        filteredEntry.entryItem.questionLk === selectedQuestionLk
      ) {
        return filteredEntry
      }
      if (
        filteredEntry.entryType === EntryType.MatrixEntryType &&
        selectedQuestionLk
      ) {
        const [questionLk, matrixTitleLk] =
          getIdsFromSelectedQuestionLk(selectedQuestionLk)
        const matrixEntry = filteredEntry.entryItem
        const selectedQuestionIsMatrixRow =
          matrixEntry.matrixTitleLk === matrixTitleLk &&
          filteredEntry.entryItem.matrixRows.some(
            (row) => row.questionLk === questionLk
          )
        return selectedQuestionIsMatrixRow ? filteredEntry : undefined
      }
      if (
        filteredEntry.entryType === EntryType.ForkEntryType &&
        filteredEntry.entryItem.fork.forkId === selectedQuestionLk
      ) {
        return filteredEntry
      }

      return undefined
    })
  }

  const selectedEntry = getSelectedEntry(selectedQuestionLk, filteredEntries)
  const canNegate = selectedEntry?.entryType !== EntryType.ForkEntryType
  const negationOptions: SelectOption[] = [
    {
      id: '0',
      name: 'is one of',
      value: MaskingRuleNegation.IS_ONE_OF
    },
    {
      id: '1',
      name: 'is not one of',
      value: MaskingRuleNegation.IS_NOT_ONE_OF
    }
  ]

  const negationOption = negated
    ? MaskingRuleNegation.IS_NOT_ONE_OF
    : MaskingRuleNegation.IS_ONE_OF

  const handleQuestionChange: (selectedQuestionLk: string) => void = (
    selectedQuestionLk
  ) => {
    const selectedEntry = getSelectedEntry(selectedQuestionLk, filteredEntries)
    setSelectedQuestionLk(selectedQuestionLk)
    if (selectedEntry?.entryType === EntryType.ForkEntryType) {
      if (onSelectedForkChange) onSelectedForkChange(selectedQuestionLk)
    } else {
      if (onChangeResponseOptions)
        onChangeResponseOptions(clauseId || '', [], undefined)
      setCheckedResponseLks([])
      if (onSelectedQuestionChange) {
        const [questionLk, matrixTitleLk] =
          getIdsFromSelectedQuestionLk(selectedQuestionLk)
        onSelectedQuestionChange(
          clauseId || '',
          selectedEntry,
          questionLk,
          matrixTitleLk
        )
      }
    }
  }

  const getCheckBoxes: (
    selectedEntry: DraftQuestionnaireEntry | undefined,
    checkedResponseLks: string[] | undefined
  ) => JSX.Element[] | undefined = (selectedEntry, checkedResponseLks) => {
    if (selectedEntry?.entryType === EntryType.QuestionEntryType) {
      const entryItem = selectedEntry.entryItem
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return responseOptionsByQuestion[entryItem.questionLk]?.map(
        (responseOption: DraftEntryResponseOption) => {
          return (
            <Checkbox
              key={responseOption.responseOptionLk}
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              label={responseOption.responseOption?.value}
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              ariaLabel={`Display logic option - ${responseOption.responseOption?.value}`}
              defaultChecked={checkedResponseLks?.includes(
                responseOption.responseOptionLk
              )}
              onChange={(event) => {
                handleCheckboxChange(
                  responseOption.responseOptionLk,
                  event.target.checked
                )
              }}
            />
          )
        }
      )
    }
    if (selectedEntry?.entryType === EntryType.MatrixEntryType) {
      const entryItem = selectedEntry.entryItem
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return responseOptionsByQuestion[entryItem.matrixTitleLk]?.map(
        (responseOption: DraftEntryResponseOption) => {
          return (
            <Checkbox
              key={responseOption.responseOptionLk}
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              label={responseOption.responseOption?.value}
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              ariaLabel={`Display logic option - ${responseOption.responseOption?.value}`}
              defaultChecked={checkedResponseLks?.includes(
                responseOption.responseOptionLk
              )}
              onChange={(event) => {
                handleCheckboxChange(
                  responseOption.responseOptionLk,
                  event.target.checked
                )
              }}
            />
          )
        }
      )
    }
    if (selectedEntry?.entryType === EntryType.ForkEntryType) {
      return selectedEntry.entryItem.fork.branches.map((branch) => (
        <Checkbox
          key={branch.branchNumber}
          label={branch.label}
          ariaLabel={`Fork branch - ${branch.label}`}
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          defaultChecked={checkedForkBranchNumbers?.includes(
            branch.branchNumber
          )}
          onChange={(event) => {
            if (selectedQuestionLk && onChangeForkBranches) {
              handleChangeForkBranch(branch.branchNumber, event.target.checked)
            }
          }}
        />
      ))
    }

    return undefined
  }

  const handleNegationChange: (
    selectedLogicalComplement: MaskingRuleNegation
  ) => void = (selectedLogicalComplement) => {
    if (onChangeNegation) {
      const negated =
        selectedLogicalComplement === MaskingRuleNegation.IS_NOT_ONE_OF

      onChangeNegation(clauseId || '', negated)
    }
  }

  const selectedQuestionId = getDefaultSelectedQuestionId()

  const hasError =
    !!selectedQuestionId && !options.some((o) => o.value === selectedQuestionId)

  return (
    <Grid container className={classes.container}>
      <Grid container justifyContent="flex-end">
        <Grid item xs="auto">
          <IconButton
            ariaLabel="Delete logic statement"
            iconName={IconName.DeleteOutline}
            iconSize={IconSize.Medium}
            iconColor={IconColor.Emphasis}
            onClick={() => {
              const proposition = clauseBlock?.clause?.find(
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (clause) => clause.propositionRef.clauseNumber !== undefined
              )
              onDelete(clauseId || '', proposition?.propositionRef.clauseNumber)
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid item container>
          <Select
            ariaLabel="Display logic - Question selector"
            placeholder="Please select…"
            variant={SelectVariant.Filled}
            optionValue={selectedQuestionLk}
            maxMenuHeight={300}
            defaultOptionValue={getDefaultSelectedQuestionId()}
            options={options}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleQuestionChange(event.target.value)
            }}
            fullWidth
            hasError={hasError}
          />
        </Grid>
      </Grid>
      {selectedQuestionLk && (
        <Grid
          item
          xs={canNegate ? 3 : 'auto'}
          className={classes.logicContainer}
        >
          {canNegate ? (
            <Grid item container>
              <Select
                ariaLabel="Masking logic - negation"
                placeholder="Please select…"
                variant={SelectVariant.Filled}
                defaultOptionValue={negationOption}
                maxMenuHeight={100}
                options={negationOptions}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleNegationChange(
                    event.target.value as MaskingRuleNegation
                  )
                }}
                minWidth={155}
              />
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.logicText}>
              {selectedQuestionLk && (
                <Grid className={classes.responseLogicText}>
                  <p
                    className={classNames(
                      textClasses.default,
                      textClasses.highlightBackground,
                      textClasses.sizeMs
                    )}
                  >
                    is one of
                  </p>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={5} className={classes.responseOptionsContainer}>
        <Grid
          container
          direction="column"
          key={`${clauseId}${displayLogicForkTags?.forkId}-${checkedResponseLks?.length}`}
        >
          {getCheckBoxes(selectedEntry, checkedResponseLks)?.map((checkBox) => {
            return checkBox
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ClauseBlockControl
