import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ErrorPage, { ErrorType } from '../../controls/ErrorPage'
import ProjectList from '../../modules/Project'
import { APP_MIN_WIDTH_PX } from '../../utils/appConstants'
import FdChat from './FdChat/FdChat'
import FdChatFindings from './FdChat/ResultsPage/Findings/FdChatFindings'
import FdChatQuestionInsight from './FdChat/ResultsPage/Findings/FdChatQuestionInsight'
import { MultiMarketRoutes } from './MultiMarket/MultiMarketRoutes'
import { RedirectToProjectPath } from './RedirectToProjectPath'
import Survey from './Survey'
import SideMenu from './Survey/SideMenu/SideMenu'

const Page: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <div
      style={{
        minWidth: !pathname.startsWith('/fdchat') ? APP_MIN_WIDTH_PX : undefined
      }}
    >
      <Grid display="flex">
        <Grid item xs="auto" display="flex">
          <SideMenu />
        </Grid>
        <Grid item xs>
          <Routes>
            <Route
              path="/project/:projectId/*"
              element={<MultiMarketRoutes />}
            />
            <Route path="/projects" element={<ProjectList />} />
            <Route
              path="project/:projectId/survey/:surveyId/*"
              element={<Survey />}
            />
            <Route
              path="survey/:surveyId/*"
              element={<RedirectToProjectPath />}
            />
            <Route path="fdchat/sample-findings-report">
              <Route index element={<FdChatFindings />} />
              <Route path=":questionId" element={<FdChatQuestionInsight />} />
            </Route>
            <Route
              path="project/:projectId/fdchat/:surveyId/*"
              element={<FdChat />}
            />
            <Route
              path="fdchat/:surveyId/*"
              element={<RedirectToProjectPath />}
            />
            <Route path="/" element={<Navigate to="/projects" replace />} />
            <Route
              path="*"
              element={<ErrorPage errorType={ErrorType.NotFound404} />}
            />
          </Routes>
        </Grid>
      </Grid>
    </div>
  )
}

export default Page
