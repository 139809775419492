import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface AddTextCardData {
  addTextCard: DraftQuestionnaireEntry
}

export const ADD_TEXT_CARD = gql`
  mutation addTextCard(
    $questionnaireId: ID!
    $position: Int!
    $title: String!
    $subtitle: String!
    $body: String!
    $sectionId: ID
  ) {
    addTextCard(
      questionnaireId: $questionnaireId
      position: $position
      title: $title
      subtitle: $subtitle
      body: $body
      sectionId: $sectionId
    ) {
      number
      position
      contextPosition
      createdDate
      sinceDate
      entryType
      entryItem {
        __typename
        ... on DraftTextCardItem {
          textCardLk
          textCard {
            textCardId
            title
            subtitle
            body
          }
          __typename
        }
      }
      __typename
    }
  }
`
