import { DraftMatrixRow } from '../../../data/gql-gen/questionnaire/graphql'
import {
  DraftEntryResponseOption,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionTypeCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { Option, RawQuestionnaireEntry } from '../../../hooks/import/models'
import {
  checkIfFreeText,
  checkIfMatrixMultipleChoice,
  checkIfMultipleChoice,
  checkIfRankedChoice,
  checkIfScale
} from '../../../utils/questionnaireUtils'

export const getQuestionTypeText = (
  entry?: DraftQuestionnaireEntry
): string => {
  switch (entry?.entryType) {
    case EntryType.MatrixEntryType: {
      const matrix = entry.entryItem
      const isMultipleChoice = checkIfMatrixMultipleChoice(matrix.settingValues)
      return isMultipleChoice ? 'Matrix multi select' : 'Matrix single select'
    }
    case EntryType.QuestionEntryType: {
      const question = entry.entryItem as DraftQuestionItem
      if (checkIfMultipleChoice(question.settingValues)) {
        return 'Multi select'
      }
      if (checkIfFreeText(question)) {
        return 'Text input'
      }
      if (checkIfScale(question)) {
        return 'Slider'
      }
      if (checkIfRankedChoice(question)) {
        return 'Ranked'
      }
      return 'Single select'
    }
    case EntryType.TextCardEntryType:
      return 'Text instruction'
    default:
      return ''
  }
}

export const getQuestionTypeTextFromRaw = (
  rawEntry?: RawQuestionnaireEntry
): string => {
  switch (rawEntry?.questionType) {
    case QuestionTypeCode.Matrix: {
      const isMultipleChoice =
        rawEntry.setting?.settingValue === SettingValue.MultipleChoice
      return isMultipleChoice ? 'Matrix multi select' : 'Matrix single select'
    }
    case QuestionTypeCode.Basic: {
      const isMultipleChoice =
        rawEntry.setting?.settingValue === SettingValue.MultipleChoice
      if (isMultipleChoice) {
        return 'Multi select'
      }
      return 'Single select'
    }
    case QuestionTypeCode.Ranked:
      return 'Ranked'
    case QuestionTypeCode.Scale:
      return 'Slider'
    case QuestionTypeCode.FreeText:
      return 'Text input'
    case QuestionTypeCode.TextCard:
      return 'Text instruction'
    default:
      return ''
  }
}

export interface MergedItem<T, R> {
  item1?: T
  item2?: R
}

export const getOptionsFromEntry = (
  entry?: DraftQuestionnaireEntry
): DraftEntryResponseOption[] => {
  const entryItem = entry?.entryItem as DraftQuestionItem
  if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem?.questionTypeCode === QuestionTypeCode.Basic ||
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem?.questionTypeCode === QuestionTypeCode.Ranked || // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem?.questionTypeCode === QuestionTypeCode.MaxDiff
  ) {
    return entryItem.responseOptions
  }
  if (entry?.entryType === EntryType.MatrixEntryType) {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const matrixQuestionEntryItem = entry?.entryItem

    return matrixQuestionEntryItem.responseOptions
  }

  return []
}

export const getRowsFromEntry = (
  entry?: DraftQuestionnaireEntry
): DraftMatrixRow[] => {
  if (entry?.entryType === EntryType.MatrixEntryType) {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const matrixQuestionEntryItem = entry?.entryItem

    return matrixQuestionEntryItem.matrixRows
  }

  return []
}

export const getMergedLists = <T, R>(
  list1: T[],
  list2: R[]
): MergedItem<T, R>[] => {
  const mergedList: MergedItem<T, R>[] = []
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const longestListLength = Math.max(list1?.length || 0, list2?.length)
  for (let i = 0; i < longestListLength; i += 1) {
    mergedList.push({
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      item1: list1 && list1[i],
      item2: list2[i]
    })
  }
  return mergedList
}

export const setOptionsCheckAndPosition = <T>(
  pairOptions: T[],
  options: Option[],
  responsePairs: GenericPair<string, string>[],
  findPairPosition: (pairOptions: T[], pairId: string) => number | undefined,
  isQuestionnaireEmpty = false
): Option[] => {
  return options.reduce((acc, option) => {
    const pairId = responsePairs.find((p) => p.item2 === option.id)?.item1
    const currentPairIndex = responsePairs.findIndex(
      (p) => p.item2 === option.id
    )
    if (pairId) {
      const pairPosition = findPairPosition(pairOptions, pairId)

      acc.push({
        ...option,
        position: pairPosition,
        isChecked: pairPosition !== undefined
      })
      return acc
    }
    const getPosition = () => {
      if (isQuestionnaireEmpty) {
        return undefined
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const previousPair = responsePairs?.[currentPairIndex - 1]
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (previousPair?.item2) {
        return (
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (acc?.find((a) => a.id === previousPair.item2)?.position as number) +
          1
        )
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (previousPair?.item1) {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const pairPosition = findPairPosition(pairOptions, previousPair?.item1)
        return pairPosition !== undefined ? pairPosition + 1 : undefined
      }
      return 0
    }
    const position = getPosition()
    acc.push({
      ...option,
      position,
      isChecked: isQuestionnaireEmpty,
      isNew: isQuestionnaireEmpty
    })
    return acc
  }, [] as Option[])
}

export const findResponsePairPosition = (
  pairOptions: DraftEntryResponseOption[],
  pairId: string
) => {
  return pairOptions.find((o) => o.responseOptionLk === pairId)?.position
}

export const findRowPairPosition = (
  pairOptions: DraftMatrixRow[],
  pairId: string
) => {
  return pairOptions.find((o) => o.questionLk === pairId)?.position
}

export const setCheckedAndPositions = (
  pairs: Pair[],
  shiftStartIndex = 0,
  isQuestionnaireEmpty = false
): Pair[] => {
  return pairs.reduce((acc, pair, index) => {
    if (index < shiftStartIndex) {
      acc.push(pair)
      return acc
    }
    const { item1, item2 } = pair
    const entryOptions = getOptionsFromEntry(item1)
    const entryRows = getRowsFromEntry(item1)
    const newResponsePairs = getMergedLists(
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      entryOptions?.map((option) => option.responseOptionLk),
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      item2?.options?.map((option) => option.id) as string[]
    )
    const newRowPairs = getMergedLists(
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      entryRows?.map((row) => row.questionLk),
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      item2?.rows?.map((row) => row.id) as string[]
    )

    const item2Options = setOptionsCheckAndPosition(
      entryOptions,
      item2?.options || [],
      newResponsePairs,
      findResponsePairPosition,
      isQuestionnaireEmpty
    )

    const item2Rows = setOptionsCheckAndPosition(
      entryRows,
      item2?.rows || [],
      newRowPairs,
      findRowPairPosition,
      isQuestionnaireEmpty
    )

    const getPosition = () => {
      if (isQuestionnaireEmpty) {
        return undefined
      }
      const previousPair = acc[index - 1]
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (previousPair?.item2) {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return (previousPair?.item2.position as number) + 1
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (previousPair?.item1) {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return (previousPair?.item1?.position as number) + 1
      }
      return 0
    }
    const position = getPosition()

    const newItem2 = {
      ...item2,
      isChecked: isQuestionnaireEmpty || item1?.position !== undefined,
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      position: item1?.position !== undefined ? item1?.position : position,
      options: item2Options,
      rows: item2Rows,
      isNew: isQuestionnaireEmpty
    } as RawQuestionnaireEntry

    acc.push({
      item1,
      item2: item2 ? newItem2 : undefined,
      responsePairs: newResponsePairs,
      rowPairs: newRowPairs
    })
    return acc
  }, [] as Pair[])
}

export const getMergedPairs = (
  list1: DraftQuestionnaireEntry[],
  list2: RawQuestionnaireEntry[]
): Pair[] => {
  const mergedList: Pair[] = []
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const longestListLength = Math.max(list1?.length || 0, list2.length)
  for (let i = 0; i < longestListLength; i += 1) {
    const responsePairs = getMergedLists(
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      getOptionsFromEntry(list1?.[i])?.map((option) => option.responseOptionLk),
      list2[i]?.options?.map((option) => option.id)
    )

    const rowPairs = getMergedLists(
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      getRowsFromEntry(list1?.[i])?.map((option) => option.questionLk),
      list2[i]?.rows?.map((row) => row.id)
    )

    mergedList.push({
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      item1: list1 && list1[i],
      item2: list2[i],
      responsePairs,
      rowPairs
    })
  }
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return setCheckedAndPositions(mergedList, 0, list1?.length === 0)
}

export interface Pair {
  item1?: DraftQuestionnaireEntry
  item2?: RawQuestionnaireEntry
  responsePairs?: GenericPair<string, string>[]
  rowPairs?: GenericPair<string, string>[]
}

export interface GenericPair<T, R> {
  item1?: T
  item2?: R
}
