import { useMutation } from '@apollo/client'
import { SetExclusiveResponseOptionMutationVariables } from '../../../data/gql-gen/fieldwork/graphql'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  SET_EXCLUSIVE_CUSTOM_AUDIENCE_RESPONSE_OPTION,
  SetExclusiveCustomAudienceResponseOptionData
} from './SetExclusiveResponses.mutations'

const useSetExclusiveCustomAudienceResponseOptionMutations = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [setExclusiveResponseOption] = useMutation<
    SetExclusiveCustomAudienceResponseOptionData,
    SetExclusiveResponseOptionMutationVariables
  >(SET_EXCLUSIVE_CUSTOM_AUDIENCE_RESPONSE_OPTION, {
    context: { clientName: 'fieldwork' },
    fetchPolicy: 'no-cache',
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
  })

  const setExclusiveCustomAudienceResponseOption = (
    questionLk: string,
    responseOptionLk: string,
    exclusive: boolean
  ) => {
    setExclusiveResponseOption({
      variables: {
        surveyId,
        questionLk,
        responseOptionLk,
        exclusive
      }
    })
  }

  return setExclusiveCustomAudienceResponseOption
}

export default useSetExclusiveCustomAudienceResponseOptionMutations
