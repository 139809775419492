import {
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { useCallback, useContext } from 'react'
import { useAppDispatch } from '../../../App.store'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { useDeleteSection } from '../../../components/Section/Section.hooks'
import { DraftSectionItem } from '../../../components/Section/Section.model'
import {
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { useSetSectionSettingValue } from '../../../hooks/questionnaire/useSetSectionSettingValue.mutations'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { isSettingEnabled } from '../../../utils/questionnaireUtils'
import { openLoopingDialog } from '../Looping/Looping.slice'
import { LoopingActionsMenu } from '../Looping/LoopingActionsMenu'
import QuestionnaireContext, {
  openDeleteSectionDialog,
  setSectionEntryNumberToBeDeleted
} from '../Questionnaire.context'
import RandomizationActionMenu from './RandomisationActionMenu.container'
import useStyles from './SectionCardHeader.styles'

enum SurveyQuestionHeaderActionsContainerOptions {
  RANDOMISE_QUESTION_ORDER,
  ADD_LOOPING,
  DELETE_SECTION
}

const SectionCardHeaderContainer = ({
  sectionEntryItem,
  entryNumber,
  contextPosition
}: {
  sectionEntryItem: DraftSectionItem
  entryNumber: number
  contextPosition: number
}) => {
  const randomisationEnabled = isSettingEnabled(
    sectionEntryItem.settingValues,
    QuestionSettingCode.SectionRandomiseQuestions
  )
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const sectionHasEntries = sectionEntryItem.entries?.length > 0

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const contextPositionValue = (contextPosition ?? 0) + 1

  const { deleteSectionWithoutQuestions } = useDeleteSection(entryNumber)

  const surveyId = useSurveyId()

  const { dispatch: dispatchToContext } = useContext(QuestionnaireContext)
  const dispatch = useAppDispatch()

  const setSectionSettingValue = useSetSectionSettingValue(
    sectionEntryItem.sectionId
  )

  const handleSetRandomisation = useCallback(() => {
    setSectionSettingValue(
      QuestionSettingCode.SectionRandomiseQuestions,
      SettingValue.Enabled
    ).then(() => {
      LogAmplitudeEvent(EventType.SectionQuestionsRandomisation, {
        surveyId,
        sectionId: sectionEntryItem.sectionId,
        enabled: true
      })
    })
  }, [sectionEntryItem.sectionId, setSectionSettingValue, surveyId])

  const handleOpenDeleteSection = () => {
    dispatchToContext(setSectionEntryNumberToBeDeleted(entryNumber))
    dispatchToContext(openDeleteSectionDialog())
  }

  const questionActions: KebabMenuOption[] = [
    {
      id: SurveyQuestionHeaderActionsContainerOptions.RANDOMISE_QUESTION_ORDER,
      textItem: 'Randomise question order',
      iconName: IconName.Shuffle,
      disabled: randomisationEnabled,
      onClickItem: handleSetRandomisation
    },
    {
      id: SurveyQuestionHeaderActionsContainerOptions.ADD_LOOPING,
      textItem: 'Add looping',
      iconName: IconName.Repeat,
      disabled: sectionEntryItem.isLoopingEnabled,
      onClickItem: () =>
        dispatch(
          openLoopingDialog({
            sectionEntryNumber: entryNumber,
            sourceEntry: null,
            sectionEntryItem: null
          })
        )
    },
    {
      id: SurveyQuestionHeaderActionsContainerOptions.DELETE_SECTION,
      textItem: sectionEntryItem.isLoopingEnabled
        ? 'Disable looping before deleting'
        : 'Delete',
      iconName: IconName.DeleteOutline,
      hasDivider: false,
      onClickItem: sectionHasEntries
        ? handleOpenDeleteSection
        : deleteSectionWithoutQuestions,
      disabled: sectionEntryItem.isLoopingEnabled
    }
  ]

  return (
    <div className="fd-grid fd-container">
      <div
        className={
          'fd-grid fd-container fd-item fd-row fd-align-items-center fd-xs-11'
        }
      >
        <>
          <p
            className={classNames(
              classes.id,
              textClasses.default,
              textClasses.sizeMs,
              textClasses.weightBold,
              textClasses.highlightBackground
            )}
          >
            S{contextPositionValue}
          </p>
          <div className={classNames(classes.questionType)}>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.marginRight,
                textClasses.highlightBackground
              )}
            >
              Section
            </p>
          </div>
        </>
        {randomisationEnabled && (
          <RandomizationActionMenu sectionEntryNumber={entryNumber} />
        )}
        {sectionEntryItem.loopingConfig &&
          sectionEntryItem.isLoopingEnabled && (
            <LoopingActionsMenu
              sectionEntryItem={sectionEntryItem}
              loopingConfig={sectionEntryItem.loopingConfig}
              sectionEntryNumber={entryNumber}
            />
          )}
      </div>
      <div
        className={
          'fd-grid fd-container fd-row fd-justify-content-flex-end fd-align-items-center fd-xs-1'
        }
      >
        <div className={classes.actionContainer}>
          <KebabMenu
            iconPosition={KebabMenuIconPosition.Left}
            kebabMenuOptions={questionActions}
            tooltipText="Configure this question"
          />
        </div>
      </div>
    </div>
  )
}
export default SectionCardHeaderContainer
