import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { addCustomResponseOptionCacheUpdate } from '../../apollo/cacheModify/Fieldwork/addCustomResponseOption'
import { detachCustomResponseOption } from '../../apollo/cacheModify/Fieldwork/detachCustomCriterionOption'
import {
  AddCustomCriterionOptionMutationVariables,
  DetachCustomAudienceMemberMutationVariables,
  DetachCustomCriterionOptionMutationVariables,
  MoveCustomCriterionOptionMutationVariables,
  RemoveCustomMemberSettingValueMutationVariables,
  RemoveCustomQualificationMutationVariables,
  SetCustomMemberSettingValueMutationVariables,
  SetCustomQualificationMutationVariables,
  SetCustomQuotaMutationVariables,
  UpdateCustomCriterionMutationVariables,
  UpdateCustomCriterionOptionMutationVariables
} from '../../data/gql-gen/fieldwork/graphql'
import {
  DETACH_CUSTOM_AUDIENCE_MEMBER,
  DetachCustomAudienceMemberData
} from '../../data/gql/fieldwork/mutations/addCustomCriterion'
import ADD_CUSTOM_CRITERION_OPTION, {
  AddCustomCriterionOptionData
} from '../../data/gql/fieldwork/mutations/addCustomCriterionOption'
import {
  REMOVE_CUSTOM_MEMBER_SETTING_VALUE,
  RemoveCustomMemberSettingValueData,
  SET_CUSTOM_MEMBER_SETTING_VALUE,
  SetCustomMemberSettingValueData
} from '../../data/gql/fieldwork/mutations/customMemberSettingValue'
import {
  DETACH_CUSTOM_CRITERION_OPTION,
  DetachCustomCriterionOptionData
} from '../../data/gql/fieldwork/mutations/detachCustomCriterionOption'
import MOVE_CUSTOM_CRITERION_OPTION, {
  MoveCustomCriterionOptionData
} from '../../data/gql/fieldwork/mutations/moveCustomCriterionOption'
import {
  CustomQualificationData,
  REMOVE_CUSTOM_QUALIFICATION,
  SET_CUSTOM_QUALIFICATION
} from '../../data/gql/fieldwork/mutations/setCustomQualification'
import {
  SET_CUSTOM_QUOTA,
  SetCustomQuotaData
} from '../../data/gql/fieldwork/mutations/setCustomQuota'
import {
  UPDATE_CUSTOM_CRITERION,
  UpdateCustomCriterionData
} from '../../data/gql/fieldwork/mutations/updateCustomCriterion'
import {
  UPDATE_CUSTOM_CRITERION_OPTION,
  UpdateCustomCriterionOptionData
} from '../../data/gql/fieldwork/mutations/updateCustomCriterionOption'
import { fieldworkAudienceRefetchQuery } from '../../data/gql/fieldwork/queries/fieldworkAudience'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import { customCriteriaRefetchQuery } from '../../data/gql/questionnaire/queries/customCriteria'
import { LoggerErrorType } from '../../data/logger'
import AudienceContext from '../../modules/Audience/Audience.context'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

const useCustomAudienceMutations = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { audienceState } = useContext(AudienceContext)

  const basicMutationContext = {
    context: { clientName: 'fieldwork' },
    refetchQueries: [
      draftAudienceRefetchQuery(projectId, surveyId),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  }
  const customMutationContext = {
    context: { clientName: 'fieldwork' },
    refetchQueries: [
      draftAudienceRefetchQuery(projectId, surveyId),
      customCriteriaRefetchQuery(projectId, surveyId),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  }

  const [detachCustomAudienceQuestion] = useMutation<
    DetachCustomAudienceMemberData,
    DetachCustomAudienceMemberMutationVariables
  >(DETACH_CUSTOM_AUDIENCE_MEMBER, {
    ...customMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'detachCustomAudienceQuestion',
        error
      )
    }
  })
  const [updateCustomCriterion] = useMutation<
    UpdateCustomCriterionData,
    UpdateCustomCriterionMutationVariables
  >(UPDATE_CUSTOM_CRITERION, {
    ...customMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateCustomCriterion',
        error
      )
    }
  })
  const [addCustomCriterionOption] = useMutation<
    AddCustomCriterionOptionData,
    AddCustomCriterionOptionMutationVariables
  >(ADD_CUSTOM_CRITERION_OPTION, {
    ...basicMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addCustomCriterionOption',
        error
      )
    },
    update: (cache, { data }) => {
      const { newAudienceQuestionLk } = audienceState
      if (data && newAudienceQuestionLk) {
        const newOption = data.addCustomCriterionOption
        addCustomResponseOptionCacheUpdate(
          cache,
          newOption,
          newAudienceQuestionLk
        )
      }
    }
  })
  const [detachCustomCriterionOption] = useMutation<
    DetachCustomCriterionOptionData,
    DetachCustomCriterionOptionMutationVariables
  >(DETACH_CUSTOM_CRITERION_OPTION, {
    ...basicMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'detachCustomCriterionOption',
        error
      )
    },
    update: (cache, { data }) => {
      if (data) {
        detachCustomResponseOption(cache, data.detachCustomCriterionOption)
      }
    }
  })
  const [updateCustomCriterionOption] = useMutation<
    UpdateCustomCriterionOptionData,
    UpdateCustomCriterionOptionMutationVariables
  >(UPDATE_CUSTOM_CRITERION_OPTION, {
    ...basicMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateCustomCriterionOption',
        error
      )
    }
  })
  const [setCustomMemberSettingValue] = useMutation<
    SetCustomMemberSettingValueData,
    SetCustomMemberSettingValueMutationVariables
  >(SET_CUSTOM_MEMBER_SETTING_VALUE, {
    ...basicMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setCustomMemberSettingValue',
        error
      )
    }
  })

  const [removeCustomMemberSettingValue] = useMutation<
    RemoveCustomMemberSettingValueData,
    RemoveCustomMemberSettingValueMutationVariables
  >(REMOVE_CUSTOM_MEMBER_SETTING_VALUE, {
    ...basicMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeCustomMemberSettingValue',
        error
      )
    }
  })

  const [setCustomQualification] = useMutation<
    CustomQualificationData,
    SetCustomQualificationMutationVariables
  >(SET_CUSTOM_QUALIFICATION, {
    ...basicMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setCustomQualification',
        error
      )
    }
  })
  const [removeCustomQualification] = useMutation<
    CustomQualificationData,
    RemoveCustomQualificationMutationVariables
  >(REMOVE_CUSTOM_QUALIFICATION, {
    ...basicMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeCustomQualification',
        error
      )
    }
  })
  const [setCustomQuota] = useMutation<
    SetCustomQuotaData,
    SetCustomQuotaMutationVariables
  >(SET_CUSTOM_QUOTA, {
    ...basicMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setCustomQuota',
        error
      )
    }
  })

  const [batchSetCustomQuota] = useMutation<
    SetCustomQuotaData,
    SetCustomQuotaMutationVariables
  >(SET_CUSTOM_QUOTA, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'batchSetCustomQuota',
        error
      )
    }
  })

  const [moveCustomCriterionOption] = useMutation<
    MoveCustomCriterionOptionData,
    MoveCustomCriterionOptionMutationVariables
  >(MOVE_CUSTOM_CRITERION_OPTION, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'moveCustomCriterionOption',
        error
      )
    },
    fetchPolicy: 'no-cache'
  })

  return {
    detachCustomAudienceQuestion,
    updateCustomCriterion,
    addCustomCriterionOption,
    detachCustomCriterionOption,
    updateCustomCriterionOption,
    setCustomMemberSettingValue,
    removeCustomMemberSettingValue,
    setCustomQualification,
    removeCustomQualification,
    setCustomQuota,
    batchSetCustomQuota,
    moveCustomCriterionOption
  }
}

export default useCustomAudienceMutations
