import {
  DraftCustomAudienceCriterionOption,
  DraftCustomCriterion,
  EnabledState,
  FieldworkAudience,
  FieldworkAudienceType
} from '../../../data/model/audience'
import { ResponseOptionType } from '../../../data/model/shared'

export const createEmptyCustomAudience = (
  isMulti: boolean
): FieldworkAudience => ({
  number: Infinity,
  position: Infinity,
  contextPosition: 0,
  memberType: FieldworkAudienceType.Custom,
  isWeighted: false,
  memberItem: {
    questionLk: 'questionLk',
    question: {
      questionId: 'questionLk',
      text: ''
    },
    criterionOptions: Array(2)
      .fill(null)
      .map(
        (_, idx) =>
          ({
            responseOptionLk: `responseOptionLk${idx + 1}`,
            responseOption: {
              responseOptionId: `responseOptionLk${idx + 1}`,
              rotype: ResponseOptionType.RoString,
              value: '',
              createdDate: new Date()
            },
            position: idx,
            qualification: {
              createdDate: new Date(),
              sinceDate: new Date()
            },
            quota: {
              percent: 0,
              createdDate: new Date(),
              sinceDate: new Date(),
              __typename: 'Quota'
            },
            pinned: false,
            exclusive: false,
            createdDate: new Date(),
            sinceDate: new Date(),
            weight: {
              percent: 0,
              createdDate: new Date(),
              sinceDate: new Date()
            },
            __typename: 'DraftCustomAudienceCriterionOption'
          }) satisfies DraftCustomAudienceCriterionOption
      ),
    settingValues: [
      {
        code: 'custom_audience_choice',
        value: isMulti ? 'multiple_choice' : 'single_choice',
        createdDate: '',
        sinceDate: ''
      },
      {
        code: 'qualifying',
        value: 'enabled',
        createdDate: '',
        sinceDate: ''
      },
      {
        code: 'quotas',
        value: 'disabled',
        createdDate: '',
        sinceDate: ''
      }
    ],
    createdDate: new Date(),
    sinceDate: new Date()
  }
})

export const createEmptyCustomCriterion = (): DraftCustomCriterion => {
  return {
    questionLk: '',
    number: Infinity,
    question: {
      questionId: '',
      text: ''
    },
    state: EnabledState.Enabled,
    createdDate: new Date(),
    sinceDate: new Date(),
    __typename: 'DraftCustomCriterion'
  }
}
