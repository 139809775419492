import { questionnaireGql } from '../../../gql-gen/questionnaire'

const UPDATE_MATRIX_QUESTION_ROW = questionnaireGql(/* GraphQL */ `
  mutation updateMatrixQuestionRow(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionLk: ID!
    $text: String!
  ) {
    updateMatrixQuestionRow(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionLk: $questionLk
      text: $text
    ) {
      ...DraftMatrixRow
    }
  }
`)

export default UPDATE_MATRIX_QUESTION_ROW
