import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { FieldworkAudienceQueryVariables } from '../../data/gql-gen/fieldwork/graphql'
import {
  FIELDWORK_AUDIENCE,
  FieldworkAudienceData
} from '../../data/gql/fieldwork/queries/fieldworkAudience'
import { LoggerErrorType } from '../../data/logger'
import { QuestionItem } from '../../data/model/fieldwork'
import { QuestionTypeCode } from '../../data/model/questionnaire'
import { SurveyParams } from '../../data/model/surveyParams'
import { captureApolloError } from '../../utils/HelperFunctions'
import { AudienceQuestionContainer } from './AudienceQuestion'

export const CustomCriteriaQuestionsListContainer = (): JSX.Element | null => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { data: fieldworkAudienceData } = useQuery<
    FieldworkAudienceData,
    FieldworkAudienceQueryVariables
  >(FIELDWORK_AUDIENCE, {
    context: { clientName: 'fieldwork' },
    variables: {
      surveyId
    },
    skip: !surveyId,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'fieldworkAudience',
        error
      )
    }
  })
  const customCriteriaQuestions =
    fieldworkAudienceData &&
    fieldworkAudienceData.fieldworkAudience.entries.map((entry, index) => {
      const entryItem = entry.entryItem as QuestionItem
      if (
        entryItem.questionTypeCode === QuestionTypeCode.CustomAudience ||
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        QuestionTypeCode.StandardAudience
      ) {
        const scrollKey = entryItem.question.questionId
        return (
          <AudienceQuestionContainer
            key={entry.number}
            audienceQuestionIndex={index}
            scrollKey={scrollKey}
            customAudienceEntryItem={entryItem}
          />
        )
      }
      return null
    })

  return <>{customCriteriaQuestions}</>
}
