import Skeleton from '@mui/material/Skeleton'
import { useGetFdChatSpecification } from '../../../../containers/Page/FdChat/FdChat.hooks'
import { useFdChatProgress } from '../../FdChatProgressProvider.context'
import Accordion from '../Accordion/Accordion'
import styles from './ProjectBriefCard.module.css'

const ProjectBriefCard = () => {
  const {
    interviewsCollected,
    loading: progressLoading,
    interviewsWanted
  } = useFdChatProgress()
  const { fdChatSpecification, loadingFdChatSpecification } =
    useGetFdChatSpecification()

  return (
    <div className={styles['project-brief-card']}>
      <h2 className={styles.title}>Project brief</h2>
      <p>
        {progressLoading ? (
          <Skeleton variant="text" height={30} />
        ) : (
          `Our AI facilitator conducted ${interviewsCollected}/${interviewsWanted} one-on-one semi-structured interviews based on
              your project brief.`
        )}
      </p>
      <div>
        <h3 className={styles['brief-label']}>Research goal</h3>
        <p>
          {loadingFdChatSpecification ? (
            <Skeleton variant="text" width={'80%'} height={30} />
          ) : (
            fdChatSpecification?.goal
          )}
        </p>
      </div>
      <div>
        <h3 className={styles['brief-label']}>Research questions</h3>
        {loadingFdChatSpecification ? (
          Array.from({ length: 3 }).map((_, index) => (
            <Skeleton variant="text" width={'90%'} height={30} key={index} />
          ))
        ) : (
          <ol className={styles.questions}>
            {fdChatSpecification?.questions.map((question, index) => (
              <li key={index}>{question}</li>
            ))}
          </ol>
        )}
      </div>
      <div>
        <Accordion
          id="context-accordion"
          summaryContent={
            <h3 className={styles['brief-label']}>Further context</h3>
          }
          detailsContent={
            <>
              {loadingFdChatSpecification ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton
                    variant="text"
                    width={'100%'}
                    height={30}
                    key={index}
                  />
                ))
              ) : (
                <p className={styles.context}>
                  {fdChatSpecification?.context ? (
                    fdChatSpecification.context
                  ) : (
                    <i className={styles['no-data']}>No context provided</i>
                  )}
                </p>
              )}
            </>
          }
        />
      </div>
    </div>
  )
}

export default ProjectBriefCard
