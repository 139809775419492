import { ApolloQueryResult } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import {
  DEFAULT_FEASIBILITY_LENGTH_OF_INTERVIEW,
  FEASIBILITY_POLL_INTERVAL
} from '../constants/feasibility'
import {
  GetFeasibilityEstimateV2Query,
  GetFeasibilityEstimateV2QueryVariables
} from '../data/gql-gen/questionnaire/graphql'
import { PollingStatus } from '../data/model/costAndFeasibility'
import useAudienceValidation from './audience/useAudienceValidation'
import useGetDraftAudience from './audience/useGetDraftAudience'
import { useFieldwork } from './useFieldwork'
import { useGetFeasibilityEstimate } from './useGetFeasibilityEstimate'

interface FeasibilityEstimateData {
  isFeasible: boolean | undefined
  isStillPolling?: boolean
  isDonePolling?: boolean
  isPollingFailed?: boolean
  isAudienceValid: boolean | undefined
  estimatedDeliveryTime: number
  feasibility: number | undefined
  loadingFieldworkEstimate: boolean | undefined
  loadingFeasibilityEstimate: boolean | undefined
  country: string
  countryId: number
  lengthOfInterview: number
  refetchFeasibilityEstimateData: (
    variables?: Partial<GetFeasibilityEstimateV2QueryVariables> | undefined
  ) => Promise<ApolloQueryResult<GetFeasibilityEstimateV2Query>>
}

const DEFAULT_ESTIMATED_DELIVERY_TIME = 2

// TODO: polling/repolling implementation is done wrong, so it is never stops polling; we should either fix it or remove it
export function useGetFeasibilityEstimateData(
  fdChatLoi?: number
): FeasibilityEstimateData {
  const { fieldwork, loading: loadingFieldworkEstimate } = useFieldwork()

  const { draftAudience } = useGetDraftAudience()

  const { isAudienceInvalid } = useAudienceValidation({
    validationErrors: draftAudience?.validationErrors
  })
  const country = fieldwork?.locale.country || 'GB'
  const countryId = fieldwork?.audience[0].countryId || 1 // Defaults to UK
  const completes = fieldwork?.samplesNeeded.unadjusted || 0
  // Hardcode LOI to 5 minutes for now regardless of the true LOI
  // to address feasible audiences being flagged as infeasible.
  // This is because CINT lowers the feasibility score while LOI increases.
  const loi = fdChatLoi ?? DEFAULT_FEASIBILITY_LENGTH_OF_INTERVIEW

  const {
    feasibilityEstimate,
    loading: loadingFeasibilityEstimate,
    refetch: refetchFeasibilityEstimateData,
    startPolling: startPollingFeasibilityEstimateData,
    stopPolling: stopPollingFeasibilityEstimateData
  } = useGetFeasibilityEstimate({
    variables: {
      lengthOfInterview: loi
    },
    skip: !loi || !completes
  })

  const rePollFeasibilityEstimateData = () => {
    stopPollingFeasibilityEstimateData()
    refetchFeasibilityEstimateData()
    startPollingFeasibilityEstimateData(FEASIBILITY_POLL_INTERVAL)
  }

  if (!feasibilityEstimate) {
    rePollFeasibilityEstimateData()
  }

  const feasibilityEstimateQueryExtra = {
    isAudienceValid: !isAudienceInvalid,
    loadingFieldworkEstimate,
    loadingFeasibilityEstimate,
    country,
    countryId,
    lengthOfInterview: loi,
    refetchFeasibilityEstimateData
  }

  if (feasibilityEstimate) {
    const {
      pollingStatus,
      isFeasible,
      estimatedDeliveryTime,
      feasibility,
      errors,
      pollingMessage
    } = feasibilityEstimate

    const isStillPolling = pollingStatus === PollingStatus.POLLING
    const isDonePolling = pollingStatus === PollingStatus.DONE
    const isPollingFailed = pollingStatus === PollingStatus.ERROR

    if (isPollingFailed || errors.length > 0) {
      datadogRum.addError(
        new Error(pollingMessage ?? 'Feasibility polling failed'),
        feasibilityEstimate
      )
    }

    if (isStillPolling || isPollingFailed) {
      rePollFeasibilityEstimateData()
    }

    if (isDonePolling) {
      stopPollingFeasibilityEstimateData()
    }

    return {
      isFeasible,
      isStillPolling,
      isDonePolling,
      isPollingFailed,
      estimatedDeliveryTime:
        estimatedDeliveryTime ?? DEFAULT_ESTIMATED_DELIVERY_TIME,
      feasibility,
      ...feasibilityEstimateQueryExtra
    }
  }

  return {
    isFeasible: undefined,
    estimatedDeliveryTime: DEFAULT_ESTIMATED_DELIVERY_TIME,
    feasibility: undefined,
    ...feasibilityEstimateQueryExtra
  }
}
