import { gql } from '@apollo/client'

export interface SetCustomQuotaData {
  setCustomQuota: {
    surveyId: string
    questionLk: string
    responseOptionLk: string
    percent: number
    sinceDate: Date
  }
}

export const SET_CUSTOM_QUOTA = gql`
  mutation setCustomQuota(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $percent: Float!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    setCustomQuota(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      percent: $percent
    ) {
      surveyId
      questionLk
      responseOptionLk
      percent
      sinceDate
    }
  }
`
