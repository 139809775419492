import { ScrollTo } from '@focaldata/cin-ui-components'
import delay from 'lodash/delay'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  SCROLL_ID_PREFIX
} from '../constants'

const scrollToWithDelay = (elementId: string): void => {
  delay(() => ScrollTo(elementId, CONTAINER_ID_QUESTIONNAIRE_CONTENT), 400)
}

export const scrollToNewInBetweenQuestionCard = (cardNumber: number) => {
  scrollToWithDelay(`${SCROLL_ID_PREFIX}${cardNumber}`)
}
