import { gql } from '@apollo/client/core'
import { DraftCustomAudienceCriterionOption } from '../../../model/audience'
import DRAFT_CUSTOM_CRITERION_OPTION from '../fragments/draftCustomCriterionOption'

export interface MoveCustomCriterionOptionData {
  moveCustomCriterionOption: DraftCustomAudienceCriterionOption
}

const MOVE_CUSTOM_CRITERION_OPTION = gql`
  mutation moveCustomCriterionOption(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $toPosition: Int!
  ) {
    moveCustomCriterionOption(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      toPosition: $toPosition
    ) {
      ...DraftCustomCriterionOption
    }
  }
  ${DRAFT_CUSTOM_CRITERION_OPTION}
`

export default MOVE_CUSTOM_CRITERION_OPTION
