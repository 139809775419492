import {
  ButtonType,
  Icon,
  IconColor,
  IconName,
  Input,
  InputVariant,
  LinkButton,
  Logo,
  PasswordInput,
  SpinnerButton,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import useStyles from '../Auth.styles'

export interface Props {
  onLogin: (email: string, password: string) => Promise<boolean | Error>
  onForgotPassword?: () => void
  onGoToRegister?: () => void
}

interface State {
  error: Error | undefined
  email: string
  password: string
  showPassword: boolean
}

enum FailStatus {
  unauthorized = '401',
  notFound = '404'
}

const Login: React.FC<Props> = (props: Props) => {
  const { onLogin, onGoToRegister, onForgotPassword }: Props = props
  const [loading, onLoading] = useState<boolean>(false)
  const [state, setState] = useState<State>({
    error: undefined,
    email: '',
    password: '',
    showPassword: false
  })

  const handleLogin: (event: React.SyntheticEvent) => void = async (event) => {
    event.preventDefault()
    onLoading(true)
    const loginResponse = await onLogin(
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      state.email?.toLowerCase(),
      state.password
    )

    if (loginResponse) {
      onLoading(false)
    }
    if (loginResponse instanceof Error) {
      setState({ ...state, error: loginResponse })
    }
  }

  const renderErrorMessage = (errorState: Error) => {
    if (
      errorState.message === FailStatus.unauthorized ||
      errorState.message === FailStatus.notFound
    ) {
      return 'Your username or password are incorrect. Please try again.'
    }

    return "There's an issue connecting to the server. Please try again."
  }

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value })
    }

  return (
    <Grid
      className={classes.page}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.maxWidth}>
        <form onSubmit={handleLogin}>
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            className={classes.container}
          >
            <Logo width={180} />
            <Grid
              item
              className={classes.gridItem}
              style={{ marginTop: '16px' }}
            >
              <p className={classNames(textClasses.default, textClasses.sizeL)}>
                Log in
              </p>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              className={classes.gridItem}
            >
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.weightSemiBold
                )}
              >
                Email
              </p>
              <Input
                fullWidth
                value={state.email}
                onChange={handleChange('email')}
                variant={InputVariant.Filled}
                ariaLabel="email"
              />
            </Grid>
            <Grid item container className={classes.gridItem}>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.weightSemiBold
                )}
              >
                Password
              </p>
              <PasswordInput
                fullWidth
                value={state.password}
                onChange={handleChange('password')}
                ariaLabel="password"
              />
            </Grid>
            <Grid item container className={classes.gridItem}>
              <SpinnerButton
                onClick={handleLogin}
                className={classes.button}
                loading={loading}
                fullWidth
                buttonType={ButtonType.Submit}
              >
                Log in
              </SpinnerButton>
            </Grid>
            {state.error && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.gridItem}
              >
                <Icon
                  name={IconName.InfoOutlinedIcon}
                  iconColor={IconColor.Error}
                />
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.sizeS,
                    textClasses.highlightError
                  )}
                >
                  {renderErrorMessage(state.error)}
                </p>
              </Grid>
            )}
            <Grid
              container
              justifyContent="center"
              className={classes.helperText}
            >
              <p className={classNames(textClasses.default)}>
                Don&apos;t have an account?
              </p>
              <LinkButton
                size={TextSize.m}
                weight={TextWeight.Bold}
                onClick={onGoToRegister}
                noPadding
                underlined
              >
                Sign up
              </LinkButton>
            </Grid>
            <Grid container justifyContent="center">
              <LinkButton
                size={TextSize.m}
                weight={TextWeight.Bold}
                onClick={onForgotPassword}
                noPadding
                underlined
              >
                Forgot your password?
              </LinkButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default Login
