import { gql } from '@apollo/client/core'
import { Project } from '../../data/gql-gen/dashboard/graphql'
import { PROJECT } from './Project.fragments'
import { Survey } from './Project.model'

export enum SortBy {
  Name = 'Name',
  SearchRank = 'SearchRank',
  SinceDate = 'SinceDate',
  Solution = 'Solution'
}

export enum SortOrder {
  Asc = 'Asc',
  Desc = 'Desc'
}
export interface ProjectSortingInput {
  sortBy: SortBy
  order: SortOrder
}

export interface SearchProjectsData {
  searchProjects: SearchProjects
}

export interface SearchProjects {
  projects: Project[]
  header: SearchProjectsHeader
}

export interface SearchProjectsHeader {
  total: number
}

export const SEARCH_PROJECTS_QUERY_NAME = 'searchProjects'

export const SEARCH_PROJECTS = gql`
  query searchProjects(
    $accountId: ID!
    $offset: Int!
    $limit: Int!
    $text: String
    $sorting: [ProjectSortingInput!]!
    $state: SurveyState
    $projectTypes: [ProjectType!]
  ) {
    searchProjects(
      accountId: $accountId
      offset: $offset
      limit: $limit
      text: $text
      sorting: $sorting
      state: $state
      projectTypes: $projectTypes
    ) {
      header {
        total
      }
      projects {
        ...Project
      }
    }
  }
  ${PROJECT}
`

export interface ProjectData {
  project: Project
}

export const PROJECT_BY_ID = gql`
  query projectById($projectId: ID!) {
    project: project(projectId: $projectId) {
      ...Project
    }
  }
  ${PROJECT}
`

export const PROJECT_BY_SURVEY_ID = gql`
  query projectBySurveyId($surveyId: ID!) {
    project: projectBySurveyId(surveyId: $surveyId) {
      ...Project
    }
  }
  ${PROJECT}
`

export const PROJECT_ID_BY_SURVEY_ID = gql`
  query projectIdBySurveyId($surveyId: ID!) {
    projectBySurveyId(surveyId: $surveyId) {
      projectId
    }
  }
`

export interface SurveyData {
  survey: Survey
}

export const SURVEY = gql`
  query survey($surveyId: ID!) {
    survey: survey(surveyId: $surveyId) {
      surveyId
      name
      state
      createdDate
      sinceDate
      fieldworkDates {
        startDate
        endDate
      }
    }
  }
`
