import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { QuestionItem } from '../../../data/model/fieldwork'
import { SurveyParams } from '../../../data/model/surveyParams'
import AudienceQuestionControl from './AudienceQuestion.control'

interface Props {
  scrollKey: string
  customAudienceEntryItem: QuestionItem
  audienceQuestionIndex: number
}

const AudienceQuestionContainer: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const { customAudienceEntryItem, audienceQuestionIndex, scrollKey } = props
  const { surveyId } = useParams<keyof SurveyParams>()

  const onClickEdit: () => void = () => {
    navigate(`/survey/${surveyId}/audience`)
  }

  return (
    <AudienceQuestionControl
      scrollKey={scrollKey}
      audienceQuestionIndex={audienceQuestionIndex}
      onClickEdit={onClickEdit}
      customAudienceEntryItem={customAudienceEntryItem}
    />
  )
}

export default AudienceQuestionContainer
