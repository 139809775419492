import { gql } from '@apollo/client/core'

export const SET_TEXT_CARD_MEDIA = gql`
  mutation setTextCardMedia(
    $questionnaireId: ID!
    $textCardLk: ID!
    $mediaName: String!
    $mediaUrl: String!
    $mediaType: MediaType!
    $renderedMediaUrl: String
  ) {
    setTextCardMedia(
      questionnaireId: $questionnaireId
      textCardLk: $textCardLk
      mediaName: $mediaName
      mediaUrl: $mediaUrl
      mediaType: $mediaType
      renderedMediaUrl: $renderedMediaUrl
    )
  }
`
