import { useMutation } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { Maybe } from 'graphql/jsutils/Maybe'
import {
  ConfigMutationsSetCustomCriterionOptionsLogicArgs,
  DraftCustomAudienceItem,
  RemoveCustomCriterionOptionsLogicMutationVariables,
  ResponseOptionsLogic,
  SetCustomCriterionOptionsLogicMutation,
  SetCustomCriterionOptionsLogicMutationVariables
} from '../../../data/gql-gen/fieldwork/graphql'
import { AUDIENCE_VALIDATION_ERRORS } from '../../../data/gql/questionnaire/queries/audienceValidationErrors'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  REMOVE_CUSTOM_CRITERION_OPTIONS_LOGIC,
  SET_CUSTOM_CRITERION_OPTIONS_LOGIC
} from './CustomCriterionOptionLogic.mutations'

const isDraftCustomAudienceItem = (
  memberItem: unknown
): memberItem is DraftCustomAudienceItem => {
  return (
    !!memberItem &&
    typeof memberItem === 'object' &&
    (memberItem as { __typename: string }).__typename ===
      'DraftCustomAudienceItem'
  )
}

export const useCustomCriterionOptionLogicActions = () => {
  const surveyId = useSurveyId()
  const [setCustomCriterionOptionsLogicMutation] = useMutation<
    SetCustomCriterionOptionsLogicMutation,
    SetCustomCriterionOptionsLogicMutationVariables
  >(SET_CUSTOM_CRITERION_OPTIONS_LOGIC, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [AUDIENCE_VALIDATION_ERRORS],
    onError: (error) => {
      datadogLogs.logger.error(
        `[setCustomCriterionOptionsLogic] ${error.message}`,
        error
      )
    }
  })

  const setCustomCriterionOptionsLogic = (
    options: Omit<
      ConfigMutationsSetCustomCriterionOptionsLogicArgs,
      'surveyId' | 'loopQuotaPriority'
    > & {
      sectionId?: string
    }
  ): Promise<Maybe<ResponseOptionsLogic>> => {
    const { sectionId, ...mutationOptions } = options
    const loopQuotaPriority = sectionId ? { sectionId } : undefined
    return setCustomCriterionOptionsLogicMutation({
      variables: {
        surveyId,
        loopQuotaPriority,
        ...mutationOptions
      }
    }).then((result) => {
      const memberItem = result.data?.setCustomCriterionOptionsLogic?.memberItem
      return isDraftCustomAudienceItem(memberItem)
        ? memberItem.responseOptionsLogic
        : undefined
    })
  }

  const [removeCustomCriterionOptionsLogicMutation] = useMutation<
    void,
    RemoveCustomCriterionOptionsLogicMutationVariables
  >(REMOVE_CUSTOM_CRITERION_OPTIONS_LOGIC, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [AUDIENCE_VALIDATION_ERRORS],
    onError: (error) => {
      datadogLogs.logger.error(
        `[removeCustomCriterionOptionsLogic] ${error.message}`,
        error
      )
    }
  })

  const removeCustomCriterionOptionsLogic = (questionLk: string) => {
    return removeCustomCriterionOptionsLogicMutation({
      variables: {
        surveyId,
        questionLk
      }
    })
  }

  return {
    setCustomCriterionOptionsLogic,
    removeCustomCriterionOptionsLogic
  }
}
