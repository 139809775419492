import { makeVar, useMutation } from '@apollo/client'
import { useContext } from 'react'
import {
  UpdateMatrixQuestionMutationVariables,
  UpdateQuestionMutationVariables,
  UpdateTextCardMutationVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import UPDATE_MATRIX_QUESTION, {
  UpdateMatrixQuestionData
} from '../../../data/gql/questionnaire/mutations/updateMatrixQuestion'
import {
  UPDATE_QUESTION,
  UpdateQuestionData
} from '../../../data/gql/questionnaire/mutations/updateQuestion'
import {
  UPDATE_TEXT_CARD,
  UpdateTextCardData
} from '../../../data/gql/questionnaire/mutations/updateTextCard'
import { EntryType } from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import QuestionnaireContext from '../Questionnaire.context'
import { flattenEntries } from '../Questionnaire.utils'
import { getTitleStyling } from './PipingDialog.utils'

export const pipingMarkerAddedVar = makeVar<Record<string, boolean>>({})

export const useUpdateTitleWithPipingMarker = () => {
  const { draftQuestionnaire } = useGetDraftQuestionnaire()
  const { questionnaireState } = useContext(QuestionnaireContext)
  const { currentPipingTargetNumber } = questionnaireState
  const entries = flattenEntries(draftQuestionnaire?.entries ?? [])

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const currentPipingTargetIndex = entries?.findIndex(
    (entry) => entry.number === currentPipingTargetNumber
  )
  const [updateQuestion] = useMutation<
    UpdateQuestionData,
    UpdateQuestionMutationVariables
  >(UPDATE_QUESTION, {
    context: { clientName: 'questionnaire' }
  })
  const [updateTextCard] = useMutation<
    UpdateTextCardData,
    UpdateTextCardMutationVariables
  >(UPDATE_TEXT_CARD, {
    context: { clientName: 'questionnaire' }
  })
  const [updateMatrixQuestion] = useMutation<
    UpdateMatrixQuestionData,
    UpdateMatrixQuestionMutationVariables
  >(UPDATE_MATRIX_QUESTION, {
    context: { clientName: 'questionnaire' }
  })

  return async (
    marker: `{A${number}}` | `{Q${number}}` | `{S${number}}`
  ): Promise<void> => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!draftQuestionnaire || !entries || entries.length === 0) {
      return
    }

    const currentEntry = entries[currentPipingTargetIndex]

    if (currentEntry.entryType === EntryType.QuestionEntryType) {
      const { question, questionLk, questionTypeCode } = currentEntry.entryItem
      const text = `${question.text}${marker}`
      const textStyling = getTitleStyling(marker, question.textStyling)
      await updateQuestion({
        variables: {
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          questionnaireId: draftQuestionnaire?.questionnaireId,
          questionLk,
          questionTypeCode,
          text,
          textStyling
        }
      })

      pipingMarkerAddedVar({ [questionLk]: true })
    }

    if (currentEntry.entryType === EntryType.TextCardEntryType) {
      const { textCard, textCardLk } = currentEntry.entryItem
      const title = `${textCard.title}${marker}`
      const titleStyling = getTitleStyling(marker, textCard.titleStyling)
      await updateTextCard({
        variables: {
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          questionnaireId: draftQuestionnaire?.questionnaireId ?? '',
          textCardLk,
          title,
          titleStyling,
          subtitle: '',
          body: textCard.body
        }
      })

      pipingMarkerAddedVar({ [textCardLk]: true })
    }

    if (currentEntry.entryType === EntryType.MatrixEntryType) {
      const { matrixTitle, matrixTitleLk } = currentEntry.entryItem
      const title = `${matrixTitle.title}${marker}`
      const titleStyling = getTitleStyling(marker, matrixTitle.titleStyling)
      await updateMatrixQuestion({
        variables: {
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          questionnaireId: draftQuestionnaire?.questionnaireId,
          matrixTitleLk,
          title,
          titleStyling
        }
      })

      pipingMarkerAddedVar({ [matrixTitleLk]: true })
    }
  }
}
