import { questionnaireGql } from '../../data/gql-gen/questionnaire'

export const GET_FIELDWORK_SOFT_LAUNCH = questionnaireGql(/* GraphQL */ `
  query fieldworkSoftLaunch($surveyId: ID!) {
    fieldwork: fieldworkV2(surveyId: $surveyId) {
      surveyId
      samplesNeeded {
        unadjusted
        adjusted
      }
      softLaunch {
        samplesNeeded
      }
      isConfiguredForSoftLaunch
    }
  }
`)
