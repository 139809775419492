import { gql } from '@apollo/client/core'

export const SET_QUESTION_SCALE = gql`
  mutation setQuestionScale(
    $questionnaireId: ID!
    $questionLk: ID!
    $min: Float!
    $max: Float!
    $step: Float!
    $startingPosition: Float!
  ) {
    setQuestionScale(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      min: $min
      max: $max
      step: $step
      startingPosition: $startingPosition
    )
  }
`
