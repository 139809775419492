import { gql } from '@apollo/client/core'

export const REMOVE_QUESTION_MEDIA = gql`
  mutation removeQuestionMedia($questionnaireId: ID!, $questionLk: ID!) {
    removeQuestionMedia(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
    )
  }
`
