import { gql } from '@apollo/client'
import { DraftMaskingRule } from '../../../../data/gql-gen/questionnaire/graphql'
import DRAFT_QUESTIONNAIRE_ENTRY from '../../../../data/gql/questionnaire/fragments/draftQuestionnaireEntry'
import { DraftQuestionnaireEntry } from '../../../../data/model/questionnaire'

export interface CreateOrUpdateMaskingRuleData {
  createOrUpdateMaskingRule: DraftMaskingRule[]
}

export interface SetMatrixRowForkTagData {
  setMatrixRowForkTag: DraftQuestionnaireEntry
}

export const CREATE_OR_UPDATE_MASKING_RULE = gql`
  mutation createOrUpdateMaskingRule(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $maskingRules: [DraftMaskingRuleInput!]!
  ) {
    createOrUpdateMaskingRule(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      maskingRules: $maskingRules
    ) {
      clause
      maskingKeys {
        questionLk
        matrixTitleLk
        responseOptionLk
      }
      negated
    }
  }
`
export const CREATE_OR_UPDATE_MATRIX_ROW_MASKING_RULE = gql`
  mutation createOrUpdateMatrixRowMaskingRule(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionLk: ID!
    $maskingRules: [DraftMaskingRuleInput!]!
  ) {
    createOrUpdateMatrixRowMaskingRule(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionLk: $questionLk
      maskingRules: $maskingRules
    ) {
      clause
      maskingKeys {
        questionLk
        matrixTitleLk
        responseOptionLk
      }
      negated
    }
  }
`

export const CREATE_OR_UPDATE_MATRIX_COLUMN_MASKING_RULE = gql`
  mutation createOrUpdateMatrixColumnMaskingRule(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $responseOptionLk: ID!
    $maskingRules: [DraftMaskingRuleInput!]!
  ) {
    createOrUpdateMatrixColumnMaskingRule(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      responseOptionLk: $responseOptionLk
      maskingRules: $maskingRules
    ) {
      clause
      maskingKeys {
        questionLk
        matrixTitleLk
        responseOptionLk
      }
    }
  }
`

export const CREATE_OR_UPDATE_MATRIX_ROW_FORKS = gql`
  mutation createOrUpdateMatrixRowForks(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionLk: ID!
    $forks: [ForkTagInput!]!
  ) {
    createOrUpdateMatrixRowForks(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionLk: $questionLk
      forks: $forks
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`
