import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { SetSectionSettingValueMutationVariables } from '../../data/gql-gen/questionnaire/graphql'
import {
  SET_SECTION_SETTING_VALUE,
  SetSectionSettingValueData
} from '../../data/gql/questionnaire/mutations/setSectionQuestionsRandomisation'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import {
  QuestionSettingCode,
  SettingValue
} from '../../data/model/questionnaire'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const useSetSectionSettingValue = (sectionId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [setSectionSettingValue] = useMutation<
    SetSectionSettingValueData,
    SetSectionSettingValueMutationVariables
  >(SET_SECTION_SETTING_VALUE, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setSectionSettingValue',
        error
      )
    },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)]
  })

  const handleSetSectionSettingValue = useCallback(
    async (
      questionSettingCode: QuestionSettingCode,
      settingValue: SettingValue | string
    ): Promise<void> => {
      await setSectionSettingValue({
        variables: {
          questionnaireId: surveyId,
          sectionId,
          setting: {
            questionSettingCode,
            settingValue
          }
        }
      })
    },
    [sectionId, setSectionSettingValue, surveyId]
  )

  return handleSetSectionSettingValue
}
