import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface AddEntryToSectionData {
  addEntryToSection: DraftQuestionnaireEntry
}

export const ADD_ENTRY_TO_SECTION = gql`
  mutation addEntryToSection(
    $questionnaireId: ID!
    $sectionId: ID!
    $entryNumber: Int!
    $toPosition: Int!
  ) {
    addEntryToSection(
      questionnaireId: $questionnaireId
      sectionId: $sectionId
      entryNumber: $entryNumber
      toPosition: $toPosition
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`
