import {
  MediaUploader as MediaUploaderComponent,
  VideoService,
  getImageContentSource
} from '@focaldata/cin-ui-components'
import { memo, useEffect, useState } from 'react'
import { MediaType } from '../../../data/model/questionnaire'
import useMediaUrlAndThumbnailUrl, {
  getImageUrl
} from '../../../data/rest/media/media'
import uploadFile from '../../../data/rest/media/uploadMedia'

interface Props {
  questionnaireId: string
  questionLk?: string
  responseOptionLk: string
  mediaUrl?: string
  renderedMediaUrl?: string | null
  mediaType?: MediaType
  imageDialogOpened?: boolean
  onDialogClose?: () => void
  onMediaUpload?: (
    questionnaireId: string,
    questionLk: string,
    responseOptionLk: string,
    mediaUrl: string,
    mediaName: string,
    mediaType: MediaType,
    renderedMediaUrl: string
  ) => Promise<void>
  onRemoveMedia?: (
    questionnaireId: string,
    questionLk: string,
    responseOptionLk: string
  ) => Promise<void>
}

const RowMediaUploader: React.FC<Props> = (props: Props) => {
  const {
    mediaUrl,
    renderedMediaUrl,
    mediaType,
    questionnaireId,
    questionLk,
    responseOptionLk,
    imageDialogOpened,
    onDialogClose,
    onMediaUpload,
    onRemoveMedia
  } = props
  const [tempUrl, setTempUrl] = useState<string | undefined>()
  const [isMediaPresent, setIsMediaPresent] = useState<boolean>(!!mediaUrl)
  const [errorOnUpload, setErrorOnUpload] = useState<boolean>(false)
  const [isImageDialogOpened, setIsImageDialogOpened] =
    useState(imageDialogOpened)
  const { resizedMediaUrl, resizedThumbnailUrl } = useMediaUrlAndThumbnailUrl(
    mediaUrl,
    mediaType,
    renderedMediaUrl
  )

  useEffect(() => {
    if (mediaUrl) {
      setIsMediaPresent(true)
    }
  }, [mediaUrl])

  useEffect(() => {
    setIsImageDialogOpened(imageDialogOpened)
  }, [imageDialogOpened])

  if (!imageDialogOpened && !isMediaPresent) {
    return null
  }

  const handleImageUpload: (file: File) => Promise<void> = async (file) => {
    try {
      setErrorOnUpload(false)
      const mediaUrl = await uploadFile(file)
      if (mediaUrl && questionLk) {
        const renderedMediaUrl = await getImageUrl(mediaUrl)
        await onMediaUpload?.(
          questionnaireId,
          questionLk,
          responseOptionLk,
          mediaUrl,
          file.name,
          MediaType.Image,
          renderedMediaUrl
        )
      }
    } catch (e) {
      setErrorOnUpload(true)
    }
  }

  const handleRemoveFile: () => void = async () => {
    setIsMediaPresent(false)
    if (questionLk)
      await onRemoveMedia?.(questionnaireId, questionLk, responseOptionLk)
  }

  const handleCloseDialog = () => {
    setIsImageDialogOpened(false)
    onDialogClose?.()
  }

  const supportedVideoService = [VideoService.YOUTUBE, VideoService.VIMEO]

  return (
    <MediaUploaderComponent
      noThumbnailBorderRadius
      allowVideo={false}
      isDialogOpened={isImageDialogOpened}
      shouldHideAddMediaButton
      shouldHideRemoveMediaButton
      errorOnUpload={errorOnUpload}
      openMenuOnClick={!!mediaUrl}
      isMediaPresent={isMediaPresent}
      mediaUrl={resizedMediaUrl}
      thumbnailUrl={tempUrl || resizedThumbnailUrl}
      mediaType={MediaType.Image}
      supportedVideoService={supportedVideoService}
      onFileUpload={async (file) => {
        const tempThumb = await getImageContentSource(file)
        setTempUrl(tempThumb)
        setIsMediaPresent(true)
        await handleImageUpload(file)
      }}
      onRemoveMedia={handleRemoveFile}
      onCloseDialog={handleCloseDialog}
      maxSizeMb={5}
    />
  )
}

export default memo(RowMediaUploader)
