import { gql } from '@apollo/client'

export const UPSERT_QUESTION_MAXDIFF_SPEC = gql`
  mutation upsertQuestionMaxDiffSpecification(
    $questionnaireId: ID!
    $questionLk: ID!
    $mostLabel: String
    $leastLabel: String
    $attributeLabel: String
    $experimentalDesigns: String
  ) {
    upsertQuestionMaxDiffSpecification(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      mostLabel: $mostLabel
      leastLabel: $leastLabel
      attributeLabel: $attributeLabel
      experimentalDesigns: $experimentalDesigns
    )
  }
`
