import { SelectOption } from '@focaldata/cin-ui-components'
import partition from 'lodash/partition'
import { CustomOptionValidationError } from '../../data/gql-gen/fieldwork/graphql'
import {
  DraftCustomAudienceCriterionOption,
  FieldworkAudienceCriterionOption,
  MemberSettingCode,
  MemberSettingValue,
  SettingValue
} from '../../data/model/audience'
import {
  CriterionValidationErrors,
  CustomCriterionValidationErrors,
  StandardCriterionOptionValidationErrors,
  StandardCriterionValidationErrors,
  StandardOptionValidationError
} from '../../data/model/fieldwork'
import { AudiencePreset } from '../../hooks/audience/usePresetAudience'

export const getStandardAudienceValidationErrors: (
  validationErrors: CriterionValidationErrors[] | undefined,
  entryCode: string
) => StandardCriterionValidationErrors | undefined = (
  validationErrors,
  entryCode
) => {
  const toReturn = validationErrors?.find(
    (errors) =>
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (errors as StandardCriterionValidationErrors)?.code === entryCode
  )

  return toReturn as StandardCriterionValidationErrors
}

export const getCustomAudienceValidationErrors: (
  validationErrors: CriterionValidationErrors[] | undefined,
  questionLk: string
) => CustomCriterionValidationErrors | undefined = (
  validationErrors,
  questionLk
) => {
  const toReturn = validationErrors?.find(
    (errors) =>
      (errors as CustomCriterionValidationErrors).questionLk === questionLk
  )

  return toReturn as CustomCriterionValidationErrors
}

export const isCustomResponseOptionEmpty: (
  responseOptionLk: string,
  validationErrors: CustomCriterionValidationErrors | undefined
) => boolean = (responseOptionLk, validationErrors) => {
  const errors = validationErrors?.customCriterionOptionsErrors.find(
    (er) => er.responseOptionLk === responseOptionLk
  )

  if (!errors) {
    return false
  }

  const isEmpty = errors.errors.some(
    (err) => err === CustomOptionValidationError.EmptyValueError
  )

  return isEmpty
}

export const getStandardResponseOptionQualificationInvalid: (
  responseOptionCode: string,
  validationErrors: StandardCriterionValidationErrors | undefined
) => StandardCriterionOptionValidationErrors | undefined = (
  responseOptionCode,
  validationErrors
) => {
  const optionErrors = validationErrors?.standardCriterionOptionsErrors.find(
    (opErr) => opErr.code === responseOptionCode
  )

  return optionErrors
}

export const getResponseOptionValidationErrorText: (
  errors: StandardCriterionOptionValidationErrors['errors'] | undefined
) => string | undefined = (errors) => {
  if (
    errors?.find(
      (error) =>
        error === StandardOptionValidationError.InvalidQualificationError
    )
  ) {
    return 'You cannot have gaps between age buckets unless you add quotas.'
  }

  if (
    errors?.find(
      (error) => error === StandardOptionValidationError.QuotaIsZeroError
    )
  ) {
    return 'Sorry, you cannot set a quota to 0%. Please change the quota % or disqualify/untick this option.'
  }

  return undefined
}

export const getTotalQuotaSum: (
  options: (
    | FieldworkAudienceCriterionOption
    | DraftCustomAudienceCriterionOption
  )[]
) => number = (options) =>
  options
    .filter((criterionOption) => criterionOption.qualification)
    .reduce(
      (sum, criterionOption) =>
        sum + (criterionOption.quota ? criterionOption.quota.percent : 0),
      0
    )

export const isQuotaSumValid: (totalQuotas: number) => boolean = (
  totalQuotas
) => Math.round(totalQuotas * 100000) / 100000 === 1

export const getIsValueInSettings: (
  settings: MemberSettingValue[],
  code: MemberSettingCode,
  value: SettingValue
) => boolean = (settings, code, settingValue) =>
  settings.some(
    (value: MemberSettingValue) =>
      value.code === code && value.value === settingValue
  )

export const getQuestionSettings = (
  settingValues: MemberSettingValue[]
): Map<MemberSettingCode, SettingValue> => {
  return settingValues.reduce((map, { code, value }) => {
    map.set(code, value)
    return map
  }, new Map())
}

export const getScreeningQuestionResponseLimit: (
  settings: MemberSettingValue[]
) => number | undefined = (settingValues) => {
  return (
    parseInt(
      getQuestionSettings(settingValues)
        .get(MemberSettingCode.ScreeningQuestionResponseLimit)
        ?.toString() || '',
      10
    ) || undefined
  )
}

export const getIsEnabledInSettings: (
  settings: MemberSettingValue[],
  code: MemberSettingCode
) => boolean = (settings, code) =>
  getIsValueInSettings(settings, code, SettingValue.Enabled)

export const presetAudienceToOption = (presets: AudiencePreset[]) => {
  return presets.map((a) => ({
    id: a.presetAudienceId || '',
    name: a.name,
    value: a.presetAudienceId || ''
  }))
}

export const getCustomAudienceSelectItem = (presets: AudiencePreset[]) => {
  const custom = presets.find((p) => p.name === 'Custom')
  return {
    id: custom?.presetAudienceId || '',
    name: custom?.name || '',
    value: custom?.presetAudienceId || ''
  }
}

export const getPresetsSelectItems = (presets: AudiencePreset[]) => {
  const [weightedPresets, unweightedPresets] = partition(
    presets.filter((p) => p.name !== 'Custom'),
    'isWeighted'
  )

  const hasWeightedPresets = weightedPresets.length
  const hasUnweightedPresets = unweightedPresets.length
  const newOptions: SelectOption[] = []
  newOptions.push(getCustomAudienceSelectItem(presets))
  if (hasWeightedPresets)
    newOptions.push({
      id: '901',
      name: 'Weighted audiences',
      value: 'weighted',
      infoText:
        'Weighted preset audiences automatically weigh the results when fieldwork is complete. Because of this, they are not editable. Weighting corrects for any imbalance between the sample we have collected and the composition of the target population.',
      disabled: true,
      boldText: true
    })
  newOptions.push(...presetAudienceToOption(weightedPresets))
  if (hasUnweightedPresets)
    newOptions.push({
      id: '902',
      name: 'Unweighted audiences',
      value: 'unweighted',
      infoText:
        'Unweighted audiences offer no automatic weighting but are more flexible and can be edited to suit your needs.',
      disabled: true,
      boldText: true
    })
  newOptions.push(...presetAudienceToOption(unweightedPresets))

  return newOptions
}
