import { SidebarAudienceEntry } from './SidebarAudienceItemList.query'

export const getAudienceCriteriaListItemPropValues: (
  audienceCriteriaEntry: SidebarAudienceEntry,
  index: number
) => [string, string, string] = (entry, index) => {
  const { entryItem } = entry
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const questionTitle = entryItem.question && entryItem.question.text
  const displayNumber = `A${index + 1}`
  return [entryItem.question.questionId, questionTitle, displayNumber]
}
