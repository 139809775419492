import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { useAddSection } from '../../../components/Section/Section.hooks'
import {
  LogCreateEntryTypeEvent,
  LogCreateQuestionEvent
} from '../../../data/amplitude'
import {
  DraftQuestionItem,
  EntryType,
  QuestionKind,
  QuestionTypeCode
} from '../../../data/model/questionnaire'
import { SurveyParams } from '../../../data/model/surveyParams'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { addQuestionTransactionDatadog } from '../../../tracking/perf/transactions'
import { getForkCount } from '../../../utils/questionnaireUtils'
import { ImportQuestions } from '../ImportQuestions'
import AddQuestionControl from './AddQuestionMenu.control'
import useAddQuestionMenuHook from './AddQuestionMenu.hooks'
import {
  AddQuestionOption,
  AddQuestionSecondaryOption
} from './AddQuestionMenu.model'

enum DEFAULT_OPTIONS_COUNT {
  ZERO = 0,
  AT_LEAST_TWO = 2
}

interface Props {
  showAddButton?: boolean
  showSectionMenu?: boolean
  position: number
  sectionId?: string
}

const AddQuestionMenu: React.FC<Props> = ({
  position,
  showAddButton = false,
  showSectionMenu = false,
  sectionId
}: Props) => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { flattenedEntries, draftQuestionnaire, refetchQuestionnaire } =
    useGetDraftQuestionnaire()

  const [importQuestionsDialogOpen, setImportQuestionsDialogOpen] =
    useState<boolean>(false)

  const questionnaireId = draftQuestionnaire?.questionnaireId || ''

  const newContextPosition = flattenedEntries
    .slice(0, position)
    .filter(
      ({ entryType }) =>
        entryType !== EntryType.TextCardEntryType &&
        entryType !== EntryType.ForkEntryType
    ).length

  const {
    addQuestion,
    addTextCard,
    addMatrixQuestion,
    addFork,
    setQuestionScale
  } = useAddQuestionMenuHook(questionnaireId)

  const addSection = useAddSection(questionnaireId)

  const handleCreateNewQuestion: (
    addQuestionOption: AddQuestionOption
  ) => void = async (addQuestionOption) => {
    const defaultOptionsCount =
      addQuestionOption.questionTypeCode === QuestionTypeCode.FreeText
        ? DEFAULT_OPTIONS_COUNT.ZERO
        : DEFAULT_OPTIONS_COUNT.AT_LEAST_TWO
    addQuestionTransactionDatadog.start()
    switch (addQuestionOption.questionTypeCode) {
      case QuestionTypeCode.Basic:
      case QuestionTypeCode.Ranked:
      case QuestionTypeCode.MaxDiff:
      case QuestionTypeCode.FreeText: {
        const questionLk = nanoid()

        await addQuestion({
          variables: {
            questionnaireId,
            questionTypeCode: addQuestionOption.questionTypeCode,
            position,
            text: '',
            setting: addQuestionOption.settings,
            defaultOptionsCount,
            sectionId
          },
          optimisticResponse: {
            addQuestion: {
              entryItem: {
                question: {
                  __typename: 'Question',
                  createdDate: '',
                  questionId: questionLk,
                  text: '',
                  textStyling: null
                },
                questionLk,
                questionTypeCode: addQuestionOption.questionTypeCode,
                settingValues: addQuestionOption.settings
                  ? [
                      {
                        code: addQuestionOption.settings.questionSettingCode,
                        value: addQuestionOption.settings.settingValue,
                        createdDate: '',
                        sinceDate: '',
                        __typename: 'EntrySettingValue'
                      }
                    ]
                  : [],
                questionLogic: [],
                responseOptions: [],
                maxDiffSpecification: null,
                __typename: 'DraftQuestionItem'
              },
              entryId: questionLk,
              forks: [],
              createdDate: '',
              sinceDate: '',
              sectionId: sectionId || '',
              entryType: EntryType.QuestionEntryType,
              questionKind: QuestionKind.QuestionnaireKind,
              number: -1,
              position,
              contextPosition: newContextPosition,
              loopSpecificDisplayLogic: null,
              __typename: 'DraftQuestionnaireEntry'
            }
          }
        })
        break
      }
      case QuestionTypeCode.Scale: {
        const addedScaleQuestion = await addQuestion({
          variables: {
            questionnaireId,
            questionTypeCode: addQuestionOption.questionTypeCode,
            position,
            text: '',
            defaultOptionsCount: 0,
            sectionId
          }
        })
        await setQuestionScale({
          variables: {
            questionnaireId,
            questionLk:
              (
                addedScaleQuestion.data?.addQuestion.entryItem as
                  | DraftQuestionItem
                  | undefined
              )?.questionLk ?? '',
            min: 0,
            max: 100,
            step: 1,
            startingPosition: 0
          }
        })
        break
      }
      case QuestionTypeCode.Matrix: {
        await addMatrixQuestion({
          variables: {
            questionnaireId,
            position,
            title: '',
            setting: addQuestionOption.settings,
            sectionId
          }
        })
        break
      }
      default:
    }
    LogCreateQuestionEvent(
      surveyId,
      addQuestionOption.questionTypeCode,
      addQuestionOption.settings,
      sectionId
    )
  }

  const handleCreateSecondaryCard: (
    addQuestionSecondaryOption: AddQuestionSecondaryOption
  ) => void = async (addQuestionSecondaryOption) => {
    switch (addQuestionSecondaryOption.entryType) {
      case EntryType.ForkEntryType:
        addFork({
          variables: {
            questionnaireId,
            position,
            name: `Fork ${getForkCount(draftQuestionnaire?.entries)}`,
            defaultBranchCount: 2
          }
        })
        break
      case EntryType.TextCardEntryType:
        addTextCard({
          variables: {
            questionnaireId,
            position,
            title: '',
            subtitle: '',
            body: '',
            sectionId
          }
        })
        break
      case EntryType.SectionEntryType: {
        addSection(position)
        break
      }
      default:
    }
    LogCreateEntryTypeEvent(surveyId, addQuestionSecondaryOption.entryType)
  }

  const handleImportFromOtherSurvey = (): void => {
    setImportQuestionsDialogOpen(true)
    LogAmplitudeEvent(EventType.OpenedImportQuestions, { surveyId })
  }

  return (
    <>
      <AddQuestionControl
        showAddButton={showAddButton}
        showSectionMenu={showSectionMenu}
        onCreateNewQuestion={handleCreateNewQuestion}
        onCreateSecondaryEntry={handleCreateSecondaryCard}
        onImportFromOtherSurveyClick={handleImportFromOtherSurvey}
      />
      {importQuestionsDialogOpen && (
        <ImportQuestions
          position={position}
          refetchQuestionnaire={refetchQuestionnaire}
          onClose={() => setImportQuestionsDialogOpen(false)}
        />
      )}
    </>
  )
}

export default React.memo(AddQuestionMenu)
