import { makeVar, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../App.store'
import {
  EmptyMatrixQuestionMutationVariables,
  EmptyQuestionCardMutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import {
  EMPTY_MATRIX_QUESTION,
  EmptyMatrixQuestionData
} from '../../data/gql/questionnaire/mutations/emptyMatrixQuestion'
import {
  EMPTY_QUESTION_CARD,
  EmptyQuestionData
} from '../../data/gql/questionnaire/mutations/emptyQuestion'
import { EntryType } from '../../data/model/questionnaire'
import { SurveyParams } from '../../data/model/surveyParams'
import { questionCardEmptied } from '../../modules/Questionnaire/Questionnaire.slice'

export const questionCardEmptiedVar = makeVar<Record<string, boolean>>({})

export const useEmptyQuestionCardMutation = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const dispatch = useAppDispatch()

  const [emptyQuestionCard] = useMutation<
    EmptyQuestionData,
    EmptyQuestionCardMutationVariables
  >(EMPTY_QUESTION_CARD, {
    context: { clientName: 'questionnaire' },
    onCompleted: ({ emptyQuestionCard }) => {
      if (emptyQuestionCard.entryType === EntryType.QuestionEntryType) {
        const { questionLk } = emptyQuestionCard.entryItem
        questionCardEmptiedVar({ [questionLk]: true })
        dispatch(
          questionCardEmptied({
            questionId: questionLk
          })
        )
      }
    }
  })

  const [emptyMatrixQuestion] = useMutation<
    EmptyMatrixQuestionData,
    EmptyMatrixQuestionMutationVariables
  >(EMPTY_MATRIX_QUESTION, {
    context: { clientName: 'questionnaire' },
    onCompleted: ({ emptyMatrixQuestion }) => {
      if (emptyMatrixQuestion.entryType === EntryType.MatrixEntryType) {
        const { matrixTitleLk } = emptyMatrixQuestion.entryItem
        questionCardEmptiedVar({ [matrixTitleLk]: true })
        dispatch(
          questionCardEmptied({
            questionId: matrixTitleLk
          })
        )
      }
    }
  })

  const emptyQuestionCardMutation = useCallback(
    (questionLk: string): void => {
      emptyQuestionCard({
        variables: {
          questionnaireId: surveyId,
          questionLk
        }
      })
    },
    [surveyId, emptyQuestionCard]
  )

  const emptyMatrixQuestionMutation = useCallback(
    (matrixTitleLk: string): void => {
      emptyMatrixQuestion({
        variables: {
          questionnaireId: surveyId,
          matrixTitleLk
        }
      })
    },
    [surveyId, emptyMatrixQuestion]
  )

  return {
    emptyQuestionCardMutation,
    emptyMatrixQuestionMutation
  }
}
