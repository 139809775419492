import { gql } from '@apollo/client/core'
import { DetachedEntryResponseOption } from '../../../model/questionnaire'

export interface DetachResponseOptionData {
  detachResponseOption: DetachedEntryResponseOption
}

export const DETACH_RESPONSE_OPTION = gql`
  mutation detachResponseOption(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
  ) {
    detachResponseOption(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
    ) {
      questionnaireId
      questionLk
      responseOptionLk
      detachedDate
    }
  }
`
