import {
  FetchResult,
  MutationFunctionOptions,
  useMutation,
  useQuery
} from '@apollo/client'
import { useContext } from 'react'
import {
  RemoveStandardCriterionMutationVariables,
  SetStandardCriterionMutationVariables
} from '../../data/gql-gen/fieldwork/graphql'
import {
  FieldworkAudienceCriterionData,
  REMOVE_CRITERION,
  SET_CRITERION
} from '../../data/gql/fieldwork/mutations/fieldworkAudienceCriterion'
import {
  EXPANDED_AUDIENCE_CATEGORIES,
  FieldworkAudienceCategoryData,
  GET_AUDIENCE_CATEGORIES
} from '../../data/gql/fieldwork/queries/categories'
import { fieldworkAudienceRefetchQuery } from '../../data/gql/fieldwork/queries/fieldworkAudience'
import { DraftAudience } from '../../data/gql/questionnaire/queries/audiences'
import { getFeasibilityEstimateRefetchQuery } from '../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { LoggerErrorType } from '../../data/logger'
import AudienceContext, {
  setNewAudienceQuestionLk
} from '../../modules/Audience/Audience.context'
import { captureApolloError } from '../../utils/HelperFunctions'
import useGetLoi from '../useGetLoi'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import useGetDraftAudience from './useGetDraftAudience'

interface Data {
  audienceCategories: FieldworkAudienceCategoryData | undefined
  audienceQuestions: DraftAudience | undefined
  setCriterion: (
    options?: MutationFunctionOptions<
      FieldworkAudienceCriterionData,
      SetStandardCriterionMutationVariables
    >
  ) => Promise<FetchResult<FieldworkAudienceCriterionData>>
  removeCriterion: (
    options?: MutationFunctionOptions<
      FieldworkAudienceCriterionData,
      RemoveStandardCriterionMutationVariables
    >
  ) => Promise<FetchResult<FieldworkAudienceCriterionData>>
}

const useStandardAudienceListGqlOperations: () => Data = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const loi = useGetLoi()
  const { data: audienceCategories, refetch: refetchCategories } =
    useQuery<FieldworkAudienceCategoryData>(GET_AUDIENCE_CATEGORIES, {
      context: { clientName: 'fieldwork' },
      variables: {
        surveyId,
        expandedCategories: EXPANDED_AUDIENCE_CATEGORIES
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    })

  const { draftAudience: audienceQuestions, refetchAudience } =
    useGetDraftAudience({
      notifyOnNetworkStatusChange: true
    })

  const { dispatch } = useContext(AudienceContext)

  const [setCriterion] = useMutation<
    FieldworkAudienceCriterionData,
    SetStandardCriterionMutationVariables
  >(SET_CRITERION, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'setCriterion', error)
    },
    onCompleted: async (data: FieldworkAudienceCriterionData) => {
      refetchCategories()
      await refetchAudience()
      dispatch(
        setNewAudienceQuestionLk(
          data.setStandardCriterion.criterion.question.questionId
        )
      )
    },
    refetchQueries: [
      getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  })

  const [removeCriterion] = useMutation<
    FieldworkAudienceCriterionData,
    RemoveStandardCriterionMutationVariables
  >(REMOVE_CRITERION, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeCriterion',
        error
      )
    },
    onCompleted: () => {
      refetchAudience()
      refetchCategories()
    },
    refetchQueries: [
      getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  })

  return {
    audienceCategories,
    audienceQuestions,
    setCriterion,
    removeCriterion
  }
}

export default useStandardAudienceListGqlOperations
