import { ApolloError, ApolloQueryResult, useMutation } from '@apollo/client'
import {
  DraftQuestionnaireV2QueryVariables,
  RemoveQuestionMediaMutationVariables,
  RemoveTextCardMediaMutationVariables,
  SetQuestionMediaMutationVariables,
  SetTextCardMediaMutationVariables
} from '../../../../data/gql-gen/questionnaire/graphql'
import { REMOVE_QUESTION_MEDIA } from '../../../../data/gql/questionnaire/mutations/removeQuestionMedia'
import { REMOVE_TEXT_CARD_MEDIA } from '../../../../data/gql/questionnaire/mutations/removeTextCardMedia'
import { SET_QUESTION_MEDIA } from '../../../../data/gql/questionnaire/mutations/setQuestionMedia'
import { SET_TEXT_CARD_MEDIA } from '../../../../data/gql/questionnaire/mutations/setTextCardMedia'
import { QuestionnaireData } from '../../../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../../../data/logger'
import { captureApolloError } from '../../../../utils/HelperFunctions'

const useMediaUploaderMutations = (
  refetchQuestionnaire: (
    variables?: Partial<DraftQuestionnaireV2QueryVariables> | undefined
  ) => Promise<ApolloQueryResult<QuestionnaireData>>
) => {
  const getMutationOptions = (apolloTag: string) => {
    return {
      context: { clientName: 'questionnaire' },
      onError: (error: ApolloError) => {
        captureApolloError(LoggerErrorType.ApolloMutation, apolloTag, error)
      },
      onCompleted: () => refetchQuestionnaire()
    }
  }

  const [setQuestionMedia] = useMutation<
    void,
    SetQuestionMediaMutationVariables
  >(SET_QUESTION_MEDIA, getMutationOptions('addQuestionImage'))
  const [setTextCardMedia] = useMutation<
    void,
    SetTextCardMediaMutationVariables
  >(SET_TEXT_CARD_MEDIA, getMutationOptions('setTextCardMedia'))
  const [removeQuestionMedia] = useMutation<
    void,
    RemoveQuestionMediaMutationVariables
  >(REMOVE_QUESTION_MEDIA, getMutationOptions('removeQuestionImage'))
  const [removeTextCardMedia] = useMutation<
    void,
    RemoveTextCardMediaMutationVariables
  >(REMOVE_TEXT_CARD_MEDIA, getMutationOptions('removeTextCardMedia'))

  return {
    setQuestionMedia,
    setTextCardMedia,
    removeQuestionMedia,
    removeTextCardMedia
  }
}

export default useMediaUploaderMutations
