import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import {
  CreateOrUpdateMatrixRowForksMutationVariables,
  CreateOrUpdateMatrixRowMaskingRuleMutationVariables,
  DraftMaskingRule,
  DraftMatrixRow,
  ForkTag
} from '../../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import { SurveyParams } from '../../../../data/model/surveyParams'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import {
  CREATE_OR_UPDATE_MATRIX_ROW_FORKS,
  CREATE_OR_UPDATE_MATRIX_ROW_MASKING_RULE,
  SetMatrixRowForkTagData
} from './MaskingButton.mutations'
import { CreateOrUpdateMatrixMaskingRuleData } from './MatrixMaskingButton.query'

const useMaskingButtonHooks = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!

  const [createOrUpdateMatrixRowMaskingRule] = useMutation<
    CreateOrUpdateMatrixMaskingRuleData,
    CreateOrUpdateMatrixRowMaskingRuleMutationVariables
  >(CREATE_OR_UPDATE_MATRIX_ROW_MASKING_RULE, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const [createOrUpdateMatrixRowForks] = useMutation<
    SetMatrixRowForkTagData,
    CreateOrUpdateMatrixRowForksMutationVariables
  >(CREATE_OR_UPDATE_MATRIX_ROW_FORKS, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'setMatrixRowForkTag',
        error
      )
    }
  })

  const setMatrixRowMaskingRule: (
    matrixTitleLk: string,
    questionLk: string,
    maskingRules: DraftMaskingRule[]
  ) => Promise<void> = async (matrixTitleLk, questionLk, maskingRules) => {
    await createOrUpdateMatrixRowMaskingRule({
      variables: {
        questionnaireId: surveyId,
        matrixTitleLk,
        questionLk,
        maskingRules
      }
    })
  }

  const setMatrixRowForks: (
    matrixTitleLk: string,
    row: DraftMatrixRow,
    forks: ForkTag[]
  ) => Promise<void> = async (matrixTitleLk, row, forks) => {
    await createOrUpdateMatrixRowForks({
      variables: {
        questionnaireId: surveyId,
        matrixTitleLk,
        questionLk: row.questionLk,
        forks
      }
    })
  }

  return { setMatrixRowMaskingRule, setMatrixRowForks }
}

export default useMaskingButtonHooks
