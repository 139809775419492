import { gql } from '@apollo/client/core'

export const REMOVE_TEXT_CARD_MEDIA = gql`
  mutation removeTextCardMedia($questionnaireId: ID!, $textCardLk: ID!) {
    removeTextCardMedia(
      questionnaireId: $questionnaireId
      textCardLk: $textCardLk
    )
  }
`
