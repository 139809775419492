import { gql } from '@apollo/client/core'
import { DraftQuestionnaire } from '../../../model/questionnaire'

export interface AddQuestionnaireData {
  addQuestionnaire: DraftQuestionnaire
}

export const ADD_QUESTIONNAIRE = gql`
  mutation addQuestionnaire($surveyId: ID!) {
    addQuestionnaire(surveyId: $surveyId) {
      questionnaireId
    }
  }
`
