import { useMutation } from '@apollo/client'
import { useSurveyId } from '../../useSurveyId'
import {
  REMOVE_LOOP_SPECIFIC_DISPLAY_LOGIC,
  SET_LOOP_SPECIFIC_DISPLAY_LOGIC
} from './useLoopSpecificDisplayLogic.questionnaire.gql'

const useLoopSpecificDisplayLogicMutations = () => {
  const [setLoopSpecificDisplayLogicMutation] = useMutation(
    SET_LOOP_SPECIFIC_DISPLAY_LOGIC,
    {
      context: {
        clientName: 'questionnaire'
      }
    }
  )

  const [removeLoopSpecificDisplayLogicMutation] = useMutation(
    REMOVE_LOOP_SPECIFIC_DISPLAY_LOGIC,
    {
      context: {
        clientName: 'questionnaire'
      }
    }
  )
  const questionnaireId = useSurveyId()
  const setLoopSpecificDisplayLogic = async (
    entryNumber: number,
    sourceIds: string[]
  ) => {
    await setLoopSpecificDisplayLogicMutation({
      variables: {
        entryNumber,
        questionnaireId,
        sourceIds
      }
    })
  }

  const removeLoopSpecificDisplayLogic = async (entryNumber: number) => {
    await removeLoopSpecificDisplayLogicMutation({
      variables: {
        entryNumber,
        questionnaireId
      }
    })
  }

  return {
    setLoopSpecificDisplayLogic,
    removeLoopSpecificDisplayLogic
  }
}

export default useLoopSpecificDisplayLogicMutations
