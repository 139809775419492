import { gql } from '@apollo/client'
import { DraftFieldworkAudienceMember } from '../../../../data/model/audience'

export interface MoveAudienceMemberData {
  moveAudienceMember: DraftFieldworkAudienceMember
}

const MOVE_AUDIENCE_MEMBER = gql`
  mutation moveAudienceMember(
    $surveyId: ID!
    $number: Int!
    $toPosition: Int!
  ) {
    moveAudienceMember(
      surveyId: $surveyId
      number: $number
      toPosition: $toPosition
    ) {
      number
      position
    }
  }
`

export default MOVE_AUDIENCE_MEMBER
