import { gql } from '@apollo/client/core'
import { DraftEntryResponseOption } from '../../../model/questionnaire'
import DRAFT_ENTRY_RESPONSE_OPTION from '../fragments/draftEntryResponseOption'

export interface MoveResponseOptionData {
  moveResponseOption: DraftEntryResponseOption
}

export const MOVE_RESPONSE_OPTION = gql`
  mutation moveResponseOption(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $toPosition: Int!
  ) {
    moveResponseOption(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      toPosition: $toPosition
    ) {
      ...DraftEntryResponseOption
    }
  }
  ${DRAFT_ENTRY_RESPONSE_OPTION}
`
