import { Dialog, DialogVariant, Text } from '@focaldata/cin-ui-components'
import { useContext, useEffect, useMemo, useState } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { ProjectType } from '../../../data/gql-gen/dashboard/graphql'
import usePresetAudience, {
  AudiencePreset,
  CUSTOM_AUDIENCE_PRESET_NAME,
  usePresetAudiences
} from '../../../hooks/audience/usePresetAudience'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { useProjectData } from '../../Project/Project.hooks'
import AudienceContext, {
  setAudiencePresetWheighting
} from '../Audience.context'
import AudiencePresetPicker from './AudiencePresetPicker.control'
import AudiencePresetPickerLoading from './AudiencePresetPickerLoading'

const isCustomPreset = (newPresetName?: string) =>
  newPresetName === CUSTOM_AUDIENCE_PRESET_NAME

const AudiencePresetPickerContainer = ({
  onChoseCustomPreset
}: {
  onChoseCustomPreset?: () => void
}) => {
  const surveyId = useSurveyId()
  const [confirmChangeOpen, setConfirmChangeOpen] = useState<boolean>(false)
  const [presetToChangeTo, setPresetToChangeTo] = useState<AudiencePreset>()
  const [customAudienceDialogIsOpen, setCustomAudienceDialogIsOpen] =
    useState<boolean>(false)
  const { dispatch, audienceState } = useContext(AudienceContext)
  const { project } = useProjectData()
  const isFdchatProject = project?.projectType === ProjectType.FdChat

  const { setPreset: setPresetAud, loading } = usePresetAudience()

  const { fieldwork, loading: loadingFieldwork, refetch } = useFieldwork()
  const { data: presetAudienceData } = usePresetAudiences({
    countryCode: fieldwork?.locale.country || '',
    language: fieldwork?.locale.language || ''
  })

  const presets = useMemo(() => {
    const presetAudiences = presetAudienceData || []

    if (isFdchatProject) {
      return presetAudiences.filter(({ name }) =>
        ['Custom', 'GB Nat Rep (18+)', 'Gender', 'US Nat Rep (18+)'].includes(
          name.trim()
        )
      )
    }

    return presetAudiences
  }, [presetAudienceData, isFdchatProject])

  const { presetAudienceId, name: presetName } = fieldwork?.presetAudience || {}

  useEffect(() => {
    const isPresetWeighted = presets.find(
      (p) => p.presetAudienceId === presetAudienceId && p.isWeighted
    )
    dispatch(setAudiencePresetWheighting(!!isPresetWeighted))
  }, [presets, presetAudienceId, dispatch])

  const handlePresetChosen = async (newPreset: AudiencePreset) => {
    // if the preset was custom and the new preset it not, open the Dialog
    if (isCustomPreset(presetName) && !isCustomPreset(newPreset.name)) {
      setPresetToChangeTo(newPreset)
      setConfirmChangeOpen(true)
    } else if (isCustomPreset(newPreset.name)) {
      setPresetToChangeTo(newPreset)
      setCustomAudienceDialogIsOpen(true)
    } else {
      await setPresetAud(newPreset)
    }
    await refetch()
  }

  const validatedPresetAudienceId = (
    (presetAudienceId
      ? presets.find((preset) => preset.presetAudienceId === presetAudienceId)
      : undefined) ?? presets.find((preset) => isCustomPreset(preset.name))
  )?.presetAudienceId

  if (loading || loadingFieldwork || !validatedPresetAudienceId)
    return <AudiencePresetPickerLoading />

  const presetAudience = {
    title: 'Preset audience',
    text: `Please confirm you'd like to switch your sample to ${presetToChangeTo?.name}`,
    audienceCallback: async () => {
      if (confirmChangeOpen && presetToChangeTo) {
        await setPresetAud(presetToChangeTo)
        setPresetToChangeTo(undefined)
      }
      setConfirmChangeOpen(false)
      await refetch()

      LogAmplitudeEvent(EventType.ChangedAudiencePreset, {
        currentPreset: presetName,
        changeToPreset: presetToChangeTo?.name
      })
    }
  }

  const customAudience = {
    title: 'Custom audience',
    text: "Please confirm you'd like to change your audience to custom. If your current project includes any targeting or screening questions, these questions will be removed.",
    audienceCallback: async () => {
      if (customAudienceDialogIsOpen && presetToChangeTo) {
        await setPresetAud(presetToChangeTo)
        onChoseCustomPreset?.()
        setPresetToChangeTo(undefined)
      }
      setCustomAudienceDialogIsOpen(false)
      onChoseCustomPreset?.()
      await refetch()

      LogAmplitudeEvent(EventType.SelectedCustomAudience, { surveyId })
    }
  }

  const audienceDialogContent = confirmChangeOpen
    ? presetAudience
    : customAudience

  return (
    <>
      <AudiencePresetPicker
        presets={presets}
        selectedPresetName={validatedPresetAudienceId}
        onPresetChosen={handlePresetChosen}
        isSelectedPresetWeighted={audienceState.isWeighted}
      />
      <Dialog
        variant={DialogVariant.SingleButton}
        title={audienceDialogContent.title}
        primaryButtonText="Confirm"
        onClose={() => {
          setConfirmChangeOpen(false)
          setCustomAudienceDialogIsOpen(false)
        }}
        primaryButtonClick={audienceDialogContent.audienceCallback}
        open={confirmChangeOpen || customAudienceDialogIsOpen}
      >
        <Text>{audienceDialogContent.text}</Text>
      </Dialog>
    </>
  )
}

export default AudiencePresetPickerContainer
