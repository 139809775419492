import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface EmptyQuestionData {
  emptyQuestionCard: DraftQuestionnaireEntry
}

export const EMPTY_QUESTION_CARD = gql`
  mutation emptyQuestionCard($questionnaireId: ID!, $questionLk: ID!) {
    emptyQuestionCard(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftQuestionItem {
          question {
            ...Question
          }
          questionLk
        }
      }
      __typename
    }
  }
`
