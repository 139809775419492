import {
  Button,
  ButtonVariant,
  Option,
  SelectOption
} from '@focaldata/cin-ui-components'
import { Dispatch, SetStateAction, useState } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { ProjectType } from '../../../data/gql-gen/dashboard/graphql'
import { useAudienceCountries } from '../../../hooks/audience/useAudienceCountries'
import useCreateProject from '../../../hooks/project/useCreateProject'
import {
  reorderCountriesDropDown,
  suggestedCountryOption
} from '../../../utils/HelperFunctions'
import CreateProjectDialog from './CreateProjectDialog'

const DEFAULT_OPTION: Option = {
  id: 'GB-132',
  countryCode: 'GB',
  name: 'United Kingdom',
  value: 'GB-en'
}

const getNewProjectButtonVariant = (projectType?: ProjectType) => {
  switch (projectType) {
    case ProjectType.AdHoc:
      return ButtonVariant.PrimaryFilled
    case ProjectType.MultiMarketV2:
      return ButtonVariant.SecondaryOutlined
    case ProjectType.FdChat:
      return ButtonVariant.SecondaryFilled
    default:
      return ButtonVariant.PrimaryOutlined
  }
}

const CreateProject = ({
  isDialogOpen,
  setIsDialogOpen,
  dialogTitle,
  type,
  hideCountry = false,
  hideCompletes = false
}: {
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  dialogTitle?: string
  type?: ProjectType
  hideCountry?: boolean
  hideCompletes?: boolean
}) => {
  const [projectType, setProjectType] = useState(ProjectType.AdHoc)
  const [country, setCountry] = useState<SelectOption>(DEFAULT_OPTION)
  const [completes, setCompletes] = useState<number>(2000)
  const { data: dataCountries } = useAudienceCountries()

  const isMultiMarketProjectSelected = projectType === ProjectType.MultiMarketV2
  const shouldHideCountry = hideCountry || isMultiMarketProjectSelected
  const shouldHideCompletes = hideCompletes || isMultiMarketProjectSelected

  const {
    loadingGetUserAndAccount,
    loadingCreateProject,
    handleCreateProject
  } = useCreateProject()

  const countriesOptions = reorderCountriesDropDown(
    dataCountries?.countries ?? []
  )

  // TODO: Set it to "suggestedCountries(options)" when we have all countries
  const suggestedCountries = suggestedCountryOption(undefined)

  const getLanguageByValue = (value: string) => {
    const language = value.split('-')[1]
    return language
  }

  const handleButtonClick = () => {
    setIsDialogOpen(!isDialogOpen)
    LogAmplitudeEvent(EventType.ClickedCreateProject, {
      projectType: type
    })
  }

  const variant = getNewProjectButtonVariant(type)

  return (
    <>
      <Button
        onClick={handleButtonClick}
        disabled={loadingGetUserAndAccount}
        dataCy="dialog-button-opener"
        style={{ width: 'max-content' }}
        variant={variant}
      >
        Create new project
      </Button>
      <CreateProjectDialog
        completes={completes}
        country={country}
        defaultOption={DEFAULT_OPTION}
        open={isDialogOpen}
        options={countriesOptions}
        setCompletes={shouldHideCompletes ? undefined : setCompletes}
        setProjectType={!type ? setProjectType : undefined}
        setCountry={shouldHideCountry ? undefined : setCountry}
        setOpen={setIsDialogOpen}
        suggestedOptions={suggestedCountries}
        primaryButtonClick={(title) =>
          handleCreateProject({
            title,
            projectType: type ?? projectType,
            countryCode: country.countryCode,
            countryLanguage: getLanguageByValue(country.value as string),
            samplesNeeded: completes
          })
        }
        loadingCreateProject={loadingCreateProject}
        title={dialogTitle}
        dataDdActionNamePrimaryButton={`Create new project (Dialog - ${
          type ?? projectType
        })`}
      />
    </>
  )
}

export default CreateProject
