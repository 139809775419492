import { gql } from '@apollo/client/core'
import { DetachedQuestionnaireEntry } from '../../../model/questionnaire'

export interface DetachQuestionnaireEntryData {
  detachQuestionnaireEntry: DetachedQuestionnaireEntry
}

export const DETACH_QUESTIONNAIRE_ENTRY = gql`
  mutation detachQuestionnaireEntry(
    $questionnaireId: ID!
    $number: Int!
    $detachAll: Boolean
  ) {
    detachQuestionnaireEntry(
      questionnaireId: $questionnaireId
      number: $number
      detachAll: $detachAll
    ) {
      number
      __typename
    }
  }
`
