import { makeVar } from '@apollo/client'
import { useCallback } from 'react'
import { RootState, useAppDispatch, useAppSelector } from '../../App.store'
import { PositionTextSelection as TextSelectionEventData } from '../../data/model/questionnaire'
import {
  responseOptionPasted,
  selectResponseOptionsByQuestionId
} from '../../modules/Questionnaire/Questionnaire.slice'
import { usePasteMutations } from '../questionnaire/usePasteMutations'
import { replacingTextAt, splitTextIntoLinesAndCleanup } from './utils'

// this is used when we need to signal that an uncontrolled input needs to update its value
// from application data. Like on copy/paste or on reorder
export const IsNonManualUIChange = makeVar<boolean>(false)

export const onChangePasteAcceptingInput: (callback: () => void) => void = (
  callback
) => {
  IsNonManualUIChange(false)
  callback()
}

interface UseCopyPasteCompleteData {
  // This one takes a text and formats and adds the title and however many response
  // options. Also cleans up bullets or such. An empty line is required between question and responses
  pasteToTitle: (
    newTitle: string,
    newTitleStyling: string,
    text: string[]
  ) => Promise<void>
  // This one takes a text and formats and adds response options. Also cleans up
  pasteToResponseOption: (
    text: string,
    questionLk: string,
    position: number,
    positionSelectedText: TextSelectionEventData
  ) => Promise<void>
}

const useCopyPasteComplete: (questionLk: string) => UseCopyPasteCompleteData = (
  questionLk
) => {
  const dispatch = useAppDispatch()
  const selectById = useCallback(
    (state: RootState) => selectResponseOptionsByQuestionId(state, questionLk),
    [questionLk]
  )

  const responseOptions = useAppSelector(selectById)

  const { pasteInTitleMutation, pasteInResponsesMutation } = usePasteMutations()

  const pasteToTitle = useCallback(
    async (
      newTitle: string,
      newTitleStyling: string,
      text: string[]
    ): Promise<void> => {
      dispatch(
        responseOptionPasted({
          questionLk,
          position: 0,
          responseOptionTexts: text
        })
      )

      await pasteInTitleMutation(questionLk, newTitle, newTitleStyling, text)
    },
    [dispatch, pasteInTitleMutation, questionLk]
  )

  const pasteToResponseOption: (
    text: string,
    questionLk: string,
    position: number,
    positionSelectedText: TextSelectionEventData
  ) => Promise<void> = useCallback(
    async (text, questionLk, position, positionSelectedText) => {
      const responseOptionTexts = splitTextIntoLinesAndCleanup(
        text,
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !!positionSelectedText?.startPositionSelectedText
      )

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!responseOptionTexts || responseOptionTexts.length < 1) {
        // TODO: show some error message here?
        return
      }
      let updatedResponseOptionAtPosition =
        responseOptions[position].responseOption.value

      if (
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        positionSelectedText &&
        typeof positionSelectedText.startPositionSelectedText === 'number' &&
        typeof positionSelectedText.endPositionSelectedText === 'number'
      ) {
        updatedResponseOptionAtPosition = replacingTextAt(
          responseOptions[position].responseOption.value,
          responseOptionTexts[0],
          positionSelectedText.startPositionSelectedText,
          positionSelectedText.endPositionSelectedText
        )
      }

      // update UI with pasted response options before actual update on backend
      dispatch(
        responseOptionPasted({
          questionLk,
          position,
          responseOptionTexts,
          positionSelectedText
        })
      )
      responseOptionTexts.splice(0, 1, updatedResponseOptionAtPosition)

      await pasteInResponsesMutation(questionLk, responseOptionTexts, position)
    },
    [dispatch, pasteInResponsesMutation, responseOptions]
  )

  return { pasteToTitle, pasteToResponseOption }
}

export default useCopyPasteComplete
