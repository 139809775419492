import { ApolloClient } from '@apollo/client'
import { AppDispatch, RootState } from '../../App.store'
import { createAppAsyncThunk } from '../../createAppAsyncThunk'
import {
  AddMatrixResponseOptionMutationVariables,
  AddResponseOptionMutationVariables,
  DetachResponseOptionMutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import {
  ADD_MATRIX_RESPONSE_OPTION,
  AddMatrixResponseOptionData
} from '../../data/gql/questionnaire/mutations/addMatrixResponseOption'
import {
  ADD_RESPONSE_OPTION,
  AddResponseOptionData
} from '../../data/gql/questionnaire/mutations/addResponseOption'
import { DETACH_MATRIX_RESPONSE_OPTION } from '../../data/gql/questionnaire/mutations/detachMatrixResponseOption'
import {
  DETACH_RESPONSE_OPTION,
  DetachResponseOptionData
} from '../../data/gql/questionnaire/mutations/detachResponseOption'
import {
  DraftEntryResponseOption,
  EntryType
} from '../../data/model/questionnaire'
import { selectResponseOptionsByQuestionId } from './Questionnaire.slice'

export const scaleConfig = {
  Agree1To5: [
    'Strongly disagree',
    'Somewhat disagree',
    'Neutral',
    'Somewhat agree',
    'Strongly agree'
  ]
}

/**
 * @warning Not for external use, only exported to use in the slice
 * @use addResponseScaleThunk
 */
export const _addResponseScaleThunk = createAppAsyncThunk(
  'questionnaire/addResponseScale',
  async ({
    questionnaireId,
    questionLk,
    entryType,
    scale,
    apolloClient,
    responseOptions
  }: {
    questionnaireId: string
    questionLk: string
    entryType: EntryType
    scale: keyof typeof scaleConfig
    apolloClient: ApolloClient<unknown>
    // We need the response options prior to the pending action as this changes
    // them, so these are passed by a wrapping thunk
    responseOptions: DraftEntryResponseOption[]
  }) => {
    if (
      entryType !== EntryType.MatrixEntryType &&
      entryType !== EntryType.QuestionEntryType
    ) {
      throw new Error(`Cannot set scale on entry type ${entryType}`)
    }

    switch (entryType) {
      case EntryType.QuestionEntryType:
        await Promise.all(
          responseOptions.map(async (responseOption) => {
            await apolloClient.mutate<
              DetachResponseOptionData,
              DetachResponseOptionMutationVariables
            >({
              mutation: DETACH_RESPONSE_OPTION,
              context: { clientName: 'questionnaire' },
              variables: {
                questionnaireId,
                questionLk,
                responseOptionLk: responseOption.responseOptionLk
              },
              fetchPolicy: 'no-cache'
            })
          })
        )
        break

      case EntryType.MatrixEntryType:
        await Promise.all(
          responseOptions.map(async (responseOption) => {
            await apolloClient.mutate({
              mutation: DETACH_MATRIX_RESPONSE_OPTION,
              context: { clientName: 'questionnaire' },
              variables: {
                questionnaireId,
                responseOptionLk: responseOption.responseOptionLk,
                matrixTitleLk: questionLk
              },
              fetchPolicy: 'no-cache'
            })
          })
        )
        break
    }

    const scaleResponseOptions = scaleConfig[scale]

    const createdResponseOptions: {
      position: number
      responseOption: DraftEntryResponseOption
    }[] = []

    switch (entryType) {
      case EntryType.QuestionEntryType: {
        for (const [
          index,
          responseOptionValue
        ] of scaleResponseOptions.entries()) {
          const { data } = await apolloClient.mutate<
            AddResponseOptionData,
            AddResponseOptionMutationVariables
          >({
            context: { clientName: 'questionnaire' },
            mutation: ADD_RESPONSE_OPTION,
            variables: {
              questionnaireId,
              questionLk,
              position: index,
              responseOptionValue
            },
            fetchPolicy: 'no-cache'
          })

          if (data) {
            createdResponseOptions.push({
              position: data.addResponseOption.position,
              responseOption: data.addResponseOption
            })
          }
        }
        break
      }
      case EntryType.MatrixEntryType: {
        for (const [
          index,
          responseOptionValue
        ] of scaleResponseOptions.entries()) {
          const { data } = await apolloClient.mutate<
            AddMatrixResponseOptionData,
            AddMatrixResponseOptionMutationVariables
          >({
            context: { clientName: 'questionnaire' },
            mutation: ADD_MATRIX_RESPONSE_OPTION,
            variables: {
              questionnaireId,
              matrixTitleLk: questionLk,
              position: index,
              responseOptionValue
            },
            fetchPolicy: 'no-cache'
          })

          if (data) {
            createdResponseOptions.push({
              position: data.addMatrixResponseOption.position,
              responseOption: data.addMatrixResponseOption
            })
          }
        }
        break
      }
    }

    return {
      createdResponseOptions,
      questionLk
    }
  }
)

// We wrap the thunk so we can pass the response options prior to the pending
// action modifying them
export const addResponseScale =
  (
    args: Omit<Parameters<typeof _addResponseScaleThunk>[0], 'responseOptions'>
  ) =>
  (dispatch: AppDispatch, getState: () => RootState) =>
    dispatch(
      _addResponseScaleThunk({
        ...args,
        responseOptions: selectResponseOptionsByQuestionId(
          getState(),
          args.questionLk
        )
      })
    ).unwrap()
