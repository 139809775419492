import {
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption
} from '@focaldata/cin-ui-components'
import { useContext } from 'react'
import {
  DraftMatrixItem,
  DraftQuestionnaireEntry,
  QuestionSettingCode,
  QuestionTypeCode
} from '../../../data/model/questionnaire'
import { isFocaldataUser } from '../../../utils/authUtils'
import { hasDisplayLogic } from '../../../utils/questionLogic'
import {
  getQuestionType,
  isSettingEnabled
} from '../../../utils/questionnaireUtils'
import QuestionnaireContext, {
  openAddMediaDialog,
  openMatrixScreeningDialog,
  openQuestionTimerDialog,
  openQuestionsDialogPiping
} from '../Questionnaire.context'
import useEntrySettingValues from '../SurveyQuestionCardHeader/useEntrySettingValues'
import useLoopSourceOptions from '../SurveyQuestionCardHeader/useLoopSourceOptions'
import useLoopSpecificDisplayLogicData from '../SurveyQuestionCardHeader/useLoopSpecificDisplayLogicData'
import useLoopingConfigData from '../SurveyQuestionCardHeader/useLoopingConfigData'

interface SurveyQuestionHeaderActionsContainerProps {
  hasQuestionMedia: boolean
  hasQuestionTimer: boolean
  disabledDuplicate: boolean
  disableDisplayLogic: boolean
  onClickDuplicateQuestion?: () => void
  onClickAddDisplayLogic?: () => void
  onClickAddLoopSpecificDisplayLogic?: () => void
  onEmptyQuestionCard?: () => void
  entry: DraftQuestionnaireEntry
}

enum SurveyQuestionHeaderActionsContainerOptions {
  RANDOMISE_QUESTION_ORDER,
  ADD_LOOPING,
  DELETE_SECTION,
  DUPLICATE,
  ADD_DISPLAY_LOGIC,
  ADD_LOOP_BASED_DISPLAY_LOGIC,
  ADD_MEDIA,
  HIDE_MEDIA,
  ADD_QUESTION_TIMER,
  PIPE,
  EMPTY,
  MATRIX_SCREENING
}

const SurveyQuestionHeaderActionsContainer = ({
  hasQuestionMedia,
  hasQuestionTimer,
  disabledDuplicate,
  disableDisplayLogic,
  onClickDuplicateQuestion,
  onClickAddDisplayLogic,
  onClickAddLoopSpecificDisplayLogic,
  onEmptyQuestionCard,
  entry
}: SurveyQuestionHeaderActionsContainerProps) => {
  const questionCardType = getQuestionType(entry)
  const { dispatch } = useContext(QuestionnaireContext)
  const loopSpecificDisplayLogicData = useLoopSpecificDisplayLogicData(
    entry.number
  )

  const settingValues = useEntrySettingValues(entry.number)
  const sourceMediaDisabled = isSettingEnabled(
    settingValues,
    QuestionSettingCode.DisableSourceMedia
  )

  const { isLoopingEnabled, loopingConfig } =
    useLoopingConfigData(entry.sectionId) ?? {}
  const loopSourceOptions = useLoopSourceOptions(
    loopingConfig?.sourceEntryNumber
  )
  const anySourceOptionHasMedia = loopSourceOptions.some((s) => s.media)

  const questionActions: KebabMenuOption[] = []

  const isFork = questionCardType === QuestionTypeCode.Fork

  if (onClickDuplicateQuestion) {
    questionActions.push({
      id: SurveyQuestionHeaderActionsContainerOptions.DUPLICATE,
      textItem: 'Duplicate',
      iconName: IconName.Duplicate,
      disabled: disabledDuplicate,
      hasDivider: true,
      onClickItem: onClickDuplicateQuestion
    })
  }

  if (onClickAddDisplayLogic && !isFork) {
    questionActions.push({
      id: SurveyQuestionHeaderActionsContainerOptions.ADD_DISPLAY_LOGIC,
      textItem: 'Add display logic',
      iconName: IconName.Preview,
      disabled: disableDisplayLogic || hasDisplayLogic(entry),
      hasDivider: true,
      onClickItem: () => {
        onClickAddDisplayLogic()
      }
    })
  }

  if (onClickAddLoopSpecificDisplayLogic && isLoopingEnabled) {
    questionActions.push({
      id: SurveyQuestionHeaderActionsContainerOptions.ADD_LOOP_BASED_DISPLAY_LOGIC,
      textItem: 'Add loop-based display logic',
      iconName: IconName.Preview,
      disabled: !!loopSpecificDisplayLogicData,
      hasDivider: true,
      onClickItem: () => {
        onClickAddLoopSpecificDisplayLogic()
      }
    })
  }

  if (
    [
      QuestionTypeCode.Basic,
      QuestionTypeCode.FreeText,
      QuestionTypeCode.TextCard,
      QuestionTypeCode.Ranked
    ].includes(questionCardType)
  ) {
    questionActions.push({
      id: SurveyQuestionHeaderActionsContainerOptions.ADD_MEDIA,
      textItem: 'Add media',
      iconName: IconName.ImageOutlined,
      disabled:
        hasQuestionMedia ||
        (isLoopingEnabled && anySourceOptionHasMedia && !sourceMediaDisabled),
      hasDivider: false,
      onClickItem: () => dispatch(openAddMediaDialog(entry.number))
    })
  }

  if (!isFork) {
    questionActions.push({
      id: SurveyQuestionHeaderActionsContainerOptions.ADD_QUESTION_TIMER,
      textItem: 'Add question timer',
      iconName: IconName.AccessTime,
      disabled: hasQuestionTimer,
      hasDivider: true,
      onClickItem: () => dispatch(openQuestionTimerDialog(entry.number))
    })
  }

  if (!isFork) {
    questionActions.push({
      id: SurveyQuestionHeaderActionsContainerOptions.PIPE,
      textItem: 'Pipe into question title',
      iconName: IconName.RouteOutlined,
      disabled: false,
      hasDivider: false,
      onClickItem: () => dispatch(openQuestionsDialogPiping(entry.number))
    })
  }

  if (
    [
      QuestionTypeCode.Basic,
      QuestionTypeCode.Ranked,
      QuestionTypeCode.Matrix
    ].includes(questionCardType)
  ) {
    questionActions.push({
      id: SurveyQuestionHeaderActionsContainerOptions.EMPTY,
      textItem: 'Empty question card',
      iconName: IconName.TextInstruction,
      disabled: false,
      hasDivider: false,
      onClickItem: onEmptyQuestionCard
    })
  }

  if (isFocaldataUser() && questionCardType === QuestionTypeCode.Matrix) {
    const lastQuestion = questionActions.at(-1)

    if (lastQuestion) {
      lastQuestion.hasDivider = true
    }

    const matrixItem = entry.entryItem as DraftMatrixItem

    questionActions.push({
      id: SurveyQuestionHeaderActionsContainerOptions.MATRIX_SCREENING,
      textItem: matrixItem.matrixQualification
        ? 'Edit screening'
        : 'Add screening',
      iconName: IconName.FilterAltOutlined,
      hasDivider: false,
      async onClickItem() {
        dispatch(openMatrixScreeningDialog(matrixItem.matrixTitleLk))
      }
    })
  }

  if (questionActions.length === 0) {
    return null
  }

  const lastActionInList = questionActions[questionActions.length - 1]

  if (lastActionInList.hasDivider) {
    lastActionInList.hasDivider = false
  }

  return (
    <KebabMenu
      iconPosition={KebabMenuIconPosition.Left}
      kebabMenuOptions={questionActions}
      tooltipText="Configure this question"
    />
  )
}

export default SurveyQuestionHeaderActionsContainer
