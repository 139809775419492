import { gql } from '@apollo/client/core'
import { DraftEntryResponseOption } from '../../../model/questionnaire'
import DRAFT_ENTRY_RESPONSE_OPTION from '../fragments/draftEntryResponseOption'

export interface MoveMatrixResponseOptionData {
  moveMatrixResponseOption: DraftEntryResponseOption
}

export const MOVE_MATRIX_RESPONSE_OPTION = gql`
  mutation moveMatrixResponseOption(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $responseOptionLk: ID!
    $toPosition: Int!
  ) {
    moveMatrixResponseOption(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      responseOptionLk: $responseOptionLk
      toPosition: $toPosition
    ) {
      ...DraftEntryResponseOption
    }
  }
  ${DRAFT_ENTRY_RESPONSE_OPTION}
`
