import {
  Dialog,
  Grid,
  IconButton,
  IconColor,
  IconName,
  Input,
  TipPlacement,
  Tooltip,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { DraftQuestionItem } from '../../../data/model/questionnaire'
import { useDebounceEffect } from '../../../hooks/useDebounce'
import { WithEntry } from '../../../utils/questionnaireUtils'
import { useMaxDiffMutations } from './MaxDiffQuestion.hooks'
import useStyles from './MaxDiffQuestion.styles'

interface MaxDiffSpecState {
  leastLabel?: string
  attributeLabel?: string
  mostLabel?: string
  experimentalDesigns?: string
}

const MaxDiffSpecs: React.FC<WithEntry> = ({ entry }: WithEntry) => {
  const entryItem = entry.entryItem as DraftQuestionItem
  const { maxDiffSpecification: maxDiffSpec, questionLk } = entryItem
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const defaultLeastLabel = maxDiffSpec?.leastLabel?.text
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const defaultAttributeLabel = maxDiffSpec?.attributeLabel?.text
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const defaultMostLabel = maxDiffSpec?.mostLabel?.text
  const hasExperimentalDesign = !!maxDiffSpec?.experimentalDesigns

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { classes } = useStyles()
  const { upsertMaxDiffSpec, error } = useMaxDiffMutations(questionLk)
  const [isEditingExperimentalDesigns, setIsEditingExperimentalDesigns] =
    useState<boolean>(false)
  const [spec, setSpec] = useState<MaxDiffSpecState>({
    leastLabel: defaultLeastLabel,
    attributeLabel: defaultAttributeLabel,
    mostLabel: defaultMostLabel,
    experimentalDesigns: ''
  })
  const { leastLabel, attributeLabel, mostLabel, experimentalDesigns } = spec

  useDebounceEffect(
    () => {
      if (leastLabel !== defaultLeastLabel) {
        upsertMaxDiffSpec({
          variables: {
            questionLk,
            leastLabel,
            attributeLabel,
            mostLabel
          }
        })
      }
    },
    leastLabel,
    { delay: 500 }
  )

  useDebounceEffect(
    () => {
      if (attributeLabel !== defaultAttributeLabel) {
        upsertMaxDiffSpec({
          variables: {
            questionLk,
            leastLabel,
            attributeLabel,
            mostLabel
          }
        })
      }
    },
    attributeLabel,
    { delay: 500 }
  )

  useDebounceEffect(
    () => {
      if (mostLabel !== defaultMostLabel) {
        upsertMaxDiffSpec({
          variables: {
            questionLk,
            leastLabel,
            attributeLabel,
            mostLabel
          }
        })
      }
    },
    mostLabel,
    { delay: 500 }
  )

  const showUploadedExperimentalDesignsIcon =
    experimentalDesigns !== '' || hasExperimentalDesign

  const getExperimentalDesignIcon = (): IconName => {
    if (error) {
      return IconName.CancelOutlined
    }

    if (showUploadedExperimentalDesignsIcon) {
      return IconName.CheckCircle
    }

    return IconName.ArrowCircleUp
  }

  const getExperimentalDesignIconColor = (): IconColor => {
    if (error) {
      return IconColor.Error
    }

    if (showUploadedExperimentalDesignsIcon) {
      return IconColor.Green
    }

    return IconColor.Background
  }

  const getExperimentalDesignTooltip = (): string => {
    if (error) {
      return 'There was an error while setting the experimental design. Please try again'
    }

    if (showUploadedExperimentalDesignsIcon) {
      return 'Experimental design set. Click here to change.'
    }

    return 'Click here to set the experimental design'
  }

  return (
    <Grid container direction="row">
      <Grid item xs={4} className={classes.inputContainer}>
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeM,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Least preferred label
        </p>
        <Input
          fullWidth
          defaultValue={leastLabel}
          onChange={(event) =>
            setSpec({ ...spec, leastLabel: event.target.value })
          }
          onFocus={() => {
            questionBeingEditedNumber(entry.number)
          }}
        />
      </Grid>
      <Grid item xs={3} className={classes.inputContainer}>
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeM,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Attribute label
        </p>
        <Input
          fullWidth
          defaultValue={attributeLabel}
          onChange={(event) =>
            setSpec({ ...spec, attributeLabel: event.target.value })
          }
          onFocus={() => {
            questionBeingEditedNumber(entry.number)
          }}
        />
      </Grid>
      <Grid item xs={4} className={classes.inputContainer}>
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeM,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Most preferred label
        </p>
        <Input
          fullWidth
          defaultValue={mostLabel}
          onChange={(event) =>
            setSpec({ ...spec, mostLabel: event.target.value })
          }
          onFocus={() => {
            questionBeingEditedNumber(entry.number)
          }}
        />
      </Grid>
      <Grid
        item
        container
        xs={1}
        direction="column"
        alignContent="end"
        className={classes.inputContainer}
        style={{ paddingTop: 40, paddingRight: 16 }}
      >
        <Tooltip
          title={getExperimentalDesignTooltip()}
          tipPlacement={TipPlacement.TopEnd}
        >
          <IconButton
            iconName={getExperimentalDesignIcon()}
            iconColor={getExperimentalDesignIconColor()}
            negativePadding
            onClick={() => setIsEditingExperimentalDesigns(true)}
          >
            Edit experimental design
          </IconButton>
        </Tooltip>
      </Grid>
      {isEditingExperimentalDesigns && (
        <Dialog
          onClose={() => setIsEditingExperimentalDesigns(false)}
          open
          title="Edit maxdiff experimental design"
          primaryButtonText="Save"
          primaryButtonClick={() => {
            if (experimentalDesigns) {
              setIsEditingExperimentalDesigns(false)
              upsertMaxDiffSpec({
                variables: {
                  questionLk,
                  leastLabel,
                  attributeLabel,
                  mostLabel,
                  experimentalDesigns
                }
              })
            }
          }}
        >
          <Input
            defaultValue={experimentalDesigns}
            multiline
            fullWidth
            onChange={(event) =>
              setSpec({ ...spec, experimentalDesigns: event.target.value })
            }
          />
        </Dialog>
      )}
    </Grid>
  )
}

export default MaxDiffSpecs
