import {
  Select,
  SelectVariant,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { ChangeEvent, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import IntervalInput from '../../../../components/IntervalInput/IntervalInput'
import useStyles from './QuestionCardFooter.styles'
import {
  MAX_GREATHER_THAT_RO_COUNT_ERROR,
  MULTI_SELECT_MAXIMUM_SELECTED,
  MULTI_SELECT_MAXIMUM_SELECTED_OPTION,
  MULTI_SELECT_OPTION,
  MULTI_SELECT_RANGE_OPTION,
  RESPONSE_OPTIONS_LIMIT_RESET_OPTION,
  multiSelectRangeOptions
} from './constants'
import { dropDownOptions } from './utils'

interface Props {
  responseOptionsCount?: number
  choiceLimit?: number
  choiceIntervalMin?: number
  choiceIntervalMax?: number
  onChangeChoiceLimit: (settingValue: number) => void
  onChangeIntervalRange?: (min: number, max: number) => void
}

const MultiSelectChoiceLimit: React.FC<Props> = (props: Props) => {
  const {
    responseOptionsCount = 0,
    choiceLimit = 0,
    choiceIntervalMin,
    choiceIntervalMax,
    onChangeChoiceLimit,
    onChangeIntervalRange
  } = props

  const optionValue =
    choiceIntervalMin !== undefined && choiceIntervalMax
      ? MULTI_SELECT_RANGE_OPTION
      : MULTI_SELECT_MAXIMUM_SELECTED_OPTION

  const [multiSelectOptionValue, setMultiSelectOptionValue] =
    useState(optionValue)

  const [intervalError, setIntervalError] = useState<string>('')

  const onMultiSelectOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const eventValue = parseInt(event.target.value, 10)
    setMultiSelectOptionValue(eventValue)
    if (eventValue === MULTI_SELECT_MAXIMUM_SELECTED_OPTION) {
      onChangeIntervalRange?.(
        RESPONSE_OPTIONS_LIMIT_RESET_OPTION,
        RESPONSE_OPTIONS_LIMIT_RESET_OPTION
      )
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      onChangeChoiceLimit?.(RESPONSE_OPTIONS_LIMIT_RESET_OPTION)
    }
    if (eventValue === MULTI_SELECT_RANGE_OPTION) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      onChangeChoiceLimit?.(RESPONSE_OPTIONS_LIMIT_RESET_OPTION)
      onChangeIntervalRange?.(
        RESPONSE_OPTIONS_LIMIT_RESET_OPTION,
        responseOptionsCount
      )
    }
  }

  const onSelectedChoiceOptionChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const eventValue = parseInt(event.target.value, 10)
    onChangeChoiceLimit(eventValue)
  }

  const handleIntervalChange = (min: number, max: number) => {
    setIntervalError('')
    onChangeIntervalRange?.(min, max)
  }

  const decreaseRangeLimitIfNeeded = () => {
    if (
      multiSelectOptionValue === MULTI_SELECT_RANGE_OPTION &&
      choiceIntervalMax &&
      responseOptionsCount < choiceIntervalMax &&
      onChangeIntervalRange
    ) {
      const newIntervalMin =
        choiceIntervalMin !== undefined &&
        choiceIntervalMin < responseOptionsCount
          ? choiceIntervalMin
          : RESPONSE_OPTIONS_LIMIT_RESET_OPTION
      onChangeIntervalRange(newIntervalMin, responseOptionsCount)
    }
  }

  const resetMaximumSelectedIfNeeded = () => {
    if (
      multiSelectOptionValue === MULTI_SELECT_MAXIMUM_SELECTED_OPTION &&
      responseOptionsCount <= choiceLimit
    ) {
      onChangeChoiceLimit(RESPONSE_OPTIONS_LIMIT_RESET_OPTION)
    }
  }

  useUpdateEffect(
    // in case response option was removed we need to (1) decrease max selection not to be greater than response options amount and (2) reset rank. to handle it we:
    // - change maximum selected to 'no limit'
    // - change max range value to be equal to response options amount, leaving min range value unless it is equal or greater than max value (in this case it is being reset to 0)
    // - reset rank to 'all ranked'
    function handleResponseOptionsRemoval() {
      resetMaximumSelectedIfNeeded()
      decreaseRangeLimitIfNeeded()
    },
    [responseOptionsCount]
  )

  const handleOnError = (error?: string) => {
    setIntervalError(error || '')
  }

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <div className="fd-grid fd-container fd-justify-content-end fd-spacing-1">
      <div className="fd-grid fd-item">
        <Select
          ariaLabel={MULTI_SELECT_OPTION}
          optionValue={multiSelectOptionValue}
          minWidth={142}
          options={multiSelectRangeOptions}
          variant={SelectVariant.Filled}
          onChange={onMultiSelectOptionChange}
        />
      </div>
      <div className="fd-grid fd-item">
        {multiSelectOptionValue === MULTI_SELECT_MAXIMUM_SELECTED_OPTION && (
          <Select
            ariaLabel={MULTI_SELECT_MAXIMUM_SELECTED}
            optionValue={choiceLimit}
            minWidth={142}
            options={dropDownOptions(responseOptionsCount)}
            variant={SelectVariant.Filled}
            onChange={onSelectedChoiceOptionChange}
          />
        )}
        {multiSelectOptionValue === MULTI_SELECT_RANGE_OPTION && (
          <IntervalInput
            min={choiceIntervalMin}
            max={choiceIntervalMax}
            rangeLimit={responseOptionsCount}
            invalidRangeErrorMessage={MAX_GREATHER_THAT_RO_COUNT_ERROR}
            onIntervalChanged={handleIntervalChange}
            onError={handleOnError}
          />
        )}
      </div>
      <div className="fd-grid fd-container fd-item fd-justify-content-end">
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.weightRegular,
            classes.errorText
          )}
        >
          {intervalError}
        </p>
      </div>
    </div>
  )
}

export default MultiSelectChoiceLimit
