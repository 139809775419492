import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../data/model/questionnaire'

export interface DuplicateQuestionnaireData {
  questionnaireId: string
  createdDate: string
  sinceDate: string
  entries: DraftQuestionnaireEntry
}

export const DUPLICATE_QUESTIONNAIRE = gql`
  mutation duplicateQuestionnaire(
    $surveyId: ID!
    $newSurveyId: ID!
    $accountMarkup: Float
  ) {
    duplicateQuestionnaire(
      surveyId: $surveyId
      newSurveyId: $newSurveyId
      accountMarkup: $accountMarkup
    ) {
      questionnaireId
      createdDate
      sinceDate
      entries {
        number
        position
        contextPosition
        createdDate
        sinceDate
      }
    }
  }
`
