import { gql } from '@apollo/client'

export const SET_MATRIX_ROW_MEDIA = gql`
  mutation setMatrixRowMedia(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionLk: ID!
    $mediaName: String!
    $mediaUrl: String!
    $mediaType: MediaType!
    $renderedMediaUrl: String
  ) {
    setMatrixRowMedia(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionLk: $questionLk
      mediaName: $mediaName
      mediaUrl: $mediaUrl
      mediaType: $mediaType
      renderedMediaUrl: $renderedMediaUrl
    )
  }
`

export const REMOVE_MATRIX_ROW_MEDIA = gql`
  mutation removeMatrixRowMedia(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionLk: ID!
  ) {
    removeMatrixRowMedia(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionLk: $questionLk
    )
  }
`
