import { gql } from '@apollo/client/core'
import { DraftEntryResponseOption } from '../../../model/questionnaire'
import DRAFT_ENTRY_RESPONSE_OPTION from '../fragments/draftEntryResponseOption'

export interface AddMatrixResponseOptionData {
  addMatrixResponseOption: DraftEntryResponseOption
}

export const ADD_MATRIX_RESPONSE_OPTION = gql`
  mutation addMatrixResponseOption(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $position: Int!
    $responseOptionValue: String!
  ) {
    addMatrixResponseOption(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      position: $position
      responseOption: {
        responseOptionValue: $responseOptionValue
        responseOptionType: RoString
      }
    ) {
      ...DraftEntryResponseOption
    }
  }
  ${DRAFT_ENTRY_RESPONSE_OPTION}
`
