import {
  ButtonType,
  LinkButton,
  SpinnerButton,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React from 'react'
import signupSuccessSvg from '../../../assets/SignupSuccess.svg'
import useStyles from '../Auth.styles'

interface Props {
  disabled?: boolean
  onClickButton: () => void
}
const ForgotPasswordSucessful: React.FC<Props> = (props: Props) => {
  const { disabled, onClickButton } = props

  const { classes, cx: classNames } = useStyles()
  const { classes: textClasses } = textStyleUtils.useTextStyles()

  return (
    <Grid
      className={classes.page}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.maxWidth}>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          direction="column"
          alignItems="center"
          className={classes.container}
        >
          <img
            className={classes.successLogo}
            src={signupSuccessSvg}
            alt="focaldata-logo"
          />
          <Grid item className={classes.gridItem} style={{ marginTop: '16px' }}>
            <p className={classNames(textClasses.default, textClasses.sizeL)}>
              Check your email
            </p>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            className={classes.paddingRight}
          >
            <p
              className={classNames(
                textClasses.default,
                textClasses.highlightBackground,
                textClasses.alignCenter
              )}
            >
              You will receive an email with instructions about how to reset
              your password in a few minutes.
            </p>
          </Grid>
          <Grid item container>
            <SpinnerButton
              onClick={onClickButton}
              className={classNames(classes.button, classes.gridItem)}
              fullWidth
              buttonType={ButtonType.Submit}
              disabled={disabled}
            >
              Resend email
            </SpinnerButton>
          </Grid>
          <Grid
            container
            justifyContent="center"
            className={classNames(classes.helperText, classes.gridItem)}
          >
            <Grid className={classes.paddingRight}>
              <p className={classNames(textClasses.default)}>
                Need help? Contact
              </p>
            </Grid>
            <LinkButton
              size={TextSize.m}
              weight={TextWeight.Bold}
              // onClick={onGoToLogin}
              noPadding
              underlined
            >
              support@focaldata.com
            </LinkButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForgotPasswordSucessful
