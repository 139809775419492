import { useMutation } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { useState } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { useAddFdChatSpecification } from '../../containers/Page/FdChat/FdChat.hooks'
import { defaultFdChatSpecification } from '../../containers/Page/FdChat/FdChat.model'
import { ProjectType } from '../../data/gql-gen/dashboard/graphql'
import { AddFieldworkMutationVariables } from '../../data/gql-gen/fieldwork/graphql'
import { AddQuestionnaireMutationVariables } from '../../data/gql-gen/questionnaire/graphql'
import {
  ADD_FIELDWORK,
  AddNewFieldworkData
} from '../../data/gql/fieldwork/mutations/addFieldwork'
import {
  ADD_QUESTIONNAIRE,
  AddQuestionnaireData
} from '../../data/gql/questionnaire/mutations/addQuestionnaire'
import { LoggerErrorType } from '../../data/logger'
import { CREATE_PROJECT } from '../../modules/Project/Project.mutations'
import { SEARCH_PROJECTS_QUERY_NAME } from '../../modules/Project/Project.query'
import { captureApolloError } from '../../utils/HelperFunctions'
import UserSession from '../../utils/UserSession'
import {
  usePresetAudiences,
  useSetPresetAudience
} from '../audience/usePresetAudience'
import useGetUserAndAccount from '../useGetUserAndAccount'
import { useNavigateToInitialProjectPage } from './useNavigateToInitialProjectPage'

export const useCreateProject = () => {
  const [loadingCreateProject, setLoadingCreateProject] = useState(false)
  const [createProject] = useMutation(CREATE_PROJECT, {
    context: { clientName: 'dashboard' },
    refetchQueries: [SEARCH_PROJECTS_QUERY_NAME],
    onCompleted: (data) => {
      datadogLogs.logger.info('Project created', {
        data
      })
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'createProject', error)
    }
  })
  const {
    userName,
    loading: loadingGetUserAndAccount,
    markup
  } = useGetUserAndAccount()
  const [addNewFieldwork] = useMutation<
    AddNewFieldworkData,
    AddFieldworkMutationVariables
  >(ADD_FIELDWORK, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addNewFieldwork',
        error
      )
    }
  })
  const [addQuestionnaire] = useMutation<
    AddQuestionnaireData,
    AddQuestionnaireMutationVariables
  >(ADD_QUESTIONNAIRE, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addQuestionnaire',
        error
      )
    }
  })
  const { data: presetAudiences } = usePresetAudiences({
    countryCode: 'GB',
    language: 'en'
  })
  const [setPresetAudience] = useSetPresetAudience()
  const { addFdChatSpecification } = useAddFdChatSpecification()
  const navigateToInitialProjectPage = useNavigateToInitialProjectPage()

  const handleCreateProject = async ({
    title,
    projectType,
    countryCode,
    countryLanguage,
    samplesNeeded
  }: {
    title?: string
    projectType: ProjectType
    countryCode?: string
    countryLanguage?: string
    samplesNeeded?: number
  }) => {
    setLoadingCreateProject(true)

    const createProjectResult = await createProject({
      variables: {
        name: title || 'My Project',
        description: '',
        projectType: projectType,
        accountId: UserSession.getAccountId(),
        solutionId: UserSession.getSolutionId(),
        creator: userName || 'N/A'
      }
    })

    const createdProject = createProjectResult.data?.createProject

    if (!createdProject) {
      throw new Error('No project created')
    }

    const surveyId = createdProject.surveys[0].surveyId || 'N/A'

    LogAmplitudeEvent(EventType.CreatedNewProject, { surveyId })

    const isFdChat = projectType === ProjectType.FdChat

    await addQuestionnaire({
      variables: {
        surveyId
      }
    })

    await addNewFieldwork({
      variables: {
        surveyId,
        countryCode: countryCode || 'GB',
        countryLanguage: countryLanguage || 'en',
        samplesNeeded: samplesNeeded || 2000,
        accountMarkup: markup,
        panelSupplierCode: isFdChat ? 'prolific' : 'cint',
        audienceDescription: isFdChat ? '' : undefined
      }
    })

    // if the project is an FD Chat project, add the default FD Chat specification and set the preset audience to Custom
    if (isFdChat) {
      await addFdChatSpecification({
        variables: {
          surveyId,
          ...defaultFdChatSpecification
        }
      })

      const preset = presetAudiences?.find(
        (presetAudience) => presetAudience.name === 'Custom'
      )

      if (preset?.presetAudienceId) {
        setPresetAudience({
          variables: {
            surveyId,
            presetAudienceId: preset.presetAudienceId
          }
        })
      }
    }

    navigateToInitialProjectPage(createdProject)

    setLoadingCreateProject(false)
  }

  return {
    loadingGetUserAndAccount,
    loadingCreateProject,
    handleCreateProject
  }
}

export default useCreateProject
