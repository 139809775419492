import { useMutation, useQuery } from '@apollo/client'
import { produce } from 'immer'
import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  MoveAudienceMemberMutationVariables,
  SidebarAudienceQuestionsQueryVariables
} from '../../../../data/gql-gen/fieldwork/graphql'
import { FIELDWORK_AUDIENCE } from '../../../../data/gql/fieldwork/queries/fieldworkAudience'
import { QUESTIONNAIRE } from '../../../../data/gql/questionnaire/queries'
import { GET_AUDIENCES } from '../../../../data/gql/questionnaire/queries/audiences'
import { LoggerErrorType } from '../../../../data/logger'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import SidebarAudienceItemListControl from './SidebarAudienceItemList.control'
import MOVE_AUDIENCE_MEMBER, {
  MoveAudienceMemberData
} from './SidebarAudienceItemList.mutations'
import {
  FieldworkAudienceData,
  SIDEBAR_AUDIENCE_QUESTIONS,
  SidebarAudienceEntry
} from './SidebarAudienceItemList.query'

const SidebarAudienceItemList: React.FC = () => {
  const surveyId = useSurveyId()
  const { data } = useQuery<
    FieldworkAudienceData,
    SidebarAudienceQuestionsQueryVariables
  >(SIDEBAR_AUDIENCE_QUESTIONS, {
    context: { clientName: 'fieldwork' },
    variables: {
      surveyId
    },
    skip: !surveyId
  })
  const audienceItems: SidebarAudienceEntry[] = useMemo(
    () => data?.fieldworkAudience.entries || [],
    [data]
  )
  const [audienceEntries, setAudienceEntries] = useState<
    SidebarAudienceEntry[]
  >( // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    audienceItems || []
  )
  useEffect(() => {
    setAudienceEntries(audienceItems)
  }, [audienceItems])

  const [moveAudienceMember] = useMutation<
    MoveAudienceMemberData,
    MoveAudienceMemberMutationVariables
  >(MOVE_AUDIENCE_MEMBER, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [GET_AUDIENCES, QUESTIONNAIRE],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'moveAudienceMember',
        error
      )
    },
    update: (cache, { data }) => {
      if (data) {
        const { number, position } = data.moveAudienceMember
        cache.updateQuery(
          { query: FIELDWORK_AUDIENCE, variables: { surveyId } },
          (data: FieldworkAudienceData | null) => {
            if (!data) {
              return null
            }
            const { entries } = data.fieldworkAudience
            const { fromPosition, toPosition } = entries.reduce(
              (positions, entry, i) => {
                if (entry.number === number) {
                  positions.fromPosition = i
                }

                if (entry.position === position) {
                  positions.toPosition = i
                }

                return positions
              },
              {
                fromPosition: -1,
                toPosition: -1
              }
            )

            return produce(data, (draft) => {
              const allAudienceEntries = draft.fieldworkAudience.entries
              const [removed] = allAudienceEntries.splice(fromPosition, 1)
              allAudienceEntries.splice(toPosition, 0, removed)
              allAudienceEntries.forEach((audienceQuestion, i) => {
                audienceQuestion.position = i
              })
            })
          }
        )
      }
    }
  })

  const reorderAudienceQuestions: (
    audiencQuestions: SidebarAudienceEntry[] | undefined,
    destinationIndex: number | undefined,
    sourceIndex: number
  ) => void = (audiencQuestions, destinationIndex, sourceIndex) => {
    if (destinationIndex !== undefined) {
      const result = audiencQuestions ? Array.from(audiencQuestions) : []
      const [removed] = result.splice(sourceIndex, 1)
      result.splice(destinationIndex, 0, removed)
      setAudienceEntries(result)
    }
  }

  const handleReorderedAudience: (
    destinationIndex: number | undefined,
    sourceIndex: number
  ) => void = (destinationIndex, sourceIndex) => {
    const shouldReorder =
      destinationIndex !== sourceIndex && destinationIndex !== undefined

    if (shouldReorder && sourceIndex < audienceEntries.length) {
      reorderAudienceQuestions(audienceItems, destinationIndex, sourceIndex)

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const number = audienceItems?.[sourceIndex].number
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (number !== undefined) {
        moveAudienceMember({
          variables: {
            surveyId,
            number,
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            toPosition: audienceEntries[destinationIndex!].position
          }
        })
      }
    }
  }

  return (
    <SidebarAudienceItemListControl
      audienceItems={audienceEntries}
      onReorderAudience={handleReorderedAudience}
    />
  )
}

export default memo(SidebarAudienceItemList)
