import { gql } from '@apollo/client'
import { DraftForkItem } from '../../../model/questionnaire'

export interface RemoveForkBranchData {
  removeForkBranch: DraftForkItem
}

const REMOVE_FORK_BRANCH = gql`
  mutation removeForkBranch(
    $questionnaireId: ID!
    $forkId: ID!
    $branchNumber: Int!
  ) {
    removeForkBranch(
      questionnaireId: $questionnaireId
      forkId: $forkId
      branchNumber: $branchNumber
    )
  }
`

export default REMOVE_FORK_BRANCH
