import { gql } from '@apollo/client'
import { DraftMatrixItem } from '../../../model/questionnaire'

export interface RemoveMatrixQualificationData {
  removeMatrixQualification: {
    entryItem: Pick<DraftMatrixItem, 'matrixTitleLk'> & {
      matrixQualification: null
    }
  }
}

const REMOVE_MATRIX_QUALIFICATION = gql`
  mutation removeMatrixQualification(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
  ) {
    removeMatrixQualification(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
    ) {
      entryItem {
        ... on DraftMatrixItem {
          matrixTitleLk
          matrixQualification {
            __typename
          }
        }
      }
    }
  }
`

export default REMOVE_MATRIX_QUALIFICATION
