import {
  IconButton,
  IconName,
  IconSize,
  KebabMenu
} from '@focaldata/cin-ui-components'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import useRemoveQuestionnaireValue from '../../../hooks/questionnaire/useRemoveQuestionnaireValue'
import { getQuestionSettings } from '../../../utils/questionnaireUtils'
import {
  removeQuestionSetting,
  selectSettingsByQuestionId
} from '../Questionnaire.slice'
import { getEntryId } from '../Questionnaire.utils'

interface Props {
  entry: DraftQuestionnaireEntry
}

export const QuestionTimerMenu = ({ entry }: Props) => {
  const removeQuestionnaireSetting = useRemoveQuestionnaireValue(entry)
  const dispatch = useAppDispatch()
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectSettingsByQuestionId(state, getEntryId(entry))
    ) || []
  const questionSettings = getQuestionSettings(settingValues)
  return (
    <KebabMenu
      kebabMenuOptions={[
        {
          id: 1,
          textItem: 'Delete',
          onClickItem: () => {
            dispatch(
              removeQuestionSetting({
                questionLk: getEntryId(entry),
                settingCode: QuestionSettingCode.QuestionTimer
              })
            )
            removeQuestionnaireSetting(QuestionSettingCode.QuestionTimer)
          }
        }
      ]}
      menuButton={
        <IconButton
          smallHoverShadowSize
          iconName={IconName.AccessTime}
          iconSize={IconSize.Large}
          tooltipText={`${questionSettings.get(
            QuestionSettingCode.QuestionTimer
          )} seconds question timer`}
        />
      }
    />
  )
}
