import { useMutation } from '@apollo/client'
import { ScrollTo } from '@focaldata/cin-ui-components'
import React, { createContext, memo, useContext } from 'react'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import {
  DuplicateMatrixQuestionMutationVariables,
  EntryType
} from '../../../data/gql-gen/questionnaire/graphql'
import {
  DUPLICATE_MATRIX_QUESTION,
  DuplicateMatrixQuestionData
} from '../../../data/gql/questionnaire/mutations/duplicateMatrixQuestion'
import {
  DraftMatrixItem,
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import MatrixQuestionLayout from '../../../layouts/MatrixQuestionLayout'
import { duplicateQuestionTransactionDatadog } from '../../../tracking/perf/transactions'
import { WithEntryOfType } from '../../../utils/questionnaireUtils'
import DefaultOptions from '../QuestionCardDefaultOptions/DefaultOptions'
import QuestionCardSwitches from '../QuestionCardSwitches'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  SCROLL_ID_PREFIX
} from '../constants'
import { MatrixCardTitleContainer } from './MatrixCardTitle'
import MatrixQuestionFooter from './MatrixQuestionFooter'
import MatrixQuestionRows from './MatrixQuestionRows/MatrixQuestionRowsList.container'
import { MatrixQuestionRowsSwitches } from './MatrixQuestionRowsSwitches'
import MatrixResponseOptions from './MatrixResponseOptions'

type Props = {
  shouldTitleInputFocus: boolean
} & WithEntryOfType<EntryType.MatrixEntryType>

export const MatrixEntryContext = createContext<DraftMatrixItem | undefined>(
  undefined
)

export const useMatrixEntryContext = () => {
  const entryItem = useContext(MatrixEntryContext)

  if (!entryItem) {
    throw new Error('Missing MatrixEntryContext.Provider')
  }

  return entryItem
}

const MatrixQuestionContainer: React.FC<Props> = (props: Props) => {
  const { entry, shouldTitleInputFocus } = props

  const {
    draftQuestionnaire,
    draftQuestionnaireEntries,
    refetchQuestionnaire
  } = useGetDraftQuestionnaire()

  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const questionnaireId = draftQuestionnaire?.questionnaireId || ''

  const { validateMatrixQuestionTitle } = useQuestionnaireValidation()

  const entryItem = entry.entryItem

  const scrollToDuplicatedMatrixQuestionCard = (cardNumber: string) => {
    setTimeout(() => {
      if (cardNumber) {
        ScrollTo(
          `${SCROLL_ID_PREFIX}${cardNumber}`,
          CONTAINER_ID_QUESTIONNAIRE_CONTENT
        )
      }
    }, 400)
  }

  const [duplicateMatrixQuestionMutation] = useMutation<
    DuplicateMatrixQuestionData,
    DuplicateMatrixQuestionMutationVariables
  >(DUPLICATE_MATRIX_QUESTION, {
    context: { clientName: 'questionnaire' }
  })

  const handleDuplicateMatrixQuestion = (
    questionnaireId: string,
    matrixTitleLk: string,
    position: number
  ) => {
    duplicateQuestionTransactionDatadog.start()
    return duplicateMatrixQuestionMutation({
      variables: {
        questionnaireId,
        matrixTitleLk,
        position
      },
      onCompleted: async ({ duplicateMatrixQuestion }) => {
        await refetchQuestionnaire()
        scrollToDuplicatedMatrixQuestionCard(
          duplicateMatrixQuestion.number.toString()
        )
        questionBeingEditedNumber(undefined)
      }
    })
  }

  const isMultipleChoice =
    entryItem.settingValues.find(
      (settingValue) => settingValue.code === QuestionSettingCode.MatrixChoice
    )?.value === SettingValue.MultipleChoice

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const emptyMatrixTitle = entryItem.matrixTitle === null
  const isBasicQuestionTextEmpty = validateMatrixQuestionTitle(
    entryItem.matrixTitleLk
  )

  return (
    <MatrixEntryContext.Provider value={entryItem}>
      <MatrixQuestionLayout
        entryNumber={entry.number}
        questionCardHeader={
          <SurveyQuestionCardHeaderContainer
            titleContent={
              <MatrixCardTitleContainer
                ariaLabel="Matrix question header"
                entryNumber={entry.number}
                hasError={isBasicQuestionTextEmpty}
                matrixTitleLk={entryItem.matrixTitleLk}
                shouldTitleInputFocus={shouldTitleInputFocus}
              />
            }
            entry={entry}
            hasError={isBasicQuestionTextEmpty}
            onClickDuplicateIcon={() => {
              return handleDuplicateMatrixQuestion(
                questionnaireId,
                entryItem.matrixTitleLk,
                entry.position + 1
              )
            }}
            isMultipleChoice={isMultipleChoice}
            disabledDuplicate={emptyMatrixTitle}
            entries={flatDraftQuestionnaireEntries}
          />
        }
        matrixQuestionRows={<MatrixQuestionRows entry={entry} />}
        matrixQuestionRowsSwitches={
          <MatrixQuestionRowsSwitches entry={entry} />
        }
        matrixResponseOptionSwitches={
          <QuestionCardSwitches
            entry={entry}
            randomiseQuestionSettingCode={
              QuestionSettingCode.MatrixRandomiseResponseOptions
            }
            flipOrderQuestionSettingCode={QuestionSettingCode.MatrixFlipOptions}
            title="COLUMNS"
            showMatrixChoiceOptions
          />
        }
        matrixResponseOptions={<MatrixResponseOptions entry={entry} />}
        questionCardFooter={<MatrixQuestionFooter entry={entry} />}
        defaultOptions={<DefaultOptions entry={entry} />}
      />
    </MatrixEntryContext.Provider>
  )
}

export default memo(MatrixQuestionContainer)
