import { useFragment } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import { ResponseOptionsLogic } from '../../../data/gql-gen/fieldwork/graphql'
import { DRAFT_CUSTOM_AUDIENCE_ITEM } from '../../../data/gql/fieldwork/fragments/draftCustomAudienceItem'

export const useResponseOptionsLogic = (questionLk: string) => {
  const { data } = useFragment({
    fragment: DRAFT_CUSTOM_AUDIENCE_ITEM,
    fragmentName: 'DraftCustomAudienceItem',
    from: { __typename: 'DraftCustomAudienceItem', questionLk },
    canonizeResults: true
  })

  return data?.responseOptionsLogic as Maybe<ResponseOptionsLogic>
}
