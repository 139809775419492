import { Checkbox, Grid, List } from '@focaldata/cin-ui-components'
import React from 'react'
import {
  DraftCustomCriterion,
  EnabledState
} from '../../../data/model/audience'
import { CriterionValidationErrors } from '../../../data/model/fieldwork'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import useStyles from './CustomAudienceList.styles'

interface Props {
  customCriteria: DraftCustomCriterion[]
  validationErrors?: CriterionValidationErrors[]
  onCustomAudienceToggleCriterion: (
    customCriterion: DraftCustomCriterion
  ) => void
}

const CustomAudienceList: React.FC<Props> = (props: Props) => {
  const {
    customCriteria,
    onCustomAudienceToggleCriterion,
    validationErrors = []
  }: Props = props
  const { classes } = useStyles()
  const { surveyAudienceInvalidQuestions } = useAudienceValidation({
    validationErrors
  })

  const customCriteriaSorted = [...customCriteria].sort(
    (a: DraftCustomCriterion, b: DraftCustomCriterion) => a.number - b.number
  )
  return (
    <div className={classes.listMarginTop}>
      <List>
        {customCriteriaSorted.map(
          (customCriterion: DraftCustomCriterion, index: number) => {
            const hasErrors = !!surveyAudienceInvalidQuestions.find(
              (id) => id === customCriterion.questionLk
            )
            return (
              <Grid
                className={classes.customAudienceItem}
                item
                key={customCriterion.questionLk}
              >
                <Checkbox
                  isGrouped
                  hasError={hasErrors}
                  checked={customCriterion.state === EnabledState.Enabled}
                  onChange={() => {
                    onCustomAudienceToggleCriterion(customCriterion)
                  }}
                  label={
                    // @todo Legacy eslint violation – fix this when editing
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    customCriterion.question
                      ? customCriterion.question.text
                      : `Screening question ${index + 1}`
                  }
                />
              </Grid>
            )
          }
        )}
      </List>
    </div>
  )
}

export default CustomAudienceList
