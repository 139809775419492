import React, { useState } from 'react'
import {
  DraftStandardAudience,
  FieldworkAudienceType
} from '../../../data/model/audience'
import useStandardAudienceFilteredListGqlOperations from '../../../hooks/audience/useStandardAudienceFilteredListGqlOperations'
import { useDebounceEffect } from '../../../hooks/useDebounce'
import { useSurveyId } from '../../../hooks/useSurveyId'
import AudienceSearchControl, {
  AudienceSearchResult
} from './AudienceSearch.control'

const AudienceSearch: React.FC = () => {
  const surveyId = useSurveyId()
  const [results, setResults] = useState<AudienceSearchResult[]>()
  const [searchCriteria, setSearchCriteria] = useState<string>('')
  const {
    searchCategories,
    loadingSearchAudience,
    audienceQuestions,
    setCriterion,
    removeCriterion,
    refetchCategories
  } = useStandardAudienceFilteredListGqlOperations(searchCriteria)

  const isCriterionCodeSelected: (code: string) => boolean = (code) => {
    return !!audienceQuestions?.members.find(
      (standardAudienceQuestion) =>
        standardAudienceQuestion.memberType ===
          FieldworkAudienceType.Standard &&
        (standardAudienceQuestion.memberItem as DraftStandardAudience).criterion
          .code === code
    )
  }

  const formatResults: () => AudienceSearchResult[] | undefined = () => {
    if (searchCategories) {
      const res: AudienceSearchResult[] = searchCategories.map(
        ({ standardCriteria, category }) => {
          const {
            criterion: { code, name, question },
            options
          } = standardCriteria
          return {
            code,
            name,
            category: category.name,
            question: question.text,
            responses: options.map(({ responseOption }) => ({
              text: responseOption.value,
              responseOptionId: responseOption.responseOptionId
            })),
            selected: isCriterionCodeSelected(code)
          }
        }
      )
      return res
    }
    return undefined
  }

  useDebounceEffect<string | undefined>(
    () => {
      if (searchCriteria) {
        refetchCategories({ searchCriteria })
      }
    },
    searchCriteria,
    { delay: 1500 }
  )

  const toggleResultSelection: (result: AudienceSearchResult) => void = (
    result
  ) => {
    if (results) {
      const newResults = [...results]
      const target = newResults.find((res) => res.code === result.code)
      if (target) {
        target.selected = !target.selected
      }

      setResults(newResults)
    }
  }

  const handleResultClick: (result: AudienceSearchResult) => void = (
    result
  ) => {
    if (!result.selected) {
      const audienceQuestionsNr: number | undefined =
        audienceQuestions?.members.length
      setCriterion({
        variables: {
          surveyId,
          position: audienceQuestionsNr || 0,
          audienceCriterionCode: result.code
        }
      })
    } else {
      removeCriterion({
        variables: {
          surveyId,
          audienceCriterionCode: result.code
        }
      })
    }

    toggleResultSelection(result)
  }

  const handleSearchCriteriaChange = (newSearchCriteria: string) => {
    setSearchCriteria(newSearchCriteria)
  }

  return (
    <AudienceSearchControl
      loading={loadingSearchAudience}
      results={formatResults()}
      onChange={handleSearchCriteriaChange}
      onResultClick={handleResultClick}
    />
  )
}

export default AudienceSearch
