import { ScrollElement } from '@focaldata/cin-ui-components'
import { memo, useContext } from 'react'
import DragAndDrop from '../../../components/DragAndDrop-react-dnd/DragAndDrop'
import {
  DraftQuestionnaireEntry,
  EntryType
} from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import GenericEntryLayout from '../../../layouts/GenericEntryLayout'
import SectionEntryLayout from '../../../layouts/SectionEntryLayout'
import { AddQuestionMenu } from '../AddQuestionMenu'
import { EmptySection } from '../EmptySection'
import EntryPlaceholder from '../EntryPlaceholder'
import { GenericEntryContainer } from '../GenericEntry'
import QuestionnaireContext from '../Questionnaire.context'
import { getNextEntryPosition } from '../Questionnaire.utils'
import { PlaceholderHeightsByNumber } from '../QuestionsList/QuestionsList.hooks'
import { SCROLL_ID_PREFIX } from '../constants'
import { useDragAndDrop } from '../useDragAndDrop.hooks'
import SectionCardHeaderContainer from './SectionCardHeader.container'
import SectionEntryFooter from './SectionEntryFooter'

const SectionEntryContainer = ({
  entry,
  placeholderHeightsByNumber,
  onChangeVisible
}: {
  entry: DraftQuestionnaireEntry<EntryType.SectionEntryType>
  placeholderHeightsByNumber: PlaceholderHeightsByNumber
  onChangeVisible: (index: number, isVisible: boolean) => void
}) => {
  const { flattenedEntries } = useGetDraftQuestionnaire()
  const { entryItem: sectionEntryItem } = entry
  const { entries: sectionEntries } = sectionEntryItem
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasSectionEntries = sectionEntries?.length > 0
  const sectionTitle =
    sectionEntryItem.title || `Section ${entry.contextPosition + 1}`
  const moveCard = useDragAndDrop()
  const {
    questionnaireState: { entriesToRender }
  } = useContext(QuestionnaireContext)

  // return empty section or list of generic entries
  const getContent = (
    entries: DraftQuestionnaireEntry[]
  ): JSX.Element | null => {
    return (
      <>
        {entries.map((entry) => {
          const currentIndex = flattenedEntries.findIndex(
            (flattennedListEntry) => flattennedListEntry === entry
          )
          return (
            <ScrollElement
              elementId={`${SCROLL_ID_PREFIX}${entry.number}`}
              key={entry.number}
              partialVisibility
              onChangeVisible={(isVisible) =>
                onChangeVisible(currentIndex, isVisible)
              }
            >
              {entriesToRender.includes(currentIndex) ? (
                <DragAndDrop
                  key={entry.number}
                  position={entry.position}
                  id={entry.number.toString()}
                  type="main"
                  moveCard={moveCard}
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  path={`${sectionEntryItem?.sectionId}/${entry.number}`}
                  accept={['basic']}
                >
                  <GenericEntryContainer entry={entry} />
                </DragAndDrop>
              ) : (
                <EntryPlaceholder
                  entry={entry}
                  height={placeholderHeightsByNumber[entry.number]}
                />
              )}
            </ScrollElement>
          )
        })}
      </>
    )
  }

  return (
    <GenericEntryLayout
      entryCard={
        <SectionEntryLayout
          header={
            <SectionCardHeaderContainer
              entryNumber={entry.number}
              contextPosition={entry.contextPosition}
              sectionEntryItem={sectionEntryItem}
            />
          }
          content={
            hasSectionEntries ? (
              getContent(sectionEntries)
            ) : (
              <EmptySection
                sectionId={sectionEntryItem.sectionId}
                nextPosition={entry.position + 1}
              />
            )
          }
          footer={<SectionEntryFooter title={sectionTitle} />}
        />
      }
      bottomActions={
        <AddQuestionMenu
          showSectionMenu={!entry.sectionId}
          position={getNextEntryPosition(entry)}
          sectionId={entry.sectionId}
        />
      }
    />
  )
}

export default memo(SectionEntryContainer)
