import { gql } from '@apollo/client'
import DRAFT_STANDARD_QUALIFICATION from '../fragments/standardQualification'

export const SET_ALL_STANDARD_QUALIFICATION = gql`
  mutation setAllStandardQualification(
    $surveyId: ID!
    $audienceCriterionCode: String!
  ) {
    setAllStandardQualification(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
    ) {
      ...DraftStandardQualification
    }
  }
  ${DRAFT_STANDARD_QUALIFICATION}
`

export const REMOVE_ALL_STANDARD_QUALIFICATION = gql`
  mutation removeAllStandardQualification(
    $surveyId: ID!
    $audienceCriterionCode: String!
  ) {
    removeAllStandardQualification(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
    ) {
      ...DraftStandardQualification
    }
  }
  ${DRAFT_STANDARD_QUALIFICATION}
`
