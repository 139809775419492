import { gql } from '@apollo/client/core'
import { FieldworkAudience } from '../../../model/audience'
import DRAFT_AUDIENCE_MEMBER from '../fragments/draftAudienceMember'

export interface PasteInAudienceTitleData {
  pasteInAudienceTitle: FieldworkAudience
}

export const PASTE_IN_AUDIENCE_TITLE = gql`
  mutation pasteInAudienceTitle(
    $surveyId: ID!
    $questionLk: ID!
    $text: String
    $responseOptions: [String!]!
  ) {
    pasteInAudienceTitle(
      surveyId: $surveyId
      questionLk: $questionLk
      text: $text
      responseOptions: $responseOptions
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`
