import { useQuery } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { Grid, IconName } from '@focaldata/cin-ui-components'
import debounce from 'lodash/debounce'
import React, { useEffect, useMemo, useState } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { EmptyState } from '../../components/EmptyState'
import Subheader from '../../components/Subheader'
import ProjectListFilters from '../../controls/ProjectListFilters'
import ProjectListSearch from '../../controls/ProjectListSearch'
import { ProjectOrderSelect } from '../../controls/ProjectOrderSelect'
import {
  Project,
  ProjectType,
  SearchProjectsQueryVariables,
  SurveyState
} from '../../data/gql-gen/dashboard/graphql'
import ProjectPageLayout from '../../layouts/ProjectsPageLayout'
import UserSession from '../../utils/UserSession'
import { PROJECT_NR_PER_PAGE } from '../../utils/appConstants'
import CreateProject from './CreateProject'
import DeleteProject from './DeleteProject'
import DuplicateProject from './DuplicateProject'
import InviteTeamDialog from './InviteTeamDialog/InviteTeamDialog.container'
import PauseProject from './PauseProject'
import {
  ProjectSortingInput,
  SEARCH_PROJECTS,
  SearchProjectsData,
  SortBy,
  SortOrder
} from './Project.query'
import ProjectListControl from './ProjectList'
import ProjectListLoading from './ProjectList/ProjectList.loading'

const Projects: React.FC = () => {
  const [isDialogCreateOpen, setIsDialogCreateOpen] = useState<boolean>(false)
  const [isDialogDuplicateOpen, setIsDialogDuplicateOpen] =
    useState<boolean>(false)
  const [isDialogDeleteOpen, setIsDialogDeleteOpen] = useState<boolean>(false)
  const [isDialogPauseOpen, setIsDialogPauseOpen] = useState<boolean>(false)
  const [selectedProject, setSelectedProject] = useState<Project | undefined>()
  const [selectedFilter, setSelectedFilter] = useState<
    SurveyState | undefined
  >()
  const [searchBarInput, setSearchBarInput] = useState<string>('')

  const [selectedSortBy, setSelectedSortBy] = useState<SortBy>(SortBy.SinceDate)
  const [selectedSortOrder, setSelectedSortOrder] = useState<SortOrder>(
    SortOrder.Desc
  )

  const projectSortingInput: ProjectSortingInput = {
    sortBy: selectedSortBy,
    order: selectedSortOrder
  }

  const handleClickDuplicate: (project: Project) => void = (project) => {
    if (!isDialogDuplicateOpen) {
      LogAmplitudeEvent(EventType.DuplicatedProject, {
        surveyId: project.surveys[0].surveyId,
        eventSource: 'projects_list'
      })
    }
    setIsDialogDuplicateOpen(!isDialogDuplicateOpen)
    setSelectedProject(project)
  }

  const handleClickDelete: (project: Project) => void = (project) => {
    setSelectedProject(project)
    setIsDialogDeleteOpen(!isDialogDeleteOpen)
  }

  const handleClickPause: (project: Project) => void = (project) => {
    setSelectedProject(project)
    setIsDialogPauseOpen(!isDialogPauseOpen)
  }

  const projectTypes = Object.values(ProjectType)

  const searchProjectVariables: SearchProjectsQueryVariables = {
    accountId: UserSession.getAccountId(),
    offset: 0,
    limit: PROJECT_NR_PER_PAGE,
    text: searchBarInput.length > 0 ? searchBarInput : undefined, // issue with server API: passing an empty search text string and filter returns zero results
    sorting: [projectSortingInput],
    state: selectedFilter,
    projectTypes
  }

  const {
    data: projectsData,
    loading: projectsLoading,
    fetchMore,
    refetch: refetchSearchProjects
  } = useQuery<SearchProjectsData, SearchProjectsQueryVariables>(
    SEARCH_PROJECTS,
    {
      context: { clientName: 'dashboard' },
      notifyOnNetworkStatusChange: true,
      variables: searchProjectVariables,
      onError: (error) => {
        datadogLogs.logger.error(`[searchProjectsQuery] ${error.message}`, {
          errorData: { searchProjectVariables, error }
        })
      }
    }
  )

  const projects = projectsData?.searchProjects.projects
  const totalProjects = projectsData?.searchProjects.header.total

  useEffect(() => {
    refetchSearchProjects()
  }, [
    selectedSortBy,
    selectedSortOrder,
    searchBarInput,
    selectedFilter,
    refetchSearchProjects
  ])

  const handleLoadMore: () => void = () => {
    fetchMore({
      variables: {
        offset: projects?.length,
        projectTypes
      }
    })
  }

  const debouncedHandleSearchBarChange = useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const inputSearchBar = event.target.value
        setSearchBarInput(inputSearchBar)
        // TODO: Include 'SearchRank' once the backend fixes the bug
        setSelectedSortBy(SortBy.SinceDate)
      }, 1000),
    []
  )

  const handleSearchBarClear = () => {
    setSearchBarInput('')
    setSelectedSortBy(SortBy.SinceDate)
    setSelectedSortOrder(SortOrder.Desc)
  }

  const getProjectList: (
    searchProjectsData: SearchProjectsData | undefined,
    projectsLoading: boolean
  ) => JSX.Element = (searchProjectsData, projectsLoading) => {
    if (projectsLoading || (projects && projects.length > 0)) {
      return (
        <>
          {projects && projects.length > 0 && (
            <ProjectListControl
              projects={projects}
              onClickDuplicate={handleClickDuplicate}
              onClickDelete={handleClickDelete}
              onClickPause={handleClickPause}
              onClickUnpause={handleClickPause}
              onLoadMore={handleLoadMore}
              loadingMore={projectsLoading}
              // if the current projects we're showing is less than the total number of projects
              // show the load more button
              showLoadMore={
                totalProjects ? projects.length < totalProjects : false
              }
            />
          )}
          {projectsLoading && <ProjectListLoading />}
        </>
      )
    }

    if (
      (searchBarInput.length > 0 || selectedFilter) &&
      searchProjectsData?.searchProjects.projects.length === 0
    ) {
      return (
        <EmptyState
          iconName={IconName.Search}
          title="No projects were found. Try a different search term or filter."
          hasElevation
        />
      )
    }

    return (
      <EmptyState
        iconName={IconName.EmptyProjectList}
        title="There are no projects in this workspace."
        buttonText="Create the first project"
        hasElevation
        onClick={() => setIsDialogCreateOpen(!isDialogCreateOpen)}
      />
    )
  }

  return (
    <>
      <Subheader
        topBottomPadding
        leftRightPadding={false}
        shadow
        leftChild={
          <ProjectListSearch
            showClearIcon={!!searchBarInput}
            onClickClear={handleSearchBarClear}
            onSearchBarChange={debouncedHandleSearchBarChange}
          />
        }
        rightChild={
          <Grid container justifyContent={'flex-end'}>
            <InviteTeamDialog />
            <CreateProject
              isDialogOpen={isDialogCreateOpen}
              setIsDialogOpen={setIsDialogCreateOpen}
            />
          </Grid>
        }
      />
      <ProjectPageLayout
        ordering={
          <ProjectOrderSelect
            onChange={(value: SortOrder) => {
              setSelectedSortOrder(value)
            }}
          />
        }
        filters={<ProjectListFilters setSelectedFilter={setSelectedFilter} />}
        projectsList={getProjectList(projectsData, projectsLoading)}
      />
      {isDialogDuplicateOpen && (
        <DuplicateProject
          isDialogDuplicateOpen={isDialogDuplicateOpen}
          setIsDialogDuplicateOpen={setIsDialogDuplicateOpen}
          selectedProject={selectedProject}
        />
      )}
      {isDialogDeleteOpen && (
        <DeleteProject
          isDialogDeleteOpen={isDialogDeleteOpen}
          setIsDialogDeleteOpen={setIsDialogDeleteOpen}
          selectedProject={selectedProject}
        />
      )}
      <PauseProject
        isOpen={isDialogPauseOpen}
        toggleIsOpen={setIsDialogPauseOpen}
        selectedProject={selectedProject}
      />
    </>
  )
}

export default Projects
