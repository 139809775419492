import gql from 'graphql-tag'
import DRAFT_QUESTIONNAIRE_ENTRY from '../../data/gql/questionnaire/fragments/draftQuestionnaireEntry'
import { DraftQuestionnaire } from '../../data/model/questionnaire'

export interface ImportData {
  importQuestionnaire: DraftQuestionnaire
}

const IMPORT_QUESTIONNAIRE = gql`
  mutation importQuestionnaire(
    $surveyId: ID!
    $entries: [ImExEntryDtoInput!]!
    $locale: LocaleInput
  ) {
    importQuestionnaire(
      surveyId: $surveyId
      entries: $entries
      locale: $locale
    ) {
      questionnaireId
      entries {
        ...DraftQuestionnaireEntry
      }
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`

export default IMPORT_QUESTIONNAIRE
