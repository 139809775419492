import { gql } from '@apollo/client/core'
import { EntrySettingValue } from '../../../model/questionnaire'
import ENTRY_SETTING_VALUE from '../fragments/entrySettingValue'

export interface SetTextCardSettingValueData {
  setTextCardSettingValue: EntrySettingValue
}

const SET_TEXT_CARD_SETTING_VALUE = gql`
  mutation setTextCardSettingValue(
    $questionnaireId: ID!
    $textCardLk: ID!
    $setting: SettingInputInput!
  ) {
    setTextCardSettingValue(
      questionnaireId: $questionnaireId
      textCardLk: $textCardLk
      setting: $setting
    ) {
      number
      entryItem {
        ... on DraftTextCardItem {
          textCardLk
          settingValues {
            ...EntrySettingValue
          }
        }
      }
    }
  }
  ${ENTRY_SETTING_VALUE}
`

export default SET_TEXT_CARD_SETTING_VALUE
