import { gql } from '@apollo/client'
import DRAFT_AUDIENCE_MEMBER from '../../../data/gql/fieldwork/fragments/draftAudienceMember'

export const ADD_CUSTOMIZED_CRITERION_OPTION = gql`
  mutation addCustomizedCriterionOption(
    $surveyId: ID!
    $criterion: String!
    $criterionOptions: [CustomizedCriterionOptionInput!]!
  ) {
    addCustomizedCriterionOption(
      surveyId: $surveyId
      criterion: $criterion
      criterionOptions: $criterionOptions
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`

export const UPDATE_CUSTOMIZED_CRITERION_OPTION = gql`
  mutation updateCustomizedCriterionOption(
    $surveyId: ID!
    $criterion: String!
    $responseOptionId: ID!
    $criterionOption: CustomizedCriterionOptionInput!
  ) {
    updateCustomizedCriterionOption(
      surveyId: $surveyId
      criterion: $criterion
      responseOptionId: $responseOptionId
      criterionOption: $criterionOption
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`

export const DETACH_CUSTOMIZED_CRITERION_OPTION = gql`
  mutation detachCustomizedCriterionOption(
    $surveyId: ID!
    $criterion: String!
    $responseOptionId: ID!
  ) {
    detachCustomizedCriterionOption(
      surveyId: $surveyId
      criterion: $criterion
      responseOptionId: $responseOptionId
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`
