import {
  Button,
  ButtonSize,
  ButtonVariant,
  Grid,
  InfoIcon,
  Input,
  InputVariant,
  Paper,
  Skeleton,
  SpinnerButton,
  Text,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import delay from 'lodash/delay'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { v4 as uuidv4 } from 'uuid'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import {
  fdChatSpecificationDebounceDelay,
  useFdChatValidation,
  useGetFdChatSpecification,
  useUpdateFdChatSpecification
} from '../FdChat.hooks'
import { defaultFdChatSpecification } from '../FdChat.model'
import useStyles from '../FdChat.styles'
import { FdChatSpecificationQuestionList } from './FdChatSpecificationQuestionList'

export const FdChatObjectives = () => {
  const shouldAutofocusKeyQuestion = useRef<boolean>(false)

  useUpdateEffect(() => {
    shouldAutofocusKeyQuestion.current = true
  })
  const { objectivesValid } = useFdChatValidation()
  const { classes } = useStyles()
  const { cx: classNames } = textStyleUtils.useTextStyles()

  const surveyId = useSurveyId()

  // watching for changes in the fdChatSpecification
  const { fdChatSpecification, loadingFdChatSpecification } =
    useGetFdChatSpecification()

  const [isPreviewShown, setIsPreviewShown] = useState(false)
  const [respondentId, setRespondentId] = useState('')

  // updating the fdChatSpecification
  const { updateFdChatSpecificationCacheAndDebounce } =
    useUpdateFdChatSpecification()

  const [previewInterviewLoading, setPreviewInterviewLoading] = useState(false)

  const handleClickPreview = useCallback(() => {
    setPreviewInterviewLoading(true)
    setRespondentId(uuidv4())
    delay(() => {
      LogAmplitudeEvent(EventType.ClickedPreviewFdChatInterview, {
        surveyId,
        fdChatSpecification
      })
      setPreviewInterviewLoading(false)
      setIsPreviewShown(true)
    }, fdChatSpecificationDebounceDelay)
  }, [fdChatSpecification, surveyId])

  const handleClickExitPreview = useCallback(() => {
    setIsPreviewShown(false)
    setRespondentId('')
  }, [])

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedFdChatObjectivesPage, { surveyId })
  }, [surveyId])

  const currentFdChatspecification =
    fdChatSpecification ?? defaultFdChatSpecification

  return (
    <Grid
      container
      className={classNames('fd-chat-objectives', classes.pageWrapper)}
      gap={'50px'}
      wrap="nowrap"
      flex={1}
    >
      <Grid item xs={6}>
        <Text size={TextSize.lxl} weight={TextWeight.Bold}>
          What are your objectives for this study?
        </Text>
        <Grid container gap={'48px'} marginY={'24px'}>
          <Grid xs={12}>
            <Text size={TextSize.m} weight={TextWeight.Regular}>
              Research goal
            </Text>
            <div style={{ marginTop: '5px' }}>
              {loadingFdChatSpecification ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Input
                  fullWidth
                  defaultValue={fdChatSpecification?.goal}
                  onChange={(e) => {
                    if (fdChatSpecification) {
                      updateFdChatSpecificationCacheAndDebounce({
                        ...fdChatSpecification,
                        goal: e.target.value
                      })
                    }
                  }}
                  variant={InputVariant.Filled}
                  placeholder={'What is your goal for this research project?'}
                />
              )}
            </div>
          </Grid>
          <Grid xs={12}>
            <Text size={TextSize.m} weight={TextWeight.Regular}>
              Research questions{' '}
              <InfoIcon
                text="List 3 - 5 questions for best results."
                style={{
                  position: 'relative',
                  top: '0.25em',
                  paddingLeft: '4px'
                }}
              />
            </Text>
            <div style={{ marginTop: '5px' }}>
              {loadingFdChatSpecification ? (
                <Skeleton height={40} />
              ) : (
                <FdChatSpecificationQuestionList
                  questions={currentFdChatspecification.questions}
                  onChange={(newQuestions) => {
                    updateFdChatSpecificationCacheAndDebounce({
                      ...currentFdChatspecification,
                      questions: newQuestions
                    })
                  }}
                  canAdd
                  canRemove={currentFdChatspecification.questions.length > 1}
                  shouldAutofocusKeyQuestion={
                    shouldAutofocusKeyQuestion.current
                  }
                />
              )}
            </div>
          </Grid>
          <Grid xs={12}>
            <Text size={TextSize.m} weight={TextWeight.Regular}>
              Further context
            </Text>
            <div style={{ marginTop: '5px' }}>
              {loadingFdChatSpecification ? (
                <Skeleton variant="rectangular" height={100} />
              ) : (
                <Input
                  fullWidth
                  defaultValue={fdChatSpecification?.context}
                  onChange={(e) => {
                    if (fdChatSpecification) {
                      updateFdChatSpecificationCacheAndDebounce({
                        ...fdChatSpecification,
                        context: e.target.value
                      })
                    }
                  }}
                  variant={InputVariant.Filled}
                  placeholder={
                    'What decision are you looking to make? What do you know about this topic already?'
                  }
                  multiline={true}
                  rows={5}
                />
              )}
            </div>
          </Grid>
        </Grid>
        {!isPreviewShown && (
          <Paper
            variant="outlined"
            style={{ padding: '24px', boxSizing: 'border-box' }}
          >
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Grid container item xs={12} md={6} gap={'6px'}>
                <Text size={TextSize.lxl} weight={TextWeight.Bold}>
                  Interview preview
                </Text>
                <Text size={TextSize.m} weight={TextWeight.Regular}>
                  You will be able to preview the interview once you add your
                  goal and research questions.
                </Text>
              </Grid>
              <Grid item>
                <SpinnerButton
                  disabled={!objectivesValid}
                  onClick={handleClickPreview}
                  loading={previewInterviewLoading}
                >
                  Preview interview
                </SpinnerButton>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
      {isPreviewShown && (
        <Grid item xs={6}>
          <section
            style={{
              position: 'fixed',
              height: '100%',
              maxHeight: 'calc(100vh - 56px - 62px)',
              bottom: '57px',
              width: '45%',
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              display: isPreviewShown ? 'block' : 'none'
            }}
          >
            <Button
              variant={ButtonVariant.SecondaryOutlined}
              buttonSize={ButtonSize.Small}
              onClick={handleClickExitPreview}
              style={{ position: 'absolute', top: 17, right: 20 }}
            >
              Exit preview
            </Button>
            <iframe
              title="fdchat"
              height="100%"
              width="100%"
              src={`${process.env.REACT_APP_FDCHAT_DOMAIN}/${surveyId}/${respondentId}/?preview=true&embedded=true&ps=fd`}
              style={{
                border: '1px solid #e6e6e6'
              }}
            />
          </section>
        </Grid>
      )}
    </Grid>
  )
}
