import { gql } from '@apollo/client'

// TODO: Remove this duplicate code and reuse the existing pin hooks
export interface PinCustomAudienceResponseOptionData {
  pinOrUnpinResponseOption: {
    responseOptionLk: string
    createdDate: string
    pinned: boolean
    __typename: string
  }
}

export const PIN_CUSTOM_AUDIENCE_RESPONSE_OPTION = gql`
  mutation pinOrUnpinResponseOption(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $pinned: Boolean!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    pinOrUnpinResponseOption(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      pinned: $pinned
    ) {
      responseOptionLk
      createdDate
      pinned
      __typename
    }
  }
`
