import { gql } from '@apollo/client'
import {
  DraftEntryResponseOption,
  TextEntryState
} from '../../../../data/model/questionnaire'

export interface SetRoExclusiveData {
  setRoExclusive: {
    responseOptionLk: string
    createdDate: string
    exclusive: boolean
    __typename: string
  }
}

export const SET_RO_EXCLUSIVE = gql`
  mutation setRoExclusive(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $exclusive: Boolean!
  ) {
    setRoExclusive(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      exclusive: $exclusive
    ) {
      responseOptionLk
      createdDate
      exclusive
      __typename
    }
  }
`

export interface RoTextEntryStateData {
  setRoTextEntryState: {
    responseOptionLk: string
    createdDate: string
    textEntryState: TextEntryState
    __typename: string
  }
}

export const SET_RO_TEXT_ENTRY_STATE = gql`
  mutation setRoTextEntryState(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $textEntryState: TextEntryState!
  ) {
    setRoTextEntryState(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      textEntryState: $textEntryState
    ) {
      responseOptionLk
      createdDate
      textEntryState
      __typename
    }
  }
`

export interface setResponseOptionMediaData {
  updateResponseOption: DraftEntryResponseOption
}

export const SET_RESPONSE_OPTION_MEDIA = gql`
  mutation setResponseOptionMedia(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $mediaName: String!
    $mediaUrl: String!
    $mediaType: MediaType!
    $renderedMediaUrl: String
  ) {
    setResponseOptionMedia(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      mediaName: $mediaName
      mediaUrl: $mediaUrl
      mediaType: $mediaType
      renderedMediaUrl: $renderedMediaUrl
    ) {
      position
      responseOptionLk
      createdDate
      media {
        mediaName
        mediaUrl
        mediaType
        renderedMediaUrl
      }
    }
  }
`

export const REMOVE_RESPONSE_OPTION_MEDIA = gql`
  mutation removeResponseOptionMedia(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
  ) {
    removeResponseOptionMedia(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
    )
  }
`
