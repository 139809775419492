import { useFragment, useMutation } from '@apollo/client'
import { IconColor, IconName, KebabMenu } from '@focaldata/cin-ui-components'
import { useContext } from 'react'
import { RemoveMatrixQualificationMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import REMOVE_MATRIX_QUALIFICATION, {
  RemoveMatrixQualificationData
} from '../../../data/gql/questionnaire/mutations/removeMatrixQualification'
import { LoggerErrorType } from '../../../data/logger'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import QuestionnaireContext, {
  openMatrixScreeningDialog
} from '../Questionnaire.context'
import { MatrixScreeningMenu_DraftMatrixItemFragment } from './MatrixScreeningMenu.questionnaire.gql'

enum MatrixScreeningMenuOptions {
  EDIT_SCREENING,
  REMOVE_SCREENING
}

export const MatrixScreeningMenu = ({
  matrixTitleLk
}: {
  matrixTitleLk: string
}) => {
  const fragment = useFragment({
    fragment: MatrixScreeningMenu_DraftMatrixItemFragment,
    fragmentName: 'MatrixScreeningMenu_DraftMatrixItemFragment',
    from: {
      __typename: 'DraftMatrixItem',
      matrixTitleLk
    },
    canonizeResults: true
  })

  const matrixItem = fragment.complete ? fragment.data : null

  const surveyId = useSurveyId()
  const { dispatch } = useContext(QuestionnaireContext)

  const [removeMatrixQualification, removeMatrixQualificationMutation] =
    useMutation<
      RemoveMatrixQualificationData,
      RemoveMatrixQualificationMutationVariables
    >(REMOVE_MATRIX_QUALIFICATION, {
      context: { clientName: 'questionnaire' },
      onError(error) {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'removeMatrixQualification',
          error
        )
      }
    })

  if (!matrixItem) {
    throw new Error('Cannot get matrix item')
  }

  if (!matrixItem.matrixQualification) {
    return null
  }

  const itemsDisabled = removeMatrixQualificationMutation.loading

  return (
    <div style={{ marginRight: 10, marginBottom: -2 }}>
      <KebabMenu
        kebabMenuOptions={[
          {
            id: MatrixScreeningMenuOptions.EDIT_SCREENING,
            textItem: 'Edit screening',
            disabled: itemsDisabled,
            onClickItem() {
              dispatch(openMatrixScreeningDialog(matrixItem.matrixTitleLk))
            }
          },
          {
            id: MatrixScreeningMenuOptions.REMOVE_SCREENING,
            textItem: 'Remove screening',
            disabled: itemsDisabled,
            async onClickItem() {
              await removeMatrixQualification({
                variables: {
                  questionnaireId: surveyId,
                  matrixTitleLk
                }
              })
            }
          }
        ]}
        disabled={itemsDisabled}
        customIcon={IconName.FilterAltOutlined}
        iconColor={IconColor.Green}
      />
    </div>
  )
}
