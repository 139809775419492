import { fieldworkGql } from '../../../data/gql-gen/fieldwork'

export const SET_CUSTOM_CRITERION_OPTIONS_LOGIC = fieldworkGql(/* GraphQL */ `
  mutation setCustomCriterionOptionsLogic(
    $surveyId: ID!
    $questionLk: ID!
    $maxResponseOptionsToDisplay: Int
    $exemptResponseOptionLks: [ID!]
    $loopQuotaPriority: LoopQuotaPriorityInputInput
    $setAsRandomised: Boolean
  ) {
    setCustomCriterionOptionsLogic(
      surveyId: $surveyId
      questionLk: $questionLk
      maxResponseOptionsToDisplay: $maxResponseOptionsToDisplay
      exemptResponseOptionLks: $exemptResponseOptionLks
      loopQuotaPriority: $loopQuotaPriority
      setAsRandomised: $setAsRandomised
    ) {
      ... on DraftFieldworkAudienceMember {
        number
        memberItem {
          ...DraftCustomAudienceItem
        }
      }
    }
  }
`)

export const REMOVE_CUSTOM_CRITERION_OPTIONS_LOGIC = fieldworkGql(
  /* GraphQL */ `
    mutation removeCustomCriterionOptionsLogic(
      $surveyId: ID!
      $questionLk: ID!
    ) {
      removeCustomCriterionOptionsLogic(
        surveyId: $surveyId
        questionLk: $questionLk
      ) {
        ... on DraftFieldworkAudienceMember {
          number
          memberItem {
            ...DraftCustomAudienceItem
          }
        }
      }
    }
  `
)
