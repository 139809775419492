import { ScrollTo } from '@focaldata/cin-ui-components'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  SCROLL_ID_PREFIX
} from '../constants'

export const scrollToDuplicatedBasicQuestionCard = (cardNumber: string) => {
  setTimeout(() => {
    if (cardNumber) {
      ScrollTo(
        `${SCROLL_ID_PREFIX}${cardNumber}`,
        CONTAINER_ID_QUESTIONNAIRE_CONTENT
      )
    }
  }, 400)
}
