import { gql } from '@apollo/client/core'
import { EntrySettingValue } from '../../../model/questionnaire'
import ENTRY_SETTING_VALUE from '../fragments/entrySettingValue'

export interface SetQuestionnaireSettingValueData {
  setQuestionnaireSettingValue: EntrySettingValue
}

export const SET_QUESTIONNAIRE_SETTING_VALUE = gql`
  mutation setQuestionnaireSettingValue(
    $questionnaireId: ID!
    $questionLk: ID!
    $setting: SettingInputInput!
  ) {
    setQuestionnaireSettingValue(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      setting: $setting
    ) {
      number
      entryItem {
        ... on DraftQuestionItem {
          questionLk
          settingValues {
            ...EntrySettingValue
          }
        }
      }
    }
  }
  ${ENTRY_SETTING_VALUE}
`
