import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { QuestionEventMetadata } from '../../data/amplitude'
import {
  MoveQuestionnaireEntryMutationVariables,
  MoveSectionEntryMutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import { MOVE_SECTION_ENTRY } from '../../data/gql/questionnaire/mutations/moveSectionEntry'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import useRemoveEntryFromSectionMutation from '../../hooks/questionnaire/useRemoveEntryFromSection'
import { useProjectId } from '../../hooks/useProjectId'
import {
  questionNewlyCreatedNumber,
  responseOptionLkNewlyAdded
} from '../../hooks/useResetNewlyCreatedEntry'
import { useSurveyId } from '../../hooks/useSurveyId'
import { captureApolloError } from '../../utils/HelperFunctions'
import { newEntryId } from '../../utils/questionnaireUtils'
import useAddQuestionMenuHook from './AddQuestionMenu/AddQuestionMenu.hooks'
import { MOVE_QUESTIONNAIRE_ENTRY } from './Sidebar/SidebarQuestionnaireItemList/SidebarQuestionnaireItemList.mutations'

interface MoveQuestionEventMetadata extends QuestionEventMetadata {
  sourceContext: string
  destContext: string
  entryNumber: number
  toPosition: number
}

interface DragAndDropItem {
  path: string
  position: number
}

export type Source = DragAndDropItem
export type Destination = DragAndDropItem

export const useDragAndDrop = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const { addEntryToSection } = useAddQuestionMenuHook(surveyId || '')

  const { removeEntryFromSection } = useRemoveEntryFromSectionMutation(
    projectId,
    surveyId
  )
  const [moveSectionEntry] = useMutation<
    void,
    MoveSectionEntryMutationVariables
  >(MOVE_SECTION_ENTRY, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'moveSectionEntry',
        error
      )
    }
  })

  const [moveQuestionniareEntry] = useMutation<
    void,
    MoveQuestionnaireEntryMutationVariables
  >(MOVE_QUESTIONNAIRE_ENTRY, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'moveQuestionniareEntry',
        error
      )
    }
  })

  const moveCard = useCallback(
    (itemId: string, source: Source, dest: Destination) => {
      if (source.path === dest.path || !itemId) return
      const sourceContext = source.path.includes('/') ? 'section' : 'sidebar'
      const destContext = dest.path.includes('/') ? 'section' : 'sidebar'

      const entryNumber = Number(itemId)

      const eventMetadata: MoveQuestionEventMetadata = {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        surveyId: surveyId ?? '',
        sourceContext,
        destContext,
        entryNumber,
        toPosition: dest.position
      }
      if (sourceContext === 'sidebar' && destContext === 'sidebar') {
        // move entry in sidebar
        const variables = {
          questionnaireId: surveyId,
          number: entryNumber,
          toPosition: dest.position
        }
        if (Number(source.path)) {
          moveQuestionniareEntry({
            variables
          })
        } else {
          moveSectionEntry({
            variables
          })
        }
      }

      if (sourceContext === 'sidebar' && destContext === 'section') {
        // move question into section
        const sectionId = dest.path.split('/')[0]
        addEntryToSection({
          variables: {
            questionnaireId: surveyId,
            sectionId,
            entryNumber,
            toPosition: dest.position
          }
        })

        eventMetadata.sectionId = sectionId
      }

      if (sourceContext === 'section' && destContext === 'sidebar') {
        // move question out of section
        removeEntryFromSection({
          variables: {
            questionnaireId: surveyId,
            entryNumber,
            toPosition: dest.position
          }
        })
      }

      if (sourceContext === 'section' && destContext === 'section') {
        const sourceSectionId = source.path.split('/')[0]
        const destSectionId = dest.path.split('/')[0]
        if (sourceSectionId !== destSectionId) {
          addEntryToSection({
            variables: {
              questionnaireId: surveyId,
              sectionId: destSectionId,
              entryNumber,
              toPosition: dest.position
            }
          })
          eventMetadata.sectionId = destSectionId
        } else {
          moveQuestionniareEntry({
            variables: {
              questionnaireId: surveyId,
              number: entryNumber,
              toPosition: dest.position
            }
          })
        }
      }

      questionNewlyCreatedNumber(undefined)
      newEntryId(undefined)
      responseOptionLkNewlyAdded(undefined)

      LogAmplitudeEvent(EventType.MovedCard, eventMetadata)
    },
    [
      addEntryToSection,
      moveQuestionniareEntry,
      moveSectionEntry,
      removeEntryFromSection,
      surveyId
    ]
  )

  return moveCard
}
