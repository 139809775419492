import { useMutation } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import BottomNav from '../../containers/Page/Survey/BottomNav'
import { UpdateFieldworkMutationVariables } from '../../data/gql-gen/fieldwork/graphql'
import { UPDATE_FIELDWORK } from '../../data/gql/fieldwork/mutations/updateFieldwork'
import { LoggerErrorType } from '../../data/logger'
import useGetDraftQuestionnaire from '../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../hooks/questionnaire/useQuestionnaireValidation'
import useCalculateLengthOfInterview from '../../hooks/useCalculateLengthOfInterview'
import { useGetFeasibilityEstimateData } from '../../hooks/useGetFeasibilityEstimateData'
import { useProjectId } from '../../hooks/useProjectId'
import { useSurveyId } from '../../hooks/useSurveyId'
import {
  captureApolloError,
  checkIfMasterSurveyPage,
  handleShowPreview
} from '../../utils/HelperFunctions'
import {
  checkIsQuestionnaireValid,
  newEntryId
} from '../../utils/questionnaireUtils'
import { AddQuestionMenu } from './AddQuestionMenu'

export const BottomNavContainer = () => {
  const { flattenedEntries, draftQuestionnaire } = useGetDraftQuestionnaire()

  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const navigate = useNavigate()
  const { isFeasible, isAudienceValid } = useGetFeasibilityEstimateData()
  const { hasValidationErrors } = useQuestionnaireValidation()
  const calculatedLengthOfInterview = useCalculateLengthOfInterview()
  const isMasterSurveyPage = checkIfMasterSurveyPage()

  // Math.max will return -Infinity for empty flattenedEntries array,
  // so -1 will handle this scenario for empty questionnaire
  const lastEntryPosition = Math.max(
    -1,
    ...flattenedEntries.map((e) => e.position)
  )

  const isQuestionnaireEmpty = flattenedEntries.length === 0

  const routeToConfirmation: () => void = useCallback(() => {
    const isQuestionnaireValid = checkIsQuestionnaireValid(draftQuestionnaire)

    if (isQuestionnaireValid) {
      navigate(`/project/${projectId}/survey/${surveyId}/confirm`)
    }
  }, [draftQuestionnaire, navigate, surveyId, projectId])

  const [updateFieldwork] = useMutation<void, UpdateFieldworkMutationVariables>(
    UPDATE_FIELDWORK,
    {
      context: { clientName: 'fieldwork' },
      onCompleted: routeToConfirmation,
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'updateFieldwork',
          error
        )
      }
    }
  )

  const handleClickToConfirmation = useCallback(() => {
    newEntryId(undefined)
    updateFieldwork({
      variables: {
        surveyId,
        panelSupplierCode: 'cint',
        lengthOfInterview: calculatedLengthOfInterview,
        samplesNeeded: null,
        startDate: null,
        endDate: null,
        countryCode: null
      }
    })
  }, [calculatedLengthOfInterview, surveyId, updateFieldwork])

  const rightButtonDisabled =
    !isFeasible ||
    isQuestionnaireEmpty ||
    !isAudienceValid ||
    hasValidationErrors()

  const middleButtonControl = useMemo(
    () =>
      draftQuestionnaire?.entries.length ? (
        <AddQuestionMenu
          showAddButton
          showSectionMenu
          position={lastEntryPosition + 1}
        />
      ) : null,
    [draftQuestionnaire?.entries.length, lastEntryPosition]
  )

  const handleClickSecondaryRightButton = useCallback(
    () => handleShowPreview(surveyId),
    [surveyId]
  )

  return (
    <BottomNav
      width={'calc(100% - 56px)'}
      overrideDisableButtons={isQuestionnaireEmpty}
      middleButtonText="Add question"
      middleButtonControl={middleButtonControl}
      rightButtonText="To confirmation"
      rightButtonDisabled={rightButtonDisabled}
      onClickRightButton={
        isMasterSurveyPage ? undefined : handleClickToConfirmation
      }
      secondaryRightButtonDisabled={isQuestionnaireEmpty}
      secondaryRightButtonText="Preview"
      onClickSecondaryRightButton={
        isMasterSurveyPage ? undefined : handleClickSecondaryRightButton
      }
    />
  )
}
