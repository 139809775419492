import { List } from '@focaldata/cin-ui-components'
import React, { memo } from 'react'
import { EntryType } from '../../../../data/gql-gen/questionnaire/graphql'
import { DraftEntryResponseOption } from '../../../../data/model/questionnaire'
import useQuestionnaireValidation from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import {
  WithEntryOfType,
  propsAreEqual
} from '../../../../utils/questionnaireUtils'
import MatrixResponseOption from './MatrixResponseOption'

interface Props extends WithEntryOfType<EntryType.MatrixEntryType> {
  matrixResponseOptions: DraftEntryResponseOption[]
  newlyAddedResponseOptionLk: string | undefined
  entryNumber: number
  maskingEnabled: boolean
  onEnter?: (position: number, matrixTitleLk: string) => void
  onChangeMatrixResponseOption: (
    inputValue: string,
    responseOptionLk: string,
    matrixTitleLk: string
  ) => void
  onDeleteMatrixResponseOption: (
    responseOptionLk: string,
    matrixTitleLk: string
  ) => void
  onReorderedMatrixResponseOptions: (
    destinationIndex: number,
    sourceIndex: number
  ) => void
  onSetExclusiveOption?: (
    responseOptionLk: string,
    isExclusive: boolean
  ) => void
}

const MatrixResponseOptions: React.FC<Props> = (props: Props) => {
  const {
    entry,
    matrixResponseOptions,
    newlyAddedResponseOptionLk,
    entryNumber,
    maskingEnabled = false,
    onEnter,
    onChangeMatrixResponseOption,
    onDeleteMatrixResponseOption,
    onReorderedMatrixResponseOptions,
    onSetExclusiveOption
  } = props
  const { validateMatrixColumnText } = useQuestionnaireValidation()

  const responseOptionsCount = matrixResponseOptions.length
  const { matrixTitleLk } = entry.entryItem

  const getMatrixResponse: (
    matrixResponseOptions: DraftEntryResponseOption[] | undefined
  ) => (JSX.Element | null)[] | undefined = (matrixResponseOptions) => {
    return (
      matrixResponseOptions &&
      matrixResponseOptions.map((matrixColumn, index) => {
        const shouldAutoFocus =
          newlyAddedResponseOptionLk === matrixColumn.responseOptionLk

        return (
          <MatrixResponseOption
            key={matrixColumn.responseOptionLk + matrixColumn.position}
            index={index}
            entryNumber={entryNumber}
            maskingEnabled={maskingEnabled}
            matrixTitleLk={matrixTitleLk}
            matrixColumn={matrixColumn}
            shouldAutoFocus={shouldAutoFocus}
            responseOptionsCount={responseOptionsCount}
            onEnter={onEnter}
            onChangeMatrixResponseOption={onChangeMatrixResponseOption}
            onDeleteMatrixResponseOption={onDeleteMatrixResponseOption}
            onSetExclusiveOption={onSetExclusiveOption}
            validateMatrixColumnText={validateMatrixColumnText}
          />
        )
      })
    )
  }

  const responseOptionInputs = getMatrixResponse(matrixResponseOptions)

  return (
    <List
      canReorderItems
      droppableId="droppable1"
      onReorderedItems={(event) => {
        if (event.destination) {
          onReorderedMatrixResponseOptions(
            event.destination.index,
            event.source.index
          )
        }
      }}
    >
      {responseOptionInputs}
    </List>
  )
}

export default memo(MatrixResponseOptions, propsAreEqual)
