import { gql } from '@apollo/client/core'
import { EntryType } from '../../../gql-gen/questionnaire/graphql'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface AddQuestionData {
  addQuestion: DraftQuestionnaireEntry<EntryType.QuestionEntryType>
}

export const ADD_QUESTION = gql`
  mutation addQuestion(
    $questionnaireId: ID!
    $questionTypeCode: String!
    $position: Int!
    $text: String
    $setting: SettingInputInput
    $defaultOptionsCount: Int
    $sectionId: ID
  ) {
    addQuestion(
      questionnaireId: $questionnaireId
      questionTypeCode: $questionTypeCode
      position: $position
      text: $text
      setting: $setting
      defaultOptionsCount: $defaultOptionsCount
      sectionId: $sectionId
    ) {
      number
      position
      entryType
      questionKind
      sectionId
      entryItem {
        __typename
        ... on DraftQuestionItem {
          questionTypeCode
          questionLk
          question {
            __typename
            questionId
            text
            createdDate
          }
          __typename
        }
      }
      __typename
    }
  }
`
