import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface DuplicateTextCardData {
  duplicateTextCard: DraftQuestionnaireEntry
}

export const DUPLICATE_TEXT_CARD = gql`
  mutation duplicateTextCard(
    $questionnaireId: ID!
    $textCardLk: ID!
    $position: Int!
  ) {
    duplicateTextCard(
      questionnaireId: $questionnaireId
      textCardLk: $textCardLk
      position: $position
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftTextCardItem {
          textCardLk
          textCard {
            textCardId
            title
            subtitle
            body
          }
          __typename
        }
      }
      __typename
    }
  }
`
