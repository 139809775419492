import { useApolloClient } from '@apollo/client'
import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import { QuestionSettingCode } from '../../../data/model/questionnaire'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import useHandleMultiSelectLimits from '../../../hooks/questionnaire/useHandleIntervalSettingsChange'
import {
  WithEntry,
  checkIfMatrixMultipleChoice,
  getQuestionSettings
} from '../../../utils/questionnaireUtils'
import MultiSelectChoiceLimit from '../BasicQuestion/QuestionCardFooter/MultiSelectChoiceLimit'
import QuestionCardFooter from '../QuestionCardFooter'
import {
  selectResponseOptionsByQuestion,
  selectSettingsByQuestionId
} from '../Questionnaire.slice'
import { addResponseScale } from '../Questionnaire.thunks'
import { getEntryId } from '../Questionnaire.utils'
import { useAddMatrixResponseOption } from './MatrixResponsOptions.hooks'

const MatrixQuestion: React.FC<WithEntry> = (props: WithEntry) => {
  const { entry } = props
  const matrixTitleLk = getEntryId(entry)
  const dispatch = useAppDispatch()
  const questionnaireId = useDraftQuestionnaireIdCache()
  const addMatrixResponseOption = useAddMatrixResponseOption()

  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const responseOptions = responseOptionsByQuestion[matrixTitleLk]

  const { handleIntervalSettingsChange, handleQuestionLimitChange } =
    useHandleMultiSelectLimits(entry)

  const apolloClient = useApolloClient()
  const handleAddScale = async () => {
    await dispatch(
      addResponseScale({
        questionLk: matrixTitleLk,
        questionnaireId,
        entryType: entry.entryType,
        scale: 'Agree1To5',
        apolloClient
      })
    )
  }

  const handleChangeChoiceLimit: (settingValue: number) => void = useCallback(
    (settingValue) =>
      handleQuestionLimitChange(QuestionSettingCode.ChoiceLimit, settingValue),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matrixTitleLk, questionnaireId]
  )

  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectSettingsByQuestionId(state, matrixTitleLk)
    ) || []

  const isMultipleChoice = checkIfMatrixMultipleChoice(settingValues)
  const questionSettings = getQuestionSettings(settingValues)
  const choiceLimitValue = questionSettings.get(QuestionSettingCode.ChoiceLimit)
  const choiceLimit = choiceLimitValue && parseInt(choiceLimitValue, 10)

  const choiceIntervalMinValue = questionSettings.get(
    QuestionSettingCode.ChoiceIntervalMin
  )
  const choiceIntervalMin =
    choiceIntervalMinValue && parseInt(choiceIntervalMinValue, 10)

  const choiceIntervalMaxValue = questionSettings.get(
    QuestionSettingCode.ChoiceIntervalMax
  )
  const choiceIntervalMax =
    choiceIntervalMaxValue && parseInt(choiceIntervalMaxValue, 10)

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const matrixResponseOptionsCount = responseOptions?.length

  return (
    <QuestionCardFooter
      entry={entry}
      onClickAddResponseOption={() => {
        addMatrixResponseOption(
          entry.number,
          responseOptions.length,
          matrixTitleLk
        )
      }}
      addButtonText="Add response"
      showAddScale
      onAddScale={handleAddScale}
      multiSelectChoiceLimit={
        isMultipleChoice ? (
          <MultiSelectChoiceLimit
            responseOptionsCount={matrixResponseOptionsCount}
            choiceLimit={choiceLimit}
            choiceIntervalMin={choiceIntervalMin}
            choiceIntervalMax={choiceIntervalMax}
            onChangeChoiceLimit={handleChangeChoiceLimit}
            onChangeIntervalRange={handleIntervalSettingsChange}
          />
        ) : undefined
      }
    />
  )
}

export default MatrixQuestion
