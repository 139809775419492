import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface UpdateQuestionData {
  updateQuestion: DraftQuestionnaireEntry
}

export const UPDATE_QUESTION = gql`
  mutation updateQuestion(
    $questionnaireId: ID!
    $questionLk: ID!
    $questionTypeCode: String!
    $text: String!
    $textStyling: String!
  ) {
    updateQuestion(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      questionTypeCode: $questionTypeCode
      text: $text
      textStyling: $textStyling
    ) {
      number
      position
      entryType
      entryItem {
        ...DraftQuestionItem
      }
      __typename
    }
  }
`
