import { gql } from '@apollo/client'

export const SET_SECTIONS_RANDOMISATION = gql`
  mutation setSectionsRandomisation(
    $questionnaireId: ID!
    $sectionIds: [ID!]!
  ) {
    setSectionsRandomisation(
      questionnaireId: $questionnaireId
      sectionIds: $sectionIds
    )
  }
`

export const REMOVE_SECTIONS_RANDOMISATION = gql`
  mutation removeSectionsRandomisation($questionnaireId: ID!) {
    removeSectionsRandomisation(questionnaireId: $questionnaireId)
  }
`
