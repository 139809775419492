import {
  FetchResult,
  MutationFunctionOptions,
  makeVar,
  useMutation,
  useReactiveVar
} from '@apollo/client'
import {
  AddForkBranchMutationVariables,
  ForkTag,
  RemoveForkBranchMutationVariables,
  RemoveQuestionnaireEntryForkBranchMutationVariables,
  SetQuestionnaireEntryForkBranchMutationVariables,
  UpdateForkBranchMutationVariables,
  UpdateForkMutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import ADD_FORK_BRANCH, {
  AddForkBranchData
} from '../../data/gql/questionnaire/mutations/addForkBranch'
import REMOVE_FORK_BRANCH, {
  RemoveForkBranchData
} from '../../data/gql/questionnaire/mutations/removeForkBranch'
import REMOVE_QUESTIONNAIRE_ENTRY_FORK_BRANCH, {
  RemoveQuestionnaireEntryForkBranchData
} from '../../data/gql/questionnaire/mutations/removeQuestionnaireEntryForkBranch'
import SET_QUESTIONNAIRE_ENTRY_FORK_BRANCH, {
  SetQuestionnaireEntryForkBranchData
} from '../../data/gql/questionnaire/mutations/setQuestionnaireEntryForkBranch'
import UPDATE_FORK, {
  UpdateForkData
} from '../../data/gql/questionnaire/mutations/updateFork'
import UPDATE_FORK_BRANCH, {
  UpdateForkBranchData
} from '../../data/gql/questionnaire/mutations/updateForkBranch'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import { DraftQuestionnaireEntry } from '../../data/model/questionnaire'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import useGetDraftQuestionnaire from './useGetDraftQuestionnaire'

interface UniqueForkNameError {
  hasError: boolean
  id: string | undefined
}

interface Data {
  questionnaireId: string
  uniqueForkNameError: UniqueForkNameError
  updateForkBranch: (
    options?: MutationFunctionOptions<
      UpdateForkBranchData,
      UpdateForkBranchMutationVariables
    >
  ) => Promise<FetchResult<UpdateForkBranchData>>
  updateFork: (
    options?: MutationFunctionOptions<
      UpdateForkData,
      UpdateForkMutationVariables
    >
  ) => Promise<FetchResult<UpdateForkData>>
  addForkBranch: (
    options?: MutationFunctionOptions<
      AddForkBranchData,
      AddForkBranchMutationVariables
    >
  ) => Promise<FetchResult<AddForkBranchData>>
  removeForkBranch: (
    options?: MutationFunctionOptions<
      RemoveForkBranchData,
      RemoveForkBranchMutationVariables
    >
  ) => Promise<FetchResult<RemoveForkBranchData>>
  setQuestionnaireEntryForkBranch: (
    options?: MutationFunctionOptions<
      SetQuestionnaireEntryForkBranchData,
      SetQuestionnaireEntryForkBranchMutationVariables
    >
  ) => Promise<FetchResult<SetQuestionnaireEntryForkBranchData>>
  removeQuestionnaireEntryForkBranch: (
    options?: MutationFunctionOptions<
      RemoveQuestionnaireEntryForkBranchData,
      RemoveQuestionnaireEntryForkBranchMutationVariables
    >
  ) => Promise<FetchResult<RemoveQuestionnaireEntryForkBranchData>>
  addForksToEntry: (
    entry: DraftQuestionnaireEntry,
    forkTags: ForkTag[]
  ) => Promise<void[]>
  removeForksFromEntry: (entry: DraftQuestionnaireEntry) => Promise<void[]>
}

export enum ForkValidationErrors {
  UniqueName = 'already exists with forkId'
}

export const hasUniqueForkNameError = makeVar<boolean>(false)
export const uniqueForkNameErrorId = makeVar<string | undefined>(undefined)

const useForkMutations: () => Data = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const hasError = useReactiveVar<boolean>(hasUniqueForkNameError)
  const forkIdError = useReactiveVar<string | undefined>(uniqueForkNameErrorId)

  const uniqueForkNameError = {
    hasError,
    id: forkIdError
  }

  const checkIfUniqueForkNameViolation: (error: Error) => void = (error) => {
    if (error.message.includes(ForkValidationErrors.UniqueName)) {
      const forkId = error.message.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)?.[0]
      hasUniqueForkNameError(true)
      uniqueForkNameErrorId(forkId)
    } else {
      hasUniqueForkNameError(false)
      uniqueForkNameErrorId(undefined)
    }
  }

  const { draftQuestionnaire } = useGetDraftQuestionnaire()
  const questionnaireId = draftQuestionnaire?.questionnaireId || ''
  const [updateForkBranch] = useMutation<
    UpdateForkBranchData,
    UpdateForkBranchMutationVariables
  >(UPDATE_FORK_BRANCH, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'updateForkBranch', error)
    }
  })
  const [updateFork] = useMutation<UpdateForkData, UpdateForkMutationVariables>(
    UPDATE_FORK,
    {
      context: { clientName: 'questionnaire' },
      refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
      onCompleted: () => {
        hasUniqueForkNameError(false)
      },
      onError: (error) => {
        checkIfUniqueForkNameViolation(error)
        captureApolloError(LoggerErrorType.ApolloQuery, 'updateFork', error)
      }
    }
  )
  const [addForkBranch] = useMutation<
    AddForkBranchData,
    AddForkBranchMutationVariables
  >(ADD_FORK_BRANCH, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'addForkBranch', error)
    }
  })
  const [removeForkBranch] = useMutation<
    RemoveForkBranchData,
    RemoveForkBranchMutationVariables
  >(REMOVE_FORK_BRANCH, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'removeForkBranch', error)
    }
  })
  const [setQuestionnaireEntryForkBranch] = useMutation<
    SetQuestionnaireEntryForkBranchData,
    SetQuestionnaireEntryForkBranchMutationVariables
  >(SET_QUESTIONNAIRE_ENTRY_FORK_BRANCH, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'setQuestionnaireEntryForkBranch',
        error
      )
    }
  })
  const [removeQuestionnaireEntryForkBranch] = useMutation<
    RemoveQuestionnaireEntryForkBranchData,
    RemoveQuestionnaireEntryForkBranchMutationVariables
  >(REMOVE_QUESTIONNAIRE_ENTRY_FORK_BRANCH, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'removeQuestionnaireEntryForkBranch',
        error
      )
    }
  })
  const addForksToEntry: (
    entry: DraftQuestionnaireEntry,
    forkTags: ForkTag[]
  ) => Promise<void[]> = (entry, forkTags) => {
    return Promise.all(
      forkTags.map(async (forkTag) => {
        await setQuestionnaireEntryForkBranch({
          variables: {
            questionnaireId,
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            number: entry?.number || 0,
            forkId: forkTag.forkId,
            branchNumber: forkTag.branchNumber
          },
          awaitRefetchQueries: true
        })
      })
    )
  }
  const removeForksFromEntry: (
    entry: DraftQuestionnaireEntry
  ) => Promise<void[]> = (entry) => {
    return Promise.all(
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      entry?.forks.map(async (fork) => {
        await removeQuestionnaireEntryForkBranch({
          variables: {
            questionnaireId,
            number: entry.number,
            forkId: fork.forkId,
            branchNumber: fork.branchNumber
          }
        })
      })
    )
  }

  return {
    questionnaireId,
    uniqueForkNameError,
    updateForkBranch,
    updateFork,
    addForkBranch,
    removeForkBranch,
    setQuestionnaireEntryForkBranch,
    removeQuestionnaireEntryForkBranch,
    addForksToEntry,
    removeForksFromEntry
  }
}

export default useForkMutations
