import React, { memo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { emptyArr } from '../../../../constants/misc'
import {
  DraftMaskingRule,
  ForkTag
} from '../../../../data/gql-gen/questionnaire/graphql'
import { EntryType } from '../../../../data/model/questionnaire'
import { useDraftMatrixRow } from '../../../../hooks/questionnaire/useDraftMatrixRow'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import {
  rowForksSet,
  rowMaskingRulesSet,
  selectResponseOptionsByQuestion
} from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import MaskingButtonControl from './MaskingButton.control'
import useMaskingButtonHooks from './MaskingButton.hooks'

interface Props {
  questionLk: string
  matrixTitleLk: string
}

const MatrixMaskingButton: React.FC<Props> = ({
  questionLk,
  matrixTitleLk
}: Props) => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const matrixRow = useDraftMatrixRow(questionLk)
  const matrixRowForks = matrixRow?.forks ?? emptyArr
  const matrixRowMasking = matrixRow?.maskingRules ?? emptyArr

  const dispatch = useAppDispatch()
  const surveyId = useSurveyId()
  const { setMatrixRowMaskingRule, setMatrixRowForks } = useMaskingButtonHooks()

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const handleSave: (
    newRules: DraftMaskingRule[],
    displayLogicForks: ForkTag[]
  ) => Promise<void> = async (newRules, forkTags) => {
    dispatch(
      rowMaskingRulesSet({
        matrixTitleLk,
        questionLk,
        maskingRules: newRules,
        flattenAllEntries: flatDraftQuestionnaireEntries
      })
    )
    await setMatrixRowMaskingRule(matrixTitleLk, questionLk, newRules)

    if (matrixRow) {
      dispatch(
        rowForksSet({
          matrixTitleLk,
          questionLk,
          forkTags
        })
      )
      await setMatrixRowForks(matrixTitleLk, matrixRow, forkTags)
    }

    LogAmplitudeEvent(EventType.AddedMatrixRowDisplayLogic, {
      surveyId,
      matrixTitleLk,
      questionLk
    })
  }

  return (
    <MaskingButtonControl
      entryType={EntryType.MatrixEntryType}
      entries={flatDraftQuestionnaireEntries}
      onSave={handleSave}
      entryItemLk={matrixTitleLk}
      maskedItemLk={questionLk}
      maskingRules={matrixRowMasking}
      forks={matrixRowForks}
      allowForks
      responseOptionsByQuestion={responseOptionsByQuestion}
    />
  )
}

export default memo(MatrixMaskingButton)
