import React, { useContext, useState } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { EnabledState, StandardCriterion } from '../../../data/model/audience'
import useStandardAudienceListGqlOperations from '../../../hooks/audience/useStandardAudienceListGqlOperations'
import { useSurveyId } from '../../../hooks/useSurveyId'
import AudienceContext, { setNewAudienceQuestionLk } from '../Audience.context'
import StandardAudienceListControl from './StandardAudienceList.control'

const StandardAudienceList: React.FC = () => {
  const surveyId = useSurveyId()
  const [showAdvancedCriteria, setShowAdvancedCriteria] =
    useState<boolean>(false)
  const {
    audienceCategories,
    audienceQuestions,
    setCriterion,
    removeCriterion
  } = useStandardAudienceListGqlOperations()

  const { dispatch } = useContext(AudienceContext)

  const handleAudienceChange: (criterion: StandardCriterion) => void = (
    criterion
  ) => {
    if (criterion.state === EnabledState.Enabled) {
      removeCriterion({
        variables: {
          surveyId,
          audienceCriterionCode: criterion.criterion.code
        }
      })
    } else {
      const audienceQuestionsNr: number | undefined =
        audienceQuestions?.members.length
      if (audienceQuestionsNr !== undefined) {
        setCriterion({
          variables: {
            surveyId,
            position: audienceQuestionsNr,
            audienceCriterionCode: criterion.criterion.code
          }
        })
        dispatch(setNewAudienceQuestionLk(undefined))

        LogAmplitudeEvent(EventType.AddedTargetingCriteria, { surveyId })
      }
    }
  }

  const handleShowAdavanceCriteria = () => {
    setShowAdvancedCriteria(!showAdvancedCriteria)
  }

  const standardAudienceCategories =
    audienceCategories &&
    audienceCategories.categories
      .slice() // "Because the array is frozen in strict mode, you'll need to copy the array before sorting it"
      .sort((categoryA, categoryB) => categoryA.position - categoryB.position)

  const defaultCriteria = standardAudienceCategories?.slice(0, 2) // Demographic and Geographic are in position 0 and 1

  return (
    <StandardAudienceListControl
      showAdvancedCriteria={showAdvancedCriteria}
      standardAudienceCategories={
        showAdvancedCriteria ? standardAudienceCategories : defaultCriteria
      }
      validationErrors={audienceQuestions?.validationErrors}
      onAudienceChange={handleAudienceChange}
      onShowAdvancedCriteria={handleShowAdavanceCriteria}
    />
  )
}

export default StandardAudienceList
