import { FetchResult, useMutation } from '@apollo/client'
import defer from 'lodash/defer'
import { useContext } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../apollo/apolloClient'
import {
  AddSectionMutationVariables,
  DetachQuestionnaireEntryMutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import {
  DETACH_QUESTIONNAIRE_ENTRY,
  DetachQuestionnaireEntryData
} from '../../data/gql/questionnaire/mutations/detatchQuestionnaireEntry'
import {
  QUESTIONNAIRE,
  draftQuestionnaireRefetchQuery
} from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import useDraftQuestionnaireIdCache from '../../hooks/localState/useDraftQuestionnaireIdCache'
import { useProjectId } from '../../hooks/useProjectId'
import { questionNewlyCreatedNumber } from '../../hooks/useResetNewlyCreatedEntry'
import { useSurveyId } from '../../hooks/useSurveyId'
import { scrollToNewInBetweenQuestionCard } from '../../modules/Questionnaire/AddQuestionMenu/AddQuestionMenu.utils'
import QuestionnaireContext, {
  closeDeleteSectionDialog
} from '../../modules/Questionnaire/Questionnaire.context'
import { deleteQuestionTransactionDatadog } from '../../tracking/perf/transactions'
import { captureApolloError } from '../../utils/HelperFunctions'
import { ADD_SECTION, AddSectionData } from './Section.mutations'
import { updateCacheOnSectionDelete } from './Section.utils'

const useAddSection = (questionnaireId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [addSectionMutation] = useMutation<
    AddSectionData,
    AddSectionMutationVariables
  >(ADD_SECTION, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onCompleted: ({ addSection }) => {
      questionBeingEditedNumber(undefined)
      questionNewlyCreatedNumber(addSection.number)
      defer(() => scrollToNewInBetweenQuestionCard(addSection.number))
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'addSection', error)
    }
  })

  return (
    sectionPosition: number
  ): Promise<
    FetchResult<AddSectionData, Record<string, any>, Record<string, any>>
  > => {
    return addSectionMutation({
      variables: {
        questionnaireId,
        title: 'Section',
        position: sectionPosition
      }
    })
  }
}

const useDeleteSection = (
  entryNumber: number
): {
  deleteSectionWithoutQuestions: () => void
  deleteSectionAndQuestions: () => void
} => {
  const [detachQuestionnaireEntry] = useMutation<
    DetachQuestionnaireEntryData,
    DetachQuestionnaireEntryMutationVariables
  >(DETACH_QUESTIONNAIRE_ENTRY, {
    context: { clientName: 'questionnaire' }
  })
  const questionnaireId = useDraftQuestionnaireIdCache()
  const { dispatch } = useContext(QuestionnaireContext)
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const deleteSection = async (includingEntries: boolean) => {
    dispatch(closeDeleteSectionDialog())
    deleteQuestionTransactionDatadog.start()
    await detachQuestionnaireEntry({
      optimisticResponse: {
        detachQuestionnaireEntry: {
          number: entryNumber,
          questionnaireId,
          detachedDate: new Date().toDateString(),
          __typename: 'DraftQuestionnaireEntry'
        }
      },
      variables: {
        questionnaireId,
        number: entryNumber,
        detachAll: includingEntries
      },
      refetchQueries: [QUESTIONNAIRE],
      onCompleted: () => {
        LogAmplitudeEvent(EventType.DeletedSection, {
          surveyId,
          questionnaireId,
          includingEntries
        })
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloQuery,
          'detachQuestionnaireEntry',
          error
        )
      },
      update: (cache) =>
        updateCacheOnSectionDelete(cache, {
          projectId,
          surveyId,
          entryNumber,
          includingEntries
        })
    })
  }

  return {
    deleteSectionWithoutQuestions: () => deleteSection(false),
    deleteSectionAndQuestions: () => deleteSection(true)
  }
}

export { useAddSection, useDeleteSection }
