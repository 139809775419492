import { List, ScrollLink } from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React, { useCallback, useMemo, useState } from 'react'
import DragAndDrop from '../../../../components/DragAndDrop-react-dnd/DragAndDrop'
import ListItemSidebar from '../../../../components/ListItemSidebar'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  CUSTOM_QUESTION_KEY,
  SCROLL_ID_PREFIX
} from '../../constants'
import { Destination, Source } from '../../useDragAndDrop.hooks'
import { SidebarAudienceEntry } from './SidebarAudienceItemList.query'
import useStyles from './SidebarAudienceItemList.styles'
import { getAudienceCriteriaListItemPropValues } from './SidebarAudienceItemList.utils'

interface Props {
  audienceItems: SidebarAudienceEntry[]
  onReorderAudience: (
    destinationIndex: number | undefined,
    sourceIndex: number
  ) => void
}

const SidebarAudienceItemList: React.FC<Props> = ({
  audienceItems,
  onReorderAudience
}: Props) => {
  const { classes } = useStyles()
  const [selectedAudienceCriteriaIndex, setSelectedAudienceCriteriaIndex] =
    useState<number | undefined>(undefined)

  const moveAudienceCard = useCallback(
    (_: any, source: Source, dest: Destination) => {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      onReorderAudience?.(dest.position, source.position)
    },
    [onReorderAudience]
  )

  const getAudienceCriteria = useCallback(
    (
      audienceCriteriaEntries: SidebarAudienceEntry[] | undefined
    ): (JSX.Element | undefined)[] | undefined => {
      return (
        audienceCriteriaEntries &&
        audienceCriteriaEntries.map((entry, index) => {
          const [key, questionTitle, questionNumber] =
            getAudienceCriteriaListItemPropValues(entry, index)

          const isSelected = index === selectedAudienceCriteriaIndex

          return (
            <DragAndDrop
              key={key}
              position={index}
              id={entry.number.toString()}
              type="audience"
              moveCard={moveAudienceCard}
              path={entry.number.toString()}
              accept={['audience']}
              draggable
            >
              <ScrollLink
                key={`${SCROLL_ID_PREFIX}${entry.number}${CUSTOM_QUESTION_KEY}`}
                offset={-20}
                containerId={CONTAINER_ID_QUESTIONNAIRE_CONTENT}
                to={`${SCROLL_ID_PREFIX}${key}${CUSTOM_QUESTION_KEY}`}
              >
                <ListItemSidebar
                  key={key}
                  questionTitle={questionTitle}
                  questionNumber={questionNumber}
                  isSelected={isSelected}
                  onClickItem={() => {
                    setSelectedAudienceCriteriaIndex(index)
                  }}
                />
              </ScrollLink>
            </DragAndDrop>
          )
        })
      )
    },
    [selectedAudienceCriteriaIndex, moveAudienceCard]
  )

  const audienceCriteriaEntries = useMemo(
    () => getAudienceCriteria(audienceItems),
    [audienceItems, getAudienceCriteria]
  )

  return (
    <>
      <List>{audienceCriteriaEntries}</List>
      <Divider className={classes.divider} />
    </>
  )
}

export default SidebarAudienceItemList
