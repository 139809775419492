import { gql } from '@apollo/client/core'

const ENTRY_SETTING_VALUE = gql`
  fragment EntrySettingValue on EntrySettingValue {
    code
    value
    createdDate
    sinceDate
  }
`

export default ENTRY_SETTING_VALUE
