import { PureQueryOptions, gql } from '@apollo/client/core'
import { FieldworkAudienceCategory } from '../../../model/audience'
import STANDARD_AUDIENCE_CRITERION from '../fragments/audienceCriterion'

export interface FieldworkAudienceCategoryData {
  categories: FieldworkAudienceCategory[]
}

export const EXPANDED_AUDIENCE_CATEGORIES = [
  'demographic',
  'geographic',
  'household',
  'mobile',
  'finance',
  'automotive',
  'travel',
  'smoking',
  'healthcare',
  'electronics',
  'hobbies_&_interests',
  'business_&_occupation',
  'food_&_beverage',
  'media',
  'mother_&_baby'
]

export const GET_AUDIENCE_CATEGORIES = gql`
  query categories($surveyId: ID!, $expandedCategories: [String!]!) {
    categories(surveyId: $surveyId, expandedCategories: $expandedCategories) {
      category {
        code
        name
        createdDate
      }
      position
      standardCriteria {
        ...StandardAudienceCriterion
      }
    }
  }
  ${STANDARD_AUDIENCE_CRITERION}
`

export const categoriesRefetchQuery: (surveyId: string) => PureQueryOptions = (
  surveyId
) => {
  return {
    query: GET_AUDIENCE_CATEGORIES,
    context: { clientName: 'fieldwork' },
    variables: { surveyId, expandedCategories: EXPANDED_AUDIENCE_CATEGORIES }
  }
}
