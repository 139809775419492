import {
  Accordion,
  Checkbox,
  Grid,
  InfoIcon,
  LinkButton,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  EnabledState,
  FieldworkAudienceCategory,
  StandardCriterion
} from '../../../data/model/audience'
import { CriterionValidationErrors } from '../../../data/model/fieldwork'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import { useSurveyId } from '../../../hooks/useSurveyId'
import useStyles from './StandardAudienceList.styles'

interface Props {
  standardAudienceCategories: FieldworkAudienceCategory[] | undefined
  validationErrors?: CriterionValidationErrors[]
  onAudienceChange: (criterion: StandardCriterion) => void
  showAdvancedCriteria: boolean
  onShowAdvancedCriteria: () => void
}

const StandardAudienceList: React.FC<Props> = (props: Props) => {
  const {
    standardAudienceCategories,
    validationErrors = [],
    onAudienceChange,
    showAdvancedCriteria,
    onShowAdvancedCriteria
  }: Props = props
  const surveyId = useSurveyId()
  const { classes } = useStyles()
  const [standardCategories, setStandardCategories] = useState<
    FieldworkAudienceCategory[]
  >([])
  const { surveyAudienceInvalidQuestions } = useAudienceValidation({
    validationErrors
  })

  useEffect(() => {
    if (standardAudienceCategories) {
      setStandardCategories(standardAudienceCategories)
    }
  }, [standardAudienceCategories])

  return (
    <Grid className={classes.container}>
      <Grid
        container
        className={classes.standardAudienceTitle}
        direction="row"
        alignItems="center"
      >
        <Text
          weight={TextWeight.Bold}
          highlight={TextHighlight.Standard}
          size={TextSize.m}
        >
          Targeting
        </Text>
        <InfoIcon text="Targeting ensures that your survey is sent to the right sample only. Add a criteria. Then use the the tick boxes to pick which groups to target or exclude. Toggle on quotas if you need a representative sample." />
      </Grid>
      {standardCategories.map((category: FieldworkAudienceCategory) => {
        return (
          <Accordion
            className={classes.accordion}
            key={category.category.code}
            title={category.category.name}
            defaultExpanded={false}
          >
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {category.standardCriteria?.map(
              (audienceCriterion: StandardCriterion) => {
                const hasErrors = !!surveyAudienceInvalidQuestions.find(
                  (id) => id === audienceCriterion.criterion.code
                )
                return (
                  <Checkbox
                    key={audienceCriterion.criterion.code}
                    isGrouped
                    hasError={hasErrors}
                    onChange={() => {
                      onAudienceChange(audienceCriterion)

                      LogAmplitudeEvent(
                        audienceCriterion.state === EnabledState.Disabled
                          ? EventType.SelectedTargetingVariable
                          : EventType.DeselectedTargetingVariable,
                        {
                          surveyId,
                          audienceCriterion: audienceCriterion.criterion.name
                        }
                      )
                    }}
                    checked={audienceCriterion.state === EnabledState.Enabled}
                    label={audienceCriterion.criterion.name}
                  />
                )
              }
            )}
          </Accordion>
        )
      })}
      <Grid item className={classes.advancedCriteriaButton}>
        <LinkButton
          highlight={TextHighlight.Emphasis}
          size={TextSize.ms}
          onClick={() => {
            onShowAdvancedCriteria()

            if (!showAdvancedCriteria)
              LogAmplitudeEvent(EventType.ClickedShowAdvancedCriteria, {
                surveyId
              })
          }}
        >
          {showAdvancedCriteria
            ? 'Hide advanced criteria'
            : 'Show advanced criteria'}
        </LinkButton>
      </Grid>
    </Grid>
  )
}

export default StandardAudienceList
