import gql from 'graphql-tag'
import { DraftMatrixRow } from '../../../gql-gen/questionnaire/graphql'

export interface AddMatrixQuestionRowData {
  addMatrixQuestionRow: DraftMatrixRow
}

const ADD_MATRIX_QUESTION_ROW = gql`
  mutation addMatrixQuestionRow(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $position: Int!
    $text: String!
  ) {
    addMatrixQuestionRow(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      position: $position
      text: $text
    ) {
      ...DraftMatrixRow
    }
  }
`

export default ADD_MATRIX_QUESTION_ROW
