import { RadioGroup } from '@focaldata/cin-ui-components'
import { ChangeEvent } from 'react'
import { MemberSettingCode, SettingValue } from '../../../data/model/audience'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  useAudienceQuestionCardMutations,
  useSurveyLengthData
} from './AudienceQuestionCard.hooks'

type AgeRangeType = 'standard' | 'custom'

export const AgeRangeSwitch = ({
  audienceCriterionCode,
  customizedCriterionOptionsEnabled
}: {
  audienceCriterionCode: string
  customizedCriterionOptionsEnabled: boolean
}) => {
  const ageRangeType = customizedCriterionOptionsEnabled ? 'custom' : 'standard'

  const { loi } = useSurveyLengthData()
  const { setSettingValue } = useAudienceQuestionCardMutations(loi)
  const surveyId = useSurveyId()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as AgeRangeType
    setSettingValue({
      variables: {
        surveyId,
        audienceCriterionCode,
        setting: {
          memberSettingCode: MemberSettingCode.CustomizedCriterionOptions,
          settingValue:
            value === 'custom' ? SettingValue.Enabled : SettingValue.Disabled
        }
      }
    })
  }

  return (
    <RadioGroup
      row
      value={ageRangeType}
      onChange={handleChange}
      caption="Select range"
      items={[
        { value: 'standard', label: 'Standard' },
        { value: 'custom', label: 'Custom' }
      ]}
    />
  )
}
