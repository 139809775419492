import { StoreObject, useMutation } from '@apollo/client'
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { produce } from 'immer'
import { useCallback } from 'react'
import { questionBeingEditedNumber } from '../../apollo/apolloClient'
import {
  RemoveQuestionnaireMatrixSettingValueMutationVariables,
  RemoveQuestionnaireSettingValueMutationVariables,
  RemoveTextCardSettingValueMutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import {
  REMOVE_QUESTIONNAIRE_MATRIX_SETTING_VALUE,
  RemoveQuestionnaireMatrixSettingValueData
} from '../../data/gql/questionnaire/mutations/removeQuestionnaireMatrixSettingValue'
import {
  REMOVE_QUESTIONNAIRE_SETTING_VALUE,
  RemoveQuestionnaireSettingValueData
} from '../../data/gql/questionnaire/mutations/removeQuestionnaireSettingsValue'
import {
  REMOVE_TEXT_CARD_SETTING_VALUE,
  RemoveTextCardSettingValueData
} from '../../data/gql/questionnaire/mutations/removeTextCardSettingValue'
import { LoggerErrorType } from '../../data/logger'
import {
  DraftQuestionnaireEntry,
  EntrySettingValue,
  EntryType,
  QuestionSettingCode
} from '../../data/model/questionnaire'
import { captureApolloError } from '../../utils/HelperFunctions'
import useDraftQuestionnaireIdCache from '../localState/useDraftQuestionnaireIdCache'

interface UseRemoveQuestionnaireValue {
  (
    questionSettingCode: QuestionSettingCode,
    fetchPolicy?: MutationFetchPolicy
  ): void
}
interface QuestionnaireMutationContext {
  context: { clientName: 'questionnaire' }
  onCompleted: () => void
}
const useRemoveQuestionnaireValue: (
  entry: DraftQuestionnaireEntry
) => UseRemoveQuestionnaireValue = (entry) => {
  const questionnaireId = useDraftQuestionnaireIdCache()

  const questionnaireMutationContext: QuestionnaireMutationContext = {
    context: { clientName: 'questionnaire' },
    onCompleted: () => {
      questionBeingEditedNumber(entry.number)
    }
  }

  const removeSettingByCode = (
    settingCode: QuestionSettingCode | undefined,
    settingValues: EntrySettingValue[]
  ) => {
    const settingToRemoveIdx = settingValues.findIndex(
      (setting) => setting.code === settingCode
    )
    if (settingToRemoveIdx === -1) {
      return settingValues
    }

    return produce(settingValues, (draft) => {
      draft.splice(settingToRemoveIdx, 1)
    })
  }

  const [removeQuestionnaireSettingValue] = useMutation<
    RemoveQuestionnaireSettingValueData,
    RemoveQuestionnaireSettingValueMutationVariables
  >(REMOVE_QUESTIONNAIRE_SETTING_VALUE, {
    ...questionnaireMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeQuestionnaireSettingValue',
        error
      )
    },
    update: (cache, mutationResult) => {
      const cacheId = cache.identify(entry.entryItem as unknown as StoreObject)
      cache.modify({
        id: cacheId,
        fields: {
          settingValues(settingValues) {
            return removeSettingByCode(
              mutationResult.data?.removeQuestionnaireSettingValue.code,
              settingValues as EntrySettingValue[]
            )
          }
        }
      })
    }
  })

  const [removeQuestionnaireSettingValueNoUpdate] = useMutation<
    RemoveQuestionnaireSettingValueData,
    RemoveQuestionnaireSettingValueMutationVariables
  >(REMOVE_QUESTIONNAIRE_SETTING_VALUE, {
    ...questionnaireMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeQuestionnaireSettingValueNoUpdate',
        error
      )
    }
  })

  const [removeQuestionnaireMatrixSettingValue] = useMutation<
    RemoveQuestionnaireMatrixSettingValueData,
    RemoveQuestionnaireMatrixSettingValueMutationVariables
  >(REMOVE_QUESTIONNAIRE_MATRIX_SETTING_VALUE, {
    ...questionnaireMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeQuestionnaireMatrixSettingValue',
        error
      )
    },
    update: (cache, mutationResult) => {
      const cacheId = cache.identify(entry.entryItem as unknown as StoreObject)
      cache.modify({
        id: cacheId,
        fields: {
          settingValues(settingValues) {
            return removeSettingByCode(
              mutationResult.data?.removeQuestionnaireMatrixSettingValue.code,
              settingValues as EntrySettingValue[]
            )
          }
        }
      })
    }
  })

  const [removeTextCardSettingValue] = useMutation<
    RemoveTextCardSettingValueData,
    RemoveTextCardSettingValueMutationVariables
  >(REMOVE_TEXT_CARD_SETTING_VALUE, {
    ...questionnaireMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeTextCardSettingValue',
        error
      )
    },
    update: (cache, mutationResult) => {
      const cacheId = cache.identify(entry.entryItem as unknown as StoreObject)
      cache.modify({
        id: cacheId,
        fields: {
          settingValues(settingValues) {
            return removeSettingByCode(
              mutationResult.data?.removeTextCardSettingValue.code,
              settingValues as EntrySettingValue[]
            )
          }
        }
      })
    }
  })

  const removeQuestionnaireSetting = useCallback(
    (
      questionSettingCode: QuestionSettingCode,
      fetchPolicy: MutationFetchPolicy = 'network-only'
    ): void => {
      const variables = {
        questionnaireId,
        questionSettingCode
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (entry?.entryType === EntryType.QuestionEntryType) {
        const removeSettingVariables = {
          ...variables,
          questionLk: entry.entryItem.questionLk
        }
        if (fetchPolicy === 'no-cache') {
          removeQuestionnaireSettingValueNoUpdate({
            variables: removeSettingVariables,
            fetchPolicy
          })
        } else {
          removeQuestionnaireSettingValue({
            variables: removeSettingVariables
          })
        }
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (entry?.entryType === EntryType.MatrixEntryType) {
        removeQuestionnaireMatrixSettingValue({
          variables: {
            ...variables,
            matrixTitleLk: entry.entryItem.matrixTitleLk
          }
        })
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (entry?.entryType === EntryType.TextCardEntryType) {
        removeTextCardSettingValue({
          variables: {
            ...variables,
            textCardLk: entry.entryItem.textCardLk
          }
        })
      }
    },
    [
      entry.entryItem,
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      entry?.entryType,
      questionnaireId,
      removeQuestionnaireMatrixSettingValue,
      removeQuestionnaireSettingValue,
      removeQuestionnaireSettingValueNoUpdate,
      removeTextCardSettingValue
    ]
  )

  return removeQuestionnaireSetting
}
export default useRemoveQuestionnaireValue
