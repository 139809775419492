import { useMutation } from '@apollo/client'
import { ScrollTo } from '@focaldata/cin-ui-components'
import React, { memo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { DuplicateQuestionMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import {
  DUPLICATE_QUESTION,
  DuplicateQuestionData
} from '../../../data/gql/questionnaire/mutations/duplicateQuestion'
import {
  DraftQuestionItem,
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import FreeTextLayout from '../../../layouts/FreeTextLayout'
import { duplicateQuestionTransactionDatadog } from '../../../tracking/perf/transactions'
import {
  WithEntry,
  getFreeTextMaxCharacters,
  getFreeTextMinCharacters
} from '../../../utils/questionnaireUtils'
import EntryMediaUploader from '../BasicQuestion/MediaUploader/MediaUploader'
import { CardTitleContainer } from '../CardTitle'
import {
  selectSettingsByQuestionId,
  setQuestionSetting
} from '../Questionnaire.slice'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  SCROLL_ID_PREFIX
} from '../constants'
import FreeTextQuestionControl from './FreeTextQuestion.control'
import {
  ContentStructureSettingValues,
  FreeTextMinSettingValue,
  FreeTextSettingValue,
  PostalCodeSettingValue
} from './FreeTextQuestion.model'

interface Props extends WithEntry {
  shouldTitleInputFocus: boolean
}

const FreeTextQuestion: React.FC<Props> = (props: Props) => {
  const { entry, shouldTitleInputFocus } = props
  const { validateBasicQuestionText, validateFreeTextResponseRange } =
    useQuestionnaireValidation()
  const dispatch = useAppDispatch()
  const handleSettingChange = useSetQuestionnaireSetting(entry)

  const {
    draftQuestionnaire,
    draftQuestionnaireEntries,
    refetchQuestionnaire
  } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const [isUploadingMedia, setIsUploadingMedia] = useState(false)

  const questionnaireId = draftQuestionnaire?.questionnaireId || ''
  const entryItem = entry.entryItem as DraftQuestionItem
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectSettingsByQuestionId(state, entryItem.questionLk)
    ) || []

  const characterLimit = getFreeTextMaxCharacters(
    settingValues
  ) as FreeTextSettingValue
  const characterMin = getFreeTextMinCharacters(
    settingValues
  ) as FreeTextMinSettingValue

  const changeSettingValue = async (
    code: QuestionSettingCode | undefined,
    value: SettingValue
  ) => {
    if (!code) {
      return
    }
    dispatch(
      setQuestionSetting({
        questionLk: entryItem.questionLk,
        code,
        value
      })
    )
    await handleSettingChange(code, value, 'no-cache')
  }

  const handleChangeCharacterLimit: (
    settingValue: FreeTextSettingValue
  ) => Promise<void> = async (settingValue) => {
    await changeSettingValue(QuestionSettingCode.CharacterLimit, settingValue)
  }

  const handleChangeTextInputType: (
    newType: ContentStructureSettingValues
  ) => void = (newType) => {
    changeSettingValue(QuestionSettingCode.ContentStructure, newType)
  }

  const handleChangePostalCodeValidation: (
    settingValue: PostalCodeSettingValue
  ) => void = (settingValue) => {
    changeSettingValue(QuestionSettingCode.ContentStructure, settingValue)
  }

  const handleChangeCharacterMin: (
    settingValue: FreeTextMinSettingValue
  ) => Promise<void> = async (settingValue) => {
    await changeSettingValue(QuestionSettingCode.CharacterMinimum, settingValue)
  }

  const scrollToDuplicatedFreeTextQuestionCard = (cardNumber: string) => {
    setTimeout(() => {
      if (cardNumber) {
        ScrollTo(
          `${SCROLL_ID_PREFIX}${cardNumber}`,
          CONTAINER_ID_QUESTIONNAIRE_CONTENT
        )
      }
    }, 400)
  }

  const [duplicateQuestionMutation] = useMutation<
    DuplicateQuestionData,
    DuplicateQuestionMutationVariables
  >(DUPLICATE_QUESTION, {
    context: { clientName: 'questionnaire' }
  })

  const handleDuplicateQuestion = (
    questionnaireId: string,
    questionLk: string,
    position: number
  ) => {
    duplicateQuestionTransactionDatadog.start()
    return duplicateQuestionMutation({
      variables: {
        questionnaireId,
        questionLk,
        position
      },
      onCompleted: async ({ duplicateQuestion }) => {
        await refetchQuestionnaire()
        scrollToDuplicatedFreeTextQuestionCard(
          duplicateQuestion.number.toString()
        )
        questionBeingEditedNumber(duplicateQuestion.number)
      }
    })
  }

  const textInputType: ContentStructureSettingValues | undefined =
    settingValues.find(
      (settingValue) =>
        settingValue.code === QuestionSettingCode.ContentStructure
    )?.value as ContentStructureSettingValues

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const emptyFreeTextQuestionTitle = entryItem.question === null
  const hasMedia = !!entryItem.questionMedia || isUploadingMedia

  // TODO: A Free Text Card gets a validation error due to a backend problem
  // It shows the error: ["ResponseOptionCountLessThan2Error"]
  // This is a momentaneous fix to avoid the error to disable the "To confirmation" BottomNav button
  const isFreeTextQuestionTextEmpty = validateBasicQuestionText(
    entryItem.questionLk
  )

  return (
    <FreeTextLayout
      entryNumber={entry.number}
      questionCardHeader={
        <SurveyQuestionCardHeaderContainer
          entry={entry}
          titleContent={
            <CardTitleContainer
              ariaLabel="Freetext question header"
              helperText="Please add a question title"
              entryNumber={entry.number}
              questionLk={entryItem.questionLk}
              questionTypeCode={entryItem.questionTypeCode}
              shouldTitleInputFocus={shouldTitleInputFocus}
              hasError={isFreeTextQuestionTextEmpty}
              shouldHandlePasteManually={false}
            />
          }
          onClickDuplicateIcon={() => {
            return handleDuplicateQuestion(
              questionnaireId,
              entryItem.questionLk,
              entry.position + 1
            )
          }}
          isMultipleChoice={false}
          disabledDuplicate={emptyFreeTextQuestionTitle}
          hasError={isFreeTextQuestionTextEmpty}
          entries={flatDraftQuestionnaireEntries}
        />
      }
      hasMedia={hasMedia}
      mediaUploader={
        <EntryMediaUploader
          entry={entry}
          onMediaUploadStart={() => setIsUploadingMedia(true)}
          onMediaUploadEnd={() => setIsUploadingMedia(false)}
        />
      }
      freeTextInput={
        <FreeTextQuestionControl
          typeSetting={textInputType}
          onChangeType={handleChangeTextInputType}
          entry={entry}
          characterLimit={characterLimit}
          characterMin={characterMin}
          validationError={validateFreeTextResponseRange(entryItem.questionLk)}
          onChangeSettingValue={(settingValue: FreeTextSettingValue) => {
            handleChangeCharacterLimit(settingValue)
          }}
          onChangePostalCodeValidation={(
            settingValue: PostalCodeSettingValue
          ) => {
            handleChangePostalCodeValidation(settingValue)
          }}
          onChangeMinSettingValue={(settingValue: FreeTextMinSettingValue) => {
            handleChangeCharacterMin(settingValue)
          }}
        />
      }
    />
  )
}

export default memo(FreeTextQuestion)
