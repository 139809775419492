import { Dispatch, ReactNode, createContext, useMemo, useReducer } from 'react'
import {
  DraftQuestionnaireContext,
  useGetDraftQuestionnaireValue
} from '../../hooks/questionnaire/useGetDraftQuestionnaire'

const firstFiveEntriesToRender = [0, 1, 2, 3, 4]

interface QuestionnaireState {
  isPreviewPanelOpened: boolean
  selectedCards: Set<number>
  isBulkDeleteDialogOpened: boolean
  openedAddMediaDialogNumber?: number
  currentPipingTargetNumber?: number
  isQuestionsDialogPipingOpened: boolean
  sidebarSelectedEntryNumber?: number
  isDeleteSectionDialogOpened: boolean
  sectionEntryNumberToBeDeleted?: number
  entriesToRender: number[]
  isQuestionTimerDialogOpened: boolean
  currentTimerEntryNumber?: number
  isRandomiseSectionsDialogOpened: boolean
  isImportDialogOpened: boolean
  matrixScreeningDialog:
    | {
        open: false
        matrixTitleLk?: never
      }
    | {
        open: true
        matrixTitleLk: string
      }
}

const initialState: QuestionnaireState = {
  isPreviewPanelOpened: false,
  selectedCards: new Set(),
  isBulkDeleteDialogOpened: false,
  openedAddMediaDialogNumber: undefined,
  currentPipingTargetNumber: undefined,
  isQuestionsDialogPipingOpened: false,
  sidebarSelectedEntryNumber: undefined,
  isDeleteSectionDialogOpened: false,
  sectionEntryNumberToBeDeleted: -1,
  entriesToRender: firstFiveEntriesToRender,
  isQuestionTimerDialogOpened: false,
  currentTimerEntryNumber: undefined,
  isRandomiseSectionsDialogOpened: false,
  isImportDialogOpened: false,
  matrixScreeningDialog: { open: false }
}

const SELECT_CARD = 'SELECT_CARD' as const
const UNSELECT_CARD = 'UNSELECT_CARD' as const
const UNSELECT_ALL_CARDS = 'UNSELECT_ALL_CARDS' as const
const SET_SELECTED_CARDS = 'SET_SELECTED_CARDS' as const

const OPEN_PREVIEW_PANEL = 'OPEN_PREVIEW_PANEL' as const
const CLOSE_PREVIEW_PANEL = 'CLOSE_PREVIEW_PANEL' as const

const OPEN_BULK_DELETE_DIALOG = 'OPEN_BULK_DELETE_DIALOG' as const
const CLOSE_BULK_DELETE_DIALOG = 'CLOSE_BULK_DELETE_DIALOG' as const

const OPEN_IMPORT_DIALOG = 'OPEN_IMPORT_DIALOG' as const
const CLOSE_IMPORT_DIALOG = 'CLOSE_IMPORT_DIALOG' as const

const OPEN_ADD_MEDIA_DIALOG = 'OPEN_ADD_MEDIA_DIALOG' as const
const CLOSE_ADD_MEDIA_DIALOG = 'CLOSE_ADD_MEDIA_DIALOG' as const

const OPEN_QUESTIONS_DIALOG_PIPING = 'OPEN_QUESTIONS_DIALOG_PIPING' as const
const CLOSE_QUESTIONS_DIALOG_PIPING = 'CLOSE_QUESTIONS_DIALOG_PIPING' as const

const SET_SIDEBAR_SELECTED_ENTRY_NUMBER = 'SET_SCROLL_TO_ELEMENT_INDEX' as const

const OPEN_DELETE_SECTION_DIALOG = 'OPEN_DELETE_SECTION_DIALOG' as const
const CLOSE_DELETE_SECTION_DIALOG = 'CLOSE_DELETE_SECTION_DIALOG' as const
const SET_SECTION_ENTRY_NUMBER_TO_BE_DELETED =
  'SET_SECTION_ENTRY_NUMBER_TO_BE_DELETED' as const

const OPEN_MATRIX_SCREENING_DIALOG = 'OPEN_MATRIX_SCREENING_DIALOG' as const
const CLOSE_MATRIX_SCREENING_DIALOG = 'CLOSE_MATRIX_SCREENING_DIALOG' as const

const SET_ENTRIES_TO_RENDER = 'SET_ENTRIES_TO_RENDER' as const
const RESET_ENTRIES_TO_RENDER = 'RESET_ENTRIES_TO_RENDER' as const

const OPEN_QUESTION_TIMER_DIALOG = 'OPEN_QUESTION_TIMER_DIALOG' as const
const CLOSE_QUESTION_TIMER_DIALOG = 'CLOSE_QUESTION_TIMER_DIALOG' as const

const OPEN_RANDOMISE_SECTIONS_DIALOG = 'OPEN_RANDOMISE_SECTIONS_DIALOG' as const
const CLOSE_RANDOMISE_SECTIONS_DIALOG =
  'CLOSE_RANDOMISE_SECTIONS_DIALOG' as const

type WithPayload<T> = T extends { payload: any } ? T : T & { payload?: never }

type QuestionnaireAction = WithPayload<
  | { type: typeof SELECT_CARD; payload: number }
  | { type: typeof UNSELECT_CARD; payload: number }
  | { type: typeof UNSELECT_ALL_CARDS }
  | {
      type: typeof SET_SELECTED_CARDS
      payload: QuestionnaireState['selectedCards']
    }
  | { type: typeof OPEN_PREVIEW_PANEL }
  | { type: typeof CLOSE_PREVIEW_PANEL }
  | { type: typeof OPEN_BULK_DELETE_DIALOG }
  | { type: typeof CLOSE_BULK_DELETE_DIALOG }
  | { type: typeof OPEN_IMPORT_DIALOG }
  | { type: typeof CLOSE_IMPORT_DIALOG }
  | { type: typeof OPEN_ADD_MEDIA_DIALOG; payload: number }
  | { type: typeof CLOSE_ADD_MEDIA_DIALOG }
  | { type: typeof OPEN_QUESTIONS_DIALOG_PIPING; payload: number }
  | { type: typeof CLOSE_QUESTIONS_DIALOG_PIPING }
  | {
      type: typeof SET_SIDEBAR_SELECTED_ENTRY_NUMBER
      payload: number | undefined
    }
  | { type: typeof OPEN_DELETE_SECTION_DIALOG }
  | { type: typeof CLOSE_DELETE_SECTION_DIALOG }
  | { type: typeof SET_SECTION_ENTRY_NUMBER_TO_BE_DELETED; payload: number }
  | { type: typeof SET_ENTRIES_TO_RENDER; payload: number[] }
  | { type: typeof RESET_ENTRIES_TO_RENDER }
  | { type: typeof OPEN_QUESTION_TIMER_DIALOG; payload: number }
  | { type: typeof CLOSE_QUESTION_TIMER_DIALOG }
  | { type: typeof OPEN_RANDOMISE_SECTIONS_DIALOG }
  | { type: typeof CLOSE_RANDOMISE_SECTIONS_DIALOG }
  | {
      type: typeof OPEN_MATRIX_SCREENING_DIALOG
      payload: { matrixTitleLk: string }
    }
  | { type: typeof CLOSE_MATRIX_SCREENING_DIALOG }
>

export const selectCard = (entryNumber: number): QuestionnaireAction => {
  return { type: SELECT_CARD, payload: entryNumber }
}
export const unselectCard = (entryNumber: number): QuestionnaireAction => {
  return { type: UNSELECT_CARD, payload: entryNumber }
}
export const unselectAllCards = (): QuestionnaireAction => {
  return { type: UNSELECT_ALL_CARDS }
}
export const setSelectedCards = (
  selectedCards: QuestionnaireState['selectedCards']
): QuestionnaireAction => {
  return { type: SET_SELECTED_CARDS, payload: selectedCards }
}
export const openBulkDeleteDialog = (): QuestionnaireAction => {
  return { type: OPEN_BULK_DELETE_DIALOG }
}
export const closeBulkDeleteDialog = (): QuestionnaireAction => {
  return { type: CLOSE_BULK_DELETE_DIALOG }
}

export const openImportDialog = (): QuestionnaireAction => {
  return { type: OPEN_IMPORT_DIALOG }
}
export const closeImportDialog = (): QuestionnaireAction => {
  return { type: CLOSE_IMPORT_DIALOG }
}

export const openPreviewPanel = (): QuestionnaireAction => {
  return { type: OPEN_PREVIEW_PANEL }
}
export const closePreviewPanel = (): QuestionnaireAction => {
  return { type: CLOSE_PREVIEW_PANEL }
}

export const openAddMediaDialog = (
  entryNumber: number
): QuestionnaireAction => {
  return {
    type: OPEN_ADD_MEDIA_DIALOG,
    payload: entryNumber
  }
}

export const closeAddMediaDialog = (): QuestionnaireAction => {
  return { type: CLOSE_ADD_MEDIA_DIALOG }
}

export const openQuestionsDialogPiping = (
  entryNumber: number
): QuestionnaireAction => {
  return {
    type: OPEN_QUESTIONS_DIALOG_PIPING,
    payload: entryNumber
  }
}

export const closeQuestionsDialogPiping = (): QuestionnaireAction => {
  return { type: CLOSE_QUESTIONS_DIALOG_PIPING }
}

export const setSidebarSelectedEntryNumber = (
  number?: number
): QuestionnaireAction => {
  return {
    type: SET_SIDEBAR_SELECTED_ENTRY_NUMBER,
    payload: number
  }
}

export const openDeleteSectionDialog = (): QuestionnaireAction => {
  return { type: OPEN_DELETE_SECTION_DIALOG }
}

export const closeDeleteSectionDialog = (): QuestionnaireAction => {
  return { type: CLOSE_DELETE_SECTION_DIALOG }
}

export const setSectionEntryNumberToBeDeleted = (
  entryNumber: number
): QuestionnaireAction => {
  return {
    type: SET_SECTION_ENTRY_NUMBER_TO_BE_DELETED,
    payload: entryNumber
  }
}

export const setEntriesToRender = (
  entriesToRender: number[]
): QuestionnaireAction => {
  return {
    type: SET_ENTRIES_TO_RENDER,
    payload: entriesToRender
  }
}

export const resetEntriesToRender = (): QuestionnaireAction => {
  return {
    type: RESET_ENTRIES_TO_RENDER
  }
}

export const openQuestionTimerDialog = (
  entryNumber: number
): QuestionnaireAction => {
  return {
    type: OPEN_QUESTION_TIMER_DIALOG,
    payload: entryNumber
  }
}
export const closeQuestionTimerDialog = (): QuestionnaireAction => {
  return { type: CLOSE_QUESTION_TIMER_DIALOG }
}

export const openRandomiseSectionsDialog = (): QuestionnaireAction => {
  return { type: OPEN_RANDOMISE_SECTIONS_DIALOG }
}

export const closeRandomiseSectionsDialog = (): QuestionnaireAction => {
  return { type: CLOSE_RANDOMISE_SECTIONS_DIALOG }
}

export const openMatrixScreeningDialog = (
  matrixTitleLk: string
): QuestionnaireAction => {
  return {
    type: OPEN_MATRIX_SCREENING_DIALOG,
    payload: { matrixTitleLk }
  }
}

export const closeMatrixScreeningDialog = (): QuestionnaireAction => ({
  type: CLOSE_MATRIX_SCREENING_DIALOG
})

const questionnaireReducer = (
  state: QuestionnaireState,
  { type, payload }: QuestionnaireAction
): QuestionnaireState => {
  switch (type) {
    case OPEN_PREVIEW_PANEL: {
      return {
        ...state,
        isPreviewPanelOpened: true
      }
    }
    case CLOSE_PREVIEW_PANEL: {
      return {
        ...state,
        isPreviewPanelOpened: false
      }
    }
    case SELECT_CARD: {
      const selectedCardsCopy = new Set(Array.from(state.selectedCards))
      selectedCardsCopy.add(payload)
      return {
        ...state,
        selectedCards: selectedCardsCopy
      }
    }
    case UNSELECT_CARD: {
      const selectedCardsCopy = new Set(Array.from(state.selectedCards))
      selectedCardsCopy.delete(payload)
      return {
        ...state,
        selectedCards: selectedCardsCopy
      }
    }
    case UNSELECT_ALL_CARDS: {
      return {
        ...state,
        selectedCards: new Set()
      }
    }
    case SET_SELECTED_CARDS: {
      return {
        ...state,
        selectedCards: payload
      }
    }
    case OPEN_BULK_DELETE_DIALOG: {
      return {
        ...state,
        isBulkDeleteDialogOpened: true
      }
    }
    case CLOSE_BULK_DELETE_DIALOG: {
      return {
        ...state,
        isBulkDeleteDialogOpened: false
      }
    }
    case OPEN_IMPORT_DIALOG: {
      return {
        ...state,
        isImportDialogOpened: true
      }
    }
    case CLOSE_IMPORT_DIALOG: {
      return {
        ...state,
        isImportDialogOpened: false
      }
    }
    case OPEN_ADD_MEDIA_DIALOG: {
      return {
        ...state,
        openedAddMediaDialogNumber: payload
      }
    }
    case CLOSE_ADD_MEDIA_DIALOG: {
      return {
        ...state,
        openedAddMediaDialogNumber: undefined
      }
    }
    case OPEN_QUESTIONS_DIALOG_PIPING: {
      return {
        ...state,
        isQuestionsDialogPipingOpened: true,
        currentPipingTargetNumber: payload
      }
    }
    case CLOSE_QUESTIONS_DIALOG_PIPING: {
      return {
        ...state,
        isQuestionsDialogPipingOpened: false,
        currentPipingTargetNumber: undefined
      }
    }
    case SET_SIDEBAR_SELECTED_ENTRY_NUMBER: {
      return {
        ...state,
        sidebarSelectedEntryNumber: payload
      }
    }
    case OPEN_DELETE_SECTION_DIALOG: {
      return {
        ...state,
        isDeleteSectionDialogOpened: true
      }
    }
    case CLOSE_DELETE_SECTION_DIALOG: {
      return {
        ...state,
        isDeleteSectionDialogOpened: false
      }
    }
    case SET_SECTION_ENTRY_NUMBER_TO_BE_DELETED: {
      return {
        ...state,
        sectionEntryNumberToBeDeleted: payload
      }
    }
    case SET_ENTRIES_TO_RENDER: {
      return {
        ...state,
        entriesToRender: payload
      }
    }
    case RESET_ENTRIES_TO_RENDER: {
      return {
        ...state,
        entriesToRender: initialState.entriesToRender
      }
    }
    case OPEN_QUESTION_TIMER_DIALOG: {
      return {
        ...state,
        isQuestionTimerDialogOpened: true,
        currentTimerEntryNumber: payload
      }
    }
    case CLOSE_QUESTION_TIMER_DIALOG: {
      return {
        ...state,
        isQuestionTimerDialogOpened: false,
        currentTimerEntryNumber: undefined
      }
    }
    case OPEN_RANDOMISE_SECTIONS_DIALOG: {
      return {
        ...state,
        isRandomiseSectionsDialogOpened: true
      }
    }
    case CLOSE_RANDOMISE_SECTIONS_DIALOG: {
      return {
        ...state,
        isRandomiseSectionsDialogOpened: false
      }
    }
    case OPEN_MATRIX_SCREENING_DIALOG: {
      return {
        ...state,
        matrixScreeningDialog: {
          open: true,
          matrixTitleLk: payload.matrixTitleLk
        }
      }
    }
    case CLOSE_MATRIX_SCREENING_DIALOG: {
      return {
        ...state,
        matrixScreeningDialog: { open: false }
      }
    }
    default:
      return state
  }
}

const QuestionnaireContext = createContext<{
  questionnaireState: QuestionnaireState
  dispatch: Dispatch<QuestionnaireAction>
}>({
  questionnaireState: initialState,
  dispatch: () => null
})

export const QuestionnaireContextProvider = ({
  children = null
}: {
  children?: ReactNode
}) => {
  const [questionnaireState, dispatch] = useReducer(
    questionnaireReducer,
    initialState
  )

  const questionnaireContext = useMemo(
    () => ({ questionnaireState, dispatch }),
    [questionnaireState]
  )

  const draftQuestionnaire = useGetDraftQuestionnaireValue()

  return (
    <QuestionnaireContext.Provider value={questionnaireContext}>
      <DraftQuestionnaireContext.Provider value={draftQuestionnaire}>
        {children}
      </DraftQuestionnaireContext.Provider>
    </QuestionnaireContext.Provider>
  )
}

export default QuestionnaireContext
