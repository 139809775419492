import { useSnackbar } from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogAmplitudePublicEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { CreatePassword as CreatePasswordControl } from '../../../components/Auth'
import { DynamicError } from '../../../components/DynamicError'
import {
  IsResetPasswordValid,
  ResetPassword
} from '../../../data/rest/accountUser'
import UserSession from '../../../utils/UserSession'
import { AccessToken, setAccountId } from '../../../utils/authUtils'
import { useURLSearchParams } from '../paramHelper'

const ResetPasswordContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [resetPasswordError, setResetPasswordError] = useState<
    Error | undefined
  >(undefined)
  const query = useURLSearchParams()
  const resetId = query.get('rpid')
  const [displayError, setDisplayError] = useState<boolean | undefined>()

  const resetPasswordErrorMessage =
    'Whoops, there was an issue completing the signup process. Please refresh and try again.'

  const passwordHistoryErrorMessage =
    "It looks like you've used this password before. Please try something different."

  useEffect(() => {
    async function isResetPasswordValid() {
      if (resetId) {
        try {
          await IsResetPasswordValid(resetId)
        } catch (ex) {
          setDisplayError(true)
        }
      }
    }
    isResetPasswordValid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetId])

  const handleResetPassword: (
    password: string
  ) => Promise<boolean | Error> = async (password) => {
    try {
      if (resetId) {
        const response = await ResetPassword(resetId, password)
        UserSession.setEmail(response.data.emailAddress)
        AccessToken.set(response.data.accessToken)
        const gotAccountId: boolean = await setAccountId(
          response.data.emailAddress,
          response.data.accessToken
        )
        LogAmplitudePublicEvent(EventType.ResetPassword, {
          email: response.data.emailAddress
        })
        navigate('/projects')
        return gotAccountId
      }
      return false
    } catch (err: any) {
      if (err.response.data.message.includes('PasswordHistoryError')) {
        enqueueSnackbar(passwordHistoryErrorMessage, {
          variant: 'error'
        })
      }
      return new Error(err)
    }
  }

  useEffect(() => {
    if (resetPasswordError) {
      setTimeout(() => {
        setResetPasswordError(undefined)
      }, 3000)
    }
  }, [resetPasswordError])

  if (resetPasswordError) {
    enqueueSnackbar(resetPasswordErrorMessage, {
      variant: 'error'
    })
  }

  const handleErrorClose = () => {
    setDisplayError(false)
  }

  return (
    <>
      {displayError && (
        <DynamicError
          title=""
          body="Sorry, this link is either invalid or expired."
          onClose={handleErrorClose}
        />
      )}
      <CreatePasswordControl
        disabled={displayError}
        onCreatePassword={handleResetPassword}
      />
    </>
  )
}

export default ResetPasswordContainer
