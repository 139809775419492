import {
  List,
  ListItemLoopingResponseOption
} from '@focaldata/cin-ui-components'
import { LoopingResponseOption } from '../Looping.model'

interface Props {
  loopingResponseOptions: LoopingResponseOption[]
  onTogglePriority: (id: string) => void
}

const LoopingResponseOptionsComponent = ({
  loopingResponseOptions,
  onTogglePriority
}: Props) => {
  return (
    <List>
      {loopingResponseOptions.map(
        ({ responseOptionLk, responseOption, priorityEnabled }) => (
          <ListItemLoopingResponseOption
            key={responseOptionLk}
            value={responseOption.value}
            priorityTooltip={
              priorityEnabled ? 'Unset priority' : 'Set priority'
            }
            priorityEnabled={priorityEnabled}
            onTogglePriority={() => onTogglePriority(responseOptionLk)}
          />
        )
      )}
    </List>
  )
}

export default LoopingResponseOptionsComponent
