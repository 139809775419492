import { useMutation } from '@apollo/client'
import { memo, useContext } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { DetachQuestionnaireEntryMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import {
  DETACH_QUESTIONNAIRE_ENTRY,
  DetachQuestionnaireEntryData
} from '../../../data/gql/questionnaire/mutations/detatchQuestionnaireEntry'
import { draftQuestionnaireRefetchQuery } from '../../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../../data/logger'
import { EntryType } from '../../../data/model/questionnaire'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import useChildFirstRender from '../../../hooks/useChildFirstRender'
import { useDisplayLogicDetector } from '../../../hooks/useDisplayLogicDetector.hooks'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { deleteQuestionTransactionDatadog } from '../../../tracking/perf/transactions'
import { captureApolloError } from '../../../utils/HelperFunctions'
import QuestionnaireContext, { unselectCard } from '../Questionnaire.context'
import DeleteQuestionButton from './DeleteQuestionButton.control'

interface Props {
  entryType: EntryType
  entryNumber: number
  entryId: string
}

const DeleteQuestionButtonContainer = ({
  entryType,
  entryNumber,
  entryId
}: Props) => {
  const questionnaireId = useDraftQuestionnaireIdCache()
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const getQPrefixForQsWithDisplayLogicBasedOnQuestion =
    useDisplayLogicDetector()

  const { dispatch } = useContext(QuestionnaireContext)

  const [detachQuestionnaireEntry] = useMutation<
    DetachQuestionnaireEntryData,
    DetachQuestionnaireEntryMutationVariables
  >(DETACH_QUESTIONNAIRE_ENTRY, {
    context: { clientName: 'questionnaire' }
  })

  const handleDeleteQuestionCard = () => {
    deleteQuestionTransactionDatadog.start()
    detachQuestionnaireEntry({
      optimisticResponse: {
        detachQuestionnaireEntry: {
          number: entryNumber,
          questionnaireId,
          detachedDate: new Date().toDateString(),
          __typename: 'DraftQuestionnaireEntry'
        }
      },
      refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
      variables: {
        questionnaireId,
        number: entryNumber
      },
      onCompleted: () => {
        questionBeingEditedNumber(entryNumber)
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloQuery,
          'detachQuestionnaireEntry',
          error
        )
      }
    })
  }

  let dialogTitle = 'Delete this card?'
  let deleteDialogText =
    'Please confirm you want to delete this card. This will permanently remove the card and any routing from it.'

  if (entryType === EntryType.ForkEntryType) {
    dialogTitle = 'Delete this fork?'
    deleteDialogText =
      'Please confirm you want to delete this fork and associated groups. This will permanently remove the fork and the groups plus any display logic connected to them.'
  }

  const {
    setInnerState: setDeleteDialogOpen,
    setOuterState: deleteQuestion,
    innerState: deleteDialogOpen
  } = useChildFirstRender({
    setOuterState: handleDeleteQuestionCard,
    initialState: false
  })

  const handleClickDeleteIcon = () => {
    setDeleteDialogOpen(true)
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }
  const handleClickDeleteQuestion = () => {
    setDeleteDialogOpen(false)
    deleteQuestion()
    dispatch(unselectCard(entryNumber))

    LogAmplitudeEvent(EventType.DeletedQuestion, {
      surveyId,
      questionnaireId
    })
  }

  const questionsPrefix = entryId
    ? getQPrefixForQsWithDisplayLogicBasedOnQuestion(entryId)
    : []

  if (questionsPrefix.length > 0) {
    deleteDialogText = `Deleting this question will also delete the display logic related to this question at ${questionsPrefix.join(
      ', '
    )}.`
  }

  return (
    <DeleteQuestionButton
      dialogTitle={dialogTitle}
      deleteDialogText={deleteDialogText}
      isDialogOpen={deleteDialogOpen}
      openDialog={handleClickDeleteIcon}
      closeDialog={handleCloseDeleteDialog}
      deleteQuestion={handleClickDeleteQuestion}
    />
  )
}

export default memo(DeleteQuestionButtonContainer)
