import {
  Grid,
  InfoIcon,
  InfoIconColor,
  Input,
  InputFontSize,
  InputType,
  InputVariant,
  InputWeight,
  Switch,
  Text,
  TextSize,
  TextWeight,
  TooltipPlacement
} from '@focaldata/cin-ui-components'
import { useStyles } from '.'

interface Props {
  isToggledOn: boolean
  inputPlaceholder?: number
  softLaunchSamplesNeeded?: number
  onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
  onInputBlur?: (value: string) => void
  hasInputError?: boolean
  inputErrorMessage?: string
  infoText?: string
}

const ConfirmationSoftLaunchControl: React.FC<Props> = (props: Props) => {
  const {
    isToggledOn,
    inputPlaceholder,
    softLaunchSamplesNeeded,
    onToggle,
    onInputBlur,
    hasInputError,
    inputErrorMessage,
    infoText = 'Launch your project initially with a fraction of the sample. Check for any issues. Then launch fully and see all data together in one place.'
  }: Props = props
  const { classes } = useStyles()

  const handleToggle: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event
  ) => {
    onToggle(event)
  }

  const handleInputBlur: (value: string) => void = (value) => {
    if (onInputBlur) onInputBlur(value)
  }

  const inputErrorClassName = hasInputError ? classes.inputErrorClassName : ''

  return (
    <>
      <Grid container item direction="row" justifyContent="space-between">
        <Grid direction="row" className={classes.infoIcon}>
          <Switch
            checked={isToggledOn}
            onChange={handleToggle}
            first
            last
            label="Soft launch"
          />
          <InfoIcon text={infoText} paddingLeft={false} />
        </Grid>
      </Grid>
      {isToggledOn ? (
        <Grid container item direction="column">
          <Grid item>
            <Text
              size={TextSize.m}
              weight={TextWeight.SemiBold}
              className={classes.labelSamplesNeeded}
            >
              Soft launch (completes)
            </Text>
          </Grid>
          <Grid container item>
            <Grid item className={inputErrorClassName}>
              <Input
                variant={InputVariant.Filled}
                placeholder={String(inputPlaceholder || 100)}
                inputWeight={InputWeight.Regular}
                inputFontSize={InputFontSize.m}
                onBlur={(event) => {
                  handleInputBlur(event.target.value)
                }}
                inputType={InputType.Number}
                defaultValue={softLaunchSamplesNeeded}
                className={classes.inputSamplesNeeded}
              />
            </Grid>
            {hasInputError && (
              <Grid item xs={1} justifyContent="flex-start" alignContent="left">
                <InfoIcon
                  className={classes.errorIcon}
                  iconColor={InfoIconColor.Error}
                  text={inputErrorMessage || ''}
                  tooltipPlacement={TooltipPlacement.Right}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}

export default ConfirmationSoftLaunchControl
