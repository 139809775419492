import { useMutation, useReactiveVar } from '@apollo/client'
import { ScrollTo } from '@focaldata/cin-ui-components'
import React, { memo, useState } from 'react'
import { useAppDispatch } from '../../../App.store'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import {
  DuplicateQuestionMutationVariables,
  SetQuestionScaleLabelMutationVariables,
  SetQuestionScaleMutationVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import {
  DUPLICATE_QUESTION,
  DuplicateQuestionData
} from '../../../data/gql/questionnaire/mutations/duplicateQuestion'
import { SET_QUESTION_SCALE } from '../../../data/gql/questionnaire/mutations/setQuestionScale'
import { SET_QUESTION_SCALE_LABEL } from '../../../data/gql/questionnaire/mutations/setQuestionScaleLabel'
import { LoggerErrorType } from '../../../data/logger'
import {
  DraftQuestionItem,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import SliderQuestionLayout from '../../../layouts/SliderQuestionLayout'
import { duplicateQuestionTransactionDatadog } from '../../../tracking/perf/transactions'
import {
  captureApolloError,
  getSettingBool
} from '../../../utils/HelperFunctions'
import { WithEntry, newEntryId } from '../../../utils/questionnaireUtils'
import { CardTitleContainer } from '../CardTitle'
import { sliderNumbersSet } from '../Questionnaire.slice'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  SCROLL_ID_PREFIX
} from '../constants'
import DefaultOptions from './DefaultOptions'
import SliderLabelsControl from './SliderLabels'
import SliderQuestionControl from './SliderQuestion.control'
import SliderSwitches from './Switches'

interface Props extends WithEntry {
  shouldTitleInputFocus: boolean
}

const SliderQuestion: React.FC<Props> = (props: Props) => {
  const { entry, shouldTitleInputFocus } = props
  const entryItem = entry.entryItem as DraftQuestionItem

  const dispatch = useAppDispatch()

  const [isSliderSwitchOn, setIsSliderSwitchOn] = useState<boolean>(
    getSettingBool(entry, QuestionSettingCode.SliderLabels)
  )

  const { validateBasicQuestionText, validateSliderNumbers } =
    useQuestionnaireValidation()

  const {
    draftQuestionnaire,
    draftQuestionnaireEntries,
    refetchQuestionnaire
  } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const questionnaireId = draftQuestionnaire?.questionnaireId || ''

  const [setQuestionScale] = useMutation<
    void,
    SetQuestionScaleMutationVariables
  >(SET_QUESTION_SCALE, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setQuestionScale',
        error
      )
    }
  })

  const [setQuestionScaleLabel] = useMutation<
    void,
    SetQuestionScaleLabelMutationVariables
  >(SET_QUESTION_SCALE_LABEL, {
    context: { clientName: 'questionnaire' },
    onCompleted: () => {
      refetchQuestionnaire()
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setQuestionScaleLabel',
        error
      )
    }
  })

  const scrollToDuplicatedSliderQuestionCard = (cardNumber: string) => {
    setTimeout(() => {
      if (cardNumber) {
        ScrollTo(
          `${SCROLL_ID_PREFIX}${cardNumber}`,
          CONTAINER_ID_QUESTIONNAIRE_CONTENT
        )
      }
    }, 400)
  }

  const [duplicateQuestionMutation] = useMutation<
    DuplicateQuestionData,
    DuplicateQuestionMutationVariables
  >(DUPLICATE_QUESTION, {
    context: { clientName: 'questionnaire' }
  })

  const handleDuplicateQuestion = (
    questionnaireId: string,
    questionLk: string,
    position: number
  ) => {
    duplicateQuestionTransactionDatadog.start()
    return duplicateQuestionMutation({
      variables: {
        questionnaireId,
        questionLk,
        position
      },
      onCompleted: async ({ duplicateQuestion }) => {
        await refetchQuestionnaire()
        scrollToDuplicatedSliderQuestionCard(
          duplicateQuestion.number.toString()
        )
        questionBeingEditedNumber(undefined)
      }
    })
  }

  const handleScaleNumberInputs = (
    minNumber: number,
    maxNumber: number,
    stepNumber: number
  ) => {
    setQuestionScale({
      variables: {
        questionnaireId,
        questionLk: entryItem.questionLk,
        min: minNumber,
        max: maxNumber,
        step: stepNumber,
        startingPosition: 0 // TODO: Change it once we allow different starting positions of the slider
      }
    })
    dispatch(
      sliderNumbersSet({
        questionLk: entryItem.questionLk,
        numbers: { min: minNumber, max: maxNumber, step: stepNumber }
      })
    )
  }

  const handleScaleLabels = (
    labelText: string | undefined,
    position: number
  ) => {
    if (labelText || labelText === '') {
      setQuestionScaleLabel({
        variables: {
          questionnaireId,
          questionLk: entryItem.questionLk,
          labelText,
          position
        }
      })
    }
  }

  const entryItemScaleQuestion = entryItem as DraftQuestionItem
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const emptyScaleQuestionTitle = entryItemScaleQuestion.question === null
  const isScaleQuestionTextEmpty = validateBasicQuestionText(
    entryItem.questionLk
  )
  const newlyCreatedEntryId = useReactiveVar(newEntryId)
  const isNewlyCreatedEntry =
    !!newlyCreatedEntryId && newlyCreatedEntryId === entryItem.questionLk

  const handleSwitchSlider = () => {
    setIsSliderSwitchOn(!isSliderSwitchOn)
  }

  return (
    <SliderQuestionLayout
      entryNumber={entry.number}
      questionCardHeader={
        <SurveyQuestionCardHeaderContainer
          entry={entry}
          hasError={!isNewlyCreatedEntry && isScaleQuestionTextEmpty}
          titleContent={
            <CardTitleContainer
              ariaLabel="Slider question header"
              entryNumber={entry.number}
              questionLk={entryItem.questionLk}
              questionTypeCode={entryItem.questionTypeCode}
              shouldTitleInputFocus={shouldTitleInputFocus}
              hasError={!isNewlyCreatedEntry && isScaleQuestionTextEmpty}
              shouldHandlePasteManually
            />
          }
          onClickDuplicateIcon={() => {
            return handleDuplicateQuestion(
              questionnaireId,
              entryItem.questionLk,
              entry.position + 1
            )
          }}
          disabledDuplicate={emptyScaleQuestionTitle}
          entries={flatDraftQuestionnaireEntries}
        />
      }
      switches={
        <SliderSwitches
          entry={entry}
          onShowSliderLabels={handleSwitchSlider}
          isSliderSwitchOn={isSliderSwitchOn}
        />
      }
      sliderInputs={
        <SliderQuestionControl
          entry={entry}
          onChangeNumbers={handleScaleNumberInputs}
          validationError={validateSliderNumbers(entryItem.questionLk)}
        />
      }
      sliderLabels={
        <SliderLabelsControl
          entry={entry}
          onChangeSliderLabels={handleScaleLabels}
        />
      }
      showLabels={isSliderSwitchOn}
      defaultOptions={<DefaultOptions entry={entry} />}
    />
  )
}

export default memo(SliderQuestion)
