import { gql } from '@apollo/client'
import { DraftCustomAudienceCriterionOption } from '../../../model/audience'
import DRAFT_CUSTOM_CRITERION_OPTION from '../fragments/draftCustomCriterionOption'

export interface UpdateCustomCriterionOptionData {
  updateCustomCriterionOption: DraftCustomAudienceCriterionOption
}

export const UPDATE_CUSTOM_CRITERION_OPTION = gql`
  mutation updateCustomCriterionOption(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $responseOptionValue: String!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    updateCustomCriterionOption(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      responseOption: {
        responseOptionValue: $responseOptionValue
        responseOptionType: RoString
      }
    ) {
      ...DraftCustomCriterionOption
    }
  }
  ${DRAFT_CUSTOM_CRITERION_OPTION}
`
