import { gql } from '@apollo/client/core'
import { FieldworkAudience } from '../../../model/audience'
import DRAFT_AUDIENCE_MEMBER from '../fragments/draftAudienceMember'

export interface FieldworkQuotaData {
  setStandardQuotas: FieldworkAudience
}

export const SET_STANDARD_QUOTAS = gql`
  mutation setStandardQuotas($surveyId: ID!, $audienceCriterionCode: String!) {
    setStandardQuotas(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`

export const REMOVE_STANDARD_QUOTAS = gql`
  mutation removeStandardQuotas(
    $surveyId: ID!
    $audienceCriterionCode: String!
  ) {
    removeStandardQuotas(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`

export interface FieldworkQuotaUpdatData {
  percent: number
  sinceDate: Date
  createdDate: Date
}

export const UPDATE_STANDARD_QUOTA = gql`
  mutation setStandardQuota(
    $surveyId: ID!
    $audienceCriterionCode: String!
    $audienceCriterionOptionCode: String!
    $percent: Float!
  ) {
    setStandardQuota(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
      audienceCriterionOptionCode: $audienceCriterionOptionCode
      percent: $percent
    ) {
      percent
      sinceDate
      createdDate
    }
  }
`
