import { gql } from '@apollo/client/core'
import { DraftCustomCriterion } from '../../../model/audience'
import DRAFT_CUSTOM_CRITERION from '../../questionnaire/fragments/draftCustomCriterion'

export interface SetCustomCriterionData {
  setCustomCriterion: DraftCustomCriterion
}

export const SET_CUSTOM_CRITERION = gql`
  mutation setCustomCriterion($surveyId: ID!, $questionLk: ID!) {
    setCustomCriterion(surveyId: $surveyId, questionLk: $questionLk) {
      ...DraftCustomCriterion
    }
  }
  ${DRAFT_CUSTOM_CRITERION}
`

export const REMOVE_CUSTOM_CRITERION = gql`
  mutation removeCustomCriterion($surveyId: ID!, $questionLk: ID!) {
    removeCustomCriterion(surveyId: $surveyId, questionLk: $questionLk) {
      ...DraftCustomCriterion
    }
  }
  ${DRAFT_CUSTOM_CRITERION}
`
