import { Paper } from '@focaldata/cin-ui-components'
import { ReactNode, memo, useContext } from 'react'
import QuestionnaireContext from '../Questionnaire.context'

interface Props {
  children: ReactNode
  entryNumber: number
}

const CardBackgroundContainer = ({ children, entryNumber }: Props) => {
  const { questionnaireState } = useContext(QuestionnaireContext)
  const isHighlighted = questionnaireState.selectedCards.has(entryNumber)

  return (
    <Paper style={{ backgroundColor: isHighlighted ? '#F7F7F7' : 'initial' }}>
      {children}
    </Paper>
  )
}

export default memo(CardBackgroundContainer)
