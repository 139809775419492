import { useReactiveVar } from '@apollo/client'
import { RoutingMenuItem } from '@focaldata/cin-ui-components'
import React, { memo, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { questionBeingEditedNumber } from '../../../../apollo/apolloClient'
import {
  DraftEntryResponseOption,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionKind
} from '../../../../data/model/questionnaire'
import { responseOptionLkNewlyAdded } from '../../../../hooks/useResetNewlyCreatedEntry'
import { chain } from '../../../../utils/lodashChain'
import {
  responseOptionAdded,
  responseOptionDeleted,
  responseOptionUpdated,
  responseOptionsReoredered,
  selectResponseOptionsByQuestion
} from '../../Questionnaire.slice'
import { useResponseOptionActions } from './ResponseOptions.hooks'
import ResponseOptionsList from './ResponseOptionsList.control'

interface Props {
  entries: DraftQuestionnaireEntry[] | undefined
  currentEntry: DraftQuestionnaireEntry
}

const getRoutingMenuItemText: (entry: DraftQuestionnaireEntry) => string = (
  entry
) => {
  switch (entry.entryType) {
    case EntryType.QuestionEntryType:
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return entry.entryItem.question?.text ?? ''
    case EntryType.MatrixEntryType:
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return entry.entryItem.matrixTitle?.title ?? ''
    case EntryType.TextCardEntryType:
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return entry.entryItem.textCard?.title ?? ''
    default:
      return ''
  }
}

const ResponseOptions: React.FC<Props> = (props: Props) => {
  const { currentEntry, entries: flatEntries } = props
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )
  const dispatch = useAppDispatch()

  // masking should be enabled if this is not the first entry
  const maskingEnabled =
    (flatEntries &&
      flatEntries.length > 0 &&
      currentEntry.number !== flatEntries[0].number) ||
    false

  const {
    addResponseOption,
    updateResponseOption,
    deleteResponseOption,
    setOrUnsetRoute,
    moveResponseOption,
    setOrUnsetExclusive,
    updateTextEntryState
  } = useResponseOptionActions(currentEntry)

  const filteredRoutingMenuItems: RoutingMenuItem[] = useMemo(
    () =>
      chain(flatEntries)
        .filter(
          ({ questionKind, position, entryType }) =>
            questionKind === QuestionKind.QuestionnaireKind &&
            position > currentEntry.position &&
            entryType !== EntryType.ForkEntryType
        )
        .map((menuEntry) => {
          const menuText = getRoutingMenuItemText(menuEntry)

          return {
            id: menuEntry.number.toString(),
            text: menuText,
            number: menuEntry.number,
            entryType: menuEntry.entryType,
            position: menuEntry.position,
            contextPosition: menuEntry.contextPosition
          }
        })
        .value(),
    [currentEntry.position, flatEntries]
  )

  const newlyAddedResponseOptionLk = useReactiveVar(responseOptionLkNewlyAdded)

  const entryItem = currentEntry.entryItem as DraftQuestionItem
  const responseOptions: DraftEntryResponseOption[] =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    responseOptionsByQuestion[entryItem.questionLk] ?? []

  const handleUpdateResponseOption = useCallback(
    (responseOptionValue: string, responseOptionLk: string) => {
      const entryItem = currentEntry.entryItem as DraftQuestionItem
      dispatch(
        responseOptionUpdated({
          questionLk: entryItem.questionLk,
          responseOptionLk,
          value: responseOptionValue
        })
      )
      updateResponseOption(responseOptionValue, responseOptionLk)
    },
    [currentEntry.entryItem, dispatch, updateResponseOption]
  )

  const handleReorderResponses = useCallback(
    (destinationIndex: number, sourceIndex: number) => {
      dispatch(
        responseOptionsReoredered({
          questionLk: (currentEntry.entryItem as DraftQuestionItem).questionLk,
          destinationIndex,
          sourceIndex
        })
      )
      moveResponseOption(destinationIndex, sourceIndex)
    },
    [currentEntry.entryItem, dispatch, moveResponseOption]
  )

  const handleAddResponseOption = useCallback(
    (newEntryPosition: number): void => {
      dispatch(
        responseOptionAdded({
          questionLk: (currentEntry.entryItem as DraftQuestionItem).questionLk,
          position: newEntryPosition
        })
      )
      addResponseOption(newEntryPosition)
    },
    [addResponseOption, currentEntry.entryItem, dispatch]
  )

  const handleDeleteResponseOption = useCallback(
    (questionLk: string, responseOptionLk: string) => {
      dispatch(responseOptionDeleted({ questionLk, responseOptionLk }))
      deleteResponseOption(questionLk, responseOptionLk)
    },
    [deleteResponseOption, dispatch]
  )

  const handleFocusResponseOption = useCallback(() => {
    questionBeingEditedNumber(currentEntry.number)
  }, [currentEntry.number])

  return (
    <ResponseOptionsList
      entries={flatEntries}
      responseOptions={responseOptions}
      ariaLabel="Basic question response option"
      maskingEnabled={maskingEnabled}
      entry={currentEntry}
      newlyAddedResponseOptionLk={newlyAddedResponseOptionLk}
      onEnter={handleAddResponseOption}
      onFocus={handleFocusResponseOption}
      filteredRoutingMenuItems={filteredRoutingMenuItems}
      onChangeResponseOption={handleUpdateResponseOption}
      onClickDeleteResponseOption={handleDeleteResponseOption}
      onReorderedItems={handleReorderResponses}
      onRoutingMenuItemClick={setOrUnsetRoute}
      onSetExclusiveOption={setOrUnsetExclusive}
      onUpdateTextEntryState={updateTextEntryState}
    />
  )
}

export default memo(ResponseOptions)
