import gql from 'graphql-tag'
import { EntryType } from '../../../gql-gen/questionnaire/graphql'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface AddMatrixQuestionData {
  addMatrixQuestion: DraftQuestionnaireEntry<EntryType.MatrixEntryType>
}

const ADD_MATRIX_QUESTION = gql`
  mutation addMatrixQuestion(
    $questionnaireId: ID!
    $position: Int!
    $title: String!
    $setting: SettingInputInput
    $sectionId: ID
  ) {
    addMatrixQuestion(
      questionnaireId: $questionnaireId
      position: $position
      title: $title
      setting: $setting
      sectionId: $sectionId
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftMatrixItem {
          matrixTitleLk
          matrixTitle {
            matrixTitleId
            title
          }
        }
      }
    }
  }
`

export default ADD_MATRIX_QUESTION
