import { gql } from '@apollo/client/core'

export const SET_QUESTION_MEDIA = gql`
  mutation setQuestionMedia(
    $questionnaireId: ID!
    $questionLk: ID!
    $mediaName: String!
    $mediaUrl: String!
    $mediaType: MediaType!
    $renderedMediaUrl: String
  ) {
    setQuestionMedia(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      mediaName: $mediaName
      mediaUrl: $mediaUrl
      mediaType: $mediaType
      renderedMediaUrl: $renderedMediaUrl
    )
  }
`
