import { useMutation, useReactiveVar } from '@apollo/client'
import { memo, useCallback } from 'react'
import { useAppDispatch } from '../../../../App.store'
import { questionBeingEditedNumber } from '../../../../apollo/apolloClient'
import {
  AddMatrixQuestionRowMutationVariables,
  EntryType
} from '../../../../data/gql-gen/questionnaire/graphql'
import ADD_MATRIX_QUESTION_ROW, {
  AddMatrixQuestionRowData
} from '../../../../data/gql/questionnaire/mutations/addMatrixQuestionRow'
import DETACH_MATRIX_QUESTION_ROW from '../../../../data/gql/questionnaire/mutations/detachMatrixQuestionRow'
import MOVE_MATRIX_QUESTION_ROW from '../../../../data/gql/questionnaire/mutations/moveMatrixQuestionRow'
import UPDATE_MATRIX_QUESTION_ROW from '../../../../data/gql/questionnaire/mutations/updateMatrixQuestionRow'
import { LoggerErrorType } from '../../../../data/logger'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { responseOptionLkNewlyAdded } from '../../../../hooks/useResetNewlyCreatedEntry'
import {
  addMatrixQuestionRowTransactionDatadog,
  deleteMatrixQuestionRowTransactionDatadog
} from '../../../../tracking/perf/transactions'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import {
  WithEntryOfType,
  propsAreEqual
} from '../../../../utils/questionnaireUtils'
import { matrixRowReordered } from '../../Questionnaire.slice'
import { createDraftMatrixRow, flattenEntries } from '../../Questionnaire.utils'
import MatrixQuestionRowsControl from './MatrixQuestionRowsList.control'
import { modifyMatrixRowsCache } from './MatrixQuestionRowsList.utils'

interface QuestionnaireMutationContext {
  context: { clientName: 'questionnaire' }
  onCompleted: () => void
}

const MatrixQuestionRows = (
  props: WithEntryOfType<EntryType.MatrixEntryType>
) => {
  const { entry } = props
  const { matrixTitleLk } = entry.entryItem

  const matrixRows = entry.entryItem.matrixRows
  const dispatch = useAppDispatch()

  const { draftQuestionnaire, draftQuestionnaireEntries } =
    useGetDraftQuestionnaire()

  const questionnaireId = draftQuestionnaire?.questionnaireId || ''
  const flatEntries = flattenEntries(draftQuestionnaireEntries)

  const maskingEnabled =
    flatEntries.length > 0 && entry.number !== flatEntries[0].number

  const questionnaireMutationContext: QuestionnaireMutationContext = {
    context: { clientName: 'questionnaire' },
    onCompleted: () => {
      questionBeingEditedNumber(entry.number)
    }
  }

  const [addMatrixQuestionRow] = useMutation<
    AddMatrixQuestionRowData,
    AddMatrixQuestionRowMutationVariables
  >(ADD_MATRIX_QUESTION_ROW, {
    context: { clientName: 'questionnaire' },
    update: async (cache, { data }) => {
      const matrixRow = data?.addMatrixQuestionRow

      if (!matrixRow) {
        return
      }

      responseOptionLkNewlyAdded(matrixRow.questionLk)
      modifyMatrixRowsCache(cache, entry, matrixRow)
    },
    optimisticResponse: ({ position }) => {
      return {
        addMatrixQuestionRow: createDraftMatrixRow(position)
      }
    }
  })

  const [updateMatrixQuestionRow] = useMutation(UPDATE_MATRIX_QUESTION_ROW, {
    ...questionnaireMutationContext
  })

  const [detachMatrixQuestionRow] = useMutation(DETACH_MATRIX_QUESTION_ROW, {
    context: { clientName: 'questionnaire' },
    onCompleted() {
      questionBeingEditedNumber(entry.number)
    },
    onError(error) {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'detachMatrixQuestionRow',
        error
      )
    }
  })

  const [moveMatrixQuestionRow] = useMutation(MOVE_MATRIX_QUESTION_ROW, {
    ...questionnaireMutationContext,
    fetchPolicy: 'no-cache'
  })

  const handleReorderedQuestionRows: (
    destinationIndex: number | undefined,
    sourceIndex: number | undefined
  ) => void = (destinationIndex, sourceIndex) => {
    if (destinationIndex !== undefined && sourceIndex !== undefined) {
      dispatch(
        matrixRowReordered({
          matrixTitleLk,
          destinationIndex,
          sourceIndex
        })
      )
      moveMatrixQuestionRow({
        variables: {
          questionnaireId,
          matrixTitleLk,
          questionLk: matrixRows[sourceIndex].questionLk,
          toPosition: destinationIndex
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'moveMatrixQuestionRow',
            error
          )
        }
      })
    }
  }

  const handleAddMatrixQuestionRow: () => void = useCallback(() => {
    addMatrixQuestionRowTransactionDatadog.start()
    addMatrixQuestionRow({
      variables: {
        questionnaireId,
        matrixTitleLk,
        position: matrixRows.length,
        text: ''
      },
      onCompleted: () => {
        questionBeingEditedNumber(entry.number)
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'addMatrixQuestionRow',
          error
        )
      }
    })
  }, [
    addMatrixQuestionRow,
    questionnaireId,
    matrixTitleLk,
    matrixRows.length,
    entry
  ])

  const handleAddMatrixQuestionRowOnEnter: (position: number) => void =
    useCallback(
      (position) => {
        const newRowPosition = position + 1
        addMatrixQuestionRow({
          variables: {
            questionnaireId,
            matrixTitleLk,
            position: newRowPosition,
            text: ''
          },
          onCompleted: () => {
            questionBeingEditedNumber(entry.number)
          },
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'addMatrixQuestionRow',
              error
            )
          }
        })
      },
      [addMatrixQuestionRow, entry, matrixTitleLk, questionnaireId]
    )

  const handleDeleteMatrixQuestionRow: (questionLk: string) => void =
    useCallback(
      (questionLk) => {
        deleteMatrixQuestionRowTransactionDatadog.start()
        detachMatrixQuestionRow({
          variables: {
            questionnaireId,
            matrixTitleLk,
            questionLk
          }
        })
      },
      [detachMatrixQuestionRow, matrixTitleLk, questionnaireId]
    )

  const handleChangeMatrixQuestionRow: (
    inputValue: string,
    questionLk: string
  ) => void = useCallback(
    (inputValue, questionLk) => {
      updateMatrixQuestionRow({
        variables: {
          questionnaireId,
          questionLk,
          matrixTitleLk,
          text: inputValue
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'updateMatrixQuestionRow',
            error
          )
        }
      })
    },
    [matrixTitleLk, questionnaireId, updateMatrixQuestionRow]
  )

  const newlyAddedRowLk = useReactiveVar(responseOptionLkNewlyAdded)

  return (
    <MatrixQuestionRowsControl
      entry={entry}
      matrixRows={matrixRows}
      entryNumber={entry.number}
      maskingEnabled={maskingEnabled}
      onEnter={handleAddMatrixQuestionRowOnEnter}
      newlyAddedRowLk={newlyAddedRowLk}
      onClickAddMatrixQuestionRow={handleAddMatrixQuestionRow}
      onChangeMatrixQuestionRow={handleChangeMatrixQuestionRow}
      onDeleteMatrixQuestionRow={handleDeleteMatrixQuestionRow}
      onReorderedQuestionRows={handleReorderedQuestionRows}
    />
  )
}

export default memo(MatrixQuestionRows, propsAreEqual)
