import { useMutation } from '@apollo/client'
import { useAppDispatch } from '../../../App.store'
import {
  PinOrUnpinMatrixResponseOptionMutationVariables,
  PinOrUnpinMatrixRowMutationVariables,
  PinOrUnpinResponseOptionMutationVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import {
  matrixRowPinToggled,
  responseOptionPinToggled
} from '../Questionnaire.slice'
import {
  PIN_MATRIX_RESPONSE_OPTION,
  PIN_MATRIX_ROW,
  PIN_RESPONSE_OPTION,
  PinMatrixResponseOptionData,
  PinMatrixRowData,
  PinResponseOptionData
} from './PinResponse.mutations'

interface Actions {
  setPinnedResponseOption: (
    questionLk: string,
    responseOptionLk: string,
    isPinned: boolean
  ) => void
  setPinnedMatrixResponseOption: (
    matrixTitleLk: string | undefined,
    responseOptionLk: string | undefined,
    isPinned: boolean
  ) => void
  setPinnedMatrixRow: (
    matrixTitleLk: string | undefined,
    questionLk: string | undefined,
    isPinned: boolean
  ) => void
}

const usePinResponseMutations = (): Actions => {
  const dispatch = useAppDispatch()
  const questionnaireId = useDraftQuestionnaireIdCache()
  const [pinOrUnpinResponseOption] = useMutation<
    PinResponseOptionData,
    PinOrUnpinResponseOptionMutationVariables
  >(PIN_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })
  const [pinOrUnpinMatrixResponseOption] = useMutation<
    PinMatrixResponseOptionData,
    PinOrUnpinMatrixResponseOptionMutationVariables
  >(PIN_MATRIX_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })
  const [pinOrUnpinMatrixRow] = useMutation<
    PinMatrixRowData,
    PinOrUnpinMatrixRowMutationVariables
  >(PIN_MATRIX_ROW, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const setPinnedResponseOption = (
    questionLk: string,
    responseOptionLk: string,
    isPinned: boolean
  ): void => {
    dispatch(
      responseOptionPinToggled({
        questionLk,
        responseOptionLk,
        pinned: isPinned
      })
    )

    pinOrUnpinResponseOption({
      variables: {
        questionnaireId,
        questionLk,
        responseOptionLk,
        pinned: isPinned
      }
    })
  }

  const setPinnedMatrixResponseOption = (
    matrixTitleLk: string | undefined,
    responseOptionLk: string | undefined,
    isPinned: boolean
  ): void => {
    dispatch(
      responseOptionPinToggled({
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        questionLk: matrixTitleLk!,
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        responseOptionLk: responseOptionLk!,
        pinned: isPinned
      })
    )

    pinOrUnpinMatrixResponseOption({
      variables: {
        questionnaireId,
        // @todo legacy type error – fix this when updating
        matrixTitleLk: matrixTitleLk!,
        // @todo legacy type error – fix this when updating
        responseOptionLk: responseOptionLk!,
        pinned: isPinned
      }
    })
  }

  const setPinnedMatrixRow = (
    matrixTitleLk: string | undefined,
    questionLk: string | undefined,
    isPinned: boolean
  ): void => {
    dispatch(
      matrixRowPinToggled({
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        matrixTitleLk: matrixTitleLk!,
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        questionLk: questionLk!,
        pinned: isPinned
      })
    )

    pinOrUnpinMatrixRow({
      variables: {
        questionnaireId,
        // @todo legacy type error – fix this when updating
        matrixTitleLk: matrixTitleLk!,
        // @todo legacy type error – fix this when updating
        questionLk: questionLk!,
        pinned: isPinned
      }
    })
  }

  return {
    setPinnedResponseOption,
    setPinnedMatrixResponseOption,
    setPinnedMatrixRow
  }
}

export default usePinResponseMutations
