import { questionnaireGql } from '../../../gql-gen/questionnaire'
import { DetachedEntryMatrixResponseOption } from '../../../model/questionnaire'

export interface DetachMatrixResponseOptionData {
  detachMatrixResponseOption: DetachedEntryMatrixResponseOption
}

export const DETACH_MATRIX_RESPONSE_OPTION = questionnaireGql(/* GraphQL */ `
  mutation detachMatrixResponseOption(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $responseOptionLk: ID!
  ) {
    detachMatrixResponseOption(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      responseOptionLk: $responseOptionLk
    ) {
      matrixTitleLk
      responseOptionLk
      entry {
        entryItem {
          ... on DraftMatrixItem {
            matrixTitleLk
            ...DraftMatrixItemQualification
          }
        }
      }
    }
  }
`)
