import { StoreObject } from '@apollo/client/cache'
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common'
import {
  KeyFieldsFunction,
  KeySpecifier
} from '@apollo/client/cache/inmemory/policies'

type CacheIdGetterOptions = {
  typename: string
  objectToReadFrom: StoreObject
  fallbackKeyField?: KeySpecifier
  readFieldFunction: ReadFieldFunction
}

export const createCacheIdGetter = ({
  typename,
  objectToReadFrom,
  fallbackKeyField,
  readFieldFunction
}: CacheIdGetterOptions) => {
  return (idPath: string[]) => {
    const id = idPath.reduce<StoreObject | undefined>((acc, path) => {
      return readFieldFunction(path, acc)
    }, objectToReadFrom)
    return id
      ? `${typename}:{"${idPath.at(-1)}":"${id}"}`
      : fallbackKeyField ?? ['number']
  }
}

type KeyFieldsFunctionFactory = (
  itemTypeToIdPath: Record<string, string[]>,
  fieldToRead: string
) => KeyFieldsFunction

export const createKeyFieldsFunction: KeyFieldsFunctionFactory =
  (itemTypeToIdPath, fieldToRead) =>
  (obj, { readField, typename }) => {
    const fallbackKeyField: KeySpecifier = ['number']
    const item: StoreObject | undefined = readField(fieldToRead, obj)

    if (!item) {
      return fallbackKeyField
    }

    const itemTypeName = readField('__typename', item)
    const idPath =
      typeof itemTypeName === 'string'
        ? itemTypeToIdPath[itemTypeName]
        : undefined

    if (!idPath || !typename) {
      return fallbackKeyField
    }

    const getCacheId = createCacheIdGetter({
      typename,
      objectToReadFrom: item,
      readFieldFunction: readField
    })

    return getCacheId(idPath)
  }

export const getDraftFieldworkAudienceMemberKeyFields: (
  itemTypeToIdPath: Record<string, string[]>
) => KeyFieldsFunction = (itemTypeToIdPath) =>
  createKeyFieldsFunction(itemTypeToIdPath, 'memberItem')

export const getDraftQuestionnaireEntryKeyFields: (
  itemTypeToIdPath: Record<string, string[]>
) => KeyFieldsFunction = (itemTypeToIdPath) =>
  createKeyFieldsFunction(itemTypeToIdPath, 'entryItem')
