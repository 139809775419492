import { ProjectType } from '../../data/gql-gen/dashboard/graphql'
import { ProjectTypesItem } from '../../modules/Project/Project.model'
import { isMultiMarketFeatureEnabled } from '../../utils/HelperFunctions'
import { isFocaldataUser } from '../../utils/authUtils'

const defaultProjectTypes: ProjectTypesItem[] = [
  {
    title: 'FD Core - Quant Survey',
    description:
      'Get robust quant survey data at exceptional quality and speed. Available across 120+ markets.',
    type: ProjectType.AdHoc
  },
  {
    title: 'FD Chat - Qual Interviews',
    description:
      'Get in-depth insights at scale using AI-moderated interviews. Available for English-speaking markets only.',
    type: ProjectType.FdChat,
    showBetaLabel: true
  }
]

const multiMarketProjectType: ProjectTypesItem = {
  title: 'FD Core - Multi-Market Project',
  description:
    'Run multi-market projects and create an automatic multi-market dashboard.',
  type: ProjectType.MultiMarketV2
}

export const getAvailableProjectTypes = () => {
  return isFocaldataUser() && isMultiMarketFeatureEnabled()
    ? [...defaultProjectTypes, multiMarketProjectType]
    : defaultProjectTypes
}
