import { FetchResult, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../../../apollo/apolloClient'
import {
  AddResponseOptionMutationVariables,
  DetachResponseOptionMutationVariables,
  MoveResponseOptionMutationVariables,
  RemoveRouteMutationVariables,
  SetRoExclusiveMutationVariables,
  SetRoTextEntryStateMutationVariables,
  SetRouteMutationVariables,
  UpdateResponseOptionMutationVariables
} from '../../../../data/gql-gen/questionnaire/graphql'
import {
  ADD_RESPONSE_OPTION,
  AddResponseOptionData
} from '../../../../data/gql/questionnaire/mutations/addResponseOption'
import {
  DETACH_RESPONSE_OPTION,
  DetachResponseOptionData
} from '../../../../data/gql/questionnaire/mutations/detachResponseOption'
import {
  MOVE_RESPONSE_OPTION,
  MoveResponseOptionData
} from '../../../../data/gql/questionnaire/mutations/moveResponseOption'
import {
  REMOVE_ROUTE,
  RemoveRouteData
} from '../../../../data/gql/questionnaire/mutations/removeRoute'
import {
  SET_ROUTE,
  SetRouteData
} from '../../../../data/gql/questionnaire/mutations/setRoute'
import {
  UPDATE_RESPONSE_OPTION,
  UpdateResponseOptionData
} from '../../../../data/gql/questionnaire/mutations/updateResponseOption'
import { LoggerErrorType } from '../../../../data/logger'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  PositionTextSelection,
  TextEntryState
} from '../../../../data/model/questionnaire'
import useCopyPasteComplete from '../../../../hooks/copyPaste/useCopyPasteComplete'
import useDraftQuestionnaireIdCache from '../../../../hooks/localState/useDraftQuestionnaireIdCache'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { responseOptionLkNewlyAdded } from '../../../../hooks/useResetNewlyCreatedEntry'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import {
  addResponseOptionTransactionDatadog,
  deleteResponseOptionTransactionDatadog,
  moveResponseOptionTransactionDatadog,
  pasteInResponseOptionTransactionDatadog
} from '../../../../tracking/perf/transactions'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import { checkIfMultipleChoice } from '../../../../utils/questionnaireUtils'
import {
  responseOptionCreated,
  responseOptionExclusiveToggled,
  responseOptionRouteSet,
  responseOptionRouteUnset,
  responseOptionTextEntryToggled,
  selectResponseOptionsByQuestion,
  selectSettingsByQuestionId
} from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import {
  RoTextEntryStateData,
  SET_RO_EXCLUSIVE,
  SET_RO_TEXT_ENTRY_STATE,
  SetRoExclusiveData
} from './ResponseOption.mutations'

export const useResponseOptionActions = (entry: DraftQuestionnaireEntry) => {
  const entryNumber = entry.number
  const entryItem = entry.entryItem as DraftQuestionItem
  const { questionLk } = entryItem
  const surveyId = useSurveyId()
  const dispatch = useAppDispatch()
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectSettingsByQuestionId(state, entryItem.questionLk)
    ) || []

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flattenAllEntries = flattenEntries(draftQuestionnaireEntries)

  const { refetchQuestionnaire } = useGetDraftQuestionnaire()
  const questionnaireId = useDraftQuestionnaireIdCache()
  const { pasteToResponseOption } = useCopyPasteComplete(questionLk)

  const [addResponseOptionMutation] = useMutation<
    AddResponseOptionData,
    AddResponseOptionMutationVariables
  >(ADD_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const addResponseOption = useCallback(
    (
      position: number
    ): Promise<
      FetchResult<
        AddResponseOptionData,
        Record<string, any>,
        Record<string, any>
      >
    > => {
      addResponseOptionTransactionDatadog.start()
      return addResponseOptionMutation({
        variables: {
          questionnaireId,
          questionLk,
          position,
          responseOptionValue: ''
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'addResponseOption',
            error
          )
        },
        onCompleted: (data) => {
          dispatch(
            responseOptionCreated({
              questionLk,
              position,
              responseOption: data.addResponseOption
            })
          )
          questionBeingEditedNumber(entryNumber)
        },
        update: async (_, { data }) => {
          if (data) {
            responseOptionLkNewlyAdded(data.addResponseOption.responseOptionLk)
          }
        }
      })
    },
    [
      addResponseOptionMutation,
      entryNumber,
      questionLk,
      questionnaireId,
      dispatch
    ]
  )

  const [updateResponseOptionMutation] = useMutation<
    UpdateResponseOptionData,
    UpdateResponseOptionMutationVariables
  >(UPDATE_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const updateResponseOption = useCallback(
    (responseOptionValue: string, responseOptionLk: string): void => {
      updateResponseOptionMutation({
        variables: {
          questionnaireId,
          questionLk,
          responseOptionLk,
          responseOptionValue
        },
        onCompleted: () => {
          questionBeingEditedNumber(entryNumber)
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'updateResponseOption',
            error
          )
          refetchQuestionnaire()
        }
      })
    },
    [
      entryNumber,
      questionLk,
      questionnaireId,
      updateResponseOptionMutation,
      refetchQuestionnaire
    ]
  )

  const [detachResponseOptionMutation] = useMutation<
    DetachResponseOptionData,
    DetachResponseOptionMutationVariables
  >(DETACH_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const deleteResponseOption = useCallback(
    (questionLk: string, responseOptionLk: string): void => {
      deleteResponseOptionTransactionDatadog.start()
      detachResponseOptionMutation({
        variables: {
          questionnaireId,
          questionLk,
          responseOptionLk
        },
        onCompleted: () => {
          questionBeingEditedNumber(entryNumber)
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'detachResponseOption',
            error
          )
          refetchQuestionnaire()
        }
      })
    },
    [
      detachResponseOptionMutation,
      questionnaireId,
      entryNumber,
      refetchQuestionnaire
    ]
  )

  const [setRouteMutation] = useMutation<
    SetRouteData,
    SetRouteMutationVariables
  >(SET_ROUTE, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const [removeRouteMutation] = useMutation<
    RemoveRouteData,
    RemoveRouteMutationVariables
  >(REMOVE_ROUTE, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const setOrUnsetRoute = useCallback(
    (
      targetEntryNumber: number | null,
      responseOptionLk: string | undefined
    ): void => {
      if (targetEntryNumber !== null && responseOptionLk) {
        dispatch(
          responseOptionRouteSet({
            questionLk,
            responseOptionLk,
            targetEntryNumber,
            flattenAllEntries
          })
        )
        setRouteMutation({
          variables: {
            questionnaireId,
            questionLk,
            responseOptionLk,
            targetNumber: targetEntryNumber
          },
          onCompleted: () => {
            questionBeingEditedNumber(entryNumber)
          },
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'setRoute',
              error
            )
          }
        })

        LogAmplitudeEvent(EventType.AddedSkipLogic, {
          surveyId,
          questionLk,
          responseOptionLk
        })
      }
      if (targetEntryNumber === null && responseOptionLk) {
        dispatch(
          responseOptionRouteUnset({
            questionLk,
            responseOptionLk,
            flattenAllEntries
          })
        )
        removeRouteMutation({
          variables: {
            questionnaireId,
            questionLk,
            responseOptionLk
          },
          onCompleted: () => {
            questionBeingEditedNumber(entryNumber)
          },
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'removeRoute',
              error
            )
          }
        })
      }
    },
    [
      dispatch,
      entryNumber,
      flattenAllEntries,
      questionLk,
      questionnaireId,
      removeRouteMutation,
      setRouteMutation,
      surveyId
    ]
  )

  const [moveResponseOptionMutation] = useMutation<
    MoveResponseOptionData,
    MoveResponseOptionMutationVariables
  >(MOVE_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const [setRoTextEntryState] = useMutation<
    RoTextEntryStateData,
    SetRoTextEntryStateMutationVariables
  >(SET_RO_TEXT_ENTRY_STATE, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const updateTextEntryState = useCallback(
    (responseOptionLk: string, newTextEntryState: TextEntryState) => {
      dispatch(
        responseOptionTextEntryToggled({
          questionLk: entryItem.questionLk,
          responseOptionLk,
          textEntryState: newTextEntryState
        })
      )
      setRoTextEntryState({
        variables: {
          questionnaireId,
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          questionLk: entryItem?.questionLk,
          responseOptionLk,
          textEntryState: newTextEntryState
        }
      })
    },
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    [dispatch, entryItem?.questionLk, questionnaireId, setRoTextEntryState]
  )

  const moveResponseOption = useCallback(
    (destinationIndex: number | undefined, sourceIndex: number | undefined) => {
      if (
        sourceIndex !== undefined &&
        destinationIndex !== undefined &&
        destinationIndex !== sourceIndex
      ) {
        moveResponseOptionTransactionDatadog.start()
        const entryItem = entry.entryItem as DraftQuestionItem
        const { responseOptionLk } =
          responseOptionsByQuestion[questionLk][sourceIndex] ?? {}
        moveResponseOptionMutation({
          variables: {
            questionnaireId,
            questionLk: entryItem.questionLk,
            responseOptionLk,
            toPosition: destinationIndex
          },
          onCompleted: () => {
            questionBeingEditedNumber(entryNumber)
          },
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'moveResponseOption',
              error
            )
            refetchQuestionnaire()
          }
        })
      }
    },
    [
      entry.entryItem,
      responseOptionsByQuestion,
      questionLk,
      moveResponseOptionMutation,
      questionnaireId,
      entryNumber,
      refetchQuestionnaire
    ]
  )

  const pasteInResponseOption = useCallback(
    async (
      text: string,
      position: number,
      positionSelectedText: PositionTextSelection
    ) => {
      pasteInResponseOptionTransactionDatadog.start()
      questionBeingEditedNumber(entryNumber)
      await pasteToResponseOption(
        text,
        questionLk,
        position,
        positionSelectedText
      )
    },
    [questionLk, entryNumber, pasteToResponseOption]
  )

  const isMultiSelectEntry =
    entry.entryType === EntryType.QuestionEntryType &&
    checkIfMultipleChoice(settingValues)

  const [setRoExlusive] = useMutation<
    SetRoExclusiveData,
    SetRoExclusiveMutationVariables
  >(SET_RO_EXCLUSIVE, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const setOrUnsetExclusive = useCallback(
    (responseOptionLk: string, isExclusive: boolean) => {
      dispatch(
        responseOptionExclusiveToggled({
          questionLk: entryItem.questionLk,
          responseOptionLk,
          exclusive: isExclusive
        })
      )
      setRoExlusive({
        variables: {
          questionnaireId,
          questionLk: entryItem.questionLk,
          responseOptionLk,
          exclusive: isExclusive
        },
        onCompleted: () => {
          questionBeingEditedNumber(entryNumber)
        }
      })
    },
    [
      dispatch,
      entryItem.questionLk,
      entryNumber,
      questionnaireId,
      setRoExlusive
    ]
  )

  return {
    addResponseOption,
    updateResponseOption,
    deleteResponseOption,
    setOrUnsetRoute,
    moveResponseOption,
    pasteInResponseOption,
    setOrUnsetExclusive: isMultiSelectEntry ? setOrUnsetExclusive : undefined,
    updateTextEntryState
  }
}
