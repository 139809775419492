import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { memo, useState } from 'react'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { EntryType, ForkBranch } from '../../../data/model/questionnaire'
import useForkMutations from '../../../hooks/questionnaire/useForkMutations'
import { useDebounceEffect } from '../../../hooks/useDebounce'
import BasicQuestionLayout from '../../../layouts/BasicQuestionLayout'
import { sumBy } from '../../../utils/array/sumBy'
import {
  WithEntry,
  getBranchBatchUpdateData,
  getTotalForkPercentage,
  propsAreEqual
} from '../../../utils/questionnaireUtils'
import { CardTitleControl } from '../CardTitle'
import { SurveyQuestionCardHeaderControl } from '../SurveyQuestionCardHeader'
import Branch from './Branch'
import ForkFooter from './ForkFooter'

const Fork = ({ entry }: WithEntry) => {
  if (entry.entryType !== EntryType.ForkEntryType) {
    throw new Error('Cannot render non-fork as fork')
  }

  const { fork } = entry.entryItem

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const {
    questionnaireId,
    uniqueForkNameError,
    updateForkBranch,
    addForkBranch,
    removeForkBranch
  } = useForkMutations()

  const [quotaBeingEdited, setQuotaBeingEdited] = useState<{
    value: number
    branch: ForkBranch
  }>()

  const triggerOnQuotaChange = async () => {
    questionBeingEditedNumber(entry.number)
    if (quotaBeingEdited) {
      await updateForkBranch({
        variables: {
          questionnaireId,
          forkId: fork.forkId,
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          branchNumber: quotaBeingEdited?.branch.branchNumber,
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          percentage: quotaBeingEdited?.value
        }
      })
    }
  }

  useDebounceEffect(
    () => {
      triggerOnQuotaChange()
    },
    quotaBeingEdited?.value,
    { delay: 500 }
  )

  const makeEqualBranches = async () => {
    await Promise.all(
      getBranchBatchUpdateData(fork.branches).map(
        ({ branchNumber, percentage }) =>
          updateForkBranch({
            variables: {
              questionnaireId,
              forkId: fork.forkId,
              branchNumber,
              percentage
            }
          })
      )
    )
  }

  const handleDeleteBranch = async (branchNumber: number) => {
    questionBeingEditedNumber(entry.number)
    await removeForkBranch({
      variables: {
        questionnaireId,
        forkId: fork.forkId,
        branchNumber
      }
    })
  }

  const handleAddGroup = async () => {
    questionBeingEditedNumber(entry.number)
    const totalPercentages =
      Math.round(sumBy(fork.branches, (b) => b.percentage) * 1000) / 1000
    const percentage =
      totalPercentages < 1
        ? Math.round((1 - totalPercentages) * 1000) / 1000
        : 0.1
    await addForkBranch({
      variables: {
        questionnaireId,
        forkId: fork.forkId,
        percentage
      }
    })
  }

  const hideDeleteButtons = fork.branches.length < 3

  const totalForkPercentage = getTotalForkPercentage(fork)

  return (
    <BasicQuestionLayout
      entryNumber={entry.number}
      questionCardHeader={
        <SurveyQuestionCardHeaderControl
          entry={entry}
          titleContent={
            <CardTitleControl
              titleValue={fork.name}
              readOnly
              ariaLabel="Fork header input"
              hasError={
                uniqueForkNameError.hasError &&
                uniqueForkNameError.id === fork.forkId
              }
            />
          }
          idContent={
            <p
              className={classNames(
                textClasses.default,
                textClasses.weightSemiBold,
                textClasses.highlightBackground
              )}
            >
              A/B
            </p>
          }
        />
      }
      responseOptions={
        <div className="fd-grid fd-container fd-column fd-align-items-flex-start">
          <p
            className={classNames(
              textClasses.default,
              textClasses.highlightBackground
            )}
          >
            Randomly divide your audience into the following groups:
          </p>
          {fork.branches.map((branch, index) => (
            <Branch
              isLast={index === fork.branches.length - 1}
              defaultPercent={branch.percentage}
              key={branch.branchNumber}
              label={branch.label}
              hideDelete={hideDeleteButtons}
              onDeleteBranch={() => handleDeleteBranch(branch.branchNumber)}
              onPercentChange={(newPercent) =>
                setQuotaBeingEdited({ value: newPercent, branch })
              }
            />
          ))}
          <ForkFooter
            totalForkPercentage={totalForkPercentage}
            onAddGroup={handleAddGroup}
            makeEqualBranches={makeEqualBranches}
          />
        </div>
      }
      defaultOptions={
        <div className="fd-grid fd-container fd-row fd-align-items-center">
          <Icon
            name={IconName.InfoOutlinedIcon}
            iconColor={IconColor.Background}
            size={IconSize.Medium}
          />
          <p
            className={classNames(
              textClasses.default,
              textClasses.marginLeft,
              textClasses.highlightBackground
            )}
          >
            Set up display logic on later questions to show/hide questions for
            each group
          </p>
        </div>
      }
    />
  )
}

export default memo(Fork, propsAreEqual)
