import { useFragment, useMutation } from '@apollo/client'
import {
  AlignInputText,
  Checkbox,
  Dialog,
  Grid,
  Input,
  InputFontSize,
  InputType,
  InputVariant,
  List,
  Text,
  TextSize,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'
import { inputClasses } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import { useContext, useMemo } from 'react'
import { SetMatrixQualificationMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import SET_MATRIX_QUALIFICATION, {
  SetMatrixQualificationData
} from '../../../data/gql/questionnaire/mutations/setMatrixQualification'
import { LoggerErrorType } from '../../../data/logger'
import { DraftEntryResponseOption } from '../../../data/model/questionnaire'
import { useDefaultState } from '../../../hooks/useDefaultState'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { defaultMatrixQualification } from '../../../utils/questionnaireUtils'
import QuestionnaireContext, {
  closeMatrixScreeningDialog
} from '../Questionnaire.context'
import { MatrixQuestionScreeningDialog_DraftMatrixItemFragment } from './MatrixScreeningDialog.questionnaire.gql'

const useStyles = makeStyles()({
  checkboxList: { marginTop: theme.spacing(2), marginBottom: theme.spacing(3) },
  checkboxLabel: { paddingLeft: theme.spacing(1), userSelect: 'none' },
  numberInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [`input[type="number"].${inputClasses.input}`]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      fontSize: `${TextSize.ms}px`
    }
  },
  maxHint: { color: theme.palette.text.secondary }
})

const STABLE_EMPTY_RESPONSE_OPTIONS: DraftEntryResponseOption[] = []
const minMatrixRows = 1

export const MatrixQuestionScreeningDialog = () => {
  const {
    questionnaireState: { matrixScreeningDialog }
  } = useContext(QuestionnaireContext)

  const fragment = useFragment({
    fragment: MatrixQuestionScreeningDialog_DraftMatrixItemFragment,
    fragmentName: 'MatrixQuestionScreeningDialog_DraftMatrixItemFragment',
    from: {
      __typename: 'DraftMatrixItem',
      /**
       * useFragment does not support `skip`, but a warning can be logged if this
       * isn't defined, so we put a fake value in
       *
       * @see https://github.com/apollographql/apollo-feature-requests/issues/350
       */
      matrixTitleLk: matrixScreeningDialog.matrixTitleLk ?? 'DOES_NOT_EXIST'
    },
    canonizeResults: true
  })

  const entryItem =
    matrixScreeningDialog.open && fragment.complete ? fragment.data : null

  const { dispatch } = useContext(QuestionnaireContext)
  const surveyId = useSurveyId()

  const { classes } = useStyles()

  const responseOptions =
    entryItem?.responseOptions ?? STABLE_EMPTY_RESPONSE_OPTIONS
  const matrixRowsCount = entryItem?.matrixRows.length ?? 0

  // Making this stable so it can be used with useDefaultState
  const defaultAllResponseOptions = useMemo(
    () => defaultMatrixQualification.qualifyingOptions(responseOptions),
    [responseOptions]
  )

  const [selectedResponseOptions, setSelectedResponseOptions] = useDefaultState(
    entryItem?.matrixQualification?.qualifyingOptions ??
      defaultAllResponseOptions
  )
  const [minimumResponseOptions, setMinimumResponseOptions] = useDefaultState(
    entryItem?.matrixQualification?.matrixRowQualifyingCondition
      .numberOfQualifyingRows ??
      defaultMatrixQualification.numberOfQualifyingRows
  )

  const [setMatrixQualification, { loading }] = useMutation<
    SetMatrixQualificationData,
    SetMatrixQualificationMutationVariables
  >(SET_MATRIX_QUALIFICATION, {
    context: {
      clientName: 'questionnaire'
    },
    onError(error) {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setMatrixQualification',
        error
      )
    }
  })

  if (!matrixScreeningDialog.open) {
    return null
  }

  if (!entryItem) {
    throw new Error('Cannot find entry for matrix screening dialog')
  }

  const handleCancel = () => {
    if (!loading) {
      dispatch(closeMatrixScreeningDialog())
    }
  }

  const handleSave = async () => {
    await setMatrixQualification({
      variables: {
        questionnaireId: surveyId,
        matrixTitleLk: matrixScreeningDialog.matrixTitleLk,
        numberOfQualifyingRows: minimumResponseOptions,
        operator: defaultMatrixQualification.operator,
        qualifyingOptions: selectedResponseOptions
      }
    })
    handleCancel()
  }

  const handleResponseOptionCheckedChange = (
    responseOptionLk: string,
    checked: boolean
  ) => {
    setSelectedResponseOptions((selectedResponseOptions) => {
      // Filters if present but not checked, and adds if not present and is checked
      if (selectedResponseOptions.includes(responseOptionLk)) {
        if (!checked) {
          return selectedResponseOptions.filter((id) => id !== responseOptionLk)
        }
      } else if (checked) {
        return [...selectedResponseOptions, responseOptionLk]
      }

      return selectedResponseOptions
    })
  }

  return (
    <Dialog
      open
      title="Matrix screening"
      onClose={handleCancel}
      primaryButtonClick={handleSave}
      onClickOutside={handleCancel}
      primaryButtonText={
        entryItem.matrixQualification ? 'Save screening' : 'Add screening'
      }
      fullWidth
      primaryButtonLoading={loading}
    >
      <div className="fd-grid fd-container fd-column">
        <Text size={TextSize.ms}>
          Qualify a respondent if the following columns are picked:
        </Text>
        <div className={classes.checkboxList}>
          <List>
            {responseOptions.map((responseOption) => (
              <ListItem key={responseOption.responseOptionLk} disableGutters>
                <Checkbox
                  disabled={loading}
                  noPadding
                  label={
                    <span className={classes.checkboxLabel}>
                      {responseOption.responseOption?.value ?? ''}
                    </span>
                  }
                  checked={selectedResponseOptions.includes(
                    responseOption.responseOptionLk
                  )}
                  onChange={(evt) => {
                    handleResponseOptionCheckedChange(
                      responseOption.responseOptionLk,
                      evt.target.checked
                    )
                  }}
                />
              </ListItem>
            ))}
          </List>
        </div>
        <Grid container alignItems="center">
          <Text size={TextSize.ms}>At least</Text>
          <Input
            aria-label="Minimum number of qualifying options"
            inputType={InputType.Number}
            alignInputText={AlignInputText.Center}
            variant={InputVariant.Underlined}
            inputFontSize={InputFontSize.s}
            // We use a default value so that we can transform the inputted
            // value before saving it to state
            defaultValue={minimumResponseOptions}
            onChange={(evt) => {
              const val = evt.target.valueAsNumber

              // Ensures we don't set NaN to state, and keeps it within min/max range
              if (!Number.isNaN(val)) {
                setMinimumResponseOptions(
                  Math.max(
                    minMatrixRows,
                    Math.min(matrixRowsCount, Number(val))
                  )
                )
              }
            }}
            onBlur={(evt) => {
              // Resets back to the save value on blur – in case the field is empty, above min/max or otherwise invalid
              evt.target.valueAsNumber = minimumResponseOptions
            }}
            max={matrixRowsCount}
            min={minMatrixRows}
            disabled={loading}
            className={classes.numberInput}
          />
          <Text size={TextSize.ms}>
            rows where these columns must be picked{' '}
            <span className={classes.maxHint}>(max: {matrixRowsCount})</span>
          </Text>
        </Grid>
      </div>
    </Dialog>
  )
}
