import {
  LinkButton,
  List,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React, { memo } from 'react'
import {
  DraftMatrixRow,
  EntryType
} from '../../../../data/gql-gen/questionnaire/graphql'
import { IsNonManualUIChange } from '../../../../hooks/copyPaste/useCopyPasteComplete'
import {
  WithEntryOfType,
  propsAreEqual
} from '../../../../utils/questionnaireUtils'
import MatrixQuestionRow from './MatrixQuestionRow'
import useStyles from './MatrixQuestionRowsList.styles'

interface Props extends WithEntryOfType<EntryType.MatrixEntryType> {
  matrixRows: DraftMatrixRow[]
  newlyAddedRowLk: string | undefined
  entryNumber: number
  maskingEnabled: boolean
  onChangeMatrixQuestionRow: (inputValue: string, questionLk: string) => void
  onDeleteMatrixQuestionRow: (questionLk: string) => void
  onClickAddMatrixQuestionRow: () => void
  onEnter: (position: number) => void
  onReorderedQuestionRows: (
    destinationIndex: number | undefined,
    sourceIndex: number
  ) => void
}

const MatrixQuestionRowsControl: React.FC<Props> = (props: Props) => {
  const {
    entry,
    matrixRows,
    newlyAddedRowLk,
    entryNumber,
    maskingEnabled = false,
    onEnter,
    onClickAddMatrixQuestionRow,
    onChangeMatrixQuestionRow,
    onDeleteMatrixQuestionRow,
    onReorderedQuestionRows
  } = props

  const { classes } = useStyles()

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const rowCount = matrixRows?.length ?? 0
  const { matrixTitleLk } = entry.entryItem

  const getMatrixRows: (
    matrixRows: DraftMatrixRow[] | undefined
  ) => (JSX.Element | null)[] | undefined = (matrixRows) => {
    return (
      matrixRows &&
      matrixRows.map((matrixRow, index) => {
        const shouldAutoFocus = newlyAddedRowLk === matrixRow.questionLk

        return (
          <MatrixQuestionRow
            key={matrixRow.questionLk}
            index={index}
            entryNumber={entryNumber}
            matrixTitleLk={matrixTitleLk}
            matrixRow={matrixRow}
            maskingEnabled={maskingEnabled}
            rowCount={rowCount}
            shouldAutoFocus={shouldAutoFocus}
            onEnter={onEnter}
            onChangeMatrixQuestionRow={onChangeMatrixQuestionRow}
            onDeleteMatrixQuestionRow={onDeleteMatrixQuestionRow}
          />
        )
      })
    )
  }

  const rowInputs = getMatrixRows(matrixRows)

  return (
    <>
      <List
        canReorderItems
        droppableId={`matrix${entryNumber}`}
        onReorderedItems={(event) => {
          IsNonManualUIChange(true)
          onReorderedQuestionRows(event.destination?.index, event.source.index)
        }}
      >
        {rowInputs}
      </List>
      <div className={classes.paddingTop}>
        <LinkButton
          highlight={TextHighlight.Emphasis}
          size={TextSize.m}
          weight={TextWeight.SemiBold}
          onClick={onClickAddMatrixQuestionRow}
          noPadding
        >
          Add row
        </LinkButton>
      </div>
    </>
  )
}

export default memo(MatrixQuestionRowsControl, propsAreEqual)
