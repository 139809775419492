import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface DuplicateQuestionData {
  duplicateQuestion: DraftQuestionnaireEntry
}

export const DUPLICATE_QUESTION = gql`
  mutation duplicateQuestion(
    $questionnaireId: ID!
    $questionLk: ID!
    $position: Int!
  ) {
    duplicateQuestion(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      position: $position
    ) {
      number
      position
      entryType
      questionKind
      entryItem {
        __typename
        ... on DraftQuestionItem {
          question {
            __typename
            questionId
            text
            createdDate
          }
          questionLk
        }
      }
      __typename
    }
  }
`
