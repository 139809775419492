import { useMutation, useReactiveVar } from '@apollo/client'
import { ScrollTo } from '@focaldata/cin-ui-components'
import React, { createContext, memo, useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import {
  DuplicateQuestionMutationVariables,
  EntryType
} from '../../../data/gql-gen/questionnaire/graphql'
import {
  DUPLICATE_QUESTION,
  DuplicateQuestionData
} from '../../../data/gql/questionnaire/mutations/duplicateQuestion'
import { LoggerErrorType } from '../../../data/logger'
import {
  DraftQuestionItem,
  QuestionSettingCode,
  QuestionTypeCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { SurveyParams } from '../../../data/model/surveyParams'
import useCopyPasteComplete from '../../../hooks/copyPaste/useCopyPasteComplete'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import BasicQuestionLayout from '../../../layouts/BasicQuestionLayout'
import {
  duplicateQuestionTransactionDatadog,
  pasteInTitleTransactionDatadog
} from '../../../tracking/perf/transactions'
import { captureApolloError } from '../../../utils/HelperFunctions'
import {
  WithEntryOfType,
  getQuestionSettings,
  newEntryId
} from '../../../utils/questionnaireUtils'
import { CardTitleContainer } from '../CardTitle'
import DefaultOptions from '../QuestionCardDefaultOptions/DefaultOptions'
import QuestionCardSwitches from '../QuestionCardSwitches'
import {
  selectSettingsByQuestionId,
  setQuestionSetting
} from '../Questionnaire.slice'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  SCROLL_ID_PREFIX
} from '../constants'
import Footer from './QuestionCardFooter/QuestionCardFooter.container'
import { ResponseOptionsListContainer } from './ResponseOptions'

interface Props extends WithEntryOfType<EntryType.QuestionEntryType> {
  shouldTitleInputFocus: boolean
}

export const BasicEntryContext = createContext<DraftQuestionItem | undefined>(
  undefined
)

export const useBasicEntryContext = () => {
  const entryItem = useContext(BasicEntryContext)

  if (!entryItem) {
    throw new Error('Missing BasicEntryContext.Provider')
  }

  return entryItem
}

const BasicQuestion: React.FC<Props> = (props: Props) => {
  const { entry, shouldTitleInputFocus } = props
  const { validateBasicQuestionText } = useQuestionnaireValidation()
  const { draftQuestionnaireEntries, refetchQuestionnaire } =
    useGetDraftQuestionnaire()
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const questionnaireId = useDraftQuestionnaireIdCache()
  const handleSettingChange = useSetQuestionnaireSetting(entry)
  const dispatch = useAppDispatch()

  const entryItem = entry.entryItem
  const { questionLk } = entryItem
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) => selectSettingsByQuestionId(state, questionLk)) ||
    []
  const { pasteToTitle } = useCopyPasteComplete(questionLk)

  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const scrollToDuplicatedBasicQuestionCard = (cardNumber: string) => {
    setTimeout(() => {
      if (cardNumber) {
        ScrollTo(
          `${SCROLL_ID_PREFIX}${cardNumber}`,
          CONTAINER_ID_QUESTIONNAIRE_CONTENT
        )
      }
    }, 400)
  }

  const [duplicateQuestionMutation] = useMutation<
    DuplicateQuestionData,
    DuplicateQuestionMutationVariables
  >(DUPLICATE_QUESTION, {
    context: { clientName: 'questionnaire' }
  })

  const handleDuplicateQuestion: () => void = useCallback(() => {
    duplicateQuestionTransactionDatadog.start()
    duplicateQuestionMutation({
      variables: {
        questionnaireId,
        questionLk,
        position: entry.position + 1
      },
      onCompleted: async ({ duplicateQuestion }) => {
        await refetchQuestionnaire()
        scrollToDuplicatedBasicQuestionCard(duplicateQuestion.number.toString())
        questionBeingEditedNumber(undefined)
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'duplicateQuestionMutation',
          error
        )
      }
    })
    LogAmplitudeEvent(EventType.DuplicatedQuestion, {
      surveyId,
      questionnaireId
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.position, questionLk, questionnaireId])

  const changeSettingValue = useCallback(
    async (code: QuestionSettingCode, value: SettingValue) => {
      dispatch(
        setQuestionSetting({
          questionLk,
          code,
          value
        })
      )
      await handleSettingChange(code, value, 'no-cache')
    },
    [dispatch, handleSettingChange, questionLk]
  )

  const handleQuestionTypeChange = useCallback(
    async (settingValue: SettingValue) => {
      const updateSettingPromises = [
        changeSettingValue(QuestionSettingCode.BasicChoice, settingValue)
      ]
      switch (settingValue) {
        case SettingValue.MultipleChoice:
          updateSettingPromises.push(
            changeSettingValue(
              QuestionSettingCode.FlipOptions,
              SettingValue.Disabled
            )
          )
          break
      }
      await Promise.all(updateSettingPromises)
    },
    [changeSettingValue]
  )

  const handlePasteInTitle = async (
    text: string,
    styledText: string,
    responseOptions: string[]
  ) => {
    pasteInTitleTransactionDatadog.start()
    questionBeingEditedNumber(entry.number)
    await pasteToTitle(text, styledText, responseOptions)
  }

  const questionSettings = getQuestionSettings(settingValues)

  const isMultipleChoice =
    questionSettings.get(QuestionSettingCode.BasicChoice) ===
    SettingValue.MultipleChoice

  // TODO: do we need it? (see `getEntryCard` in `GenericEntry.utils`)
  const showDefaultOptions =
    entryItem.questionTypeCode === QuestionTypeCode.Basic ||
    entryItem.questionTypeCode === QuestionTypeCode.Ranked

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasEmptyQuestionTitle = entryItem.question === null
  const isBasicQuestionTextEmpty = validateBasicQuestionText(questionLk)
  const newlyCreatedEntryId = useReactiveVar(newEntryId)
  const isNewlyCreatedEntry =
    !!newlyCreatedEntryId && newlyCreatedEntryId === questionLk

  return (
    <BasicEntryContext.Provider value={entryItem}>
      <BasicQuestionLayout
        entryNumber={entry.number}
        questionCardHeader={
          <SurveyQuestionCardHeaderContainer
            entry={entry}
            hasError={!isNewlyCreatedEntry && isBasicQuestionTextEmpty}
            titleContent={
              <CardTitleContainer
                ariaLabel="Basic question header"
                // for basic question when multilined pasting occurs only first line will be pasted, the rest will be handled by "smart pasting"
                shouldPasteOneLine
                entryNumber={entry.number}
                questionLk={questionLk}
                hasError={!isNewlyCreatedEntry && isBasicQuestionTextEmpty}
                questionTypeCode={entryItem.questionTypeCode}
                shouldTitleInputFocus={shouldTitleInputFocus}
                onPasteInTitle={handlePasteInTitle}
              />
            }
            onQuestionTypeChange={handleQuestionTypeChange}
            onClickDuplicateIcon={handleDuplicateQuestion}
            isMultipleChoice={isMultipleChoice}
            disabledDuplicate={hasEmptyQuestionTitle}
            entries={flatDraftQuestionnaireEntries}
          />
        }
        switches={
          <QuestionCardSwitches
            entry={entry}
            randomiseQuestionSettingCode={QuestionSettingCode.RandomiseOptions}
            flipOrderQuestionSettingCode={QuestionSettingCode.FlipOptions}
            showFileUpload
          />
        }
        responseOptions={
          <ResponseOptionsListContainer
            entries={flatDraftQuestionnaireEntries}
            currentEntry={entry}
          />
        }
        questionCardFooter={<Footer entry={entry} />}
        defaultOptions={
          showDefaultOptions ? <DefaultOptions entry={entry} /> : null
        }
      />
    </BasicEntryContext.Provider>
  )
}

export default memo(BasicQuestion)
