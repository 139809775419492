import { gql } from '@apollo/client'

export interface SetExclusiveMatrixColData {
  setExclusiveMatrixRow: {
    responseOptionLk: string
    createdDate: string
    exclusive: boolean
    __typename: string
  }
}

// wrong naming of mutation on backend by mistake;
// should be renamed to have 'column' instead of 'row'
export const SET_EXCLUSIVE_MATRIX_COL = gql`
  mutation setExclusiveMatrixRow(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $responseOptionLk: ID!
    $exclusive: Boolean!
  ) {
    setExclusiveMatrixRow(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      responseOptionLk: $responseOptionLk
      exclusive: $exclusive
    ) {
      responseOptionLk
      createdDate
      exclusive
      __typename
    }
  }
`
