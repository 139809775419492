import { gql } from '@apollo/client/core'
import { AudienceCriterion } from '../../../model/audience'
import STANDARD_AUDIENCE_CRITERION from '../fragments/audienceCriterion'

export interface FieldworkAudienceCriterionData {
  setStandardCriterion: { criterion: AudienceCriterion }
}

export const REMOVE_CRITERION = gql`
  mutation removeStandardCriterion(
    $surveyId: ID!
    $audienceCriterionCode: String!
  ) {
    removeStandardCriterion(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
    ) {
      ...StandardAudienceCriterion
    }
  }
  ${STANDARD_AUDIENCE_CRITERION}
`

export const SET_CRITERION = gql`
  mutation setStandardCriterion(
    $surveyId: ID!
    $position: Int!
    $audienceCriterionCode: String!
  ) {
    setStandardCriterion(
      surveyId: $surveyId
      position: $position
      audienceCriterionCode: $audienceCriterionCode
    ) {
      ...StandardAudienceCriterion
    }
  }
  ${STANDARD_AUDIENCE_CRITERION}
`
