import { gql } from '@apollo/client/core'
import { DraftRoute } from '../../../model/questionnaire'
import DRAFT_ROUTE from '../fragments/draftRoute'

export interface RemoveRouteData {
  removeRoute: DraftRoute
}

export const REMOVE_ROUTE = gql`
  mutation removeRoute(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
  ) {
    removeRoute(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
    ) {
      ...DraftRoute
    }
  }
  ${DRAFT_ROUTE}
`
