import React, { memo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { emptyArr } from '../../../../constants/misc'
import { DraftMaskingRule } from '../../../../data/gql-gen/questionnaire/graphql'
import { EntryType } from '../../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import {
  maskingRulesSet,
  selectResponseOptionsByQuestion
} from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import MaskingButtonControl from './MaskingButton.control'

interface Props {
  questionLk: string | undefined
  responseOptionLk: string
  entryType: EntryType
  createOrUpdateMaskingRule: (maskingRules: DraftMaskingRule[]) => Promise<void>
}

const MaskingButton: React.FC<Props> = ({
  questionLk,
  responseOptionLk,
  entryType,
  createOrUpdateMaskingRule
}: Props) => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )
  const dispatch = useAppDispatch()
  const surveyId = useSurveyId()

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const responseOptionMasking = questionLk
    ? responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )?.maskingRules ?? emptyArr
    : emptyArr

  const handleSave: (newRules: DraftMaskingRule[]) => Promise<void> = async (
    newRules
  ) => {
    dispatch(
      maskingRulesSet({
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        questionLk: questionLk!,
        responseOptionLk,
        maskingRules: newRules,
        flattenAllEntries: flatDraftQuestionnaireEntries
      })
    )
    await createOrUpdateMaskingRule(newRules)

    const negatedMaskingRules = newRules.filter((newRule) => newRule.negated)

    LogAmplitudeEvent(EventType.AddedResponseDisplayLogic, {
      surveyId,
      questionLk,
      responseOptionLk,
      entryType,
      hasNegatedMaskingRules: negatedMaskingRules.length > 0,
      numOfNegatedMaskingRules: negatedMaskingRules.length
    })
  }

  return (
    <MaskingButtonControl
      entryType={entryType}
      isResponseOption
      entries={flatDraftQuestionnaireEntries}
      onSave={handleSave}
      entryItemLk={questionLk}
      maskedItemLk={responseOptionLk}
      maskingRules={responseOptionMasking}
      responseOptionsByQuestion={responseOptionsByQuestion}
    />
  )
}

export default memo(MaskingButton)
