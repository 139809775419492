import { useMutation } from '@apollo/client'
import { MutationTuple } from '@apollo/client/react/types/types'
import { UpsertQuestionMaxDiffSpecificationMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../data/logger'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { UPSERT_QUESTION_MAXDIFF_SPEC } from './MaxDiffQuestion.mutations'

export const useMaxDiffMutations = (questionLk: string) => {
  const questionnaireId = useDraftQuestionnaireIdCache()
  // No need for optimistic updates since the inputs are uncontrolled
  const [upsertMaxDiffSpec, { error }] = useMutation<
    void,
    UpsertQuestionMaxDiffSpecificationMutationVariables
  >(UPSERT_QUESTION_MAXDIFF_SPEC, {
    context: { clientName: 'questionnaire' },
    variables: {
      questionnaireId,
      questionLk
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'upsertQuestionMaxDiffSpecification',
        error
      )
    }
  }) as MutationTuple<
    void,
    Omit<
      UpsertQuestionMaxDiffSpecificationMutationVariables,
      'questionnaireId' | 'questionLk'
    > &
      Partial<
        Pick<
          UpsertQuestionMaxDiffSpecificationMutationVariables,
          'questionnaireId' | 'questionLk'
        >
      >
  >

  return {
    upsertMaxDiffSpec,
    error
  }
}
