import { useQuery } from '@apollo/client'
import { SidebarAudienceQuestionCountQueryVariables } from '../../../../data/gql-gen/fieldwork/graphql'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import {
  FieldworkAudienceData,
  SIDEBAR_AUDIENCE_QUESTION_COUNT
} from './AudienceQuestionsToggle.query'

export const useHasAudienceQuestions = () => {
  const surveyId = useSurveyId()

  const { data } = useQuery<
    FieldworkAudienceData,
    SidebarAudienceQuestionCountQueryVariables
  >(SIDEBAR_AUDIENCE_QUESTION_COUNT, {
    context: { clientName: 'fieldwork' },
    variables: {
      surveyId
    },
    skip: !surveyId
  })

  return (data?.fieldworkAudience.entries.length ?? 0) > 0
}
