import { useMutation } from '@apollo/client'
import { useAppDispatch } from '../../../App.store'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { AddMatrixResponseOptionMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import {
  ADD_MATRIX_RESPONSE_OPTION,
  AddMatrixResponseOptionData
} from '../../../data/gql/questionnaire/mutations/addMatrixResponseOption'
import { LoggerErrorType } from '../../../data/logger'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import { responseOptionLkNewlyAdded } from '../../../hooks/useResetNewlyCreatedEntry'
import { addMatrixQuestionResponseOptionTransactionDatadog } from '../../../tracking/perf/transactions'
import { captureApolloError } from '../../../utils/HelperFunctions'
import {
  responseOptionAdded,
  responseOptionCreated
} from '../Questionnaire.slice'

export const useAddMatrixResponseOption = () => {
  const dispatch = useAppDispatch()
  const questionnaireId = useDraftQuestionnaireIdCache()

  const [addMatrixResponseOption] = useMutation<
    AddMatrixResponseOptionData,
    AddMatrixResponseOptionMutationVariables
  >(ADD_MATRIX_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const handleAddMatrixResponseOption: (
    entryNumber: number,
    position: number,
    matrixTitleLk: string
  ) => void = (number, position, matrixTitleLk) => {
    addMatrixQuestionResponseOptionTransactionDatadog.start()
    dispatch(
      responseOptionAdded({
        questionLk: matrixTitleLk,
        position
      })
    )
    addMatrixResponseOption({
      variables: {
        questionnaireId,
        matrixTitleLk,
        position,
        responseOptionValue: ''
      },
      onCompleted: (data) => {
        dispatch(
          responseOptionCreated({
            questionLk: matrixTitleLk,
            position,
            responseOption: data.addMatrixResponseOption
          })
        )
        questionBeingEditedNumber(number)
        responseOptionLkNewlyAdded(
          data.addMatrixResponseOption.responseOptionLk
        )
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'addMatrixResponseOption',
          error
        )
      }
    })
  }

  return handleAddMatrixResponseOption
}
