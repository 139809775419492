import { useCallback, useContext, useState } from 'react'
import { useAppDispatch } from '../../../App.store'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import QuestionnaireContext, {
  closeQuestionTimerDialog
} from '../Questionnaire.context'
import { setQuestionSetting } from '../Questionnaire.slice'
import { getEntryId } from '../Questionnaire.utils'
import QuestionTimerDialogComponent from './QuestionTimerDialog.component'

// default is used for adding timer
const DEFAULT_TIMER_VALUE = '10'
// empty string, zero, or up to 3-digit integer
const VALID_TIMER_VALUE_REGEX = /^$|^0$|^[1-9]\d{0,2}?$/

interface Props {
  entry: DraftQuestionnaireEntry
}

const QuestionTimerDialog = ({ entry }: Props) => {
  const {
    dispatch: dispatchToContext,
    questionnaireState: { isQuestionTimerDialogOpened }
  } = useContext(QuestionnaireContext)

  const dispatch = useAppDispatch()

  const handleSettingChange = useSetQuestionnaireSetting(entry)

  const [timerValue, setTimerValue] = useState(DEFAULT_TIMER_VALUE)

  const handleCloseDialog = useCallback(() => {
    dispatchToContext(closeQuestionTimerDialog())
  }, [dispatchToContext])

  const handleSaveTimer = useCallback(() => {
    dispatch(
      setQuestionSetting({
        questionLk: getEntryId(entry),
        code: QuestionSettingCode.QuestionTimer,
        value: timerValue
      })
    )
    handleSettingChange(
      QuestionSettingCode.QuestionTimer,
      timerValue,
      'no-cache'
    )
    handleCloseDialog()
  }, [dispatch, entry, handleCloseDialog, handleSettingChange, timerValue])

  const handleChangeTimer = (value: string) => {
    if (VALID_TIMER_VALUE_REGEX.test(value)) {
      setTimerValue(value)
    }
  }

  if (!isQuestionTimerDialogOpened) {
    return null
  }

  return (
    <QuestionTimerDialogComponent
      countdownSeconds={timerValue}
      savingDisabled={false}
      onChange={handleChangeTimer}
      onClose={handleCloseDialog}
      onSave={handleSaveTimer}
    />
  )
}

// @todo Legacy eslint violation – fix this when editing
// eslint-disable-next-line react/display-name
export default () => {
  const {
    questionnaireState: { currentTimerEntryNumber }
  } = useContext(QuestionnaireContext)
  const { flattenedEntries } = useGetDraftQuestionnaire()
  const currentEntry = flattenedEntries.find((entry) => {
    return entry.number === currentTimerEntryNumber
  })
  return currentEntry ? <QuestionTimerDialog entry={currentEntry} /> : null
}
