import { gql } from '@apollo/client'
import { FieldworkAudience } from '../../../model/audience'
import DRAFT_AUDIENCE_MEMBER from '../fragments/draftAudienceMember'

export interface AddCustomCriterionData {
  addCustomCriterion: FieldworkAudience & {
    __typename: 'DraftFieldworkAudienceMember'
  }
}

export const ADD_CUSTOM_CRITERION = gql`
  mutation addCustomCriterion(
    $surveyId: ID!
    $position: Int!
    $text: String
    $setting: SettingInputInput
    $settings: [SettingInputInput!]
    $defaultOptionsCount: Int
    $skipIRSNotificationRecording: Boolean = false
  ) {
    recordAudienceChangedForIRSNotification
      @client(always: true)
      @skip(if: $skipIRSNotificationRecording)
    addCustomCriterion(
      surveyId: $surveyId
      position: $position
      text: $text
      setting: $setting
      settings: $settings
      defaultOptionsCount: $defaultOptionsCount
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`

export interface DetachCustomAudienceMemberData {
  detachCustomAudienceMember: {
    surveyId: string
    number: number
    detachedDate: Date
  }
}

export const DETACH_CUSTOM_AUDIENCE_MEMBER = gql`
  mutation detachCustomAudienceMember($surveyId: ID!, $number: Int!) {
    recordAudienceChangedForIRSNotification @client(always: true)
    detachCustomAudienceMember(surveyId: $surveyId, number: $number) {
      surveyId
      number
      detachedDate
    }
  }
`
