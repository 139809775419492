import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface EmptyMatrixQuestionData {
  emptyMatrixQuestion: DraftQuestionnaireEntry
}

export const EMPTY_MATRIX_QUESTION = gql`
  mutation emptyMatrixQuestion($questionnaireId: ID!, $matrixTitleLk: ID!) {
    emptyMatrixQuestion(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftMatrixItem {
          matrixTitleLk
          matrixTitle {
            ...MatrixTitle
          }
        }
      }
      __typename
    }
  }
`
