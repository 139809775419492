import { gql } from '@apollo/client/core'
import { AudiencePreset } from '../../../hooks/audience/usePresetAudience'

export interface NewPresetsData {
  getPresetAudiences: AudiencePreset[]
}

export const GET_PRESET_AUDIENCES = gql`
  query getPresetAudiences($locale: LocaleInput!) {
    getPresetAudiences(locale: $locale) {
      presetAudienceId
      name
      isWeighted
    }
  }
`

export const SET_PRESET_AUDIENCE = gql`
  mutation setPresetAudience($surveyId: ID!, $presetAudienceId: ID!) {
    setPresetAudience(surveyId: $surveyId, presetAudienceId: $presetAudienceId)
  }
`
