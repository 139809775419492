import {
  LinkButton,
  Logo,
  TextHighlight,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import useStyles from '../Auth.styles'
import SignupErrorText from './SignupErrorText'
import SignupForm from './SignupForm'
import { CustomError } from './useSignupForm'

interface Props {
  predefinedOrganisation?: string
  predefinedEmail?: string
  showPasswordInput?: boolean
  disableEmailInput?: boolean
  disableOrganisationInput?: boolean
  onClickPrivacyPolicy: () => void
  onClickTerms: () => void
  onGoToLogin: () => void
  onSignup: (
    firstName: string,
    lastName: string,
    email: string,
    organisation: string,
    password?: string
  ) => Promise<boolean | Error>
}

const Signup: React.FC<Props> = (props: Props) => {
  const {
    predefinedOrganisation,
    predefinedEmail,
    showPasswordInput,
    disableEmailInput = false,
    disableOrganisationInput = false,
    onSignup,
    onClickPrivacyPolicy,
    onClickTerms,
    onGoToLogin
  } = props
  const [signupError, setSignupError] = useState<CustomError | undefined>()

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid
      className={classes.page}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.maxWidth}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          className={classes.container}
        >
          <Logo width={180} />
          <Grid item className={classes.gridItem} style={{ marginTop: '16px' }}>
            <p className={classNames(textClasses.default, textClasses.sizeL)}>
              Create your account
            </p>
          </Grid>
          {!(predefinedEmail && predefinedOrganisation) && (
            <Grid item className={classes.gridItem}>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.sizeMs,
                  textClasses.alignCenter
                )}
              >
                If your team already has an account, then ask a team member to
                invite you.
              </p>
            </Grid>
          )}
          <SignupForm
            showPasswordInput={showPasswordInput}
            disableEmailInput={disableEmailInput}
            disableOrganisationInput={disableOrganisationInput}
            predefinedEmail={predefinedEmail}
            predefinedOrganisation={predefinedOrganisation}
            onSignup={onSignup}
            onSignupError={(e) => setSignupError(e)}
          />
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.gridItem}
          >
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeS,
                textClasses.highlightBackground
              )}
            >
              By clicking ‘Sign up’ you agree to Focaldata’s
            </p>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <LinkButton
                highlight={TextHighlight.Background}
                weight={TextWeight.Regular}
                size={TextSize.s}
                onClick={onClickTerms}
                underlined
              >
                Terms & Conditions
              </LinkButton>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.sizeS,
                  textClasses.highlightBackground
                )}
              >
                and
              </p>
              <LinkButton
                highlight={TextHighlight.Background}
                weight={TextWeight.Regular}
                size={TextSize.s}
                onClick={onClickPrivacyPolicy}
                underlined
              >
                Privacy Policy
              </LinkButton>
            </Grid>
          </Grid>
          <SignupErrorText error={signupError} />
          <Grid
            container
            justifyContent="center"
            className={classes.helperText}
          >
            <p className={classNames(textClasses.default)}>
              Already have an account?
            </p>
            <LinkButton
              size={TextSize.m}
              weight={TextWeight.Bold}
              onClick={onGoToLogin}
              noPadding
              underlined
            >
              Log in
            </LinkButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Signup
