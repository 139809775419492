import { gql } from '@apollo/client'
import DRAFT_QUALIFICATION from '../fragments/draftQualification'

export interface CustomQualificationData {
  setCustomQualification?: {
    surveyId: string
    questionLk: string
    responseOptionLk: string
    sinceDate: string
  }
}

export const SET_CUSTOM_QUALIFICATION = gql`
  mutation setCustomQualification(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    setCustomQualification(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
    ) {
      ...DraftQualification
    }
  }
  ${DRAFT_QUALIFICATION}
`

export const REMOVE_CUSTOM_QUALIFICATION = gql`
  mutation removeCustomQualification(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    removeCustomQualification(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
    ) {
      ...DraftQualification
    }
  }
  ${DRAFT_QUALIFICATION}
`
