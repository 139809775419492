import { gql } from '@apollo/client/core'

const DRAFT_ROUTE = gql`
  fragment DraftRoute on DraftRoute {
    questionnaireId
    questionLk
    responseOptionLk
  }
`

export default DRAFT_ROUTE
