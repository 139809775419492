import { useMutation } from '@apollo/client'
import {
  RemoveCustomCriterionOptionMediaMutationVariables,
  SetCustomCriterionOptionMediaMutationVariables
} from '../../../data/gql-gen/fieldwork/graphql'
import { LoggerErrorType } from '../../../data/logger'
import { captureApolloError } from '../../../utils/HelperFunctions'
import {
  REMOVE_CUSTOM_CRITERION_OPTION_MEDIA,
  SET_CUSTOM_CRITERION_OPTION_MEDIA,
  SetCustomCriterionOptionMediaData
} from './CustomCriterionOption.mutations'

export const useCustomCriterionOptionMediaActions = () => {
  const [setCustomCriterionOptionMedia] = useMutation<
    SetCustomCriterionOptionMediaData,
    SetCustomCriterionOptionMediaMutationVariables
  >(SET_CUSTOM_CRITERION_OPTION_MEDIA, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addResponseOptionImage',
        error
      )
    }
  })

  const [removeCustomCriterionOptionMedia] = useMutation<
    void,
    RemoveCustomCriterionOptionMediaMutationVariables
  >(REMOVE_CUSTOM_CRITERION_OPTION_MEDIA, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeResponseOptionImage',
        error
      )
    }
  })

  return {
    setCustomCriterionOptionMedia,
    removeCustomCriterionOptionMedia
  }
}
