import { gql } from '@apollo/client'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface RemoveQuestionnaireEntryForkBranchData {
  removeQuestionnaireEntryForkBranch: DraftQuestionnaireEntry
}

const REMOVE_QUESTIONNAIRE_ENTRY_FORK_BRANCH = gql`
  mutation removeQuestionnaireEntryForkBranch(
    $questionnaireId: ID!
    $number: Int!
    $forkId: ID!
    $branchNumber: Int!
  ) {
    removeQuestionnaireEntryForkBranch(
      questionnaireId: $questionnaireId
      number: $number
      forkId: $forkId
      branchNumber: $branchNumber
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`

export default REMOVE_QUESTIONNAIRE_ENTRY_FORK_BRANCH
