import { gql } from '@apollo/client'

export interface PinResponseOptionData {
  pinOrUnpinResponseOption: {
    responseOptionLk: string
    createdDate: string
    pinned: boolean
    __typename: string
  }
}

export const PIN_RESPONSE_OPTION = gql`
  mutation pinOrUnpinResponseOption(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $pinned: Boolean!
  ) {
    pinOrUnpinResponseOption(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      pinned: $pinned
    ) {
      responseOptionLk
      createdDate
      pinned
      __typename
    }
  }
`

export interface PinMatrixResponseOptionData {
  pinOrUnpinMatrixResponseOption: {
    responseOptionLk: string
    createdDate: string
    pinned: boolean
    __typename: string
  }
}

export const PIN_MATRIX_RESPONSE_OPTION = gql`
  mutation pinOrUnpinMatrixResponseOption(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $responseOptionLk: ID!
    $pinned: Boolean!
  ) {
    pinOrUnpinMatrixResponseOption(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      responseOptionLk: $responseOptionLk
      pinned: $pinned
    ) {
      responseOptionLk
      createdDate
      pinned
      __typename
    }
  }
`

export interface PinMatrixRowData {
  pinOrUnpinMatrixRow: {
    questionLk: string
    createdDate: string
    pinned: boolean
    __typename: string
  }
}

export const PIN_MATRIX_ROW = gql`
  mutation pinOrUnpinMatrixRow(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionLk: ID!
    $pinned: Boolean!
  ) {
    pinOrUnpinMatrixRow(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionLk: $questionLk
      pinned: $pinned
    ) {
      questionLk
      createdDate
      pinned
      __typename
    }
  }
`
