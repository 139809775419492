import { gql } from '@apollo/client/core'

export const UPDATE_FIELDWORK = gql`
  mutation updateFieldwork(
    $surveyId: ID!
    $panelSupplierCode: String!
    $lengthOfInterview: Int
    $startDate: Instant
    $endDate: Instant
    $samplesNeeded: Int
    $countryCode: String
    $countryLanguage: String
    $incidenceRate: Int
    $audienceDescription: String
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    updateFieldwork(
      surveyId: $surveyId
      panelSupplierCode: $panelSupplierCode
      lengthOfInterview: $lengthOfInterview
      startDate: $startDate
      endDate: $endDate
      samplesNeeded: $samplesNeeded
      countryCode: $countryCode
      countryLanguage: $countryLanguage
      incidenceRate: $incidenceRate
      audienceDescription: $audienceDescription
    )
  }
`
