import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { DuplicateQuestionMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import {
  DUPLICATE_QUESTION,
  DuplicateQuestionData
} from '../../../data/gql/questionnaire/mutations/duplicateQuestion'
import { LoggerErrorType } from '../../../data/logger'
import { DraftQuestionItem } from '../../../data/model/questionnaire'
import useCopyPasteComplete from '../../../hooks/copyPaste/useCopyPasteComplete'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { useSurveyId } from '../../../hooks/useSurveyId'
import BasicQuestionLayout from '../../../layouts/BasicQuestionLayout'
import {
  duplicateQuestionTransactionDatadog,
  pasteInTitleTransactionDatadog
} from '../../../tracking/perf/transactions'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { WithEntry, newEntryId } from '../../../utils/questionnaireUtils'
import { BasicEntryContext } from '../BasicQuestion/BasicQuestion.container'
import Footer from '../BasicQuestion/QuestionCardFooter/QuestionCardFooter.container'
import { ResponseOptionsListContainer } from '../BasicQuestion/ResponseOptions'
import { CardTitleContainer } from '../CardTitle'
import { scrollToDuplicatedBasicQuestionCard } from '../QuestionCommon/QuestionCommon.utils'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import MaxDiffSpecs from './MaxDiffSpecs'

interface Props extends WithEntry {
  shouldTitleInputFocus: boolean
}

const MaxDiffQuestionContainer: React.FC<Props> = ({
  entry,
  shouldTitleInputFocus
}: Props) => {
  const surveyId = useSurveyId()
  const entryItem = entry.entryItem as DraftQuestionItem
  const { questionLk } = entryItem
  const { draftQuestionnaireEntries, refetchQuestionnaire } =
    useGetDraftQuestionnaire()
  const questionnaireId = useDraftQuestionnaireIdCache()
  const { pasteToTitle } = useCopyPasteComplete(questionLk)
  const { validateBasicQuestionText } = useQuestionnaireValidation()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )
  const isBasicQuestionTextEmpty = validateBasicQuestionText(
    entryItem.questionLk
  )
  const newlyCreatedEntryId = useReactiveVar(newEntryId)
  const isNewlyCreatedEntry =
    !!newlyCreatedEntryId && newlyCreatedEntryId === entryItem.questionLk
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasEmptyQuestionTitle = entryItem.question === null

  const [duplicateQuestionMutation] = useMutation<
    DuplicateQuestionData,
    DuplicateQuestionMutationVariables
  >(DUPLICATE_QUESTION, {
    context: { clientName: 'questionnaire' }
  })

  // TODO: this can also be completely moved to a hook
  const handleDuplicateQuestion: () => void = useCallback(() => {
    duplicateQuestionTransactionDatadog.start()
    duplicateQuestionMutation({
      variables: {
        questionnaireId,
        questionLk: entryItem.questionLk,
        position: entry.position + 1
      },
      onCompleted: async ({ duplicateQuestion }) => {
        await refetchQuestionnaire()
        scrollToDuplicatedBasicQuestionCard(duplicateQuestion.number.toString())
        questionBeingEditedNumber(undefined)
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloQuery,
          'duplicateQuestionMutation',
          error
        )
      }
    })
    LogAmplitudeEvent(EventType.DuplicatedQuestion, {
      surveyId,
      questionnaireId
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.position, entryItem.questionLk, questionnaireId])

  const handlePasteInTitle = async (
    text: string,
    styledText: string,
    responseOptions: string[]
  ) => {
    pasteInTitleTransactionDatadog.start()
    questionBeingEditedNumber(entry.number)
    await pasteToTitle(text, styledText, responseOptions)
  }

  return (
    <BasicEntryContext.Provider value={entryItem}>
      <BasicQuestionLayout
        entryNumber={entry.number}
        questionCardHeader={
          <SurveyQuestionCardHeaderContainer
            entry={entry}
            hasError={!isNewlyCreatedEntry && isBasicQuestionTextEmpty}
            titleContent={
              <CardTitleContainer
                // for max-diff question when multilined pasting occur only first line will be pasted, the rest will be handled by "smart pasting"
                shouldPasteOneLine
                ariaLabel="MaxDiff question header"
                entryNumber={entry.number}
                questionLk={entryItem.questionLk}
                hasError={!isNewlyCreatedEntry && isBasicQuestionTextEmpty}
                questionTypeCode={entryItem.questionTypeCode}
                shouldTitleInputFocus={shouldTitleInputFocus}
                onPasteInTitle={handlePasteInTitle}
              />
            }
            onClickDuplicateIcon={handleDuplicateQuestion}
            disabledDuplicate={hasEmptyQuestionTitle}
            entries={flatDraftQuestionnaireEntries}
          />
        }
        responseOptions={
          <ResponseOptionsListContainer
            entries={flatDraftQuestionnaireEntries}
            currentEntry={entry}
          />
        }
        questionCardFooter={<Footer entry={entry} />}
        defaultOptions={<MaxDiffSpecs entry={entry} />}
      />
    </BasicEntryContext.Provider>
  )
}

export default MaxDiffQuestionContainer
