import { gql } from '@apollo/client/core'
import { MemberSettingValue } from '../../../model/audience'
import MEMBER_SETTING_VALUE from '../fragments/memberSettingValue'

export interface SetCustomMemberSettingValueData {
  setCustomMemberSettingValue: MemberSettingValue
}

export const SET_CUSTOM_MEMBER_SETTING_VALUE = gql`
  mutation setCustomMemberSettingValue(
    $surveyId: ID!
    $questionLk: ID!
    $setting: SettingInputInput!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    setCustomMemberSettingValue(
      surveyId: $surveyId
      questionLk: $questionLk
      setting: $setting
    ) {
      ...MemberSettingValue
    }
  }
  ${MEMBER_SETTING_VALUE}
`

export interface RemoveCustomMemberSettingValueData {
  removeCustomMemberSettingValue: MemberSettingValue
}

export const REMOVE_CUSTOM_MEMBER_SETTING_VALUE = gql`
  mutation removeCustomMemberSettingValue(
    $surveyId: ID!
    $questionLk: ID!
    $memberSettingCode: String!
  ) {
    removeCustomMemberSettingValue(
      surveyId: $surveyId
      questionLk: $questionLk
      memberSettingCode: $memberSettingCode
    ) {
      ...MemberSettingValue
    }
  }
  ${MEMBER_SETTING_VALUE}
`
