import { gql } from '@apollo/client'

const DRAFT_STANDARD_QUALIFICATION = gql`
  fragment DraftStandardQualification on DraftStandardQualification {
    surveyId
    audienceCriterionCode
    audienceCriterionOptionCode
    createDate
    sinceDate
  }
`

export default DRAFT_STANDARD_QUALIFICATION
