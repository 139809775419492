import { questionnaireGql } from '../../../gql-gen/questionnaire'

const MOVE_MATRIX_QUESTION_ROW = questionnaireGql(/* GraphQL */ `
  mutation moveMatrixQuestionRow(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionLk: ID!
    $toPosition: Int!
  ) {
    moveMatrixQuestionRow(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionLk: $questionLk
      toPosition: $toPosition
    ) {
      ...DraftMatrixRow
    }
  }
`)

export default MOVE_MATRIX_QUESTION_ROW
