import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ImExEntryDtoInput } from '../../../data/gql-gen/questionnaire/graphql'
import { SurveyParams } from '../../../data/model/surveyParams'
import useImportQuestionnaire from '../../../hooks/import/import.hooks'
import { RawQuestionnaireEntryInput } from '../../../hooks/import/models'
import { useFieldworkData } from '../../Audience/Audience.hooks'
import QuestionnaireContext, {
  closeImportDialog
} from '../Questionnaire.context'

export const useDialogHooks = (resetPreview: () => void) => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)
  const [isImportButtonDisabled, setIsImportButtonDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const { fieldworkData } = useFieldworkData()
  const { importQuestionnaire } = useImportQuestionnaire()

  const handleDialogClose = () => {
    dispatch(closeImportDialog())
    resetPreview()
  }

  const handleDialogSave = (entries: RawQuestionnaireEntryInput[]) => {
    importQuestionnaire({
      variables: {
        surveyId,
        // @todo legacy type error – fix this when updating
        entries: entries as unknown as ImExEntryDtoInput[],
        locale: fieldworkData?.locale
      }
    })
    handleDialogClose()
  }

  return {
    isDialogOpen: questionnaireState.isImportDialogOpened,
    isImportButtonDisabled,
    setIsImportButtonDisabled,
    handleDialogClose,
    isLoading,
    setIsLoading,
    handleDialogSave
  }
}
