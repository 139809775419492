import { useMutation } from '@apollo/client'
import { ClipboardEvent } from 'react'
import {
  PasteInAudienceTitleMutationVariables,
  PasteInCriterionOptionsMutationVariables
} from '../../../data/gql-gen/fieldwork/graphql'
import {
  PASTE_IN_AUDIENCE_TITLE,
  PasteInAudienceTitleData
} from '../../../data/gql/fieldwork/mutations/pasteInAudienceTitle'
import {
  PASTE_IN_CRITERION_OPTIONS,
  PasteInCriterionOptionsData
} from '../../../data/gql/fieldwork/mutations/pasteInCriterionOptions'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { DraftCustomAudience } from '../../../data/model/audience'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { IsNonManualUIChange } from '../../../hooks/copyPaste/useCopyPasteComplete'
import {
  replacingTextAt,
  splitTextIntoLinesAndCleanup
} from '../../../hooks/copyPaste/utils'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'

export const usePasteInAudienceTitle = (questionLk?: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [pasteInAudienceTitle] = useMutation<
    PasteInAudienceTitleData,
    PasteInAudienceTitleMutationVariables
  >(PASTE_IN_AUDIENCE_TITLE, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
  })

  return async (text: string, responseOptions: string[]) => {
    if (!questionLk) {
      return
    }
    await pasteInAudienceTitle({
      variables: {
        surveyId,
        questionLk,
        text,
        responseOptions
      }
    })

    IsNonManualUIChange(true)
  }
}

export const usePasteInCriterionOptions = (
  questionLk: string,
  startPosition: number
) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [pasteInCriterionOptions] = useMutation<
    PasteInCriterionOptionsData,
    PasteInCriterionOptionsMutationVariables
  >(PASTE_IN_CRITERION_OPTIONS, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
  })

  const { draftAudience } = useGetDraftAudience()

  return async (event: ClipboardEvent<HTMLInputElement>) => {
    const { criterionOptions } = draftAudience?.members.find(
      (m) => (m.memberItem as DraftCustomAudience).questionLk === questionLk
    )?.memberItem as DraftCustomAudience

    const text = event.clipboardData.getData('text')
    const { selectionStart, selectionEnd } = event.target as HTMLTextAreaElement

    const responseOptionTexts = splitTextIntoLinesAndCleanup(text)
    const responseOptionToBeUpdated =
      criterionOptions[startPosition].responseOption

    const updatedRoValue = replacingTextAt(
      responseOptionToBeUpdated.value,
      responseOptionTexts[0],
      selectionStart,
      selectionEnd
    )

    responseOptionTexts[0] = updatedRoValue

    await pasteInCriterionOptions({
      variables: {
        surveyId,
        questionLk,
        responseOptions: responseOptionTexts,
        startPosition
      }
    })
  }
}
