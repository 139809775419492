import { Grid, PaperGroupItem } from '@focaldata/cin-ui-components'
import React from 'react'
import { CardBackgroundContainer } from '../../modules/Questionnaire/CardBackground'

interface Props {
  questionCardHeader: JSX.Element
  switches: JSX.Element
  sliderInputs: JSX.Element
  defaultOptions: JSX.Element | null
  sliderLabels?: JSX.Element | null
  showLabels?: boolean
  entryNumber: number
}

const SliderQuestionLayout: React.FC<Props> = (props: Props) => {
  const {
    questionCardHeader,
    switches,
    sliderInputs,
    sliderLabels,
    showLabels,
    defaultOptions,
    entryNumber
  } = props

  return (
    <Grid container>
      <CardBackgroundContainer entryNumber={entryNumber}>
        <PaperGroupItem>{questionCardHeader}</PaperGroupItem>
        <PaperGroupItem paddingTopBottom={2}>{switches}</PaperGroupItem>
        <PaperGroupItem paddingTopBottom={2}>{sliderInputs}</PaperGroupItem>
        {showLabels && (
          <PaperGroupItem paddingTopBottom={2}>{sliderLabels}</PaperGroupItem>
        )}
        {defaultOptions && (
          <PaperGroupItem paddingTopBottom={2}>{defaultOptions}</PaperGroupItem>
        )}
      </CardBackgroundContainer>
    </Grid>
  )
}

export default SliderQuestionLayout
