import { useFragment, useMutation, useReactiveVar } from '@apollo/client'
import debounce from 'lodash/debounce'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { questionBeingEditedNumber } from '../../../../apollo/apolloClient'
import { UpdateMatrixQuestionMutationVariables } from '../../../../data/gql-gen/questionnaire/graphql'
import { MATRIX_TITLE } from '../../../../data/gql/questionnaire/fragments/draftMatrixItem'
import UPDATE_MATRIX_QUESTION, {
  UpdateMatrixQuestionData
} from '../../../../data/gql/questionnaire/mutations/updateMatrixQuestion'
import { LoggerErrorType } from '../../../../data/logger'
import useDraftQuestionnaireIdCache from '../../../../hooks/localState/useDraftQuestionnaireIdCache'
import { questionCardEmptiedVar } from '../../../../hooks/questionnaire/useEmptyQuestionCard'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import { CardTitleControl } from '../../CardTitle'
import { getTextFromRawTitleStyling } from '../../CardTitle/CardTitle.utils'
import { pipingMarkerAddedVar } from '../../PipingDialog/PipingDialog.hooks'

interface Props {
  matrixTitleLk: string
  ariaLabel: string
  shouldTitleInputFocus?: boolean
  entryNumber?: number
  hasError?: boolean
  placeholder?: string
  helperText?: string
}

const MatrixCardTitleContainer = ({
  matrixTitleLk,
  entryNumber,
  ariaLabel = 'Question header input',
  shouldTitleInputFocus,
  hasError,
  placeholder = 'Type your question',
  helperText = 'Please add a question title'
}: Props) => {
  const questionnaireId = useDraftQuestionnaireIdCache()

  const { data: matrixTitleData } = useFragment({
    fragment: MATRIX_TITLE,
    fragmentName: 'MatrixTitle',
    from: { __typename: 'MatrixTitle', matrixTitleId: matrixTitleLk },
    canonizeResults: true
  })

  const [titleStyling, setTitleStyling] = useState(matrixTitleData.titleStyling)

  const pipingMarkerAdded = useReactiveVar(pipingMarkerAddedVar)
  const questionCardEmptied = useReactiveVar(questionCardEmptiedVar)

  useEffect(() => {
    if (pipingMarkerAdded[matrixTitleLk]) {
      pipingMarkerAddedVar({ [matrixTitleLk]: false })
      setTitleStyling(matrixTitleData.titleStyling)
    } else if (questionCardEmptied[matrixTitleLk]) {
      questionCardEmptiedVar({ [matrixTitleLk]: false })
      setTitleStyling(matrixTitleData.titleStyling)
    }
  }, [
    matrixTitleData.titleStyling,
    matrixTitleLk,
    pipingMarkerAdded,
    questionCardEmptied
  ])

  const [updateMatrixQuestion] = useMutation<
    UpdateMatrixQuestionData,
    UpdateMatrixQuestionMutationVariables
  >(UPDATE_MATRIX_QUESTION, {
    context: { clientName: 'questionnaire' }
  })
  const handleUpdateMatrixTitle = useCallback(
    (rawTitleStyling: string): void => {
      updateMatrixQuestion({
        variables: {
          questionnaireId,
          matrixTitleLk,
          title: getTextFromRawTitleStyling(rawTitleStyling),
          titleStyling: rawTitleStyling
        },
        onCompleted: () => {
          questionBeingEditedNumber(entryNumber)
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'updateMatrixQuestion',
            error
          )
        }
      })
    },
    [entryNumber, matrixTitleLk, questionnaireId, updateMatrixQuestion]
  )

  const handleOnFocus = () => {
    questionBeingEditedNumber(entryNumber)
  }

  const handleOnEditorChangeDebounced = useMemo(
    () =>
      debounce((newRawTitleStyling: string) => {
        handleUpdateMatrixTitle(newRawTitleStyling)
      }, 1000),
    [handleUpdateMatrixTitle]
  )

  const handleOnEditorChange = (newRawTitleStyling: string) => {
    setTitleStyling(newRawTitleStyling)
    handleOnEditorChangeDebounced(newRawTitleStyling)
  }

  return (
    <CardTitleControl
      hasEditor
      shouldHandlePasteManually
      titleValue={matrixTitleData.title}
      titleStyling={titleStyling ?? undefined}
      ariaLabel={ariaLabel}
      shouldTitleInputFocus={shouldTitleInputFocus}
      hasError={hasError}
      placeholder={placeholder}
      helperText={helperText}
      onFocus={handleOnFocus}
      onEditorChange={handleOnEditorChange}
    />
  )
}

export default memo(MatrixCardTitleContainer)
