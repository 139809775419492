import {
  Grid,
  IconButton,
  IconName,
  InfoIcon,
  InfoIconColor,
  Input,
  InputVariant,
  Switch,
  TooltipPlacement
} from '@focaldata/cin-ui-components'
import { ChangeEvent, useState } from 'react'
import { NumberFormatCustom } from '../../../utils/NumberFormatCustom'
import { isFocaldataUser } from '../../../utils/authUtils'
import useStyles from './AudienceQuestionCardFooter.styles'
import { MaxDisplayedOptions } from './MaxDisplayedOptions'

export const CARD_FOOTER_ROW_HEIGHT = 40

/**
 * Replace every prop with an optional never – this prevents that prop being
 * passed, while still allowing it to be destructured in the component itself
 */
type WithoutProps<T> = { [Property in keyof T]?: never }

type HardDisqualificationProps = {
  isHardDisqualification: boolean
  onIsHardDisqualificationChange: (isHardDisqualification: boolean) => void
}

type MultiChoiceProps = {
  qualifyingResponsesCount?: number
  screeningQuestionResponseLimit?: number
  onSetScreeningQuestionResponseLimit?: (limit: number) => void
  onToggleOffScreeningQuestionResponseLimit?: () => void
}

/**
 * Attempting to model the different 'modes' of this component, but quite
 * difficult to do because the component does too much. We shouldn't have so
 * many optional props.
 */
type AudienceQuestionCardFooterProps = {
  questionLk?: string
  defaultQuotasState?: boolean
  showQuotaSumSpread?: boolean
  isSumQuotasButtonDisabled?: boolean
  defaultAddedToSurveyState?: boolean
  onToggleQuotasState?: () => void
  onSumQuotas?: () => void
  onToggleAddedToSurveyState?: () => void
} & (
  | ({
      isSingleChoice: true
      isTargeting?: boolean
    } & WithoutProps<HardDisqualificationProps> &
      WithoutProps<MultiChoiceProps>)
  | ({
      isSingleChoice: false
    } & MultiChoiceProps &
      (
        | ({ isTargeting?: false } & HardDisqualificationProps)
        | ({ isTargeting: true } & WithoutProps<HardDisqualificationProps>)
      ))
)

/**
 * @todo this component does too many distinct things – split it up
 */
const AudienceQuestionCardFooter = ({
  questionLk,
  qualifyingResponsesCount,
  screeningQuestionResponseLimit,
  defaultQuotasState,
  defaultAddedToSurveyState,
  showQuotaSumSpread,
  isSumQuotasButtonDisabled,
  isTargeting = false,
  isHardDisqualification = false,
  onIsHardDisqualificationChange,
  isSingleChoice,
  onToggleQuotasState,
  onToggleOffScreeningQuestionResponseLimit,
  onSetScreeningQuestionResponseLimit,
  onSumQuotas,
  onToggleAddedToSurveyState
}: AudienceQuestionCardFooterProps) => {
  const { classes } = useStyles()

  const [displayQuestionLimitInput, setDisplayQuestionLimitInput] = useState(
    !!screeningQuestionResponseLimit
  )

  const isThresholdValid = (threshold: number) =>
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    threshold !== undefined &&
    qualifyingResponsesCount !== undefined &&
    threshold <= qualifyingResponsesCount &&
    threshold >= 0

  const handleToggleScreeningQuestionResponseLimit = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setDisplayQuestionLimitInput(true)
    } else {
      setDisplayQuestionLimitInput(false)
      onToggleOffScreeningQuestionResponseLimit?.()
    }
  }

  const handleLimitInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newInput = Number(event.target.value)
    if (Number.isNaN(newInput) || newInput < 0) {
      return
    }

    if (newInput === 0) {
      onToggleOffScreeningQuestionResponseLimit?.()
      return
    }

    onSetScreeningQuestionResponseLimit?.(newInput)
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        minHeight={CARD_FOOTER_ROW_HEIGHT}
      >
        <Grid className={classes.infoIcon}>
          {isSingleChoice ? (
            <Switch
              first
              label="Quotas"
              defaultChecked={defaultQuotasState}
              onChange={onToggleQuotasState}
            />
          ) : (
            <>
              {!isTargeting && isFocaldataUser() ? (
                <Switch
                  first
                  label="Hard disqualification"
                  defaultChecked={isHardDisqualification}
                  onChange={(evt) => {
                    // Typescript doesn't know onIsHardDisqualificationChange will
                    // always exist as our props type has more than one
                    // discriminant. This is sadly a limitation of TS.
                    onIsHardDisqualificationChange?.(evt.target.checked)
                  }}
                />
              ) : null}
              <Switch
                first={!isFocaldataUser()}
                label="Minimum qualifying responses"
                defaultChecked={!!screeningQuestionResponseLimit}
                onChange={handleToggleScreeningQuestionResponseLimit}
              />
              {displayQuestionLimitInput && (
                <>
                  <Input
                    type="number"
                    defaultValue={screeningQuestionResponseLimit || 0}
                    variant={InputVariant.Outlined}
                    width={50}
                    isError={
                      !isThresholdValid(screeningQuestionResponseLimit || 0)
                    }
                    helperText=""
                    onChange={handleLimitInputChange}
                    customFormat={NumberFormatCustom}
                  />
                  {!isThresholdValid(screeningQuestionResponseLimit || 0) && (
                    <InfoIcon
                      tooltipPlacement={TooltipPlacement.Top}
                      text="Threshold should be lower than/equal to the total number of qualifying response options."
                      iconColor={InfoIconColor.Error}
                    />
                  )}
                </>
              )}
            </>
          )}
          {isTargeting && (
            <>
              <Switch
                defaultChecked={defaultAddedToSurveyState}
                onChange={onToggleAddedToSurveyState}
                first
                last
                label="Add survey question"
              />
              <InfoIcon text="Add this targeting criterion as a question in your survey. If added, you will be able to monitor quota performance and filter results by this variable." />
            </>
          )}
        </Grid>
        {showQuotaSumSpread && (
          <IconButton
            iconName={IconName.FormatLineSpacingIcon}
            className={classes.iconButton}
            ariaLabel="Sum quotas to 100%"
            text="Sum quotas to 100%"
            onClick={onSumQuotas}
            disabled={isSumQuotasButtonDisabled}
          />
        )}
      </Grid>

      {!isTargeting && isFocaldataUser() && questionLk ? (
        <MaxDisplayedOptions questionLk={questionLk} />
      ) : null}
    </>
  )
}

export default AudienceQuestionCardFooter
