import {
  Button,
  ButtonVariant,
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import React from 'react'
import useStyles from './EmptyState.style'

interface Props extends React.HTMLAttributes<HTMLElement> {
  title?: string
  subtitle?: string
  buttonText?: string
  iconName: IconName
  onClick?: () => void
  hasElevation?: boolean
}

const EmptyState: React.FC<Props> = (props: Props) => {
  const {
    onClick,
    style,
    title,
    subtitle,
    buttonText,
    iconName,
    hasElevation
  }: Props = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Paper
      className={classes.emptyPaperStyle}
      style={style}
      elevation={hasElevation ? 1 : 0}
    >
      <Grid
        container
        className={classes.emptyOuterDiv}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          className={classes.emptyInnerDiv}
          spacing={2}
        >
          <Grid item xs={12}>
            <Icon
              name={iconName}
              size={IconSize.Large}
              iconColor={IconColor.Background}
            />
          </Grid>
          <Grid container item xs={12} direction="column" alignItems="center">
            <Grid item xs={12}>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.highlightBackground,
                  textClasses.sizeL,
                  textClasses.weightRegular
                )}
              >
                {title}
              </p>
            </Grid>
            <Grid item xs={12}>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.highlightBackground,
                  textClasses.sizeM,
                  textClasses.weightRegular
                )}
              >
                {subtitle}
              </p>
            </Grid>
          </Grid>
          {buttonText && (
            <Grid item xs={12}>
              <Button variant={ButtonVariant.PrimaryOutlined} onClick={onClick}>
                {buttonText}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default EmptyState
