import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../App.store'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../data/model/questionnaire'
import { SurveyParams } from '../../data/model/surveyParams'
import {
  removeQuestionSetting,
  setQuestionSetting
} from '../../modules/Questionnaire/Questionnaire.slice'
import { getEntryId } from '../../modules/Questionnaire/Questionnaire.utils'
import useRemoveQuestionnaireValue from './useRemoveQuestionnaireValue'
import useSetQuestionnaireSetting from './useSetQuestionnaireSetting'

interface UseHandleSettingsValueChange {
  handleIntervalSettingsChange: (min: number, max: number) => void
  handleQuestionLimitChange: (
    settingCode: QuestionSettingCode,
    settingValue: number
  ) => void
}

const useHandleMultiSelectLimits: (
  entry: DraftQuestionnaireEntry
) => UseHandleSettingsValueChange = (entry) => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const handleSettingChange = useSetQuestionnaireSetting(entry)
  const removeQuestionnaireSetting = useRemoveQuestionnaireValue(entry)
  const dispatch = useAppDispatch()
  const entryId = getEntryId(entry)

  const changeSettingValue = async (
    code: QuestionSettingCode,
    value: string
  ) => {
    dispatch(
      setQuestionSetting({
        questionLk: entryId,
        code,
        value
      })
    )
    await handleSettingChange(code, value, 'no-cache')
  }

  const handleIntervalSettingsChange = (min: number, max: number) => {
    if (min === 0 && max === 0) {
      removeQuestionnaireSetting(
        QuestionSettingCode.ChoiceIntervalMin,
        'no-cache'
      )
      removeQuestionnaireSetting(
        QuestionSettingCode.ChoiceIntervalMax,
        'no-cache'
      )
      dispatch(
        removeQuestionSetting({
          questionLk: entryId,
          settingCode: QuestionSettingCode.ChoiceIntervalMin
        })
      )
      dispatch(
        removeQuestionSetting({
          questionLk: entryId,
          settingCode: QuestionSettingCode.ChoiceIntervalMax
        })
      )
    } else {
      changeSettingValue(QuestionSettingCode.ChoiceIntervalMin, min.toString())
      changeSettingValue(QuestionSettingCode.ChoiceIntervalMax, max.toString())
      LogAmplitudeEvent(EventType.AddChoiceResponseRange, {
        surveyId,
        entryId,
        range: `${min}-${max}`
      })
    }
  }

  const handleQuestionLimitChange: (
    settingCode: QuestionSettingCode,
    settingValue: number
  ) => void = (settingCode, settingValue) => {
    if (settingValue === 0) {
      removeQuestionnaireSetting(settingCode, 'no-cache')
      dispatch(removeQuestionSetting({ questionLk: entryId, settingCode }))
    } else {
      changeSettingValue(settingCode, settingValue.toString())
    }
  }
  return { handleIntervalSettingsChange, handleQuestionLimitChange }
}

export default useHandleMultiSelectLimits
