import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../App.store'
import {
  PasteInMatrixResponsesMutationVariables,
  PasteInMatrixRowsMutationVariables,
  PasteInResponsesMutationVariables,
  PasteInTitleMutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import {
  PASTE_IN_MATRIX_RESPONSES,
  PASTE_IN_MATRIX_ROWS,
  PASTE_IN_RESPONSES,
  PasteInMatrixResponsesData,
  PasteInMatrixRowsData,
  PasteInResponsesData
} from '../../data/gql/questionnaire/mutations/pasteInResponses'
import {
  PASTE_IN_TITLE,
  PasteInTitleData
} from '../../data/gql/questionnaire/mutations/pasteInTitle'
import { DraftQuestionItem } from '../../data/model/questionnaire'
import { SurveyParams } from '../../data/model/surveyParams'
import { responseOptionCreated } from '../../modules/Questionnaire/Questionnaire.slice'

export const usePasteMutations = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const dispatch = useAppDispatch()
  const [pasteInTitle] = useMutation<
    PasteInTitleData,
    PasteInTitleMutationVariables
  >(PASTE_IN_TITLE, {
    context: { clientName: 'questionnaire' }
  })

  const [pasteInResponses] = useMutation<
    PasteInResponsesData,
    PasteInResponsesMutationVariables
  >(PASTE_IN_RESPONSES, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const [pasteInMatrixResponses] = useMutation<
    PasteInMatrixResponsesData,
    PasteInMatrixResponsesMutationVariables
  >(PASTE_IN_MATRIX_RESPONSES, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const [pasteInMatrixRows] = useMutation<
    PasteInMatrixRowsData,
    PasteInMatrixRowsMutationVariables
  >(PASTE_IN_MATRIX_ROWS, {
    context: { clientName: 'questionnaire' }
  })

  const pasteInTitleMutation = useCallback(
    (
      questionLk: string,
      text: string,
      textStyling: string,
      responseOptions: string[]
    ): void => {
      pasteInTitle({
        variables: {
          questionnaireId: surveyId,
          questionLk,
          text,
          textStyling,
          responseOptions
        },
        onCompleted: (data) => {
          const entryItem = data.pasteInTitle.entryItem as DraftQuestionItem
          entryItem.responseOptions.forEach((response) => {
            dispatch(
              responseOptionCreated({
                questionLk,
                position: response.position,
                responseOption: response
              })
            )
          })
        }
      })
    },
    [pasteInTitle, surveyId, dispatch]
  )

  const pasteInResponsesMutation = useCallback(
    (questionLk: string, responseOptions: string[], position: number): void => {
      pasteInResponses({
        variables: {
          questionnaireId: surveyId,
          questionLk,
          responseOptions,
          position
        },
        onCompleted: (data) => {
          const entryItem = data.pasteInResponses.entryItem as DraftQuestionItem
          entryItem.responseOptions.forEach((response) => {
            dispatch(
              responseOptionCreated({
                questionLk,
                position: response.position,
                responseOption: response
              })
            )
          })
        }
      })
    },
    [pasteInResponses, surveyId, dispatch]
  )

  const pasteInMatrixResponsesMutation = useCallback(
    (
      matrixTitleLk: string,
      responseOptions: string[],
      position: number
    ): void => {
      pasteInMatrixResponses({
        variables: {
          questionnaireId: surveyId,
          matrixTitleLk,
          responseOptions,
          position
        },
        onCompleted: (data) => {
          const entryItem = data.pasteInMatrixResponses.entryItem
          entryItem.responseOptions.forEach((response) => {
            dispatch(
              responseOptionCreated({
                questionLk: matrixTitleLk,
                position: response.position,
                responseOption: response
              })
            )
          })
        }
      })
    },
    [pasteInMatrixResponses, surveyId, dispatch]
  )

  const pasteInMatrixRowsMutation = useCallback(
    (matrixTitleLk: string, rows: string[], position: number): void => {
      pasteInMatrixRows({
        variables: {
          questionnaireId: surveyId,
          matrixTitleLk,
          rows,
          position
        }
      })
    },
    [pasteInMatrixRows, surveyId]
  )

  return {
    pasteInTitleMutation,
    pasteInResponsesMutation,
    pasteInMatrixResponsesMutation,
    pasteInMatrixRowsMutation
  }
}
