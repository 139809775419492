import { ResponseOptionsLogic } from '../gql-gen/fieldwork/graphql'
import { ResponseOptionMedia } from './questionnaire'
import { ResponseOptionType } from './shared'

export enum EnabledState {
  Disabled = 'Disabled',
  Enabled = 'Enabled'
}

export type FieldworkAudience = {
  number: number
  position: number
  contextPosition: number
  isWeighted: boolean
} & (
  | {
      memberType: FieldworkAudienceType.Standard
      memberItem: DraftStandardAudience
    }
  | {
      memberType: FieldworkAudienceType.Custom
      memberItem: DraftCustomAudience
    }
)

export enum FieldworkAudienceType {
  Interlock = 'Interlock',
  Standard = 'Standard',
  Custom = 'Custom'
}

export interface DraftStandardAudience {
  criterion: AudienceCriterion
  settingValues: MemberSettingValue[]
  standardCriterionOptions: FieldworkAudienceCriterionOption[]
  customizedCriterionOptions?: FieldworkAudienceCriterionOption[]
}

export interface FieldworkAudienceCriterionOption {
  criterionOption: AudienceCriterionOption
  position: number
  state: EnabledState
  qualification: Qualification
  quota: Quota | null
  weight: Weight
}

export interface AudienceCriterionOption {
  code: string
  createdDate: Date
  responseOption: AudienceResponseOption
}

export interface AudienceResponseOption {
  responseOptionId: string
  rotype: ResponseOptionType
  value: string
  createdDate: Date
}

export interface AudienceCriterion {
  code: string
  name: string
  question: Question
  createdDate: Date
}

export interface Question {
  questionId: string
  text: string
}

export type Qualification = DateStamped | null

export interface Quota extends DateStamped {
  percent: number
  __typename?: string
}

export interface Weight extends DateStamped {
  percent: number
}

export interface DraftCustomAudience extends DateStamped {
  questionLk: string
  question: Question
  criterionOptions: DraftCustomAudienceCriterionOption[]
  settingValues: MemberSettingValue[]
  responseOptionsLogic?: ResponseOptionsLogic
}

export interface DraftCustomAudienceCriterionOption extends DateStamped {
  responseOptionLk: string
  responseOption: AudienceResponseOption
  position: number
  qualification: Qualification
  quota: Quota | null
  pinned: boolean
  exclusive: boolean
  weight: Weight
  media?: ResponseOptionMedia | null
  __typename?: string
}

export interface MemberSettingValue {
  code: string
  value: string
  createdDate: string
  sinceDate: string
}

export interface FieldworkAudienceCategory {
  category: AudienceCategory
  position: number
  standardCriteria: StandardCriterion[]
}

export interface FieldworkAudienceWithOptions {
  category: AudienceCategory
  standardCriteria: StandardCriterionWithOptions
}

export interface AudienceCategory {
  code: string
  name: string
  createdDate: Date
}

export interface StandardCriterion extends DateStamped {
  criterion: AudienceCriterion
  state: EnabledState
}

export interface StandardCriterionWithOptions extends StandardCriterion {
  options: AudienceCriterionOption[]
}

export interface DraftCustomCriterion extends DateStamped {
  questionLk: string
  number: number
  question: Question
  state: EnabledState
  __typename: string
}

export interface SettingInputInput {
  memberSettingCode: MemberSettingCode
  settingValue: SettingValue | string
}

export enum MemberSettingCode {
  Qualifying = 'qualifying',
  Quotas = 'quotas',
  CustomAudienceChoice = 'custom_audience_choice',
  StandardAudienceChoice = 'standard_audience_choice',
  HideSurveyQuestion = 'hide_survey_question',
  RandomiseOptions = 'randomise_options',
  ScreeningQuestionResponseLimit = 'screening_question_response_limit',
  CustomizedCriterionOptions = 'customized_criterion_options',
  HardDisqualification = 'hard_disqualification'
}

export enum SettingValue {
  Enabled = 'enabled',
  Disabled = 'disabled',
  SingleChoice = 'single_choice',
  MultipleChoice = 'multiple_choice'
}

interface DateStamped {
  createdDate: Date
  sinceDate: Date
}

export interface DraftFieldworkAudienceMember {
  number: number
  position: number
  contextPosition: string
  memberType: FieldworkAudienceType
  memberItem: DraftCustomAudience | DraftStandardAudience
  isWeighted: boolean
}
