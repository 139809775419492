import groupBy from 'lodash/groupBy'
import { nanoid } from 'nanoid'
import { ForkTag } from '../data/gql-gen/questionnaire/graphql'
import {
  DraftForkItem,
  DraftLogicClauseProposition,
  DraftMatrixItem,
  DraftMatrixResponseOption,
  DraftQuestionItem,
  DraftQuestionResponseOption,
  DraftQuestionnaireEntry,
  DraftTextCardItem,
  EntryType,
  LogicPropositionType
} from '../data/model/questionnaire'
import { ResponseOptionsByQuestion } from '../modules/Questionnaire/Questionnaire.slice'
import { flattenEntries } from '../modules/Questionnaire/Questionnaire.utils'
import { getQuestionEntryPrefix } from './questionnaireUtils'

export enum Operator {
  And = 'And',
  Or = 'Or'
}

export const hasQuestionLogic = ({ entryItem }: DraftQuestionnaireEntry) =>
  'questionLogic' in entryItem &&
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  entryItem.questionLogic &&
  entryItem.questionLogic.length > 0

export const hasDisplayLogic = (entry: DraftQuestionnaireEntry): boolean => {
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const entryHasForks = entry.forks && entry.forks.length > 0
  return hasQuestionLogic(entry) || entryHasForks
}

export const getDefaultOperator = (
  entry: DraftQuestionnaireEntry | undefined
): Operator | undefined => {
  if (!entry) {
    return undefined
  }

  const entryItemLogicCapable = entry.entryItem as
    | DraftQuestionItem
    | DraftMatrixItem
    | DraftTextCardItem

  if (hasQuestionLogic(entry)) {
    return entryItemLogicCapable.questionLogic.length === 1
      ? Operator.Or
      : Operator.And
  }

  return undefined
}

interface ForkLogicTextData {
  forkName: string
  branchLabel: string
}
export const getForkLogicTextData = (
  fork: ForkTag,
  entries: DraftQuestionnaireEntry[]
): ForkLogicTextData | undefined => {
  const forkEntry = entries.find(
    (entry) =>
      entry.entryType === EntryType.ForkEntryType &&
      (entry.entryItem as DraftForkItem).fork.forkId === fork.forkId
  )

  if (!forkEntry) {
    return undefined
  }

  const draftForkItem = forkEntry.entryItem as DraftForkItem

  const forkName = draftForkItem.fork.name
  const branchLabel =
    draftForkItem.fork.branches.find(
      (branch) => branch.branchNumber === fork.branchNumber
    )?.label ?? ''

  return {
    forkName,
    branchLabel
  }
}

export interface QuestionLogicTextData {
  questionText: string
  roTexts: string[]
}

export const getQuestionLogicTextData = (
  clause: DraftLogicClauseProposition[],
  entries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion
): QuestionLogicTextData | undefined => {
  const leadProposition = clause[0].proposition as DraftQuestionResponseOption
  const entry = entries.find(
    (entry) =>
      entry.entryType === EntryType.QuestionEntryType &&
      entry.entryItem.questionLk === leadProposition.questionLk
  )

  if (!entry) {
    return undefined
  }

  const questionEntryPrefix = getQuestionEntryPrefix(entry)
  const dqEntry = entry.entryItem as DraftQuestionItem
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const questionText = `${questionEntryPrefix} ${dqEntry.question?.text}`

  const roTexts = clause.map((proposition) => {
    const roLK = (proposition.proposition as DraftQuestionResponseOption)
      .responseOptionLk
    const text =
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      responseOptionsByQuestion[dqEntry.questionLk]?.find(
        (ro) => ro.responseOptionLk === roLK
      )?.responseOption.value ?? ''

    return text
  })

  return {
    questionText,
    roTexts
  }
}

export const getMatrixLogicTextData = (
  clause: DraftLogicClauseProposition[],
  entries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion
): QuestionLogicTextData | undefined => {
  const leadProposition = clause[0].proposition as DraftMatrixResponseOption
  const entry = entries.find(
    (
      entry: DraftQuestionnaireEntry
    ): entry is DraftQuestionnaireEntry<EntryType.MatrixEntryType> =>
      entry.entryType === EntryType.MatrixEntryType &&
      entry.entryItem.matrixTitleLk === leadProposition.matrixTitleLk
  )

  if (!entry) {
    return undefined
  }

  const dmqEntry = entry.entryItem
  const question = dmqEntry.matrixRows.find(
    (row) => row.questionLk === leadProposition.questionLk
  )

  if (!question) {
    return undefined
  }
  const questionEntryPrefix = getQuestionEntryPrefix(entry)
  const questionText = `${questionEntryPrefix} ${dmqEntry.matrixTitle.title} ${question.question?.text}`
  const roTexts = clause.map((proposition) => {
    const roLK = (proposition.proposition as DraftMatrixResponseOption)
      .responseOptionLk
    const text =
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      responseOptionsByQuestion[dmqEntry.matrixTitleLk]?.find(
        (ro) => ro.responseOptionLk === roLK
      )?.responseOption.value ?? ''

    return text
  })

  return {
    questionText,
    roTexts
  }
}
export const canHaveQuestionLogic = (
  entry: DraftQuestionnaireEntry | undefined
) => {
  if (!entry) {
    return false
  }

  return [
    EntryType.QuestionEntryType,
    EntryType.MatrixEntryType,
    EntryType.TextCardEntryType
  ].includes(entry.entryType)
}

const getDefaultSelectedEntry = (
  draftQuestionnaireEntries: DraftQuestionnaireEntry[],
  clause: DraftLogicClauseProposition[]
): DraftQuestionnaireEntry | undefined => {
  const { propositionType, proposition } = clause[0]

  const questionEntries: DraftQuestionnaireEntry<EntryType.QuestionEntryType>[] =
    []
  const matrixEntries: DraftQuestionnaireEntry<EntryType.MatrixEntryType>[] = []

  flattenEntries(draftQuestionnaireEntries).forEach((entry) => {
    if (entry.entryType === EntryType.QuestionEntryType) {
      questionEntries.push(entry)
    } else if (entry.entryType === EntryType.MatrixEntryType) {
      matrixEntries.push(entry)
    }
  })

  switch (propositionType) {
    case LogicPropositionType.QuestionResponseOptionType:
      const { questionLk } = proposition as DraftQuestionResponseOption
      return questionEntries.find(
        ({ entryItem }) => entryItem.questionLk === questionLk
      )
    case LogicPropositionType.MatrixResponseOptionType:
      const { matrixTitleLk } = proposition as DraftMatrixResponseOption
      return matrixEntries.find(
        (entry) => entry.entryItem.matrixTitleLk === matrixTitleLk
      )
  }
  return undefined
}

const getDefaultSelectedQuestionResponseOptionLks: (
  clause: DraftLogicClauseProposition[]
) => string[] = (clause) => {
  return clause.map(
    (proposition) =>
      (proposition.proposition as DraftQuestionResponseOption).responseOptionLk
  )
}

export const getReferencedIds = (
  clause: DraftLogicClauseProposition[]
): {
  questionLk: string | undefined
  matrixTitleLk: string | undefined
} => {
  const { questionLk, matrixTitleLk } = clause[0]
    .proposition as DraftQuestionResponseOption & DraftMatrixResponseOption
  return { questionLk, matrixTitleLk }
}

export interface ClauseBlockContainer {
  clauseNumber: number | undefined
  clauseBlocks: ClauseBlock[]
}

export interface ClauseBlock {
  clauseId: string
  clauseNumber?: number
  clause: DraftLogicClauseProposition[] | undefined
  propositionEntry: DraftQuestionnaireEntry | undefined
  matrixTitleLk?: string
  questionLk?: string
  propositionResponseOptionLks: string[]
  shouldDelete: boolean
  negated: boolean
}

const getClauseBlocks = (
  draftQuestionnaireEntries: DraftQuestionnaireEntry[],
  clause: DraftLogicClauseProposition[],
  clauseNumber: number | undefined
): ClauseBlock[] => {
  // TODO preserve the order
  const questionPropositionsByPropositionLk = groupBy(
    clause.filter(
      (proposition) =>
        proposition.propositionType ===
        LogicPropositionType.QuestionResponseOptionType
    ),
    'proposition.questionLk'
  )

  const matrixPropositionsByPropositionLk = groupBy(
    clause.filter(
      (proposition) =>
        proposition.propositionType ===
        LogicPropositionType.MatrixResponseOptionType
    ),
    'proposition.questionLk'
  )

  const clauseByLk = {
    ...questionPropositionsByPropositionLk,
    ...matrixPropositionsByPropositionLk
  }

  const clauseBlocks: ClauseBlock[] = Object.keys(clauseByLk).map((lk) => {
    const clause = clauseByLk[lk]
    const isNegated = clause[0].negated
    const { questionLk, matrixTitleLk } = getReferencedIds(clause)
    return {
      clauseId: nanoid(),
      clauseNumber,
      clause,
      propositionEntry: getDefaultSelectedEntry(
        draftQuestionnaireEntries,
        clause
      ),
      questionLk,
      matrixTitleLk,
      propositionResponseOptionLks:
        getDefaultSelectedQuestionResponseOptionLks(clause),
      shouldDelete: false,
      negated: isNegated
    }
  })
  return clauseBlocks
}

export const getClauseBlockContainers = (
  draftQuestionnaireEntries: DraftQuestionnaireEntry[],
  entry: DraftQuestionnaireEntry | undefined
): ClauseBlockContainer[] => {
  if (!entry) {
    return []
  }

  if (!canHaveQuestionLogic(entry)) {
    return []
  }

  const entryItem = entry.entryItem as
    | DraftQuestionItem
    | DraftMatrixItem
    | DraftTextCardItem

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (entryItem.questionLogic ?? []).map((clause) => {
    const { clauseNumber } = clause[0].propositionRef
    return {
      clauseNumber,
      clauseBlocks: getClauseBlocks(
        draftQuestionnaireEntries,
        clause,
        clauseNumber
      )
    }
  })
}

export const getDefaultClause: () => ClauseBlock = () => {
  return {
    clauseId: nanoid(),
    clause: undefined,
    propositionEntry: undefined,
    questionLk: undefined,
    propositionResponseOptionLks: [],
    shouldDelete: false,
    negated: false
  }
}
