import { useQuery } from '@apollo/client'
import {
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  Text,
  TextDisplay,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import React from 'react'
import { ImportQuestionsDraftQuestionnaireQueryVariables } from '../../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import ImportQuestionsPickerControl from './ImportQuestionsPicker.control'
import ImportQuestionsPickerLoading from './ImportQuestionsPicker.loading'
import {
  IMPORT_QUESTIONS_DRAFT_QUESTIONNAIRE,
  ImportQuestionsData
} from './ImportQuestionsPicker.query'
import useStyles from './ImportQuestionsPicker.styles'
import { flatImportedQuestions } from './ImportQuestionsPicker.utils'

interface Props {
  projectId: string
  surveyId: string
  questionSelection: number[]
  onBackButton: () => void
  setQuestionSelection: (newSelection: number[]) => void
}

const ImportQuestionsPicker: React.FC<Props> = ({
  projectId,
  surveyId,
  questionSelection,
  onBackButton,
  setQuestionSelection
}: Props) => {
  const { classes } = useStyles()
  const { data, loading } = useQuery<
    ImportQuestionsData,
    ImportQuestionsDraftQuestionnaireQueryVariables
  >(IMPORT_QUESTIONS_DRAFT_QUESTIONNAIRE, {
    context: { clientName: 'questionnaire' },
    notifyOnNetworkStatusChange: true,
    // We cache only current survey, not imported one.
    // Apart from not having a need for caching, we also have issues while trying to store 2 different surveys in apollo cache because caching is based on entry numbers which are not unique withing different surveys. This causes values to be overwritten in the cache.
    fetchPolicy: 'no-cache',
    variables: {
      projectId,
      surveyId
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'importQuestionsProjectQuery',
        error
      )
    }
  })

  const entries = flatImportedQuestions(data?.draftQuestionnaire.entries || [])

  const handleSelectDeselectAll = (): void => {
    if (questionSelection.length === entries.length) {
      // all selected
      setQuestionSelection([])
    } else {
      setQuestionSelection(entries.map((entry) => entry.number))
    }
  }

  const getFrameContent = (innerContent: JSX.Element): JSX.Element => (
    <Grid container>
      <Grid container direction="row" justifyContent="space-between">
        <Grid
          item
          direction="row"
          className={classes.bulkActionButton}
          onClick={onBackButton}
          justifyContent="center"
        >
          <Icon
            name={IconName.ChevronLeft}
            size={IconSize.Large}
            className={classes.backChevron}
            iconColor={IconColor.Emphasis}
          />
          <Text
            highlight={TextHighlight.Emphasis}
            display={TextDisplay.Inline}
            size={TextSize.ms}
          >
            Back
          </Text>
        </Grid>
        <Grid
          direction="row"
          className={classes.bulkActionButton}
          onClick={handleSelectDeselectAll}
        >
          <Text size={TextSize.ms} highlight={TextHighlight.Emphasis}>
            {questionSelection.length === entries.length
              ? 'Deselect all'
              : 'Select all'}
          </Text>
        </Grid>
      </Grid>
      {innerContent}
    </Grid>
  )

  if (loading) {
    return getFrameContent(<ImportQuestionsPickerLoading />)
  }

  return getFrameContent(
    <ImportQuestionsPickerControl
      entries={entries}
      questionSelection={questionSelection}
      setQuestionSelection={setQuestionSelection}
    />
  )
}

export default ImportQuestionsPicker
