import { questionnaireGql } from '../../../gql-gen/questionnaire'

const DETACH_MATRIX_QUESTION_ROW = questionnaireGql(/* GraphQL */ `
  mutation detachMatrixQuestionRow(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionLk: ID!
  ) {
    detachMatrixQuestionRow(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionLk: $questionLk
    ) {
      questionnaireId
      matrixTitleLk
      questionLk
      detachedDate
      entry {
        number
        entryItem {
          ...DraftMatrixItem
        }
      }
    }
  }
`)

export default DETACH_MATRIX_QUESTION_ROW
