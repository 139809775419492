import { useMutation } from '@apollo/client'
import { RelocaliseQuestionnaireMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import { RELOCALISE_QUESTIONNAIRE } from '../../../data/gql/questionnaire/mutations/relocaliseQuestionnaire'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { fieldworkRefetchQuery } from '../../../data/gql/questionnaire/queries/fieldwork'
import { getFeasibilityEstimateRefetchQuery } from '../../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { useAudienceCountries } from '../../../hooks/audience/useAudienceCountries'
import {
  usePresetAudiences,
  useSetPresetAudience
} from '../../../hooks/audience/usePresetAudience'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { reorderCountriesDropDown } from '../../../utils/HelperFunctions'
import { useProjectType } from '../../Project/Project.hooks'
import { DEFAULT_LOI } from '../../Survey/SurveyStats/SurveyStats.utils'
import CountryPickerControl from './CountryPicker.control'
import CountryPickerLoading from './CountryPickerLoading'

const CountryPicker = ({
  setShowErrorPage
}: {
  setShowErrorPage?: (show: true) => void
}) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { isFdChat } = useProjectType()

  const {
    data: dataCountries,
    loading: getCountriesLoading,
    error: getCountriesError
  } = useAudienceCountries()

  const { fieldwork, loading: getSupplierFieldworkLoading } = useFieldwork()
  const loi = fieldwork?.lengthOfInterview.adjusted || DEFAULT_LOI
  const [relocaliseQuestionnaire] = useMutation<
    void,
    RelocaliseQuestionnaireMutationVariables
  >(RELOCALISE_QUESTIONNAIRE, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [
      fieldworkRefetchQuery(surveyId),
      getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi),
      draftAudienceRefetchQuery(projectId, surveyId)
    ]
  })
  const [setPresetAudience] = useSetPresetAudience()
  const { data: presetAudiences } = usePresetAudiences({
    countryCode: 'GB',
    language: 'en'
  })

  const handleChangeCountry = async (optionValue: string): Promise<void> => {
    const [newCountryCode, newLanguage] = optionValue.split('-')
    const newLocale = dataCountries?.countries.find(
      ({ countryCode, locale }) =>
        countryCode === newCountryCode && locale.language === newLanguage
    )?.locale

    if (newLocale) {
      await relocaliseQuestionnaire({
        variables: {
          surveyId,
          newLocale
        }
      })

      // if the project is an FD Chat project and the new locale is GB-en, set the preset audience to Custom
      if (
        isFdChat &&
        newLocale.country === 'GB' &&
        newLocale.language === 'en'
      ) {
        const preset = presetAudiences?.find(
          (presetAudience) => presetAudience.name === 'Custom'
        )

        if (preset?.presetAudienceId) {
          setPresetAudience({
            variables: {
              surveyId,
              presetAudienceId: preset.presetAudienceId
            }
          })
        }
      }
    }
  }

  const availableCountries = dataCountries?.countries ?? []

  const countries = reorderCountriesDropDown(availableCountries)
  const { country, language } = fieldwork?.locale || {
    country: 'GB',
    language: 'en'
  }
  const selectedCountryCode: string = `${country}-${language}` // If undefined default to United Kingdom

  if (getCountriesLoading || getSupplierFieldworkLoading) {
    return <CountryPickerLoading />
  }

  if (getCountriesError) {
    setShowErrorPage?.(true)
  }

  return (
    <CountryPickerControl
      defaultCountryCode={selectedCountryCode}
      countries={countries}
      onChangeSelectCountry={(event) => {
        handleChangeCountry(event.target.value)
      }}
    />
  )
}

export default CountryPicker
