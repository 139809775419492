import { ApolloCache } from '@apollo/client'
import { produce } from 'immer'
import { AddCustomCriterionOptionData } from '../../../data/gql/fieldwork/mutations/addCustomCriterionOption'
import { DraftAudience } from '../../../data/gql/questionnaire/queries/audiences'
import {
  DraftCustomAudience,
  DraftCustomAudienceCriterionOption,
  FieldworkAudienceType
} from '../../../data/model/audience'

export const addCustomResponseOptionCacheUpdate: (
  cache: ApolloCache<AddCustomCriterionOptionData>,
  newResponseOption: DraftCustomAudienceCriterionOption,
  newAudienceQuestionLk: string | undefined
) => void = (cache, newResponseOption, newAudienceQuestionLk) => {
  cache.modify({
    fields: {
      draftAudience(existing) {
        const draftAudienceCache = existing as DraftAudience
        const criterionIndex = draftAudienceCache.members.findIndex(
          (audience) =>
            audience.memberType === FieldworkAudienceType.Custom &&
            (audience.memberItem as DraftCustomAudience).questionLk ===
              newAudienceQuestionLk
        )

        if (criterionIndex === -1) {
          return draftAudienceCache
        }

        return produce(draftAudienceCache, (draft) => {
          const member = draft.members[criterionIndex]
            .memberItem as DraftCustomAudience
          member.criterionOptions.push(newResponseOption)
        })
      }
    }
  })
}
