import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'
import DRAFT_RESPONSE_OPTION from '../fragments/draftEntryResponseOption'

export interface PasteInTitleData {
  pasteInTitle: DraftQuestionnaireEntry
}

export const PASTE_IN_TITLE = gql`
  mutation pasteInTitle(
    $questionnaireId: ID!
    $questionLk: ID!
    $text: String
    $textStyling: String
    $responseOptions: [String!]!
  ) {
    pasteInTitle(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      text: $text
      textStyling: $textStyling
      responseOptions: $responseOptions
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftQuestionItem {
          question {
            ...Question
          }
          questionLk
          responseOptions {
            ...DraftEntryResponseOption
          }
        }
      }
      __typename
    }
  }
  ${DRAFT_RESPONSE_OPTION}
`
