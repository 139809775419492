import { gql } from '@apollo/client/core'
import DRAFT_TEXT_CARD_ITEM from './DraftTextCardItem'
import DRAFT_RESPONSE_OPTION from './draftEntryResponseOption'
import DRAFT_FORK from './draftForkItem'
import DRAFT_LOOPING_CONFIG from './draftLoopingConfig'

const DRAFT_QUESTIONNAIRE_ENTRY = gql`
  fragment DraftQuestionnaireEntry on DraftQuestionnaireEntry {
    number
    position
    contextPosition
    questionKind
    entryId @client
    entryItem {
      ...DraftEntryItem
      ... on DraftSectionItem {
        __typename
        title
        sectionId
        entries {
          number
          position
          contextPosition
          questionKind
          sectionId
          entryType
          entryId @client
          entryItem {
            ...DraftEntryItem
          }
          forks {
            forkId
            branchNumber
          }
          loopSpecificDisplayLogic {
            entryNumber
            sourceIds
          }
        }
        settingValues {
          code
          value
          createdDate
          sinceDate
        }
        isLoopingEnabled @client
        sectionMaskingRules {
          ...DraftMaskingRule
        }
        loopingConfig {
          ...DraftLoopingConfig
        }
      }
    }
    entryType
    forks {
      forkId
      branchNumber
    }
    __typename
  }
  fragment DraftEntryItem on DraftEntryItem {
    ...DraftMatrixItem
    ...DraftTextCardItem
    ... on DraftForkItem {
      fork {
        ...DraftFork
      }
      __typename
    }
    ...DraftQuestionItem
  }
  ${DRAFT_LOOPING_CONFIG}
  ${DRAFT_FORK}
  ${DRAFT_RESPONSE_OPTION}
  ${DRAFT_TEXT_CARD_ITEM}
`

export default DRAFT_QUESTIONNAIRE_ENTRY
