import { gql } from '@apollo/client'
import { DraftForkItem } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface UpdateForkBranchData {
  updateForkBranch: DraftForkItem
}

const UPDATE_FORK_BRANCH = gql`
  mutation updateForkBranch(
    $questionnaireId: ID!
    $forkId: ID!
    $branchNumber: Int!
    $percentage: Float!
  ) {
    updateForkBranch(
      questionnaireId: $questionnaireId
      forkId: $forkId
      branchNumber: $branchNumber
      percentage: $percentage
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`

export default UPDATE_FORK_BRANCH
