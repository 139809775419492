import gql from 'graphql-tag'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface DuplicateMatrixQuestionData {
  duplicateMatrixQuestion: DraftQuestionnaireEntry
}

export const DUPLICATE_MATRIX_QUESTION = gql`
  mutation duplicateMatrixQuestion(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $position: Int!
  ) {
    duplicateMatrixQuestion(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      position: $position
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftMatrixItem {
          matrixTitleLk
          matrixTitle {
            matrixTitleId
            title
          }
        }
      }
      __typename
    }
  }
`
