import { gql } from '@apollo/client'

const MEMBER_SETTING_VALUE = gql`
  fragment MemberSettingValue on MemberSettingValue {
    code
    value
    createdDate
    sinceDate
  }
`

export default MEMBER_SETTING_VALUE
