import {
  Badge,
  CircularProgress,
  Grid,
  Status,
  TipPlacement,
  Tooltip,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import { useGetSoftLaunchOrder } from '../../../components/SoftLaunch'
import { DEFAULT_INCIDENCE_RATE } from '../../../constants/feasibility'
import { SurveyState } from '../../../data/gql-gen/dashboard/graphql'
import {
  WeightingData,
  transformWeightedQuestionsToTooltipMarkup
} from '../../../utils/resultsUtils'
import { ETABadgeContainer } from '../../Results/ETABadge'
import { SoftLaunchBadgeContainer } from '../../Results/SoftLaunchBadge'

interface Props {
  wantedCompletes: number
  collectedCompletes: number
  surveyState: SurveyState
  incidenceRate?: number
  weightingScheme?: WeightingData[]
  nonUK?: boolean
}

const SurveyCompletionProgress: React.FC<Props> = (props: Props) => {
  const {
    wantedCompletes,
    collectedCompletes,
    surveyState,
    incidenceRate = DEFAULT_INCIDENCE_RATE,
    nonUK = false,
    weightingScheme
  }: Props = props

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const { isSoftLaunchOrder } = useGetSoftLaunchOrder()
  // When the survey was just set live and there are no results yet the wantedCompletes come back as 0
  // This is just so we don't show 0/0 in this case and instead show 0% and then an empty progress bar
  const completesText = wantedCompletes
    ? `${collectedCompletes} / ${wantedCompletes}`
    : '0%'
  const wantedCompletesForProgress = wantedCompletes === 0 ? 1 : wantedCompletes

  const getCompletes: (surveyState: SurveyState) => JSX.Element = (
    surveyState
  ) => {
    switch (surveyState) {
      case SurveyState.Completed:
        return (
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeMs,
              textClasses.weightSemiBold
            )}
          >
            {`${collectedCompletes}`}
          </p>
        )
      default:
        return (
          <>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.weightSemiBold,
                textClasses.marginRight
              )}
            >
              {completesText}
            </p>
            <CircularProgress
              style={{ display: 'flex' }}
              total={wantedCompletesForProgress}
              current={collectedCompletes}
              size={24}
            />
            <ETABadgeContainer
              collectedCompletes={collectedCompletes}
              wantedCompletes={wantedCompletes}
              incidenceRate={incidenceRate}
              nonUK={nonUK}
            />
            {isSoftLaunchOrder && <SoftLaunchBadgeContainer />}
          </>
        )
    }
  }
  const completesProgess = getCompletes(surveyState)

  return (
    <Grid direction="row" container justifyContent="center" alignItems="center">
      {!!weightingScheme && (
        <Tooltip
          tipPlacement={TipPlacement.BottomStart}
          title={transformWeightedQuestionsToTooltipMarkup(weightingScheme)}
        >
          <Badge
            status={Status.DRAFT}
            label="Weighted data"
            style={{ marginRight: 16, marginLeft: -8 }}
          />
        </Tooltip>
      )}
      <p
        className={classNames(
          textClasses.default,
          textClasses.sizeMs,
          textClasses.marginRight
        )}
      >
        Completes:{' '}
      </p>
      {completesProgess}
    </Grid>
  )
}

export default SurveyCompletionProgress
