import { gql } from '@apollo/client/core'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface RemoveEntryFromSectionData {
  addEntryToSection: DraftQuestionnaireEntry
}

export const REMOVE_ENTRY_FROM_SECTION = gql`
  mutation removeEntryFromSection(
    $questionnaireId: ID!
    $entryNumber: Int!
    $toPosition: Int!
  ) {
    removeEntryFromSection(
      questionnaireId: $questionnaireId
      entryNumber: $entryNumber
      toPosition: $toPosition
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`
