import { gql } from '@apollo/client/core'

export interface DetachedCustomCriterionOption {
  surveyId: string
  questionLk: string
  responseOptionLk: string
  detachedDate: Date
}
export interface DetachCustomCriterionOptionData {
  detachCustomCriterionOption: DetachedCustomCriterionOption
}

export const DETACH_CUSTOM_CRITERION_OPTION = gql`
  mutation detachCustomCriterionOption(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    detachCustomCriterionOption(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
    ) {
      surveyId
      questionLk
      responseOptionLk
      detachedDate
    }
  }
`
