import { gql } from '@apollo/client/core'

export const MOVE_QUESTIONNAIRE_ENTRY = gql`
  mutation moveQuestionnaireEntry(
    $questionnaireId: ID!
    $number: Int!
    $toPosition: Int!
  ) {
    moveQuestionnaireEntry(
      questionnaireId: $questionnaireId
      number: $number
      toPosition: $toPosition
    ) {
      position
      number
      __typename
    }
  }
`
