import { gql } from '@apollo/client/core'

export interface QualificationData {
  setStandardQualification: {
    surveyId: string
    audienceCriterionCode: string
    audienceCriterionOptionCode: string
  }
}

export const SET_QUALIFICATION = gql`
  mutation setStandardQualification(
    $surveyId: ID!
    $audienceCriterionCode: String!
    $audienceCriterionOptionCode: String!
  ) {
    setStandardQualification(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
      audienceCriterionOptionCode: $audienceCriterionOptionCode
    ) {
      surveyId
      audienceCriterionCode
      audienceCriterionOptionCode
    }
  }
`

export const REMOVE_QUALIFICATION = gql`
  mutation removeStandardQualification(
    $surveyId: ID!
    $audienceCriterionCode: String!
    $audienceCriterionOptionCode: String!
  ) {
    removeStandardQualification(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
      audienceCriterionOptionCode: $audienceCriterionOptionCode
    ) {
      surveyId
      audienceCriterionCode
      audienceCriterionOptionCode
    }
  }
`
