import { useMutation } from '@apollo/client'
import { ImportQuestionnaireMutationVariables } from '../../data/gql-gen/questionnaire/graphql'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import IMPORT_QUESTIONNAIRE, { ImportData } from './import.query'

const useImportQuestionnaire = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [importQuestionnaire] = useMutation<
    ImportData,
    ImportQuestionnaireMutationVariables
  >(IMPORT_QUESTIONNAIRE, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'importQuestionnaire',
        error
      )
    }
  })

  return { importQuestionnaire }
}

export default useImportQuestionnaire
