import { Checkbox, Grid, SearchBarInput } from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { Highlight } from '../../../components/Highlight'
import { TextHighlight, TextSize, TextWeight } from '../../../constants/text'
import useStyles from './AudienceSearch.styles'

export interface ResponseResult {
  text: string
  responseOptionId: string
}
export interface AudienceSearchResult {
  code: string
  name: string
  category: string
  question: string
  responses?: ResponseResult[]
  selected: boolean
}

interface Props {
  loading?: boolean
  onChange: (newValue: string) => void
  results?: AudienceSearchResult[]
  onResultClick: (result: AudienceSearchResult) => void
}

const AudienceSearch: React.FC<Props> = (props: Props) => {
  const { loading, onChange, results, onResultClick }: Props = props
  const [value, setValue] = useState<string>('')
  const { classes, cx: classNames } = useStyles()

  const handleChange: (newValue: string) => void = (newValue) => {
    setValue(newValue)
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (onChange) {
      onChange(newValue)
    }
  }

  const getResultUI = (): JSX.Element[] | undefined =>
    results?.map((result, index) => (
      <Grid
        container
        direction="row"
        onClick={() => {
          onResultClick(result)
        }}
        className={classes.result}
        key={result.code}
      >
        <Grid xs={1} item>
          <Checkbox checked={result.selected} />
        </Grid>
        <Grid
          xs={11}
          item
          container
          direction="row"
          className={classNames(classes.resultText, {
            [classes.borderBottom]: index !== results.length - 1
          })}
        >
          <Grid container item direction="column" xs={10}>
            <p style={{ fontWeight: TextWeight.SemiBold }}>
              <Highlight text={result.name} highlight={value} />
            </p>
            <p>
              <Highlight text={result.question} highlight={value} />
            </p>
          </Grid>
          <Grid
            xs={2}
            container
            item
            justifyContent="flex-end"
            className={classNames(classes.resultCategory)}
          >
            <p
              style={{ color: TextHighlight.Background, fontSize: TextSize.s }}
            >
              <Highlight text={result.category} highlight={value} />
            </p>
          </Grid>
          <p style={{ color: TextHighlight.Background, fontSize: TextSize.s }}>
            {result.responses
              ?.filter((response) =>
                response.text.toLowerCase().includes(value)
              )
              ?.map((response, index, filteredResponses) => (
                <span key={response.responseOptionId}>
                  <Highlight
                    text={response.text}
                    highlight={value}
                    collapsed
                    characterLimit={40}
                  />
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                  {index < filteredResponses?.length - 1 && <span>, </span>}
                </span>
              ))}
          </p>
        </Grid>
      </Grid>
    ))

  const hasInputValue = Boolean(value)

  return (
    <div className={classes.container}>
      <SearchBarInput
        isLoading={loading}
        onChange={(event) => handleChange(event.target.value)}
        placeholder="Search criteria"
        showResultsPopup={hasInputValue}
        value={value}
        showClearIcon={hasInputValue}
        onClickClear={() => handleChange('')}
        searchResults={hasInputValue ? getResultUI() : undefined}
      />
    </div>
  )
}

export default AudienceSearch
