import { ComponentProps, useCallback, useContext, useMemo } from 'react'
import {
  RemoveCustomQualificationMutationVariables,
  SetCustomQualificationMutationVariables
} from '../../../data/gql-gen/fieldwork/graphql'
import {
  DraftCustomAudience,
  DraftCustomAudienceCriterionOption,
  FieldworkAudienceType,
  MemberSettingCode
} from '../../../data/model/audience'
import useCustomAudienceMutations from '../../../hooks/audience/useCustomAudienceMutations'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { percentToProportion } from '../../../utils/HelperFunctions'
import AudienceContext from '../Audience.context'
import {
  getCustomAudienceValidationErrors,
  getIsEnabledInSettings
} from '../utils'
import CustomAudienceResponseOptionsListControl from './CustomAudienceResponsesList.control'

const CustomAudienceResponseOptionsList = ({
  questionLk,
  isAudienceInvalid,
  onSetQuotaInfo
}: {
  questionLk: string
  isAudienceInvalid?: boolean
  onSetQuotaInfo?: (responseOptionLk: string, value: number) => void
}) => {
  const surveyId = useSurveyId()

  const questionMutationVariable = useMemo(
    () => ({
      surveyId,
      questionLk
    }),
    [questionLk, surveyId]
  )
  const { audienceState } = useContext(AudienceContext)
  const customAudience = audienceState.audienceMembers.find(
    (m) =>
      m.memberType === FieldworkAudienceType.Custom &&
      (m.memberItem as DraftCustomAudience).questionLk === questionLk
  )?.memberItem as DraftCustomAudience

  const {
    setCustomQualification,
    removeCustomQualification,
    setCustomQuota,
    moveCustomCriterionOption,
    detachCustomCriterionOption,
    updateCustomCriterionOption
  } = useCustomAudienceMutations()

  const qualifyingEnabled = getIsEnabledInSettings(
    customAudience.settingValues,
    MemberSettingCode.Qualifying
  )

  const quotasEnabled = getIsEnabledInSettings(
    customAudience.settingValues,
    MemberSettingCode.Quotas
  )

  const handleUpdateOption = useCallback<
    ComponentProps<
      typeof CustomAudienceResponseOptionsListControl
    >['onUpdateOptionText']
  >(
    async (responseOption, newText) => {
      await updateCustomCriterionOption({
        variables: {
          ...questionMutationVariable,
          responseOptionLk: responseOption.responseOptionLk,
          responseOptionValue: newText
        },
        optimisticResponse: {
          updateCustomCriterionOption: {
            ...responseOption,
            responseOption: {
              ...responseOption.responseOption,
              value: newText
            }
          }
        }
      })
    },
    [questionMutationVariable, updateCustomCriterionOption]
  )

  const handleSetCustomQuota = useCallback(
    (responseOptionLk: string, percent: number) => {
      setCustomQuota({
        variables: {
          ...questionMutationVariable,
          responseOptionLk,
          percent: Number.isNaN(percent) ? 0 : percentToProportion(percent) // on the server it's 0-1
        }
      })
    },
    [questionMutationVariable, setCustomQuota]
  )

  const handleMoveCustomOption = useCallback(
    (responseOptionLk: string, toPosition: number) => {
      moveCustomCriterionOption({
        variables: {
          ...questionMutationVariable,
          responseOptionLk,
          toPosition
        }
      })
    },
    [moveCustomCriterionOption, questionMutationVariable]
  )

  const handleDeleteOption = useCallback(
    (responseOptionLk: string) => {
      detachCustomCriterionOption({
        variables: {
          ...questionMutationVariable,
          responseOptionLk
        }
      })
    },
    [detachCustomCriterionOption, questionMutationVariable]
  )

  const handleToggleQualification = useCallback(
    (option: DraftCustomAudienceCriterionOption, enable: boolean) => {
      const variables:
        | SetCustomQualificationMutationVariables
        | RemoveCustomQualificationMutationVariables = {
        ...questionMutationVariable,
        responseOptionLk: option.responseOptionLk
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!option.quota) {
        handleSetCustomQuota(option.responseOptionLk, 0)
      }
      if (enable) {
        setCustomQualification({ variables })
      } else {
        removeCustomQualification({ variables })
      }
    },
    [
      handleSetCustomQuota,
      questionMutationVariable,
      removeCustomQualification,
      setCustomQualification
    ]
  )

  const validationErrors = getCustomAudienceValidationErrors(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    audienceState?.validationErrors,
    customAudience.questionLk
  )

  return (
    <CustomAudienceResponseOptionsListControl
      questionLk={customAudience.questionLk}
      responseOptions={customAudience.criterionOptions}
      validationErrors={validationErrors}
      qualifyingEnabled={qualifyingEnabled}
      quotasEnabled={quotasEnabled}
      isAudienceInvalid={isAudienceInvalid}
      onToggleQualification={handleToggleQualification}
      onUpdateQuotaValues={onSetQuotaInfo}
      onDeleteOption={handleDeleteOption}
      onMoveOption={handleMoveCustomOption}
      onSetCustomQuota={handleSetCustomQuota}
      onUpdateOptionText={handleUpdateOption}
    />
  )
}

export default CustomAudienceResponseOptionsList
