import gql from 'graphql-tag'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'

export interface UpdateMatrixQuestionData {
  updateMatrixQuestion: DraftQuestionnaireEntry
}

const UPDATE_MATRIX_QUESTION = gql`
  mutation updateMatrixQuestion(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $title: String!
    $titleStyling: String!
  ) {
    updateMatrixQuestion(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      title: $title
      titleStyling: $titleStyling
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftMatrixItem {
          matrixTitleLk
          matrixTitle {
            matrixTitleId
            title
            titleStyling
          }
        }
      }
      __typename
    }
  }
`

export default UPDATE_MATRIX_QUESTION
