import { useMutation } from '@apollo/client'
import { UpdateFieldworkMutationVariables } from '../../data/gql-gen/fieldwork/graphql'
import { UPDATE_FIELDWORK } from '../../data/gql/fieldwork/mutations/updateFieldwork'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'

export const useUpdateFieldwork = () => {
  const [updateFieldwork, { loading: updateFieldworkLoading }] = useMutation<
    void,
    UpdateFieldworkMutationVariables
  >(UPDATE_FIELDWORK, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateFieldwork',
        error
      )
    }
  })
  return { updateFieldwork, updateFieldworkLoading }
}
