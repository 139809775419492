import {
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { useCallback } from 'react'
import {
  useAppDispatch,
  useAppSelector,
  useAppStore
} from '../../../../App.store'
import {
  InputMaybe,
  LoopBasis,
  MatrixLoopingConfigInput
} from '../../../../data/gql-gen/questionnaire/graphql'
import { EntryType } from '../../../../data/model/questionnaire'
import { isFocaldataUser } from '../../../../utils/authUtils'
import {
  useEntryByEntryNumber,
  useLoopingSelectedEntry,
  useLoopingSourceResponseOptions,
  useSetLooping
} from '../Looping.hooks'
import { LoopingConfig } from '../Looping.model'
import {
  closeLoopingDialog,
  selectCurrentLoopingSectionNumberRequired,
  selectInitialSourceEntryNumber,
  selectLoopBasis,
  selectLoopRandomisationEnabled,
  selectMatrixLoopingConfig,
  selectMaxIterations,
  selectPrioritizedResponseOptionIds,
  selectSourceChosen,
  selectSourceEntryNumber,
  selectStaticLoopingEnabled
} from '../Looping.slice'
import { LoopingHint } from '../LoopingHint'
import { LoopingSourceSelector } from '../LoopingSourceSelector/LoopingSourceSelector'
import { LoopingMatrixContent } from './LoopingMatrixContent'
import { LoopingMultiSelectContent } from './LoopingMultiSelectContent'
import { StaticLoopingSection } from './StaticLoopingSection'

export const DialogLooping = () => {
  const sectionEntryNumber = useAppSelector(
    selectCurrentLoopingSectionNumberRequired
  )
  const dispatch = useAppDispatch()
  const sourceEntry = useLoopingSelectedEntry()
  const initialSourceEntryNumber = useAppSelector(
    selectInitialSourceEntryNumber
  )
  const initialSourceEntry = useEntryByEntryNumber(initialSourceEntryNumber)
  const sourceEntryType = sourceEntry?.entryType

  const setLooping = useSetLooping({
    sectionEntryNumber,
    sourceEntry,
    prevSourceEntry: initialSourceEntry
  })
  const loopingSourceResponseOptions = useLoopingSourceResponseOptions()
  const store = useAppStore()

  const handleCloseDialog = useCallback(() => {
    dispatch(closeLoopingDialog())
  }, [dispatch])

  const handleSaveLooping = useCallback(async () => {
    const state = store.getState()

    const loopingSubConfig: Pick<
      LoopingConfig,
      'loopBasis' | 'matrixLoopingConfig'
    > = {
      loopBasis: selectLoopBasis(state)
    }

    if (sourceEntryType === EntryType.MatrixEntryType) {
      // in case looping source is matrix, loopBasis is always `QuotaTarget` and cannot be configured via UI
      loopingSubConfig.loopBasis = 'QuotaTarget'
      // `matrixLoopingConfig` is only used for matrix source
      loopingSubConfig.matrixLoopingConfig = selectMatrixLoopingConfig(state)
    }

    await setLooping({
      loopingConfig: {
        maxIterations: selectMaxIterations(state),
        sourceEntryNumber: selectSourceEntryNumber(state),
        loopRandomisationEnabled: selectLoopRandomisationEnabled(state),
        prioritizedResponseOptionIds: selectPrioritizedResponseOptionIds(state),
        ...loopingSubConfig,
        loopBasis: LoopBasis[loopingSubConfig.loopBasis],
        // @todo legacy type error – fix this when updating
        matrixLoopingConfig:
          loopingSubConfig.matrixLoopingConfig as InputMaybe<MatrixLoopingConfigInput>
      },
      enabled: true,
      enableStaticLooping: selectStaticLoopingEnabled(state)
    })

    handleCloseDialog()
  }, [handleCloseDialog, setLooping, sourceEntryType, store])

  const wasSourceEntrySelected = useAppSelector(selectSourceChosen)

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Dialog
      title="Looping"
      fullWidth
      maxWidth={DialogMaxWidth.Sm}
      open
      onClose={handleCloseDialog}
      primaryButtonText="Save"
      primaryButtonDisabled={!wasSourceEntrySelected}
      primaryButtonClick={handleSaveLooping}
      statusComponent={wasSourceEntrySelected ? <LoopingHint /> : null}
      variant={
        wasSourceEntrySelected
          ? DialogVariant.SingleButtonStatus
          : DialogVariant.SingleButton
      }
    >
      <Grid container>
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeM,
            textClasses.highlightBackground,
            textClasses.gutterBottom
          )}
        >
          Loop based on a question
        </p>
        <Grid container>
          <Grid item xs>
            <LoopingSourceSelector />
          </Grid>
        </Grid>
        {isFocaldataUser() && <StaticLoopingSection />}
      </Grid>
      {sourceEntryType === EntryType.MatrixEntryType && (
        <LoopingMatrixContent />
      )}
      {sourceEntryType === EntryType.QuestionEntryType &&
        loopingSourceResponseOptions.length > 0 && (
          <LoopingMultiSelectContent />
        )}
    </Dialog>
  )
}
