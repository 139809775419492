import {
  Chip,
  Grid,
  Paper,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { useState } from 'react'
import CreateProject from '../../modules/Project/CreateProject'
import { ProjectTypesItem } from '../../modules/Project/Project.model'

const ProjectTypeCard = ({
  title,
  description,
  type,
  showBetaLabel
}: ProjectTypesItem) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <Grid
        item
        component={Paper}
        variant="outlined"
        style={{
          padding: '24px',
          display: 'grid',
          gap: '20px',
          gridAutoRows: 'max-content 1fr'
        }}
        key={type}
        data-testid="project-type-card"
      >
        <div style={{ position: 'relative' }}>
          <Text size={TextSize.m} weight={TextWeight.Bold}>
            {title}{' '}
            {showBetaLabel && (
              <Chip
                label="Beta"
                style={{
                  marginLeft: '8px',
                  position: 'absolute',
                  marginTop: '-0.25em'
                }}
              />
            )}
          </Text>
        </div>
        <Text
          size={TextSize.m}
          weight={TextWeight.Regular}
          highlight={TextHighlight.Background}
        >
          {description}
        </Text>
        <CreateProject
          isDialogOpen={dialogOpen}
          setIsDialogOpen={setDialogOpen}
          dialogTitle="Name new project"
          type={type}
          hideCountry
          hideCompletes
        />
      </Grid>
    </>
  )
}

export default ProjectTypeCard
