import { questionnaireGql } from '../../../data/gql-gen/questionnaire'

export const SET_LOOP_SPECIFIC_DISPLAY_LOGIC = questionnaireGql(/* GraphQL */ `
  mutation SetLoopSpecificDisplayLogic(
    $questionnaireId: ID!
    $entryNumber: Int!
    $sourceIds: [ID!]!
  ) {
    setLoopSpecificDisplayLogic(
      questionnaireId: $questionnaireId
      entryNumber: $entryNumber
      sourceIds: $sourceIds
    ) {
      number
      loopSpecificDisplayLogic {
        entryNumber
        sourceIds
      }
    }
  }
`)

export const REMOVE_LOOP_SPECIFIC_DISPLAY_LOGIC = questionnaireGql(
  /* GraphQL */ `
    mutation RemoveLoopSpecificDisplayLogic(
      $questionnaireId: ID!
      $entryNumber: Int!
    ) {
      removeLoopSpecificDisplayLogic(
        questionnaireId: $questionnaireId
        entryNumber: $entryNumber
      ) {
        number
        loopSpecificDisplayLogic {
          __typename
        }
      }
    }
  `
)
