import { gql } from '@apollo/client'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface AddForkData {
  addFork: DraftQuestionnaireEntry
}

const ADD_FORK = gql`
  mutation addFork(
    $questionnaireId: ID!
    $position: Int!
    $name: String!
    $defaultBranchCount: Int!
  ) {
    addFork(
      questionnaireId: $questionnaireId
      position: $position
      name: $name
      defaultBranchCount: $defaultBranchCount
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`

export default ADD_FORK
