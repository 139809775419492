import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { convertAudienceType } from '../../containers/Page/FdChat/AudiencePage/FdChatAudience'
import {
  GetPresetAudiencesQueryVariables,
  SetPresetAudienceMutationVariables
} from '../../data/gql-gen/fieldwork/graphql'
import { fieldworkAudienceRefetchQuery } from '../../data/gql/fieldwork/queries/fieldworkAudience'
import { GET_COST_QUOTE } from '../../data/gql/questionnaire/queries/getCostQuote'
import { getFeasibilityEstimateRefetchQuery } from '../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { LoggerErrorType } from '../../data/logger'
import { FieldworkAudienceType } from '../../data/model/audience'
import { FieldworkAudienceType as FwAudienceType } from '../../data/model/fieldwork'
import { useFieldworkData } from '../../modules/Audience/Audience.hooks'
import {
  GET_PRESET_AUDIENCES,
  NewPresetsData,
  SET_PRESET_AUDIENCE
} from '../../modules/Audience/AudiencePresetPicker/AudiencePresetPicker.query'
import { captureApolloError } from '../../utils/HelperFunctions'
import useDisplayedLOI from '../localState/useDisplayedLOI'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import useGetDraftAudience from './useGetDraftAudience'
import { useUpdateFieldwork } from './useUpdateFieldwork'

export const CUSTOM_AUDIENCE_PRESET_NAME = 'Custom'

export interface AudiencePreset {
  name: string
  presetAudienceId?: string
  isWeighted?: boolean
}
export interface UsePresetAudienceData {
  setPreset: (preset: AudiencePreset) => Promise<void>
  loading: boolean
}

const usePresetAudience: () => UsePresetAudienceData = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { displayedLOI } = useDisplayedLOI()
  const [loading, setLoading] = useState<boolean>(false)

  const { draftAudience, refetchAudience } = useGetDraftAudience()
  const { updateFieldwork } = useUpdateFieldwork()
  const { fieldworkData } = useFieldworkData()
  const [setPresetAudience] = useSetPresetAudience()

  const setPreset: (preset: AudiencePreset) => Promise<void> = async (
    preset
  ) => {
    setLoading(true)
    if (
      draftAudience?.members.some(
        (audience) => audience.memberType !== FieldworkAudienceType.Standard
      )
    ) {
      const audience = fieldworkData?.audience[0]
      await updateFieldwork({
        variables: {
          surveyId,
          panelSupplierCode: convertAudienceType(
            audience?.__typename as FwAudienceType
          ),
          incidenceRate: 100,
          audienceDescription:
            audience?.__typename !== 'CintAudience'
              ? audience?.description
              : undefined
        }
      })
    }
    await setPresetAudience({
      variables: {
        surveyId,
        // @todo legacy type error – fix this when updating
        presetAudienceId: preset.presetAudienceId!
      },
      refetchQueries: [
        getFeasibilityEstimateRefetchQuery(projectId, surveyId, displayedLOI),
        GET_COST_QUOTE,
        fieldworkAudienceRefetchQuery(surveyId)
      ]
    })

    await refetchAudience()
    setLoading(false)
  }

  return { setPreset, loading }
}

export default usePresetAudience

export const useSetPresetAudience = () => {
  const setPresetAudience = useMutation<
    void,
    SetPresetAudienceMutationVariables
  >(SET_PRESET_AUDIENCE, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setPresetAudience',
        error
      )
    }
  })

  return setPresetAudience
}

export const usePresetAudiences = ({
  countryCode,
  language
}: {
  countryCode: string
  language: string
}) => {
  const { data, loading } = useQuery<
    NewPresetsData,
    GetPresetAudiencesQueryVariables
  >(GET_PRESET_AUDIENCES, {
    context: { clientName: 'fieldwork' },
    variables: {
      locale: {
        country: countryCode,
        language
      }
    },
    skip: !countryCode || !language
  })

  return { data: data?.getPresetAudiences, loading }
}
