import { questionnaireGql } from '../../../data/gql-gen/questionnaire'

export const REPLACE_QUESTION_LOGIC = questionnaireGql(/* GraphQL */ `
  mutation ReplaceQuestionLogic(
    $questionnaireId: ID!
    $entryNumber: Int!
    $logic: [[ReplaceQuestionLogicInputLogicInput!]!]!
  ) {
    replaceQuestionLogic(
      questionnaireId: $questionnaireId
      entryNumber: $entryNumber
      logic: $logic
    ) {
      number
      entryItem {
        ... on DraftQuestionItem {
          questionLk
          questionLogic {
            ...DraftLogicClauseProposition
          }
        }

        ... on DraftMatrixItem {
          matrixTitleLk
          questionLogic {
            ...DraftLogicClauseProposition
          }
        }

        ... on DraftTextCardItem {
          textCardLk
          questionLogic {
            ...DraftLogicClauseProposition
          }
        }
      }
    }
  }
`)
