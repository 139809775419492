import {
  ApolloQueryResult,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery
} from '@apollo/client'
import { ScrollTo } from '@focaldata/cin-ui-components'
import {
  RemoveStandardCriterionMutationVariables,
  SetStandardCriterionMutationVariables
} from '../../data/gql-gen/fieldwork/graphql'
import { SearchCategoriesV2Query } from '../../data/gql-gen/questionnaire/graphql'
import {
  FieldworkAudienceCriterionData,
  REMOVE_CRITERION,
  SET_CRITERION
} from '../../data/gql/fieldwork/mutations/fieldworkAudienceCriterion'
import { fieldworkAudienceRefetchQuery } from '../../data/gql/fieldwork/queries/fieldworkAudience'
import { DraftAudience } from '../../data/gql/questionnaire/queries/audiences'
import { getFeasibilityEstimateRefetchQuery } from '../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { SEARCH_AUDIENCE_CATEGORIES } from '../../data/gql/questionnaire/queries/searchCategories'
import { LoggerErrorType } from '../../data/logger'
import { AUDIENCE_SCROLL_CONTAINER_ID } from '../../modules/Audience/constants'
import { SCROLL_ID_PREFIX } from '../../modules/Questionnaire/constants'
import { captureApolloError } from '../../utils/HelperFunctions'
import useGetLoi from '../useGetLoi'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import useGetDraftAudience from './useGetDraftAudience'

interface Data {
  searchCategories: SearchCategoriesV2Query['searchCategories']
  loadingSearchAudience: boolean
  audienceQuestions: DraftAudience | undefined
  setCriterion: (
    options?: MutationFunctionOptions<
      FieldworkAudienceCriterionData,
      SetStandardCriterionMutationVariables
    >
  ) => Promise<FetchResult<FieldworkAudienceCriterionData>>
  removeCriterion: (
    options?: MutationFunctionOptions<
      FieldworkAudienceCriterionData,
      RemoveStandardCriterionMutationVariables
    >
  ) => Promise<FetchResult<FieldworkAudienceCriterionData>>
  refetchCategories: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<SearchCategoriesV2Query>>
}

const useStandardAudienceListGqlOperations = (searchCriteria: string): Data => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const {
    data: audienceCategories,
    refetch: refetchCategories,
    loading: loadingSearchAudience
  } = useQuery(SEARCH_AUDIENCE_CATEGORIES, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId,
      searchCriteria
    },
    skip: !searchCriteria,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })
  const { draftAudience: audienceQuestions, refetchAudience } =
    useGetDraftAudience({
      notifyOnNetworkStatusChange: true
    })

  const loi = useGetLoi()

  const [setCriterion] = useMutation<
    FieldworkAudienceCriterionData,
    SetStandardCriterionMutationVariables
  >(SET_CRITERION, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'setCriterion', error)
    },
    onCompleted: async (data: FieldworkAudienceCriterionData) => {
      refetchCategories()
      await refetchAudience()
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (data.setStandardCriterion.criterion.code !== undefined) {
        setTimeout(() => {
          ScrollTo(
            `${SCROLL_ID_PREFIX}${data.setStandardCriterion.criterion.code}`,
            AUDIENCE_SCROLL_CONTAINER_ID
          )
        }, 0)
      }
    },
    refetchQueries: [
      getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  })

  const [removeCriterion] = useMutation<
    FieldworkAudienceCriterionData,
    RemoveStandardCriterionMutationVariables
  >(REMOVE_CRITERION, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeCriterion',
        error
      )
    },
    onCompleted: () => {
      refetchAudience()
      refetchCategories()
    },
    refetchQueries: [
      getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  })

  return {
    searchCategories: audienceCategories?.searchCategories,
    loadingSearchAudience,
    audienceQuestions,
    setCriterion,
    removeCriterion,
    refetchCategories
  }
}

export default useStandardAudienceListGqlOperations
