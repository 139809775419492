import { ScrollTo } from '@focaldata/cin-ui-components'
import React, { memo, useContext, useEffect } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  DraftStandardAudience,
  FieldworkAudience,
  FieldworkAudienceCriterionOption,
  FieldworkAudienceType,
  MemberSettingCode,
  SettingValue
} from '../../../data/model/audience'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { percentToProportion } from '../../../utils/HelperFunctions'
import AudienceContext, { setNewAudienceQuestionLk } from '../Audience.context'
import { AUDIENCE_SCROLL_CONTAINER_ID, SCROLL_ID_PREFIX } from '../constants'
import {
  getIsEnabledInSettings,
  getStandardAudienceValidationErrors
} from '../utils'
import AudienceQuestionCardControl from './AudienceQuestionCard.control'
import {
  useAudienceQuestionCardMutations,
  useSumQuotas,
  useSurveyLengthData
} from './AudienceQuestionCard.hooks'

interface Props {
  audience: FieldworkAudience
}

const AudienceQuestionCard: React.FC<Props> = (props: Props) => {
  const { audience }: Props = props
  const surveyId = useSurveyId()
  const { loi, totalCompletes } = useSurveyLengthData()
  const sumQuotas = useSumQuotas(audience, loi)
  const {
    removeCriterion,
    enableQuotas,
    disableQuotas,
    enableOption,
    disableOption,
    updateQuota,
    setAllStandardQualification,
    removeAllStandardQualification,
    setSettingValue
  } = useAudienceQuestionCardMutations(loi)
  const { draftAudience } = useGetDraftAudience()

  const { dispatch, audienceState } = useContext(AudienceContext)

  const entry: DraftStandardAudience =
    audience.memberItem as DraftStandardAudience

  const validationErrors = getStandardAudienceValidationErrors(
    draftAudience?.validationErrors,
    entry.criterion.code
  )
  useEffect(() => {
    if (
      entry.criterion.question.questionId ===
      audienceState.newAudienceQuestionLk
    ) {
      setTimeout(() => {
        ScrollTo(
          `${SCROLL_ID_PREFIX}${entry.criterion.code}`,
          AUDIENCE_SCROLL_CONTAINER_ID
        )
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDelete: () => void = () => {
    removeCriterion({
      variables: {
        surveyId,
        audienceCriterionCode: entry.criterion.code
      }
    })

    const filteredStandardAudienceCount =
      (draftAudience?.members.filter(
        (aud) => aud.memberType === FieldworkAudienceType.Standard
      ).length as number) - 1

    if (filteredStandardAudienceCount === 0)
      LogAmplitudeEvent(EventType.DeletedAllTargetingVariables, { surveyId })
  }

  const handleOptionChanged: (
    option: FieldworkAudienceCriterionOption
  ) => void = (option) => {
    const mutationVariables = {
      variables: {
        surveyId,
        audienceCriterionCode: entry.criterion.code,
        audienceCriterionOptionCode: option.criterionOption.code
      }
    }
    if (option.qualification) {
      disableOption(mutationVariables)
    } else {
      enableOption(mutationVariables)
    }
  }

  const handleToggleQuotas: () => void = () => {
    const mutationVariables = {
      variables: {
        surveyId,
        audienceCriterionCode: entry.criterion.code
      }
    }
    if (getIsEnabledInSettings(entry.settingValues, MemberSettingCode.Quotas)) {
      disableQuotas(mutationVariables)
      LogAmplitudeEvent(EventType.DisabledQuotas, {
        surveyId: mutationVariables.variables.surveyId,
        audienceCriterionCode: mutationVariables.variables.audienceCriterionCode
      })
    } else {
      enableQuotas(mutationVariables)
      LogAmplitudeEvent(EventType.EnabledQuotas, {
        surveyId: mutationVariables.variables.surveyId,
        audienceCriterionCode: mutationVariables.variables.audienceCriterionCode
      })
    }
  }

  const handleToggleAddedToSurvey = (): void => {
    if (
      getIsEnabledInSettings(
        entry.settingValues,
        MemberSettingCode.HideSurveyQuestion
      )
    ) {
      setSettingValue({
        variables: {
          surveyId,
          audienceCriterionCode: entry.criterion.code,
          setting: {
            memberSettingCode: MemberSettingCode.HideSurveyQuestion,
            settingValue: SettingValue.Disabled
          }
        }
      })
      LogAmplitudeEvent(EventType.AddTargetingToSurvey, {
        surveyId,
        audienceCriterionCode: entry.criterion.code
      })
    } else {
      setSettingValue({
        variables: {
          surveyId,
          audienceCriterionCode: entry.criterion.code,
          setting: {
            memberSettingCode: MemberSettingCode.HideSurveyQuestion,
            settingValue: SettingValue.Enabled
          }
        }
      })
      LogAmplitudeEvent(EventType.RemoveTargetingFromSurvey, {
        surveyId,
        audienceCriterionCode: entry.criterion.code
      })
    }
  }

  const handleOptionQuotaChanged: (
    option: FieldworkAudienceCriterionOption,
    newValue: number
  ) => void = (option, newValue) => {
    updateQuota({
      variables: {
        surveyId,
        audienceCriterionCode: entry.criterion.code,
        audienceCriterionOptionCode: option.criterionOption.code,
        percent: Number.isNaN(newValue) ? 0 : percentToProportion(newValue) // on the server it's 0-1
      }
    })
  }

  const handleEnteringCard: () => void = () => {
    if (audienceState.newAudienceQuestionLk !== entry.criterion.code) {
      dispatch(setNewAudienceQuestionLk(undefined))
    }
  }

  const handleChangeAll: (selected: boolean) => void = (selected) => {
    if (selected) {
      setAllStandardQualification({
        variables: {
          surveyId,
          audienceCriterionCode: entry.criterion.code
        }
      })
    } else {
      removeAllStandardQualification({
        variables: {
          surveyId,
          audienceCriterionCode: entry.criterion.code
        }
      })
    }
  }

  return (
    <AudienceQuestionCardControl
      key={audience.number}
      audience={audience}
      onEnteringCard={handleEnteringCard}
      validationErrors={validationErrors}
      totalCompletes={totalCompletes}
      onDelete={handleDelete}
      onOptionChanged={handleOptionChanged}
      onChangeAll={handleChangeAll}
      onOptionQuotaChanged={handleOptionQuotaChanged}
      onToggleQuotasState={handleToggleQuotas}
      onSumQuotas={sumQuotas}
      onToggleAddedToSurveyState={handleToggleAddedToSurvey}
    />
  )
}

export default memo(AudienceQuestionCard)
