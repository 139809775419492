import { useMutation } from '@apollo/client'
import { RemoveEntryFromSectionMutationVariables } from '../../data/gql-gen/questionnaire/graphql'
import {
  REMOVE_ENTRY_FROM_SECTION,
  RemoveEntryFromSectionData
} from '../../data/gql/questionnaire/mutations/removeEntryFromSection'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'

const useRemoveEntryFromSectionMutation = (
  projectId: string,
  surveyId: string
) => {
  const [removeEntryFromSection] = useMutation<
    RemoveEntryFromSectionData,
    RemoveEntryFromSectionMutationVariables
  >(REMOVE_ENTRY_FROM_SECTION, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeEntryFromSection',
        error
      )
    }
  })

  return { removeEntryFromSection }
}

export default useRemoveEntryFromSectionMutation
