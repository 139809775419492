import { useReactiveVar } from '@apollo/client'
import {
  FreeText,
  Grid,
  IconButton,
  IconColor,
  IconName,
  IconSize,
  Matrix,
  MultipleChoice,
  Ranked,
  SingleChoice,
  Slider,
  TextInstructions
} from '@focaldata/cin-ui-components'
import MatrixItem from '@focaldata/cin-ui-components/lib/components/SurveyQuestion/Common/Model/MatrixItem'
import QuestionItem from '@focaldata/cin-ui-components/lib/components/SurveyQuestion/Common/Model/QuestionItem'
import TextCardItem from '@focaldata/cin-ui-components/lib/components/SurveyQuestion/Common/Model/TextCardItem'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { SurveyParams } from '../../../data/model/surveyParams'
import useTransformDraftQuestionnaireToPreviewData, {
  getFreeTextLoc,
  getMatrixMultiChoiceLoc,
  getMultiChoiceLoc,
  getRankedLoc,
  getTextInstructionsLoc,
  loc,
  scaleLoc
} from '../../../hooks/questionnaire/useTransformDraftQuestionnaireToPreviewData'
import {
  checkIfFreeText,
  checkIfMultipleChoice,
  checkIfRankedChoice,
  checkIfScale,
  checkIfSingleChoice,
  isSettingEnabled
} from '../../../utils/questionnaireUtils'
import useStyles from './PreviewPanel.styles'

interface Props {
  entries: DraftQuestionnaireEntry[] | undefined
  onClose: () => void
  onRefresh: () => void
}
const PreviewPanelControl: React.FC<Props> = (props: Props) => {
  const { entries: flatEntries, onClose, onRefresh } = props
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const { classes } = useStyles()
  const { previewEntries: flattenPreviewEntries } =
    useTransformDraftQuestionnaireToPreviewData(flatEntries)
  const entryBeingEditedNumber = useReactiveVar(questionBeingEditedNumber)
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!

  const entryBeingEditedIndex = flatEntries?.some(
    (entry) => entry.number === entryBeingEditedNumber
  )
    ? // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      flattenPreviewEntries?.findIndex(
        (previewEntry) => previewEntry?.number === entryBeingEditedNumber
      )
    : 0

  const incrementIndex: (currentIndex: number) => void = (currentIndex) => {
    setCurrentIndex(currentIndex + 1)
  }
  const handleOnNext: (currentIndex: number) => void = (currentIndex) => {
    const entriesLength = flatEntries?.length || 0
    if (currentIndex !== entriesLength - 1) {
      incrementIndex(currentIndex)
    }
  }

  useEffect(() => {
    setCurrentIndex(entryBeingEditedIndex)
  }, [entryBeingEditedIndex])

  const progress = {
    current: currentIndex,
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    total: flattenPreviewEntries?.length || 0
  }
  const next = {
    disabled: false,
    loading: false,
    onNext: () => {
      handleOnNext(currentIndex)
    }
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid item container xs="auto" justifyContent="space-between">
        <Grid item className={classes.refresh}>
          <IconButton
            iconName={IconName.Refresh}
            iconSize={IconSize.Medium}
            iconColor={IconColor.Emphasis}
            ariaLabel="Refresh preview"
            onClick={() => {
              onRefresh()
              setCurrentIndex(0)
            }}
          />
        </Grid>
        <Grid item className={classes.close}>
          <IconButton
            iconName={IconName.ChevronDoubleRight}
            iconSize={IconSize.Large}
            iconColor={IconColor.Emphasis}
            ariaLabel="Collapse preview sidebar"
            onClick={() => {
              LogAmplitudeEvent(EventType.ClosedSurveyPreviewPane, {
                surveyId
              })
              onClose()
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs="auto" className={classes.previewContainer}>
        {flatEntries?.map((entry, index) => {
          const previewEntry = flattenPreviewEntries[index]
          if (progress.current === index)
            if (entry.entryType === EntryType.QuestionEntryType) {
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if ((previewEntry as QuestionItem)?.question) {
                const entryItem = entry.entryItem as DraftQuestionItem
                if (checkIfSingleChoice(entryItem)) {
                  return (
                    <SingleChoice
                      key={entry.number}
                      item={previewEntry as QuestionItem}
                      progress={progress}
                      loc={loc}
                      next={next}
                      isPreviewPanel
                    />
                  )
                }
                if (checkIfMultipleChoice(entryItem.settingValues)) {
                  return (
                    <MultipleChoice
                      key={entry.number}
                      item={previewEntry as QuestionItem}
                      progress={progress}
                      loc={getMultiChoiceLoc(entryItem)}
                      next={next}
                      isPreviewPanel
                    />
                  )
                }
                if (checkIfRankedChoice(entryItem)) {
                  return (
                    <Ranked
                      key={entry.number}
                      item={previewEntry as QuestionItem}
                      progress={progress}
                      loc={getRankedLoc(entryItem)}
                      next={next}
                      isPreviewPanel
                    />
                  )
                }
                if (checkIfFreeText(entryItem)) {
                  const type = entryItem.settingValues.find(
                    (sv) => sv.code === QuestionSettingCode.ContentStructure
                  )
                  const isCompact =
                    type &&
                    (type.value === SettingValue.Numeric1 ||
                      type.value === SettingValue.PostCode ||
                      type.value === SettingValue.Email)
                  const numericOnly =
                    type && type.value === SettingValue.Numeric1
                  return (
                    <FreeText
                      key={entry.number}
                      item={previewEntry as QuestionItem}
                      settings={{
                        isCompact,
                        numericOnly
                      }}
                      progress={progress}
                      loc={getFreeTextLoc()}
                      next={next}
                      isPreviewPanel
                    />
                  )
                }
                if (checkIfScale(entryItem)) {
                  return (
                    <Slider
                      shouldShowLabels={isSettingEnabled(
                        entryItem.settingValues,
                        QuestionSettingCode.SliderLabels
                      )}
                      key={entry.number}
                      item={previewEntry as QuestionItem}
                      progress={progress}
                      loc={scaleLoc}
                      next={next}
                      isPreviewPanel
                    />
                  )
                }
              }
            } else if (entry.entryType === EntryType.MatrixEntryType) {
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if ((previewEntry as MatrixItem)?.matrixTitle) {
                return (
                  <Matrix
                    key={entry.number}
                    item={previewEntry as MatrixItem}
                    progress={progress}
                    loc={getMatrixMultiChoiceLoc(entry.entryItem)}
                    next={next}
                    isPreviewPanel
                  />
                )
              }
            } else if (entry.entryType === EntryType.TextCardEntryType) {
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if ((previewEntry as TextCardItem)?.textCard.title) {
                return (
                  <TextInstructions
                    key={entry.number}
                    item={previewEntry as TextCardItem}
                    progress={progress}
                    loc={getTextInstructionsLoc(previewEntry as TextCardItem)}
                    next={next}
                    isPreviewPanel
                  />
                )
              }
            }
          return null
        })}
      </Grid>
    </Grid>
  )
}

export default PreviewPanelControl
