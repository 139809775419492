import {
  Select,
  SelectIconColor,
  SelectVariant,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { LoopBasis, LoopBasisSelectorOption } from '../Looping.model'

interface Props {
  isValid: boolean
  loopBasis: LoopBasis
  loopBasisSelectorOptions: LoopBasisSelectorOption[]
  onChangeLoopBasis: (loopBasis: LoopBasis) => void
}

const LoopBasisSelectorComponent = ({
  isValid,
  loopBasis,
  loopBasisSelectorOptions,
  onChangeLoopBasis
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        outline: isValid ? 'none' : '1px solid #FF4500'
      }}
    >
      <Select
        variant={SelectVariant.Underlined}
        minWidth={100}
        noWhiteSpaceAfterSelection
        textSize={TextSize.ms}
        selectedTextHighlight={TextHighlight.Standard}
        selectedTextWeight={TextWeight.SemiBold}
        options={loopBasisSelectorOptions}
        optionValue={loopBasis}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChangeLoopBasis(event.target.value as LoopBasis)
        }}
        iconColor={SelectIconColor.Secondary}
      />
    </div>
  )
}

export default LoopBasisSelectorComponent
