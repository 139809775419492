import {
  ButtonType,
  LinkButton,
  SpinnerButton,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React from 'react'
import signupSuccessSvg from '../../../assets/SignupSuccess.svg'
import useStyles from '../Auth.styles'

interface Props {
  email: string
  onClickButton: () => void
}
const SignupSuccessful: React.FC<Props> = (props: Props) => {
  const { email, onClickButton } = props

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid
      className={classes.page}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.maxWidth}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          className={classes.container}
        >
          <img
            className={classes.successLogo}
            src={signupSuccessSvg}
            alt="focaldata-logo"
          />
          <Grid item className={classes.gridItem} style={{ marginTop: '16px' }}>
            <p className={classNames(textClasses.default, textClasses.sizeL)}>
              Thank you for signing up with Focaldata.
            </p>
          </Grid>
          <Grid item container direction="row" justifyContent="center">
            <Grid item className={classes.paddingRight}>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.weightRegular,
                  textClasses.highlightBackground
                )}
              >
                We will send a confirmation email to
              </p>
            </Grid>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeM,
                textClasses.weightSemiBold,
                textClasses.highlightBackground
              )}
            >
              {email}
            </p>

            <Grid item container justifyContent="center">
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.alignCenter,
                  textClasses.weightRegular,
                  textClasses.highlightBackground
                )}
              >
                once your account has been approved.
              </p>
            </Grid>
          </Grid>
          <Grid item container className={classes.gridItem}>
            <SpinnerButton
              onClick={onClickButton}
              className={classes.button}
              fullWidth
              buttonType={ButtonType.Submit}
            >
              Back to focaldata.com
            </SpinnerButton>
          </Grid>
          <Grid
            container
            justifyContent="center"
            className={classes.helperText}
          >
            <Grid className={classes.paddingRight}>
              <p className={classNames(textClasses.default, textClasses.sizeM)}>
                Need help? Contact
              </p>
            </Grid>
            <LinkButton
              size={TextSize.m}
              weight={TextWeight.Bold}
              // onClick={onGoToLogin}
              noPadding
              underlined
            >
              support@focaldata.com
            </LinkButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignupSuccessful
