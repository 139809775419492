import {
  Grid,
  Switch,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import useAudienceQuestionsToggleVar from '../../../../hooks/localState/useAudienceQuestionsToggleVar'
import { useHasAudienceQuestions } from './AudienceQuestionsToggle.hooks'

export const AudienceQuestionsToggle = () => {
  const { shouldShowAudienceQuestions, toggleShowAudienceQuestions } =
    useAudienceQuestionsToggleVar()
  const hasAudienceQuestions = useHasAudienceQuestions()

  if (!hasAudienceQuestions) {
    return null
  }

  return (
    <Grid item xs="auto">
      <Grid container>
        <Text size={TextSize.ms} highlight={TextHighlight.Background}>
          Show audience questions
        </Text>
        <Switch
          ariaLabel="Show audience questions switch"
          checked={shouldShowAudienceQuestions}
          onChange={toggleShowAudienceQuestions}
        />
      </Grid>
    </Grid>
  )
}
