import { useFragment, useMutation, useReactiveVar } from '@apollo/client'
import debounce from 'lodash/debounce'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { UpdateQuestionMutationVariables } from '../../../data/gql-gen/questionnaire/graphql'
import { QUESTION_FRAGMENT } from '../../../data/gql/questionnaire/fragments/draftQuestionItem'
import {
  UPDATE_QUESTION,
  UpdateQuestionData
} from '../../../data/gql/questionnaire/mutations/updateQuestion'
import { LoggerErrorType } from '../../../data/logger'
import { QuestionTypeCode } from '../../../data/model/questionnaire'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import { questionCardEmptiedVar } from '../../../hooks/questionnaire/useEmptyQuestionCard'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { pipingMarkerAddedVar } from '../PipingDialog/PipingDialog.hooks'
import CardTitle from './CardTitle.control'
import { getTextFromRawTitleStyling } from './CardTitle.utils'

interface Props {
  questionLk: string
  questionTypeCode: QuestionTypeCode
  ariaLabel: string
  shouldTitleInputFocus?: boolean
  entryNumber: number
  hasError?: boolean
  placeholder?: string
  helperText?: string
  shouldHandlePasteManually?: boolean
  shouldPasteOneLine?: boolean
  onPasteInTitle?: (
    text: string,
    styledText: string,
    responseOptions: string[]
  ) => void
}

const CardTitleContainer = ({
  questionLk,
  questionTypeCode,
  entryNumber,
  ariaLabel = 'Question header input',
  shouldTitleInputFocus,
  hasError,
  placeholder = 'Type your question',
  helperText = 'Please add a question title',
  shouldHandlePasteManually = true,
  shouldPasteOneLine,
  onPasteInTitle
}: Props) => {
  const questionnaireId = useDraftQuestionnaireIdCache()

  const { data: questionFragmentData } = useFragment({
    fragment: QUESTION_FRAGMENT,
    fragmentName: 'Question',
    from: { __typename: 'Question', questionId: questionLk },
    canonizeResults: true
  })

  const pipingMarkerAdded = useReactiveVar(pipingMarkerAddedVar)
  const questionCardEmptied = useReactiveVar(questionCardEmptiedVar)

  const [titleStyling, setTitleStyling] = useState(
    questionFragmentData.textStyling
  )

  useEffect(() => {
    if (pipingMarkerAdded[questionLk]) {
      pipingMarkerAddedVar({ [questionLk]: false })
      setTitleStyling(questionFragmentData.textStyling)
    } else if (questionCardEmptied[questionLk]) {
      questionCardEmptiedVar({ [questionLk]: false })
      setTitleStyling(questionFragmentData.textStyling)
    }
  }, [
    pipingMarkerAdded,
    questionCardEmptied,
    questionFragmentData.textStyling,
    questionLk
  ])

  const [updateQuestion] = useMutation<
    UpdateQuestionData,
    UpdateQuestionMutationVariables
  >(UPDATE_QUESTION, {
    context: { clientName: 'questionnaire' }
  })

  const handleUpdateTitle = useCallback(
    (rawTitleStyling: string): void => {
      updateQuestion({
        variables: {
          questionnaireId,
          questionLk,
          questionTypeCode,
          text: getTextFromRawTitleStyling(rawTitleStyling),
          textStyling: rawTitleStyling
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'updateQuestion',
            error
          )
        }
      })
    },
    [questionLk, questionTypeCode, questionnaireId, updateQuestion]
  )

  const handleOnPaste = async (
    text: string,
    styledText: string,
    responseOptions: string[]
  ) => {
    if (onPasteInTitle) await onPasteInTitle(text, styledText, responseOptions)
  }

  const handleOnFocus = () => {
    questionBeingEditedNumber(entryNumber)
  }

  const handleOnEditorChangeDebounced = useMemo(
    () =>
      debounce((newRawTitleStyling: string) => {
        handleUpdateTitle(newRawTitleStyling)
      }, 1000),
    [handleUpdateTitle]
  )

  const handleOnEditorChange = (newRawTitleStyling: string) => {
    setTitleStyling(newRawTitleStyling)
    handleOnEditorChangeDebounced(newRawTitleStyling)
  }

  return (
    <CardTitle
      hasEditor
      shouldHandlePasteManually={shouldHandlePasteManually}
      titleValue={questionFragmentData.text}
      titleStyling={titleStyling ?? undefined}
      ariaLabel={ariaLabel}
      shouldTitleInputFocus={shouldTitleInputFocus}
      shouldPasteOneLine={shouldPasteOneLine}
      hasError={hasError}
      placeholder={placeholder}
      helperText={helperText}
      onEditorPaste={handleOnPaste}
      onFocus={handleOnFocus}
      onEditorChange={handleOnEditorChange}
    />
  )
}

export default memo(CardTitleContainer)
