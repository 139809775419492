import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useAppDispatch } from '../../../App.store'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import {
  EntryType,
  SetExclusiveMatrixRowMutationVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import { DraftQuestionnaireEntry } from '../../../data/model/questionnaire'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import { responseOptionExclusiveToggled } from '../Questionnaire.slice'
import {
  SET_EXCLUSIVE_MATRIX_COL,
  SetExclusiveMatrixColData
} from './MatrixQuestion.mutations'

export const useSetOrUnsetExclusive = (
  entry: DraftQuestionnaireEntry<EntryType.MatrixEntryType>
) => {
  const dispatch = useAppDispatch()
  const questionnaireId = useDraftQuestionnaireIdCache()
  const { matrixTitleLk } = entry.entryItem
  const [setExclusiveMatrixCol] = useMutation<
    SetExclusiveMatrixColData,
    SetExclusiveMatrixRowMutationVariables
  >(SET_EXCLUSIVE_MATRIX_COL, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      questionBeingEditedNumber(entry.number)
    }
  })

  const setOrUnsetExclusive = useCallback(
    (responseOptionLk: string, isExclusive: boolean) => {
      dispatch(
        responseOptionExclusiveToggled({
          questionLk: matrixTitleLk,
          responseOptionLk,
          exclusive: isExclusive
        })
      )
      setExclusiveMatrixCol({
        variables: {
          questionnaireId,
          matrixTitleLk,
          responseOptionLk,
          exclusive: isExclusive
        }
      })
    },
    [matrixTitleLk, questionnaireId, setExclusiveMatrixCol, dispatch]
  )

  return setOrUnsetExclusive
}
