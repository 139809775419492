import { useMutation } from '@apollo/client'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BreadcrumbLinkVariant,
  Breadcrumbs,
  BreadcrumbsOption
} from '../../../../components/Breadcrumbs'
import { UpdateFieldworkMutationVariables } from '../../../../data/gql-gen/fieldwork/graphql'
import { UPDATE_FIELDWORK } from '../../../../data/gql/fieldwork/mutations/updateFieldwork'
import { LoggerErrorType } from '../../../../data/logger'
import { SurveyParams } from '../../../../data/model/surveyParams'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import useCalculateLengthOfInterview from '../../../../hooks/useCalculateLengthOfInterview'
import { useGetFeasibilityEstimateData } from '../../../../hooks/useGetFeasibilityEstimateData'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import { newEntryId } from '../../../../utils/questionnaireUtils'

const getBreadcrumbLinkVariantFromUrl = (
  url: string,
  routeName: string
): BreadcrumbLinkVariant => {
  if (url.endsWith(routeName)) {
    return BreadcrumbLinkVariant.Selected
  }
  return BreadcrumbLinkVariant.Selectable
}

const BreadcrumbNavigation: React.FC = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const navigate = useNavigate()

  const { draftQuestionnaire } = useGetDraftQuestionnaire()

  const calculatedLengthOfInterview = useCalculateLengthOfInterview()

  const [updateFieldwork] = useMutation<void, UpdateFieldworkMutationVariables>(
    UPDATE_FIELDWORK,
    {
      context: { clientName: 'fieldwork' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'updateFieldwork',
          error
        )
      }
    }
  )

  const handleToConfirmationClick: () => void = async () => {
    await updateFieldwork({
      variables: {
        surveyId,
        panelSupplierCode: 'cint',
        lengthOfInterview: calculatedLengthOfInterview,
        samplesNeeded: null,
        startDate: null,
        endDate: null,
        countryCode: null
      }
    })
    newEntryId(undefined)

    navigate('confirm')
  }

  const entryQuestionsCount = draftQuestionnaire?.entries.length
  const isQuestionnairePresent = entryQuestionsCount
    ? entryQuestionsCount !== 0
    : false

  const { isFeasible, isAudienceValid } = useGetFeasibilityEstimateData()
  const { hasValidationErrors } = useQuestionnaireValidation()
  const isQuestionnaireEmpty = draftQuestionnaire?.entries.length === 0

  const breadcrumbsOption: BreadcrumbsOption[] = [
    {
      name: 'Audience',
      onClick: () => navigate('audience'),
      linkVariant: getBreadcrumbLinkVariantFromUrl(
        window.location.pathname,
        '/audience'
      )
    },
    {
      name: 'Survey',
      onClick: () => navigate('questionnaire'),
      linkVariant:
        isFeasible || !isQuestionnaireEmpty
          ? getBreadcrumbLinkVariantFromUrl(
              window.location.pathname,
              '/questionnaire'
            )
          : BreadcrumbLinkVariant.Disabled
    },
    {
      name: 'Confirm',
      onClick: handleToConfirmationClick,
      linkVariant:
        isFeasible &&
        isQuestionnairePresent &&
        isAudienceValid &&
        !hasValidationErrors()
          ? getBreadcrumbLinkVariantFromUrl(
              window.location.pathname,
              '/confirm'
            )
          : BreadcrumbLinkVariant.Disabled
    }
  ]

  return <Breadcrumbs options={breadcrumbsOption} />
}

export default BreadcrumbNavigation
