import { fieldworkGql } from '../../../gql-gen/fieldwork'

export const COUNTRIES = fieldworkGql(/* GraphQL */ `
  query countries {
    countries {
      name
      countryCode
      languageName
      locale {
        language
        country
      }
    }
  }
`)
