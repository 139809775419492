import { additionalPalette, makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  bold: { fontWeight: theme.typography.fontWeightBold as any },
  button: {
    borderRadius: 0,
    boxShadow: 'none',
    height: theme.spacing(7),
    marginTop: theme.spacing(2),
    width: '100%'
  },
  subtitle: {
    margin: theme.spacing(2, 0, 5, 0),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(2, 0, 4, 0)
    }
  },
  marginTop: { marginTop: theme.spacing(4) },
  hoverOrange: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      background: 'none',
      '& > span': {
        color: theme.palette.primary.main,
        background: 'none'
      }
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: '25ch'
  },
  linkText: {
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'none'
    }
  },
  container: {
    backgroundColor: '#FFF',
    padding: theme.spacing(4),
    boxShadow: '16px 16px 0px 0px #000000'
  },
  signupContainer: {
    borderRadius: 2,
    backgroundColor: '#FFF',
    padding: theme.spacing(4, 4, 0, 4)
  },
  gridItem: {
    padding: theme.spacing(1, 2, 1, 2)
  },
  maxWidth: {
    width: 500
  },
  page: {
    height: '100vh',
    backgroundColor: theme.palette.primary.main
  },
  lastName: {
    paddingLeft: theme.spacing(2)
  },
  firstName: {
    paddingRight: theme.spacing(2)
  },
  successLogo: {
    height: 30,
    width: 35,
    padding: theme.spacing(2)
  },
  paddingRight: {
    paddingRight: theme.spacing(0.7)
  },
  helperText: {
    marginTop: theme.spacing(2)
  },
  signupHelperText: {
    marginTop: theme.spacing(2)
  },
  passwordChecklist: {
    padding: theme.spacing(1, 2)
  },
  checkListTitle: {
    paddingBottom: theme.spacing(1)
  },
  checkListItem: {
    height: theme.spacing(4),
    padding: theme.spacing(0.5, 0)
  },
  uncheckedCircle: {
    margin: theme.spacing(0, 0.25),
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    borderRadius: 50,
    backgroundColor: additionalPalette.lightGrey.main
  }
}))

export default useStyles
