import { gql } from '@apollo/client'

export interface ImportQuestionnaireEntriesData {
  importQuestionnaireEntries: unknown
}

export const IMPORT_QUESTIONNAIRE_ENTRIES = gql`
  mutation importQuestionnaireEntries(
    $questionnaireId: ID!
    $sourceQuestionnaireId: ID!
    $entryNumbers: [Int!]!
    $position: Int
  ) {
    importQuestionnaireEntries(
      questionnaireId: $questionnaireId
      sourceQuestionnaireId: $sourceQuestionnaireId
      entryNumbers: $entryNumbers
      position: $position
    ) {
      questionnaireId
    }
  }
`
