import { gql } from '@apollo/client/core'
import { FdChatSpecification } from './FdChat.model'

export interface FdChatSpecificationData {
  questionnaire: { fdChatSpecification: FdChatSpecification }
}

export const FD_CHAT_SPECIFICATION = gql`
  query questionnaire($surveyId: ID!) {
    questionnaire: questionnaire(surveyId: $surveyId) {
      fdChatSpecification {
        ... on AdHocTestingSpecification {
          fdChatSpecificationId
          maxNumberQuestions
          goal
          questions
          themes
          context
          maxLengthOfInterview
        }
      }
    }
  }
`
