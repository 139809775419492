import { useMutation } from '@apollo/client'
import {
  RemoveMatrixRowMediaMutationVariables,
  SetMatrixRowMediaMutationVariables
} from '../../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import {
  REMOVE_MATRIX_ROW_MEDIA,
  SET_MATRIX_ROW_MEDIA
} from './MatrixRowMedia.mutations'

export const useMatrixRowMediaActions = () => {
  const [setMatrixRowMedia] = useMutation<
    void,
    SetMatrixRowMediaMutationVariables
  >(SET_MATRIX_ROW_MEDIA, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addMatrixRowImage',
        error
      )
    }
  })

  const [removeMatrixRowMedia] = useMutation<
    void,
    RemoveMatrixRowMediaMutationVariables
  >(REMOVE_MATRIX_ROW_MEDIA, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeMatrixRowImage',
        error
      )
    }
  })

  return {
    setMatrixRowMedia,
    removeMatrixRowMedia
  }
}
