import { IconName, MenuOption } from '@focaldata/cin-ui-components'
import React, { memo, useState } from 'react'
import { useAppSelector } from '../../../App.store'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { emptyArr } from '../../../constants/misc'
import { ForkTag } from '../../../data/gql-gen/questionnaire/graphql'
import { Operator } from '../../../data/model/common'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  QuestionKind,
  QuestionTypeCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { IsNonManualUIChange } from '../../../hooks/copyPaste/useCopyPasteComplete'
import { useEmptyQuestionCardMutation } from '../../../hooks/questionnaire/useEmptyQuestionCard'
import useForkMutations from '../../../hooks/questionnaire/useForkMutations'
import useLoopSpecificDisplayLogicMutations from '../../../hooks/questionnaire/useLoopSpecificDisplayLogic/useLoopSpecificDisplayLogic.mutations'
import useQuestionLogic from '../../../hooks/questionnaire/useQuestionLogic'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { ClauseBlockContainer } from '../../../utils/questionLogic'
import {
  getLetterPrefix,
  getQuestionType
} from '../../../utils/questionnaireUtils'
import DisplayLogicHeader from '../DisplayLogicHeader'
import { selectQuestionLogicByQuestionId } from '../Questionnaire.slice'
import DialogAddDisplayLogic from './DialogAddDisplayLogic'
import DialogAddLoopSpecificDisplayLogic from './DialogAddLoopSpecificDisplayLogic'
import IdContent, { QuestionTypeString } from './IdContent'
import SurveyQuestionCardHeader from './SurveyQuestionCardHeader.control'

export enum QuestionType {
  SingleChoice,
  MultipleChoice,
  Ranked,
  Scale,
  FreeText,
  Matrix
}

interface Props {
  entry: DraftQuestionnaireEntry
  hasError?: boolean
  isMultipleChoice?: boolean
  disabledDuplicate?: boolean
  entries?: DraftQuestionnaireEntry[]
  titleContent?: JSX.Element
  onQuestionTypeChange?: (settingValue: SettingValue) => void
  onClickDuplicateIcon?: () => void
}

const SurveyQuestionCardHeaderContainer: React.FC<Props> = (props: Props) => {
  const {
    entry,
    hasError = false,
    isMultipleChoice = false,
    disabledDuplicate,
    entries = emptyArr,
    titleContent,
    onClickDuplicateIcon,
    onQuestionTypeChange
  } = props
  const [displayLogicDialogOpen, setDisplayLogicDialogOpen] = useState(false)
  const [
    loopSpecificDisplayLogicDialogOpen,
    setLoopSpecificDisplayLogicDialogOpen
  ] = useState(false)
  const { setQuestionLogic, removeLogic, loading } = useQuestionLogic()
  const { setLoopSpecificDisplayLogic, removeLoopSpecificDisplayLogic } =
    useLoopSpecificDisplayLogicMutations()
  const { addForksToEntry, removeForksFromEntry } = useForkMutations()
  const questionCardType = getQuestionType(entry)
  const questionCodeMap: Map<QuestionTypeCode, QuestionTypeString> = new Map<
    QuestionTypeCode,
    QuestionTypeString
  >([
    [
      QuestionTypeCode.Basic,
      isMultipleChoice ? 'Multi select' : 'Single select'
    ],
    [QuestionTypeCode.Ranked, 'Ranked'],
    [QuestionTypeCode.FreeText, 'Free text'],
    [QuestionTypeCode.Scale, 'Slider'],
    [QuestionTypeCode.TextCard, 'Text'],
    [QuestionTypeCode.Matrix, 'Matrix'],
    [QuestionTypeCode.Section, 'Section'],
    [QuestionTypeCode.MaxDiff, 'Max Diff']
  ])

  const {
    number: entryNumber,
    position: entryPosition,
    entryType,
    sectionId
  } = entry
  const entryItem = entry.entryItem as DraftQuestionItem
  const entryMatrixItem = entry.entryItem as DraftMatrixItem
  const questionLogic =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectQuestionLogicByQuestionId(state, entry.entryId)
    ) || []
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const position = entryPosition ?? 0
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const contextPosition = entry.contextPosition ?? 0
  const { forks } = entry
  const typeName = questionCodeMap.get(questionCardType)
  const letterPrefix = getLetterPrefix(entryType)
  const isBasicQuestion = questionCardType === QuestionTypeCode.Basic
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const surveyHasAudienceQuestions = entries?.some(
    (entry) => entry.questionKind === QuestionKind.AudienceKind
  )
  const { emptyQuestionCardMutation, emptyMatrixQuestionMutation } =
    useEmptyQuestionCardMutation()

  const surveyId = useSurveyId()

  const handleEmptyQuestionCard = async () => {
    IsNonManualUIChange(true)
    if (
      [QuestionTypeCode.Basic, QuestionTypeCode.Ranked].includes(
        questionCardType
      )
    )
      await emptyQuestionCardMutation(entryItem.questionLk)
    else if (questionCardType === QuestionTypeCode.Matrix) {
      await emptyMatrixQuestionMutation(entryMatrixItem.matrixTitleLk)
    }
    LogAmplitudeEvent(EventType.EmptiedQuestionCard, {
      surveyId,
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      matrixTitleLk: entryMatrixItem?.matrixTitleLk,
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      questionLk: entryItem?.questionLk
    })
  }

  const handleRemoveLogic: () => void = () => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (entry) {
      questionBeingEditedNumber(entry.number)
      removeForksFromEntry(entry)
      removeLogic(entry)
    }
  }
  const handleRemoveLoopBasedLogic: () => void = () => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (entry) {
      questionBeingEditedNumber(entry.number)
      removeLoopSpecificDisplayLogic(entry.number)
    }
  }
  const simpleMenu: MenuOption[] = [
    {
      id: 0,
      options: [
        {
          text: 'Single select',
          onClick: () => {
            if (onQuestionTypeChange) {
              onQuestionTypeChange(SettingValue.SingleChoice)
            }
          },
          iconName: IconName.SingleChoice
        },
        {
          text: 'Multi select',
          onClick: () => {
            if (onQuestionTypeChange) {
              onQuestionTypeChange(SettingValue.MultipleChoice)
            }
          },
          iconName: IconName.MultipleChoice
        }
      ]
    }
  ]
  const handleOnSave: (
    entry: DraftQuestionnaireEntry | undefined,
    clauseBlockContainers: ClauseBlockContainer[],
    operator: Operator,
    forkTags: ForkTag[]
  ) => void = async (entry, clauseBlockContainers, operator, forkTags) => {
    if (entry) {
      questionBeingEditedNumber(entry.number)
      await removeForksFromEntry(entry)
      await addForksToEntry(entry, forkTags)

      const clauseBlocks = clauseBlockContainers.flatMap(
        (container) => container.clauseBlocks
      )

      await setQuestionLogic(entry, clauseBlocks, operator)

      const negatedClauseBlocks = clauseBlocks.filter(
        (clauseBlock) => clauseBlock.negated
      )

      LogAmplitudeEvent(EventType.AddDisplayLogicToQuestion, {
        surveyId,
        entryNumber: entry.number,
        hasNegatedClauseBlocks: negatedClauseBlocks.length > 0,
        numOfNegatedClauseBlocks: negatedClauseBlocks.length
      })
    }
    if (!loading) {
      setDisplayLogicDialogOpen(false)
    }
  }

  const handleSaveLoopSpecificDisplayLogic = (
    entryNumber: number,
    sourceIds: string[]
  ) => {
    setLoopSpecificDisplayLogic(entryNumber, sourceIds)
  }

  return (
    <>
      <SurveyQuestionCardHeader
        entry={entry}
        disabledDuplicate={disabledDuplicate}
        disableDisplayLogic={entryPosition === 0 && !surveyHasAudienceQuestions}
        onClickDuplicateIcon={onClickDuplicateIcon}
        onClickAddDisplayLogic={() => {
          setDisplayLogicDialogOpen(true)
        }}
        onClickAddLoopSpecificDisplayLogic={() => {
          setLoopSpecificDisplayLogicDialogOpen(true)
        }}
        onEmptyQuestionCard={handleEmptyQuestionCard}
        idContent={
          <IdContent
            hasError={hasError}
            letterPrefix={letterPrefix}
            isBasicQuestion={isBasicQuestion}
            typeName={typeName}
            simpleMenu={simpleMenu}
            contextPosition={contextPosition}
          />
        }
        logicContent={
          <DisplayLogicHeader
            questionLogic={questionLogic}
            entry={entry}
            forks={forks}
            entries={entries}
            onEditDisplayLogic={() => {
              setDisplayLogicDialogOpen(!displayLogicDialogOpen)
            }}
            onRemoveDisplayLogic={() => {
              handleRemoveLogic()
            }}
            onEditLoopBasedDisplayLogic={() => {
              setLoopSpecificDisplayLogicDialogOpen(
                !loopSpecificDisplayLogicDialogOpen
              )
            }}
            onRemoveLoopBasedDisplayLogic={() => {
              handleRemoveLoopBasedLogic()
            }}
          />
        }
        titleContent={titleContent}
      />
      {displayLogicDialogOpen && (
        <DialogAddDisplayLogic
          entryPosition={position}
          contextPosition={contextPosition}
          open={displayLogicDialogOpen}
          onClose={() => {
            setDisplayLogicDialogOpen(false)
          }}
          isSaving={loading}
          onSave={(
            entry,
            clauseBlockContainer: ClauseBlockContainer[],
            operator: Operator,
            forkTags: ForkTag[]
          ) => {
            handleOnSave(entry, clauseBlockContainer, operator, forkTags)
          }}
        />
      )}
      {loopSpecificDisplayLogicDialogOpen && (
        <DialogAddLoopSpecificDisplayLogic
          sectionId={sectionId}
          entryNumber={entryNumber}
          contextPosition={contextPosition}
          open={loopSpecificDisplayLogicDialogOpen}
          onClose={() => {
            setLoopSpecificDisplayLogicDialogOpen(false)
          }}
          onSave={handleSaveLoopSpecificDisplayLogic}
          isSaving={loading}
        />
      )}
    </>
  )
}
export default memo(SurveyQuestionCardHeaderContainer)
