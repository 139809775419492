import { gql } from '@apollo/client/core'
import { DraftEntryResponseOption } from '../../../model/questionnaire'
import DRAFT_ENTRY_RESPONSE_OPTION from '../fragments/draftEntryResponseOption'

export interface AddResponseOptionData {
  addResponseOption: DraftEntryResponseOption
}

export const ADD_RESPONSE_OPTION = gql`
  mutation addResponseOption(
    $questionnaireId: ID!
    $questionLk: ID!
    $position: Int!
    $responseOptionValue: String!
  ) {
    addResponseOption(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      position: $position
      responseOption: {
        responseOptionValue: $responseOptionValue
        responseOptionType: RoString
      }
    ) {
      ...DraftEntryResponseOption
    }
  }
  ${DRAFT_ENTRY_RESPONSE_OPTION}
`
