import { useMutation } from '@apollo/client'
import { useAppDispatch } from '../../../App.store'
import {
  RemoveSectionsRandomisationMutationVariables,
  SetSectionsRandomisationMutationVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../data/logger'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import {
  RemoveSectionsRandomisationData,
  SetSectionsRandomisationData
} from './SectionsRandomisation.model'
import {
  REMOVE_SECTIONS_RANDOMISATION,
  SET_SECTIONS_RANDOMISATION
} from './SectionsRandomisation.mutations'
import {
  randomisedSectionsSet,
  sectionsRandomisationRemoved
} from './SectionsRandomisation.slice'

export const useSetSectionsRandomisation = () => {
  const questionnaireId = useSurveyId()
  const dispatch = useAppDispatch()

  const [setSectionsRandomisationMutation] = useMutation<
    SetSectionsRandomisationData,
    SetSectionsRandomisationMutationVariables
  >(SET_SECTIONS_RANDOMISATION, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setSectionsRandomisation',
        error
      )
    }
  })

  const setSectionsRandomisation = (
    sectionIds: SetSectionsRandomisationMutationVariables['sectionIds']
  ) => {
    return setSectionsRandomisationMutation({
      variables: {
        questionnaireId,
        sectionIds
      },
      onCompleted(data) {
        dispatch(randomisedSectionsSet(data.setSectionsRandomisation))
      }
    })
  }

  return setSectionsRandomisation
}

export const useRemoveSectionsRandomisation = () => {
  const questionnaireId = useSurveyId()
  const dispatch = useAppDispatch()

  const [removeSectionsRandomisationMutation] = useMutation<
    RemoveSectionsRandomisationData,
    RemoveSectionsRandomisationMutationVariables
  >(REMOVE_SECTIONS_RANDOMISATION, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeSectionsRandomisation',
        error
      )
    }
  })

  const removeSectionsRandomisation = () => {
    return removeSectionsRandomisationMutation({
      variables: {
        questionnaireId
      },
      onCompleted() {
        dispatch(sectionsRandomisationRemoved())
      }
    })
  }

  return removeSectionsRandomisation
}
