import { useMutation, useReactiveVar } from '@apollo/client'
import {
  Dialog,
  ExclusiveButton,
  IconColor,
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption,
  ListItemQuestionnaireResponseOption,
  RoutingButton,
  RoutingMenu,
  RoutingMenuItem,
  TextEntryButton,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../../../apollo/apolloClient'
import {
  QuestionDialogItem,
  QuestionsDialog
} from '../../../../components/QuestionsDialog'
import {
  CreateOrUpdateMaskingRuleMutationVariables,
  DraftMaskingRule
} from '../../../../data/gql-gen/questionnaire/graphql'
import {
  DraftEntryResponseOption,
  DraftQuestionnaireEntry,
  EntryType,
  MediaType,
  PositionTextSelection,
  QuestionSettingCode,
  QuestionTypeCode,
  TextEntryState
} from '../../../../data/model/questionnaire'
import { SurveyParams } from '../../../../data/model/surveyParams'
import useCopyPasteComplete, {
  onChangePasteAcceptingInput
} from '../../../../hooks/copyPaste/useCopyPasteComplete'
import useDraftQuestionnaireIdCache from '../../../../hooks/localState/useDraftQuestionnaireIdCache'
import { isNonEmptyResponseError } from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import { useDebounceEffect } from '../../../../hooks/useDebounce'
import { useDisplayLogicDetector } from '../../../../hooks/useDisplayLogicDetector.hooks'
import {
  addResponseOptionTransactionDatadog,
  deleteResponseOptionTransactionDatadog,
  moveResponseOptionTransactionDatadog,
  pasteInResponseOptionTransactionDatadog,
  pasteInTitleTransactionDatadog
} from '../../../../tracking/perf/transactions'
import {
  isLastEntry,
  isSettingEnabled,
  newEntryId
} from '../../../../utils/questionnaireUtils'
import {
  MaskingButton,
  MaskingDialogContext
} from '../../Masking/MaskingButton'
import {
  CREATE_OR_UPDATE_MASKING_RULE,
  CreateOrUpdateMaskingRuleData
} from '../../Masking/MaskingButton/MaskingButton.mutations'
import RowMediaUploader from '../../MediaUploader/MediaUploader'
import {
  PinResponse,
  PinnedItemType,
  usePinResponseMutations
} from '../../PinResponse'
import {
  responseOptionImageRemoved,
  responseOptionImageSet,
  selectSettingsByQuestionId
} from '../../Questionnaire.slice'
import { useBasicEntryContext } from '../BasicQuestion.container'
import { useResponseOptionMediaActions } from './ResponseOptionMedia.hooks'
import { ROUTE_TO_END_SURVEY_NUMBER } from './ResponseOptionsList.control'

interface Props {
  entries: DraftQuestionnaireEntry[] | undefined
  routingText: string | undefined
  ariaLabel: string
  responseOption: DraftEntryResponseOption
  showEndOfSurveyRouting?: boolean
  shouldAutoFocus: boolean
  filteredRoutingMenuItems: RoutingMenuItem[] | undefined
  cannotDelete: boolean
  questionLk: string
  entryNumber: number
  routingDisabled: boolean
  maskingEnabled: boolean
  errorText: string
  onEnter: (position: number) => void
  onFocus: () => void
  onClickDeleteResponseOption: (
    questionLk: string,
    responseOptionLk: string
  ) => void
  onChangeResponseOption: (inputValue: string, responseOptionLk: string) => void
  onRoutingMenuItemClick: (
    targetNumber: number | null,
    responseOptionLk: string
  ) => void
  onSetExclusiveOption?: (
    responseOptionLk: string,
    isExclusive: boolean
  ) => void
  onUpdateTextEntryState?: (
    responseOptionLk: string,
    newTextEntryState: TextEntryState
  ) => void
}

const ResponseOption: React.FC<Props> = (props: Props) => {
  const {
    responseOption,
    ariaLabel = 'Basic response option',
    shouldAutoFocus,
    entries: flatEntries,
    questionLk,
    entryNumber,
    routingText,
    maskingEnabled,
    showEndOfSurveyRouting,
    filteredRoutingMenuItems,
    cannotDelete,
    routingDisabled,
    errorText,
    onEnter: addResponseOption,
    onFocus,
    onClickDeleteResponseOption,
    onChangeResponseOption,
    onRoutingMenuItemClick,
    onSetExclusiveOption,
    onUpdateTextEntryState
  }: Props = props
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { setPinnedResponseOption } = usePinResponseMutations()

  const entryItem = useBasicEntryContext()

  const [pendingDeletionPosition, setPendingDeletionPosition] = useState<
    number | undefined
  >(undefined)
  const [isRoutingPickerOpen, setIsRoutingPickerOpen] = useState(false)
  const [isMaskingDialogOpen, setIsMaskingDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [responseOptionValue, setResponseOptionValue] = useState<
    string | undefined
  >( // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    responseOption.responseOption && responseOption.responseOption?.value
  )
  const [positionSelectedText, setPositionSelectedText] =
    useState<PositionTextSelection>({
      startPositionSelectedText: 0,
      endPositionSelectedText: 0
    })
  const newlyAddedEntryId = useReactiveVar(newEntryId)
  const [hasBeenFocused, setHasBeenFocused] = useState<boolean>(false)
  const [isImageDialogOpened, setIsImageDialogOpened] = useState(false)
  const dispatch = useAppDispatch()
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) => selectSettingsByQuestionId(state, questionLk)) ||
    []
  const { setResponseOptionMedia, removeResponseOptionMedia } =
    useResponseOptionMediaActions()
  const disableRouting = isLastEntry(flatEntries, entryNumber)

  const isTextEntry =
    responseOption.textEntryState === TextEntryState.TextEntryEnabled
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const maskingRules = responseOption?.maskingRules

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const questionnaireId = useDraftQuestionnaireIdCache()

  const { pasteToResponseOption } = useCopyPasteComplete(questionLk)

  const getQPrefixForQsWithDisplayLogicBasedOnResponse =
    useDisplayLogicDetector()

  const questionsPrefix = useMemo(
    () =>
      getQPrefixForQsWithDisplayLogicBasedOnResponse(
        responseOption.responseOptionLk
      ),
    [
      responseOption.responseOptionLk,
      getQPrefixForQsWithDisplayLogicBasedOnResponse
    ]
  )

  const debounceDelayMs = 500

  const pasteInResponseOption = useCallback(
    async (
      text: string,
      position: number,
      positionSelectedText: PositionTextSelection
    ) => {
      pasteInResponseOptionTransactionDatadog.start()
      questionBeingEditedNumber(entryNumber)
      await pasteToResponseOption(
        text,
        questionLk,
        position,
        positionSelectedText
      )
    },
    [questionLk, entryNumber, pasteToResponseOption]
  )

  const handleOnFocusedWithDelay: (delay?: number) => void = (
    delay = debounceDelayMs
  ) => {
    if (hasBeenFocused) return

    setTimeout(() => {
      setHasBeenFocused(true)
    }, delay)
  }

  const triggerResponseOptionChanged: () => void = () => {
    if (
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      responseOptionValue !== null &&
      responseOptionValue !== undefined && // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (!responseOption.responseOption || // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        responseOptionValue !== responseOption.responseOption?.value)
    ) {
      onChangeResponseOption(
        responseOptionValue,
        responseOption.responseOptionLk
      )
      handleOnFocusedWithDelay(100)
    }
  }

  const [createOrUpdateMaskingRule] = useMutation<
    CreateOrUpdateMaskingRuleData,
    CreateOrUpdateMaskingRuleMutationVariables
  >(CREATE_OR_UPDATE_MASKING_RULE, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  useDebounceEffect<string | undefined>(
    () => {
      triggerResponseOptionChanged()
    },
    responseOptionValue,
    { delay: debounceDelayMs }
  )

  useEffect(
    () => {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      setResponseOptionValue(responseOption.responseOption?.value)
    }, // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    [responseOption.responseOption?.value]
  )

  useMount(() => {
    addResponseOptionTransactionDatadog.end()
    pasteInResponseOptionTransactionDatadog.end()
    pasteInTitleTransactionDatadog.end()
    moveResponseOptionTransactionDatadog.end()
  })

  useUnmount(() => {
    deleteResponseOptionTransactionDatadog.end()
  })

  const showRoutingBadge = routingText !== undefined

  const hasTempId = /^responseOptionLk-\d+$/.test(
    responseOption.responseOptionLk
  )
  const isEditable = responseOption.createdDate !== '' && !hasTempId

  const handleEnterResponseOption = () => {
    addResponseOption(responseOption.position + 1)
  }

  if (responseOption.position === pendingDeletionPosition) {
    return null
  }

  const isRandomiseOn = isSettingEnabled(
    settingValues,
    QuestionSettingCode.RandomiseOptions
  )

  const handleResponseOptionSelectedText = (
    selectionStart?: number | null,
    selectionEnd?: number | null
  ) => {
    setPositionSelectedText({
      startPositionSelectedText: selectionStart,
      endPositionSelectedText: selectionEnd
    })
  }

  const deleteResponse = () => {
    setPendingDeletionPosition(responseOption.position)
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (entryItem?.questionLk) {
      onClickDeleteResponseOption(
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        entryItem?.questionLk,
        responseOption.responseOptionLk
      )
    }
    LogAmplitudeEvent(EventType.DeletedResponseOption, {
      surveyId,
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      questionId: entryItem?.question.questionId
    })
  }

  const handleDelete = () => {
    if (questionsPrefix.length > 0) {
      setIsDeleteDialogOpen(true)
    } else {
      deleteResponse()
    }
  }

  const handleDeleteDialogConfirm = () => {
    setIsDeleteDialogOpen(false)
    deleteResponse()
  }

  const handleInputChange: (value: string) => void = (value) => {
    onChangePasteAcceptingInput(() => setResponseOptionValue(value))
  }
  const routingMenuItems = isRoutingPickerOpen
    ? filteredRoutingMenuItems
    : undefined

  const handleRoTextEntryState = (newTextEntryState: TextEntryState) => {
    onUpdateTextEntryState?.(responseOption.responseOptionLk, newTextEntryState)
  }

  const disableTextEntry = () => {
    handleRoTextEntryState(TextEntryState.TextEntryDisabled)
  }

  const handlePinResponseOption = () => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (entryItem) {
      setPinnedResponseOption(
        entryItem.questionLk,
        responseOption.responseOptionLk,
        true
      )
    }
  }

  const handleOpenMaskingDialog = () => {
    setIsMaskingDialogOpen(true)
  }
  const isMaxDiffQuestion =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem?.questionTypeCode === QuestionTypeCode.MaxDiff

  const responseActions: KebabMenuOption[] = []

  if (!isMaxDiffQuestion) {
    responseActions.push({
      id: 2,
      textItem: 'Add display logic',
      disabled:
        // OR if this question is the first one and there are no other questions before it
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !maskingEnabled || (maskingRules && maskingRules.length > 0),
      iconName: IconName.Visibility,
      onClickItem: handleOpenMaskingDialog
    })
    responseActions.push({
      id: 3,
      textItem: 'Pin response',
      disabled: responseOption.pinned || !isRandomiseOn,
      iconName: IconName.PushPin,
      onClickItem: handlePinResponseOption
    })
    responseActions.push({
      id: 4,
      textItem: 'Add skip logic',
      disabled: showRoutingBadge || routingDisabled,
      iconName: IconName.TimelineOutlined,
      hasDivider: true,
      onClickItem: () => setIsRoutingPickerOpen(true)
    })
  }

  // onSetExclusiveOption is available only for matrix and for multi-select
  if (onSetExclusiveOption) {
    responseActions.push({
      id: 5,
      textItem: 'Make exclusive',
      iconName: IconName.Filter1Outlined,
      disabled: responseOption.exclusive,
      onClickItem: () => {
        onSetExclusiveOption(responseOption.responseOptionLk, true)

        LogAmplitudeEvent(EventType.MadeResponseExclusive, {
          surveyId,
          responseOptionValue: responseOption.responseOption.value
        })
      }
    })
  }

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (entryItem?.questionTypeCode === QuestionTypeCode.Basic) {
    responseActions.push({
      id: 6,
      textItem: 'Enable text entry',
      disabled: isTextEntry,
      iconName: IconName.TextFields,
      onClickItem: () => {
        handleRoTextEntryState(TextEntryState.TextEntryEnabled)

        LogAmplitudeEvent(EventType.EnabledTextEntry, {
          surveyId,
          responseOptionValue: responseOption.responseOption.value
        })
      }
    })
  }

  const handleOpenAddFileDialog = () => {
    setIsImageDialogOpened(true)
  }

  if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem?.questionTypeCode === QuestionTypeCode.Basic || // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem?.questionTypeCode === QuestionTypeCode.Ranked
  ) {
    responseActions.push({
      id: 7,
      textItem: 'Add image',
      iconName: IconName.Image,
      disabled: !!responseOption.media,
      onClickItem: handleOpenAddFileDialog
    })
  }

  const lastItemInResponseActions = responseActions[responseActions.length - 1]

  // last item in menu should not have divider line
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (lastItemInResponseActions?.hasDivider) {
    lastItemInResponseActions.hasDivider = false
  }

  const handelExclusiveIconClick = onSetExclusiveOption
    ? () => {
        onSetExclusiveOption(responseOption.responseOptionLk, false)
      }
    : undefined

  const handleMediaUpload = async (
    questionnaireId: string,
    questionLk: string,
    responseOptionLk: string,
    mediaUrl: string,
    mediaName: string,
    mediaType: MediaType,
    renderedMediaUrl: string
  ) => {
    dispatch(
      responseOptionImageSet({
        questionLk,
        responseOptionLk,
        mediaName,
        mediaUrl,
        renderedMediaUrl
      })
    )
    await setResponseOptionMedia({
      variables: {
        questionnaireId,
        questionLk,
        responseOptionLk,
        mediaUrl,
        mediaName,
        mediaType,
        renderedMediaUrl
      }
    })
  }

  const handleRemoveMedia = async (
    questionnaireId: string,
    questionLk: string,
    responseOptionLk: string
  ) => {
    dispatch(
      responseOptionImageRemoved({
        questionLk,
        responseOptionLk
      })
    )
    await removeResponseOptionMedia({
      variables: {
        questionnaireId,
        questionLk,
        responseOptionLk
      }
    })
  }

  const handleCreateOrUpdateMaskingRule = async (
    maskingRules: DraftMaskingRule[]
  ) => {
    await createOrUpdateMaskingRule({
      variables: {
        questionnaireId: surveyId,
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        questionLk: entryItem?.questionLk,
        responseOptionLk: responseOption.responseOptionLk,
        maskingRules
      }
    })
  }

  const closeDeleteResponseDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  let deleteDialogText = ''
  if (questionsPrefix.length > 0) {
    deleteDialogText = `Deleting this response will also delete the display logic related to this question at ${questionsPrefix.join(
      ', '
    )}.`
  }
  const isNewlyAddedEntryId = newlyAddedEntryId === questionLk
  const canShowError =
    !!errorText &&
    (hasBeenFocused ||
      !isNewlyAddedEntryId ||
      isNonEmptyResponseError(errorText))

  return (
    <>
      <ListItemQuestionnaireResponseOption
        responseOptionId={responseOption.responseOptionLk}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={shouldAutoFocus}
        ariaLabel={ariaLabel}
        isError={canShowError}
        helperText={canShowError ? errorText : ''}
        cannotDelete={cannotDelete}
        onDelete={handleDelete}
        index={responseOption.position}
        onEnter={handleEnterResponseOption}
        editable={isEditable}
        disableGutters
        draggableId={`dr${responseOption.responseOptionLk}`}
        canBeReordered={!isMaxDiffQuestion}
        value={responseOptionValue || ''}
        placeholder="Type an option"
        onChange={handleInputChange}
        onSelect={handleResponseOptionSelectedText}
        mediaButton={
          <RowMediaUploader
            questionnaireId={questionnaireId}
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            questionLk={entryItem?.questionLk}
            responseOptionLk={responseOption.responseOptionLk}
            mediaUrl={responseOption.media?.mediaUrl}
            renderedMediaUrl={responseOption.media?.renderedMediaUrl}
            mediaType={responseOption.media?.mediaType}
            imageDialogOpened={isImageDialogOpened}
            onDialogClose={() => setIsImageDialogOpened(false)}
            onMediaUpload={handleMediaUpload}
            onRemoveMedia={handleRemoveMedia}
          />
        }
        maskingIconButton={
          <MaskingDialogContext.Provider
            value={{
              isDialogOpen: isMaskingDialogOpen,
              setIsDialogOpen: setIsMaskingDialogOpen
            }}
          >
            <MaskingButton
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              questionLk={entryItem?.questionLk}
              responseOptionLk={responseOption.responseOptionLk}
              entryType={EntryType.QuestionEntryType}
              createOrUpdateMaskingRule={handleCreateOrUpdateMaskingRule}
            />
          </MaskingDialogContext.Provider>
        }
        pinningIconButton={
          responseOption.pinned && isRandomiseOn ? (
            <PinResponse
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              questionLk={entryItem?.questionLk}
              responseOptionLk={responseOption.responseOptionLk}
              itemType={PinnedItemType.ResponseOption}
              isPinned={responseOption.pinned}
            />
          ) : undefined
        }
        exclusiveButton={
          responseOption.exclusive ? (
            <ExclusiveButton onExclusiveIconClick={handelExclusiveIconClick} />
          ) : undefined
        }
        textEntryButton={
          isTextEntry ? (
            <TextEntryButton onDisableTextEntry={disableTextEntry} />
          ) : undefined
        }
        routingButton={
          showRoutingBadge ? (
            <RoutingButton
              routingBadgeDisplayString={routingText}
              isRoutingDisabled={disableRouting}
              ariaLabel={ariaLabel}
              onRoutingMenuClick={() =>
                setIsRoutingPickerOpen(!isRoutingPickerOpen)
              }
            />
          ) : undefined
        }
        responseActionsMenu={
          responseActions.length > 0 ? (
            <KebabMenu
              kebabMenuOptions={responseActions}
              horizontal
              iconPosition={KebabMenuIconPosition.Left}
              iconColor={IconColor.Background}
              tooltipText="Configure this response option"
            />
          ) : undefined
        }
        questionsDialog={
          <QuestionsDialog
            title="Add skip logic"
            primaryButtonText="Add skip logic"
            endOfSurveyNumber={ROUTE_TO_END_SURVEY_NUMBER}
            showEndOfSurvey={showEndOfSurveyRouting}
            isQuestionDialogOpen={isRoutingPickerOpen && !showRoutingBadge}
            onQuestionDialogClose={() =>
              setIsRoutingPickerOpen(!isRoutingPickerOpen)
            }
            questionDialogItems={routingMenuItems as QuestionDialogItem[]}
            onSelectQuestionDialogItem={(targetNumber) => {
              onRoutingMenuItemClick(
                targetNumber,
                responseOption.responseOptionLk
              )
            }}
          />
        }
        renderRoutingMenu={(inputRef?: React.RefObject<HTMLInputElement>) => (
          <RoutingMenu
            anchorEl={inputRef && inputRef.current}
            endOfSurveyNumber={ROUTE_TO_END_SURVEY_NUMBER}
            showEndOfSurvey={showEndOfSurveyRouting}
            hasRouting={showRoutingBadge}
            isRoutingMenuOpen={isRoutingPickerOpen && showRoutingBadge}
            onRoutingMenuClose={() => setIsRoutingPickerOpen(false)}
            routingMenuItems={routingMenuItems}
            onRoutingMenuItemClick={(targetNumber) => {
              onRoutingMenuItemClick(
                targetNumber,
                responseOption.responseOptionLk
              )
            }}
          />
        )}
        onFocus={onFocus}
        onBlur={() => {
          handleOnFocusedWithDelay()
        }}
        onPaste={(text: string) => {
          pasteInResponseOption(
            text,
            responseOption.position,
            positionSelectedText
          )
        }}
      />
      <Dialog
        title="Delete this response option?"
        open={isDeleteDialogOpen}
        onClose={closeDeleteResponseDialog}
        primaryButtonText="Delete response option"
        primaryButtonClick={handleDeleteDialogConfirm}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold
          )}
        >
          {deleteDialogText}
        </p>
      </Dialog>
    </>
  )
}

export default memo(ResponseOption)
