import { useApolloClient, useMutation } from '@apollo/client'
import { useContext } from 'react'
import { DetachQuestionnaireEntryMutationVariables } from '../../../../data/gql-gen/questionnaire/graphql'
import {
  DETACH_QUESTIONNAIRE_ENTRY,
  DetachQuestionnaireEntryData
} from '../../../../data/gql/questionnaire/mutations/detatchQuestionnaireEntry'
import { QUESTIONNAIRE } from '../../../../data/gql/questionnaire/queries'
import useDraftQuestionnaireIdCache from '../../../../hooks/localState/useDraftQuestionnaireIdCache'
import QuestionnaireContext, {
  closeBulkDeleteDialog,
  openBulkDeleteDialog,
  setSelectedCards,
  unselectAllCards
} from '../../../../modules/Questionnaire/Questionnaire.context'
import { QuestionsBulkDeleteButtonControl } from './QuestionsBulkDeleteButton.control'
import { useOptimisticDeleteQuestions } from './QuestionsBulkDeleteButton.hooks'

export const QuestionsBulkDeleteButtonContainer = (): JSX.Element | null => {
  const client = useApolloClient()
  const questionnaireId = useDraftQuestionnaireIdCache()
  const optimisticDeleteQuestions = useOptimisticDeleteQuestions()
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)
  const { selectedCards } = questionnaireState
  const [detachQuestionnaireEntry] = useMutation<
    DetachQuestionnaireEntryData,
    DetachQuestionnaireEntryMutationVariables
  >(DETACH_QUESTIONNAIRE_ENTRY, {
    context: { clientName: 'questionnaire' }
  })

  if (!selectedCards.size) {
    return null
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openBulkDeleteDialog())
  }
  const handleCloseDeleteDialog = () => {
    dispatch(closeBulkDeleteDialog())
  }
  const handleDeleteSelectedCards = async () => {
    handleCloseDeleteDialog()

    optimisticDeleteQuestions()

    dispatch(unselectAllCards())

    const allSelectedCards: number[] = Array.from(selectedCards)

    Promise.allSettled(
      allSelectedCards.map((entryNumber) => {
        return detachQuestionnaireEntry({
          variables: {
            questionnaireId,
            number: entryNumber
          }
        })
      })
    ).then((results) => {
      const rejectedEntryNumbers: Set<number> = new Set()

      // find entry numbers for questions which could not be deleted on backend
      results.forEach((result, i) => {
        if (result.status === 'rejected') {
          rejectedEntryNumbers.add(allSelectedCards[i])
        }
      })

      // if some cards couldn't be deleted set them selected again
      if (rejectedEntryNumbers.size) {
        dispatch(setSelectedCards(rejectedEntryNumbers))
      }

      client.refetchQueries({
        include: [QUESTIONNAIRE]
      })
    })
  }

  return (
    <QuestionsBulkDeleteButtonControl
      isDeleteDialogOpened={questionnaireState.isBulkDeleteDialogOpened}
      onClickDelete={handleOpenDeleteDialog}
      onCloseDeleteDialog={handleCloseDeleteDialog}
      onConfirmDelete={handleDeleteSelectedCards}
    />
  )
}
