import React, { memo, useCallback, useContext, useState } from 'react'
import { questionBeingEditedNumber } from '../../../../apollo/apolloClient'
import DragAndDrop from '../../../../components/DragAndDrop-react-dnd/DragAndDrop'
import ListItemSectionSidebar from '../../../../components/ListItemSectionSidebar'
import { EntryType } from '../../../../data/model/questionnaire'
import useQuestionnaireValidation from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import EmptySectionSideBar from '../../EmptySection/EmptySectionSidebar.control'
import QuestionnaireContext, {
  setSidebarSelectedEntryNumber
} from '../../Questionnaire.context'
import { CONTAINER_ID_QUESTIONNAIRE_CONTENT } from '../../constants'
import { Destination, Source, useDragAndDrop } from '../../useDragAndDrop.hooks'
import { EmptyStateHelperText } from '../AddQuestionHelperText'
import SidebarLoader from '../Sidebar.loader'
import DraggableSidebarItem from './DraggableSidebarItem'
import { SidebarEntry } from './SidebarQuestionnaireItemList.query'
import { getEntryListItemPropValues } from './SidebarQuestionnaireItemListControl.utils'

const scrollIntoView = (node: Element) =>
  node.scrollIntoView({
    behavior: 'smooth'
  })

interface Props {
  questionItems: SidebarEntry[]
  loading: boolean | undefined
  onReorderItems?: (from: number, to: number, sectionId?: string) => void
}

const SidebarQuestionnaireItemListControl: React.FC<Props> = ({
  questionItems,
  loading = false,
  onReorderItems
}: Props) => {
  const [selectedEntryNumber, setSelectedEntryNumber] = useState<
    number | undefined
  >(0)
  const { dispatch } = useContext(QuestionnaireContext)
  const { entryHasValidationErrors } = useQuestionnaireValidation()
  const hasEmptyEntriesList = !loading && questionItems.length === 0

  const moveCard = useDragAndDrop()

  const handleSidebarItemClick = (entryNumber: number) => () => {
    const cardNode = document
      .querySelector(`#${CONTAINER_ID_QUESTIONNAIRE_CONTENT}`)
      ?.querySelector(`[name^=scroll-id-${entryNumber}]`)
    if (cardNode) {
      scrollIntoView(cardNode)
      // TODO: remove this workaround when we get rid of rendering only visible elements in QuestionsListContainer
      setTimeout(() => {
        scrollIntoView(cardNode)
      }, 250)
    }
    setSelectedEntryNumber(entryNumber)
    questionBeingEditedNumber(entryNumber)
    dispatch(setSidebarSelectedEntryNumber(entryNumber))
  }

  const handleMoveCard = useCallback(
    (itemId: string, source: Source, dest: Destination) => {
      const sectionId = dest.path.includes('/')
        ? dest.path.split('/')[0]
        : undefined
      onReorderItems?.(source.position, dest.position, sectionId)
      moveCard(itemId, source, dest)
    },
    [moveCard, onReorderItems]
  )

  const getEntries = (
    entries: SidebarEntry[] | undefined,
    sectionId?: string,
    sectionIndex?: number
  ): JSX.Element[] | undefined => {
    return (
      entries &&
      entries.map((entry) => {
        const [key, questionTitle, questionNumber] =
          getEntryListItemPropValues(entry)
        const isSelected = entry.number === selectedEntryNumber

        if (EntryType.SectionEntryType === entry.entryType) {
          const sectionItem = entry.entryItem
          const sectionEntries = sectionItem.entries
          return (
            <DragAndDrop
              key={sectionItem.sectionId}
              position={entry.position}
              id={entry.number.toString()}
              type="section"
              moveCard={handleMoveCard}
              path={sectionItem.sectionId}
              accept={['basic', 'section']}
              draggable
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              childrenCount={sectionEntries?.length}
            >
              <ListItemSectionSidebar
                isError={entryHasValidationErrors(entry.number)}
                questionTitle={questionTitle}
                isSelected={isSelected}
                onClickItem={handleSidebarItemClick(entry.number)}
              >
                {sectionEntries.length === 0 ? (
                  <EmptySectionSideBar
                    sectionId={sectionItem.sectionId}
                    nextPosition={entry.position + 1}
                  />
                ) : (
                  getEntries(
                    sectionEntries,
                    sectionItem.sectionId,
                    entry.position
                  )
                )}
              </ListItemSectionSidebar>
            </DragAndDrop>
          )
        }
        const path = sectionId
          ? `${sectionId}/${entry.number}`
          : entry.number.toString()
        const cardType =
          entry.entryType === EntryType.ForkEntryType ? 'fork' : 'basic'
        return (
          <DragAndDrop
            key={key}
            position={entry.position}
            id={entry.number.toString()}
            type={cardType}
            moveCard={handleMoveCard}
            path={path}
            parentPosition={sectionIndex}
            accept={sectionId ? ['basic'] : ['basic', 'section', 'fork']}
            draggable
          >
            <DraggableSidebarItem
              questionTitle={questionTitle}
              entryNumber={entry.number}
              questionNumber={questionNumber}
              isSelected={isSelected}
              onItemClick={handleSidebarItemClick(entry.number)}
            />
          </DragAndDrop>
        )
      })
    )
  }

  return (
    <>
      {hasEmptyEntriesList && <EmptyStateHelperText />}
      {loading && <SidebarLoader />}
      {!loading &&
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        questionItems &&
        questionItems.length > 0 &&
        getEntries(questionItems)}
    </>
  )
}

export default memo(SidebarQuestionnaireItemListControl)
