import { useAppDispatch, useAppSelector } from '../../../App.store'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  DraftQuestionItem,
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  ResponseOrderingOptions,
  WithEntry,
  checkIfSingleChoice,
  getOrdering,
  getQuestionEnabledSettings
} from '../../../utils/questionnaireUtils'
import {
  selectSettingsByQuestionId,
  setQuestionSetting
} from '../Questionnaire.slice'
import { getEntryId } from '../Questionnaire.utils'

interface QuestionCardSwitchesProps extends WithEntry {
  randomiseQuestionSettingCode: QuestionSettingCode
  flipOrderQuestionSettingCode?: QuestionSettingCode
}

const useQuestionCardSwitches = (props: QuestionCardSwitchesProps) => {
  const surveyId = useSurveyId()
  const { entry, randomiseQuestionSettingCode, flipOrderQuestionSettingCode } =
    props
  const entryId = getEntryId(entry)
  const dispatch = useAppDispatch()
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) => selectSettingsByQuestionId(state, entryId)) || []

  const handleSettingChange = useSetQuestionnaireSetting(entry)

  const changeSettingValue = async (
    code: QuestionSettingCode | undefined,
    value: SettingValue
  ) => {
    if (!code) {
      return
    }
    dispatch(
      setQuestionSetting({
        questionLk: entryId,
        code,
        value
      })
    )
    await handleSettingChange(code, value, 'no-cache')
  }

  const getLogEvent = (ordering: ResponseOrderingOptions) => {
    if (ordering === ResponseOrderingOptions.Random) {
      return EventType.RandomisedOrder
    }
    if (ordering === ResponseOrderingOptions.FlipOrder) {
      return EventType.FlippedOrder
    }
    return EventType.FixedOrder
  }

  const onOrderingChange = async (ordering: ResponseOrderingOptions) => {
    switch (ordering) {
      case ResponseOrderingOptions.Random:
        await Promise.all([
          changeSettingValue(
            randomiseQuestionSettingCode,
            SettingValue.Enabled
          ),
          changeSettingValue(
            flipOrderQuestionSettingCode,
            SettingValue.Disabled
          )
        ])
        break
      case ResponseOrderingOptions.FlipOrder:
        await Promise.all([
          changeSettingValue(
            flipOrderQuestionSettingCode,
            SettingValue.Enabled
          ),
          changeSettingValue(
            randomiseQuestionSettingCode,
            SettingValue.Disabled
          )
        ])
        break
      default:
        await Promise.all([
          changeSettingValue(
            randomiseQuestionSettingCode,
            SettingValue.Disabled
          ),
          changeSettingValue(
            flipOrderQuestionSettingCode,
            SettingValue.Disabled
          )
        ])
        break
    }

    const logEvent = getLogEvent(ordering)

    LogAmplitudeEvent(logEvent, {
      surveyId,
      questionId: entryId
    })
  }

  const onChangeMatrixChoice: (
    settingValue: SettingValue
  ) => Promise<void> = async (settingValue) => {
    const updateSettingPromises = [
      changeSettingValue(QuestionSettingCode.MatrixChoice, settingValue)
    ]
    if (settingValue === SettingValue.MultipleChoice) {
      updateSettingPromises.push(
        changeSettingValue(
          QuestionSettingCode.MatrixFlipOptions,
          SettingValue.Disabled
        )
      )
    }
    await Promise.all(updateSettingPromises)
  }

  const enabledSettings = getQuestionEnabledSettings(settingValues)

  const isRandomiseOn = enabledSettings.has(randomiseQuestionSettingCode)
  const isFlipOrderOn =
    flipOrderQuestionSettingCode &&
    enabledSettings.has(flipOrderQuestionSettingCode)

  const matrixChoiceDefaultSettingValue = settingValues.find(
    (settingValue) => settingValue.code === QuestionSettingCode.MatrixChoice
  )?.value

  const isSingleChoice =
    checkIfSingleChoice({
      settingValues
    } as DraftQuestionItem) ||
    matrixChoiceDefaultSettingValue === SettingValue.SingleChoice

  const showFlipOrder =
    isSingleChoice && flipOrderQuestionSettingCode !== undefined

  const ordering = getOrdering(isRandomiseOn, isFlipOrderOn)

  return {
    ordering,
    matrixChoiceDefaultSettingValue,
    showFlipOrder,
    onOrderingChange,
    onChangeMatrixChoice
  }
}

export default useQuestionCardSwitches
