import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'

const useLoopSpecificDisplayLogicData = (entryNumber: number) => {
  const { flattenedEntries } = useGetDraftQuestionnaire()
  for (const entry of flattenedEntries) {
    if (entry.number !== entryNumber) {
      continue
    }

    return entry.loopSpecificDisplayLogic
  }
}

export default useLoopSpecificDisplayLogicData
