import {
  Button,
  ButtonVariant,
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  Paper,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { formatThousandsWithCommas } from '../../utils/HelperFunctions'
import DialogPurchaseContent from '../DialogPurchaseContent'
import useStyles from './styles'

interface Props {
  costPerComplete?: number
  completes?: number
  totalCost?: number
  completionDate: string
  surveyLaunchDate?: string
  isOpen?: boolean
  confirmButtonDisabled?: boolean
  loadingCreateSurveyOrder?: boolean
  toggleIsOpen?: () => void
  onPlaceOrder?: () => void
  onShowPreview?: () => void
  statusComponent?: React.ReactNode
}

const ConfirmationCostBreakdown: React.FC<Props> = (props: Props) => {
  const {
    costPerComplete,
    completes,
    totalCost,
    completionDate,
    surveyLaunchDate,
    isOpen = false,
    confirmButtonDisabled = false,
    toggleIsOpen,
    onPlaceOrder,
    onShowPreview,
    loadingCreateSurveyOrder = false,
    statusComponent
  } = props
  const { classes } = useStyles()

  return (
    <Paper>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.content}
      >
        <Grid item className={classes.purchaseGrid}>
          <Button
            variant={ButtonVariant.PrimaryFilled}
            onClick={toggleIsOpen}
            fullWidth
            disabled={confirmButtonDisabled}
          >
            Confirm
          </Button>
          <Dialog
            open={isOpen}
            onClose={toggleIsOpen}
            primaryButtonClick={() => onPlaceOrder && onPlaceOrder()}
            primaryButtonText="Purchase"
            secondaryButtonClick={() => {
              return onShowPreview && onShowPreview()
            }}
            secondaryButtonText="Preview"
            title="Purchase"
            variant={DialogVariant.DoubleButton}
            maxWidth={DialogMaxWidth.Md}
            onClickOutside={toggleIsOpen}
            primaryButtonDisabled={loadingCreateSurveyOrder}
            primaryButtonLoading={loadingCreateSurveyOrder}
          >
            {statusComponent}
            <DialogPurchaseContent
              surveyLaunchDate={surveyLaunchDate}
              totalCost={totalCost}
            />
          </Dialog>
        </Grid>
        <Grid item className={classes.costBreakdownOuterGrid}>
          <Grid
            container
            item
            className={classes.costBreakdownInnerGrid}
            spacing={1}
          >
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  Cost per complete:
                </Text>
              </Grid>
              <Grid item>
                <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                  £{costPerComplete ? costPerComplete.toFixed(2) : '...'}
                </Text>
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  Number of completes:
                </Text>
              </Grid>
              <Grid item>
                <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                  {completes ? formatThousandsWithCommas(completes) : '...'}
                </Text>
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                  Total cost for completes:
                </Text>
              </Grid>
              <Grid item>
                <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                  £{totalCost ? formatThousandsWithCommas(totalCost) : '...'}
                </Text>
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  Hosting:
                </Text>
              </Grid>
              <Grid item>
                <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                  £0
                </Text>
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  Raw data:
                </Text>
              </Grid>
              <Grid item>
                <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                  £0
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.purchaseGrid}>
          <Grid container item direction="row" justifyContent="space-between">
            <Grid item>
              <Text size={TextSize.m} weight={TextWeight.SemiBold}>
                Total cost:
              </Text>
            </Grid>
            <Grid item>
              <Text size={TextSize.m} weight={TextWeight.SemiBold}>
                £{totalCost ? formatThousandsWithCommas(totalCost) : '...'}{' '}
                (GBP)
              </Text>
            </Grid>
          </Grid>
          <Grid container item direction="row" justifyContent="space-between">
            <Grid item>
              <Text size={TextSize.m} weight={TextWeight.SemiBold}>
                Completion date:
              </Text>
            </Grid>
            <Grid item>
              <Text size={TextSize.m} weight={TextWeight.SemiBold}>
                {completionDate}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ConfirmationCostBreakdown
