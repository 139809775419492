import { gql } from '@apollo/client'
import { FieldworkAudience } from '../../../model/audience'
import DRAFT_AUDIENCE_MEMBER from '../fragments/draftAudienceMember'

export interface UpdateCustomCriterionData {
  updateCustomCriterion: FieldworkAudience
}

export const UPDATE_CUSTOM_CRITERION = gql`
  mutation updateCustomCriterion(
    $surveyId: ID!
    $questionLk: ID!
    $text: String
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    updateCustomCriterion(
      surveyId: $surveyId
      questionLk: $questionLk
      text: $text
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`
