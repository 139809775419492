import { gql } from '@apollo/client/core'
import { EntrySettingValue } from '../../../model/questionnaire'
import ENTRY_SETTING_VALUE from '../fragments/entrySettingValue'

export interface RemoveTextCardSettingValueData {
  removeTextCardSettingValue: EntrySettingValue
}

export const REMOVE_TEXT_CARD_SETTING_VALUE = gql`
  mutation removeTextCardSettingValue(
    $questionnaireId: ID!
    $textCardLk: ID!
    $questionSettingCode: String!
  ) {
    removeTextCardSettingValue(
      questionnaireId: $questionnaireId
      textCardLk: $textCardLk
      questionSettingCode: $questionSettingCode
    ) {
      ...EntrySettingValue
    }
  }
  ${ENTRY_SETTING_VALUE}
`
