import { useFragment, useMutation, useReactiveVar } from '@apollo/client'
import { ScrollTo } from '@focaldata/cin-ui-components'
import debounce from 'lodash/debounce'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import {
  DuplicateTextCardMutationVariables,
  UpdateTextCardMutationVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import { TEXT_CARD } from '../../../data/gql/questionnaire/fragments/DraftTextCardItem'
import {
  DUPLICATE_TEXT_CARD,
  DuplicateTextCardData
} from '../../../data/gql/questionnaire/mutations/duplicateTextCard'
import {
  UPDATE_TEXT_CARD,
  UpdateTextCardData
} from '../../../data/gql/questionnaire/mutations/updateTextCard'
import { LoggerErrorType } from '../../../data/logger'
import { DraftTextCardItem } from '../../../data/model/questionnaire'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { WithEntry, propsAreEqual } from '../../../utils/questionnaireUtils'
import { CardTitleControl } from '../CardTitle'
import { getTextFromRawTitleStyling } from '../CardTitle/CardTitle.utils'
import { pipingMarkerAddedVar } from '../PipingDialog/PipingDialog.hooks'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  SCROLL_ID_PREFIX
} from '../constants'
import TextCardControl from './TextCard.control'

interface QuestionnaireMutationContext {
  context: { clientName: 'questionnaire' }
  onCompleted: () => void
}

interface Props extends WithEntry {
  shouldTitleInputFocus: boolean
}

const TextCard: React.FC<Props> = (props: Props) => {
  const { entry, shouldTitleInputFocus } = props
  const { validateBasicQuestionText } = useQuestionnaireValidation()
  const { draftQuestionnaireEntries, refetchQuestionnaire } =
    useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const questionnaireMutationContext: QuestionnaireMutationContext = {
    context: { clientName: 'questionnaire' },
    onCompleted: () => {
      questionBeingEditedNumber(entry.number)
    }
  }

  const questionnaireId = useDraftQuestionnaireIdCache()
  const entryItem = entry.entryItem as DraftTextCardItem

  const isTextCardEmpty = validateBasicQuestionText(entryItem.textCardLk)

  const { textCardLk } = entryItem
  const { data: textCardData } = useFragment({
    fragment: TEXT_CARD,
    fragmentName: 'TextCard',
    from: { __typename: 'TextCard', textCardId: textCardLk },
    canonizeResults: true
  })

  const [titleStyling, setTitleStyling] = useState(textCardData.titleStyling)
  const pipingMarkerAdded = useReactiveVar(pipingMarkerAddedVar)

  useEffect(() => {
    if (pipingMarkerAdded[textCardLk]) {
      pipingMarkerAddedVar({ [textCardLk]: false })
      setTitleStyling(textCardData.titleStyling)
    }
  }, [pipingMarkerAdded, textCardData.titleStyling, textCardLk])

  const bodyState = useRef(textCardData.body)

  useEffect(() => {
    bodyState.current = textCardData.body
  }, [textCardData.body])

  const [updateTextCard] = useMutation<
    UpdateTextCardData,
    UpdateTextCardMutationVariables
  >(UPDATE_TEXT_CARD, {
    ...questionnaireMutationContext,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateTextCard',
        error
      )
    }
  })

  const scrollToDuplicatedTextCard = (cardNumber: string) => {
    setTimeout(() => {
      if (cardNumber) {
        ScrollTo(
          `${SCROLL_ID_PREFIX}${cardNumber}`,
          CONTAINER_ID_QUESTIONNAIRE_CONTENT
        )
      }
    }, 400)
  }
  const [duplicateTextCardMutation] = useMutation<
    DuplicateTextCardData,
    DuplicateTextCardMutationVariables
  >(DUPLICATE_TEXT_CARD, {
    context: { clientName: 'questionnaire' },
    onCompleted: ({ duplicateTextCard }) => {
      refetchQuestionnaire()
      scrollToDuplicatedTextCard(duplicateTextCard.number.toString())
      questionBeingEditedNumber(undefined)
    }
  })

  const updateTextCardDetails = useCallback(
    (rawTitleStyling: string, newBody?: string) => {
      updateTextCard({
        variables: {
          questionnaireId,
          textCardLk,
          title: getTextFromRawTitleStyling(rawTitleStyling),
          titleStyling: rawTitleStyling,
          subtitle: '',
          body: newBody || bodyState.current || ''
        }
      })
    },
    [updateTextCard, questionnaireId, textCardLk]
  )

  const handleOnEditorChangeDebounced = useMemo(
    () =>
      debounce((newRawTitleStyling: string) => {
        updateTextCardDetails(newRawTitleStyling)
      }, 1000),
    [updateTextCardDetails]
  )

  const handleOnEditorChange = (newRawTitleStyling: string) => {
    setTitleStyling(newRawTitleStyling)
    handleOnEditorChangeDebounced(newRawTitleStyling)
  }

  const handleOnChangeBody = (newBody: string) => {
    bodyState.current = newBody
    updateTextCardDetails((titleStyling || textCardData.title) ?? '', newBody)
  }

  return (
    <TextCardControl
      entry={entry}
      questionCardHeader={
        <SurveyQuestionCardHeaderContainer
          entry={entry}
          hasError={isTextCardEmpty}
          onClickDuplicateIcon={() =>
            duplicateTextCardMutation({
              variables: {
                questionnaireId,
                textCardLk: entryItem.textCardLk,
                position: entry.position + 1
              }
            })
          }
          isMultipleChoice={false}
          titleContent={
            <CardTitleControl
              hasEditor
              shouldHandlePasteManually
              hasError={isTextCardEmpty}
              titleValue={textCardData.title}
              titleStyling={titleStyling ?? undefined}
              ariaLabel="Text card question header"
              shouldTitleInputFocus={shouldTitleInputFocus}
              placeholder="Type in your instructions here"
              helperText="Please add a question title"
              onFocus={() => questionBeingEditedNumber(entry.number)}
              onEditorChange={handleOnEditorChange}
            />
          }
          entries={flatDraftQuestionnaireEntries}
        />
      }
      defaultBody={textCardData.body}
      onChangeBody={handleOnChangeBody}
    />
  )
}

export default memo(TextCard, propsAreEqual)
