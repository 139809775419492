import { useQuery } from '@apollo/client'

import { COUNTRIES } from '../../data/gql/fieldwork/queries/countries'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'

export const useAudienceCountries = () => {
  const { data, loading, error } = useQuery(COUNTRIES, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'countries', error)
    }
  })

  return { data, loading, error }
}
