import { useMutation } from '@apollo/client'
import {
  AddCustomizedCriterionOptionMutationVariables,
  CustomizedCriterionOptionInput,
  DetachCustomizedCriterionOptionMutationVariables,
  UpdateCustomizedCriterionOptionMutationVariables
} from '../../../data/gql-gen/fieldwork/graphql'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { DraftFieldworkAudienceMember } from '../../../data/model/audience'
import {
  StandardCriterionOptionValidationErrors,
  StandardCriterionValidationErrors
} from '../../../data/model/fieldwork'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  ADD_CUSTOMIZED_CRITERION_OPTION,
  DETACH_CUSTOMIZED_CRITERION_OPTION,
  UPDATE_CUSTOMIZED_CRITERION_OPTION
} from './CustomAgeRanges.mutations'
import {
  AGE_RANGES_CRITERION_CODE,
  getErrorMessage
} from './CustomAgeRanges.utils'

type ErrorsByOptionCode = Record<
  StandardCriterionOptionValidationErrors['code'],
  {
    hasError: boolean
    errorCodes: string[]
  }
>

export const useAddCustomAgeRange = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [addCustomizedCriterionOption] = useMutation<
    DraftFieldworkAudienceMember,
    AddCustomizedCriterionOptionMutationVariables
  >(ADD_CUSTOMIZED_CRITERION_OPTION, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
  })

  return () => {
    addCustomizedCriterionOption({
      variables: {
        surveyId,
        criterion: AGE_RANGES_CRITERION_CODE,
        criterionOptions: [
          {
            criterionOptionCode: '_-_'
          }
        ]
      }
    })
  }
}

export const useDeleteCustomAgeRange = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [detachCustomizedCriterionOption] = useMutation<
    DraftFieldworkAudienceMember,
    DetachCustomizedCriterionOptionMutationVariables
  >(DETACH_CUSTOMIZED_CRITERION_OPTION, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
  })

  return (responseOptionId: string) => {
    detachCustomizedCriterionOption({
      variables: {
        surveyId,
        criterion: AGE_RANGES_CRITERION_CODE,
        responseOptionId
      }
    })
  }
}

export const useUpdateCustomizedCriterionOption = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [updateCustomizedCriterionOption] = useMutation<
    DraftFieldworkAudienceMember,
    UpdateCustomizedCriterionOptionMutationVariables
  >(UPDATE_CUSTOMIZED_CRITERION_OPTION, {
    context: { clientName: 'fieldwork' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
  })

  return (
    responseOptionId: string,
    {
      min,
      max,
      qualified,
      quota
    }: {
      min: string
      max: string
      qualified: boolean
      quota?: number
    }
  ) => {
    const criterionOption: CustomizedCriterionOptionInput = {
      criterionOptionCode: `${min}_-_${max}`,
      qualification: qualified
        ? {
            createdDate: new Date().toISOString(),
            sinceDate: new Date().toISOString()
          }
        : null,
      quota:
        typeof quota === 'number'
          ? {
              createdDate: new Date().toISOString(),
              sinceDate: new Date().toISOString(),
              percent: quota
            }
          : null
    }

    return updateCustomizedCriterionOption({
      variables: {
        surveyId,
        criterion: AGE_RANGES_CRITERION_CODE,
        responseOptionId,
        criterionOption
      }
    })
  }
}

export const useCustomAgeRangesValidation = (): {
  errorsByOptionCode: ErrorsByOptionCode
  errorMessage: string
} => {
  const { draftAudience } = useGetDraftAudience()

  const standardCriterionValidationErrors = draftAudience?.validationErrors as
    | StandardCriterionValidationErrors[]
    | undefined

  const standardCriterionOptionsErrors =
    standardCriterionValidationErrors?.find(
      ({ code }: StandardCriterionValidationErrors) =>
        code === AGE_RANGES_CRITERION_CODE
    )?.standardCriterionOptionsErrors ?? []

  const errorsByOptionCode: ErrorsByOptionCode = {}
  const allErrors: StandardCriterionOptionValidationErrors['errors'] = []

  standardCriterionOptionsErrors.forEach(
    ({ code, errors }: StandardCriterionOptionValidationErrors) => {
      allErrors.push(...errors)
      errorsByOptionCode[code] = {
        hasError: true,
        errorCodes: errors
      }
    }
  )

  return { errorsByOptionCode, errorMessage: getErrorMessage(allErrors) }
}
