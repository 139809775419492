import { PinResponse } from '@focaldata/cin-ui-components'
import React from 'react'
import usePinResponseMutations from './PinResponse.hooks'
import { PinnedItemType } from './PinResponse.model'

interface Props {
  itemType: PinnedItemType
  isPinned: boolean
  matrixTitleLk?: string
  questionLk?: string
  responseOptionLk?: string
}

const getTooltipMessage = (
  itemType: PinnedItemType,
  pinned: boolean
): string => {
  switch (itemType) {
    case PinnedItemType.ResponseOption:
    case PinnedItemType.MatrixResponseOption:
    case PinnedItemType.MatrixRow: {
      if (pinned) {
        return 'Unpin row'
      }

      return 'Pin row'
    }
    default: {
      if (pinned) {
        return 'Unpin response option'
      }

      return 'Pin response option'
    }
  }
}

const PinResponseContainer: React.FC<Props> = ({
  itemType,
  isPinned,
  matrixTitleLk,
  questionLk,
  responseOptionLk
}: Props) => {
  const {
    setPinnedResponseOption,
    setPinnedMatrixResponseOption,
    setPinnedMatrixRow
  } = usePinResponseMutations()

  const handleTogglePinned = (): void => {
    switch (itemType) {
      case PinnedItemType.ResponseOption: {
        if (questionLk) {
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setPinnedResponseOption(questionLk, responseOptionLk!, !isPinned)
        }
        break
      }
      case PinnedItemType.MatrixResponseOption: {
        setPinnedMatrixResponseOption(
          matrixTitleLk,
          responseOptionLk,
          !isPinned
        )
        break
      }
      case PinnedItemType.MatrixRow: {
        setPinnedMatrixRow(matrixTitleLk, questionLk, !isPinned)
        break
      }
      default:
        break
    }
  }

  return (
    <PinResponse
      tooltip={getTooltipMessage(itemType, isPinned)}
      onTogglePinned={handleTogglePinned}
    />
  )
}

export default PinResponseContainer
