import { gql } from '@apollo/client'
import { DraftCustomAudienceCriterionOption } from '../../../data/model/audience'

export interface SetCustomCriterionOptionMediaData {
  setCustomCriterionOptionMedia: DraftCustomAudienceCriterionOption
}

export const SET_CUSTOM_CRITERION_OPTION_MEDIA = gql`
  mutation setCustomCriterionOptionMedia(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $mediaName: String!
    $mediaUrl: String!
    $mediaType: MediaType!
    $renderedMediaUrl: String
  ) {
    setCustomCriterionOptionMedia(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      mediaName: $mediaName
      mediaUrl: $mediaUrl
      mediaType: $mediaType
      renderedMediaUrl: $renderedMediaUrl
    ) {
      position
      responseOptionLk
      createdDate
      media {
        mediaName
        mediaUrl
        mediaType
        renderedMediaUrl
      }
    }
  }
`

export const REMOVE_CUSTOM_CRITERION_OPTION_MEDIA = gql`
  mutation removeCustomCriterionOptionMedia(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
  ) {
    removeCustomCriterionOptionMedia(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
    ) {
      position
      responseOptionLk
      createdDate
      media {
        mediaName
        mediaUrl
        mediaType
        renderedMediaUrl
      }
    }
  }
`
