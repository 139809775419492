import { gql } from '@apollo/client/core'
import {
  MemberSettingCode,
  SettingValue
} from '../../../../data/model/audience'

export interface SidebarAudienceEntry {
  number: number
  position: number
  contextPosition: number
  entryItem: {
    question: {
      questionId: string
      text: string
    }
    settingValues: {
      code: MemberSettingCode
      value: SettingValue
    }[]
  }
}

export interface FieldworkAudienceData {
  fieldworkAudience: {
    surveyId: string
    entries: SidebarAudienceEntry[]
  }
}

export const SIDEBAR_AUDIENCE_QUESTIONS = gql`
  query sidebarAudienceQuestions($surveyId: ID!) {
    fieldworkAudience: fieldworkAudience(surveyId: $surveyId) {
      surveyId
      entries {
        number
        position
        contextPosition
        entryItem {
          ... on QuestionItem {
            question {
              __typename
              questionId
              text
              createdDate
            }
            settingValues {
              code
              value
            }
          }
        }
        __typename
      }
    }
  }
`
