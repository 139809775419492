import gql from 'graphql-tag'
import { EntrySettingValue } from '../../../model/questionnaire'
import ENTRY_SETTING_VALUE from '../fragments/entrySettingValue'

export interface SetSectionSettingValueData {
  setSectionSettingValue: EntrySettingValue
}

export const SET_SECTION_SETTING_VALUE = gql`
  mutation setSectionSettingValue(
    $questionnaireId: ID!
    $sectionId: ID!
    $setting: SettingInputInput!
  ) {
    setSectionSettingValue(
      questionnaireId: $questionnaireId
      sectionId: $sectionId
      setting: $setting
    ) {
      ...EntrySettingValue
      __typename
    }
  }
  ${ENTRY_SETTING_VALUE}
`
