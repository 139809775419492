import {
  ApolloCache,
  ApolloError,
  isReference,
  useMutation
} from '@apollo/client'
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import { useCallback } from 'react'
import { questionBeingEditedNumber } from '../../apollo/apolloClient'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  SetQuestionnaireMatrixSettingValueMutationVariables,
  SetQuestionnaireSettingValueMutationVariables,
  SetTextCardSettingValueMutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import {
  SET_QUESTIONNAIRE_SETTING_VALUE,
  SetQuestionnaireSettingValueData
} from '../../data/gql/questionnaire/mutations/setQuesitionnaireSettingValue'
import SET_QUESTIONNAIRE_MATRIX_SETTING_VALUE, {
  SetQuestionnaireMatrixSettingValueData
} from '../../data/gql/questionnaire/mutations/setQuestionnaireMatrixSettingValue'
import SET_TEXT_CARD_SETTING_VALUE, {
  SetTextCardSettingValueData
} from '../../data/gql/questionnaire/mutations/setTextCardSettingValue'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode,
  SettingValue
} from '../../data/model/questionnaire'
import { getEntryId } from '../../modules/Questionnaire/Questionnaire.utils'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

const useSetQuestionnaireSetting = (
  entry: DraftQuestionnaireEntry | null | undefined
) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const questionnaireMutationContext = {
    context: { clientName: 'questionnaire' },
    onCompleted: () => {
      if (entry) {
        questionBeingEditedNumber(entry.number)
      }
    },
    onError: (error: ApolloError) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setQuestionnaireSettingValue',
        error
      )
    },
    update: (
      cache: ApolloCache<unknown>,
      _response: unknown,
      {
        variables
      }: {
        variables?:
          | SetQuestionnaireSettingValueMutationVariables
          | SetQuestionnaireMatrixSettingValueMutationVariables
          | SetTextCardSettingValueMutationVariables
      }
    ) => {
      if (variables) {
        const { questionSettingCode, settingValue } = variables.setting
        if (
          entry &&
          (entry.entryType === EntryType.QuestionEntryType ||
            entry.entryType === EntryType.MatrixEntryType) &&
          [
            QuestionSettingCode.BasicChoice,
            QuestionSettingCode.MatrixChoice
          ].includes(questionSettingCode as QuestionSettingCode) &&
          settingValue === SettingValue.SingleChoice
        ) {
          const id =
            entry.entryType === EntryType.MatrixEntryType
              ? `DraftMatrixItem:{"matrixTitleLk":"${entry.entryItem.matrixTitleLk}"}`
              : `DraftQuestionItem:{"questionLk":"${entry.entryItem.questionLk}"}`

          cache.modify<DraftMatrixItem | DraftQuestionItem>({
            id,
            fields: {
              settingValues: (settingValues) =>
                settingValues.map((questionSetting) => {
                  if (isReference(questionSetting)) {
                    throw new Error('Unexpected reference found')
                  }

                  if (
                    questionSetting.code === QuestionSettingCode.AutoAnswerAll
                  ) {
                    return {
                      ...questionSetting,
                      value: SettingValue.Disabled
                    }
                  }

                  return questionSetting
                })
            }
          })

          for (const responseOption of entry.entryItem.responseOptions) {
            cache.modify({
              id: `DraftEntryResponseOption:{"responseOptionLk":"${responseOption.responseOptionLk}"}`,
              fields: {
                exclusive: () => false
              }
            })
          }
        }
      }
    }
  }

  const [setQuestionnaireSettingValue] = useMutation<
    SetQuestionnaireSettingValueData,
    SetQuestionnaireSettingValueMutationVariables
  >(SET_QUESTIONNAIRE_SETTING_VALUE, {
    ...questionnaireMutationContext
  })

  const [setQuestionnaireMatrixSettingValue] = useMutation<
    SetQuestionnaireMatrixSettingValueData,
    SetQuestionnaireMatrixSettingValueMutationVariables
  >(SET_QUESTIONNAIRE_MATRIX_SETTING_VALUE, {
    ...questionnaireMutationContext
  })

  const [setTextCardSettingValue] = useMutation<
    SetTextCardSettingValueData,
    SetTextCardSettingValueMutationVariables
  >(SET_TEXT_CARD_SETTING_VALUE, {
    ...questionnaireMutationContext
  })

  const handleSettingChange = useCallback(
    async (
      settingCode: QuestionSettingCode,
      settingValue: SettingValue | string,
      fetchPolicy: MutationFetchPolicy = 'network-only'
    ): Promise<void> => {
      if (!surveyId) {
        throw new Error('Cannot change settings as missing questionnaire ID')
      }

      const variables = {
        questionnaireId: surveyId,
        setting: {
          questionSettingCode: settingCode,
          settingValue
        }
      }

      const refetchQueries =
        fetchPolicy === 'no-cache'
          ? undefined
          : [draftQuestionnaireRefetchQuery(projectId, surveyId)]

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      switch (entry?.entryType) {
        case EntryType.QuestionEntryType:
          await setQuestionnaireSettingValue({
            variables: {
              ...variables,
              questionLk: getEntryId(entry)
            },
            refetchQueries
          })
          break
        case EntryType.MatrixEntryType:
          await setQuestionnaireMatrixSettingValue({
            variables: {
              ...variables,
              matrixTitleLk: entry.entryItem.matrixTitleLk
            },
            refetchQueries
          })
          break
        case EntryType.TextCardEntryType:
          await setTextCardSettingValue({
            variables: {
              ...variables,
              textCardLk: entry.entryItem.textCardLk
            },
            refetchQueries
          })
          break
      }
    },
    [
      entry,
      projectId,
      surveyId,
      setQuestionnaireMatrixSettingValue,
      setQuestionnaireSettingValue,
      setTextCardSettingValue
    ]
  )

  return handleSettingChange
}

export default useSetQuestionnaireSetting
