import { useMutation, useReactiveVar } from '@apollo/client'
import { memo, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import { questionBeingEditedNumber } from '../../../apollo/apolloClient'
import { emptyArr } from '../../../constants/misc'
import {
  EntryType,
  MoveMatrixResponseOptionMutationVariables,
  UpdateMatrixResponseOptionMutationVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import { DETACH_MATRIX_RESPONSE_OPTION } from '../../../data/gql/questionnaire/mutations/detachMatrixResponseOption'
import {
  MOVE_MATRIX_RESPONSE_OPTION,
  MoveMatrixResponseOptionData
} from '../../../data/gql/questionnaire/mutations/moveMatrixResponseOption'
import {
  UPDATE_MATRIX_RESPONSE_OPTION,
  UpdateMatrixResponseOptionData
} from '../../../data/gql/questionnaire/mutations/updateMatrixResponseOption'
import { LoggerErrorType } from '../../../data/logger'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { responseOptionLkNewlyAdded } from '../../../hooks/useResetNewlyCreatedEntry'
import {
  deleteMatrixQuestionResponseOptionTransactionDatadog,
  moveMatrixQuestionResponseOptionTransactionDatadog
} from '../../../tracking/perf/transactions'
import { captureApolloError } from '../../../utils/HelperFunctions'
import {
  WithEntryOfType,
  checkIfMatrixMultipleChoice,
  propsAreEqual
} from '../../../utils/questionnaireUtils'
import {
  responseOptionDeleted,
  responseOptionUpdated,
  responseOptionsReoredered,
  selectResponseOptionsByQuestion,
  selectSettingsByQuestionId
} from '../Questionnaire.slice'
import { flattenEntries } from '../Questionnaire.utils'
import { useSetOrUnsetExclusive } from './MatrixQuestion.hooks'
import { useAddMatrixResponseOption } from './MatrixResponsOptions.hooks'
import { MatrixResponseOptionsList } from './MatrixResponseOptionsList'

const MatrixResponseOptions = (
  props: WithEntryOfType<EntryType.MatrixEntryType>
) => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )
  const dispatch = useAppDispatch()
  const { entry } = props
  const { matrixTitleLk } = entry.entryItem
  const addMatrixResponseOption = useAddMatrixResponseOption()
  const matrixResponseOptions =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    responseOptionsByQuestion[matrixTitleLk] ?? emptyArr

  const { draftQuestionnaire, draftQuestionnaireEntries } =
    useGetDraftQuestionnaire()

  const questionnaireId = draftQuestionnaire?.questionnaireId || ''

  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectSettingsByQuestionId(state, matrixTitleLk)
    ) || []

  const setOrUnsetExclusive = useSetOrUnsetExclusive(entry)
  const flatEntries = flattenEntries(draftQuestionnaireEntries)
  const maskingEnabled =
    flatEntries.length > 0 && entry.number !== flatEntries[0].number

  const [moveMatrixResponseOption] = useMutation<
    MoveMatrixResponseOptionData,
    MoveMatrixResponseOptionMutationVariables
  >(MOVE_MATRIX_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const [detachMatrixResponseOption] = useMutation(
    DETACH_MATRIX_RESPONSE_OPTION,
    {
      context: { clientName: 'questionnaire' },
      onCompleted() {
        questionBeingEditedNumber(entry.number)
      },
      onError(error) {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'detachMatrixResponseOption',
          error
        )
      }
    }
  )

  const [updateMatrixResponseOption] = useMutation<
    UpdateMatrixResponseOptionData,
    UpdateMatrixResponseOptionMutationVariables
  >(UPDATE_MATRIX_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const handleDeleteMatrixResponseOption: (
    responseOptionLk: string,
    matrixTitleLk: string
  ) => void = useCallback(
    (responseOptionLk, matrixTitleLk) => {
      deleteMatrixQuestionResponseOptionTransactionDatadog.start()
      dispatch(
        responseOptionDeleted({
          questionLk: matrixTitleLk,
          responseOptionLk
        })
      )
      detachMatrixResponseOption({
        variables: {
          questionnaireId,
          matrixTitleLk,
          responseOptionLk
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionnaireId]
  )

  const handleReorderedMatrixResponseOptions: (
    destinationIndex: number,
    matrixTitleLk: string,
    responseOptionLk: string
  ) => void = useCallback(
    (destinationIndex, matrixTitleLk, responseOptionLk) => {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (destinationIndex !== undefined) {
        moveMatrixQuestionResponseOptionTransactionDatadog.start()
        moveMatrixResponseOption({
          variables: {
            questionnaireId,
            matrixTitleLk,
            responseOptionLk,
            toPosition: destinationIndex
          },
          onCompleted: () => {
            questionBeingEditedNumber(entry.number)
          },
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'moveMatrixResponseOption',
              error
            )
          }
        })
      }
    },
    [entry.number, moveMatrixResponseOption, questionnaireId]
  )

  const handleChangeMatrixResponseOption: (
    inputValue: string,
    responseOptionLk: string
  ) => void = useCallback(
    (inputValue, responseOptionLk) => {
      dispatch(
        responseOptionUpdated({
          questionLk: matrixTitleLk,
          responseOptionLk,
          value: inputValue
        })
      )
      updateMatrixResponseOption({
        variables: {
          questionnaireId,
          responseOptionLk,
          matrixTitleLk,
          responseOptionValue: inputValue
        },
        onCompleted: () => {
          questionBeingEditedNumber(entry.number)
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'updateMatrixResponseOption',
            error
          )
        }
      })
    },
    [
      dispatch,
      entry.number,
      matrixTitleLk,
      questionnaireId,
      updateMatrixResponseOption
    ]
  )

  const handleAddMatrixResponseOption: (
    position: number,
    matrixTitleLk: string
  ) => void = (position, matrixTitleLk) => {
    addMatrixResponseOption(entry.number, position + 1, matrixTitleLk)
  }

  const handleOnReorderedMatrixResponseOptions: (
    destinationIndex: number,
    sourceIndex: number
  ) => void = useCallback(
    (destinationIndex, sourceIndex) => {
      dispatch(
        responseOptionsReoredered({
          questionLk: matrixTitleLk,
          destinationIndex,
          sourceIndex
        })
      )
      handleReorderedMatrixResponseOptions(
        destinationIndex,
        matrixTitleLk,
        matrixResponseOptions[sourceIndex].responseOptionLk
      )
    },
    [
      dispatch,
      matrixTitleLk,
      handleReorderedMatrixResponseOptions,
      matrixResponseOptions
    ]
  )
  const newlyAddedResponseOptionLk = useReactiveVar(responseOptionLkNewlyAdded)

  const isMultipleChoice = checkIfMatrixMultipleChoice(settingValues)

  return (
    <MatrixResponseOptionsList
      entry={entry}
      matrixResponseOptions={matrixResponseOptions}
      onEnter={handleAddMatrixResponseOption}
      entryNumber={entry.number}
      maskingEnabled={maskingEnabled}
      newlyAddedResponseOptionLk={newlyAddedResponseOptionLk}
      onChangeMatrixResponseOption={handleChangeMatrixResponseOption}
      onDeleteMatrixResponseOption={handleDeleteMatrixResponseOption}
      onReorderedMatrixResponseOptions={handleOnReorderedMatrixResponseOptions}
      onSetExclusiveOption={isMultipleChoice ? setOrUnsetExclusive : undefined}
    />
  )
}

export default memo(MatrixResponseOptions, propsAreEqual)
