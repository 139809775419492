import { gql } from '@apollo/client/core'
import { questionnaireGql } from '../../../gql-gen/questionnaire'
import {
  DraftQuestionnaireEntry,
  EntryType
} from '../../../model/questionnaire'

export interface PasteInResponsesData {
  pasteInResponses: DraftQuestionnaireEntry
}

export interface PasteInMatrixResponsesData {
  pasteInMatrixResponses: DraftQuestionnaireEntry<EntryType.MatrixEntryType>
}

export interface PasteInMatrixRowsData {
  pasteInMatrixRows: DraftQuestionnaireEntry<EntryType.MatrixEntryType>
}

export const PASTE_IN_RESPONSES = gql`
  mutation pasteInResponses(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptions: [String!]!
    $position: Int!
  ) {
    pasteInResponses(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptions: $responseOptions
      position: $position
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftQuestionItem {
          question {
            __typename
            questionId
            createdDate
          }
          responseOptions {
            ...DraftEntryResponseOption
          }
          questionLk
        }
      }
      __typename
    }
  }
`

export const PASTE_IN_MATRIX_RESPONSES = gql`
  mutation pasteInMatrixResponses(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $responseOptions: [String!]!
    $position: Int!
  ) {
    pasteInMatrixResponses(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      responseOptions: $responseOptions
      position: $position
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftMatrixItem {
          matrixTitleLk
          matrixTitle {
            __typename
            matrixTitleId
            title
            titleStyling
          }
          responseOptions {
            ...DraftEntryResponseOption
          }
          matrixRows {
            ...DraftMatrixRow
          }
        }
      }
      __typename
    }
  }
`

export const PASTE_IN_MATRIX_ROWS = questionnaireGql(/* GraphQL */ `
  mutation pasteInMatrixRows(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $rows: [String!]!
    $position: Int!
  ) {
    pasteInMatrixRows(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      rows: $rows
      position: $position
    ) {
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftMatrixItem {
          matrixTitleLk
          matrixTitle {
            __typename
            matrixTitleId
            title
            titleStyling
          }
          responseOptions {
            ...DraftEntryResponseOption
          }
          matrixRows {
            ...DraftMatrixRow
          }
        }
      }
      __typename
    }
  }
`)
