import { questionnaireGql } from '../../../gql-gen/questionnaire'
import { DraftMatrixItem } from '../../../model/questionnaire'

export interface SetMatrixQualificationData {
  setMatrixQualification: {
    entryItem: Pick<DraftMatrixItem, 'matrixTitleLk' | 'matrixQualification'>
  }
}

const SET_MATRIX_QUALIFICATION = questionnaireGql(/* GraphQL */ `
  mutation setMatrixQualification(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $qualifyingOptions: [ID!]!
    $numberOfQualifyingRows: Int!
    $operator: MatrixRowQualifyingOperator
  ) {
    setMatrixQualification(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      qualifyingOptions: $qualifyingOptions
      numberOfQualifyingRows: $numberOfQualifyingRows
      operator: $operator
    ) {
      entryItem {
        ... on DraftMatrixItem {
          matrixTitleLk
          ...DraftMatrixItemQualification
        }
      }
    }
  }
`)

export default SET_MATRIX_QUALIFICATION
