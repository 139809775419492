import { useContext } from 'react'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import QuestionnaireContext, {
  closeDeleteSectionDialog,
  setSectionEntryNumberToBeDeleted
} from '../../../modules/Questionnaire/Questionnaire.context'
import { useDeleteSection } from '../Section.hooks'
import { DraftSectionItem } from '../Section.model'
import DialogDeleteSectionComponent from './DialogDeleteSection.component'

const DialogDeleteSection = () => {
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)
  const { draftQuestionnaire } = useGetDraftQuestionnaire()

  const sectionEntry = draftQuestionnaire?.entries.find(
    (entry) => entry.number === questionnaireState.sectionEntryNumberToBeDeleted
  )

  const { deleteSectionAndQuestions, deleteSectionWithoutQuestions } =
    useDeleteSection(sectionEntry?.number ?? 0)

  const handleCloseDeleteSection = () => {
    dispatch(setSectionEntryNumberToBeDeleted(-1))
    dispatch(closeDeleteSectionDialog())
  }

  if (!sectionEntry) {
    return null
  }

  const { contextPosition, entryItem } = sectionEntry
  const sectionName = `Section ${contextPosition + 1}`
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const numberOfEntries = (entryItem as DraftSectionItem).entries?.length ?? 0

  return (
    <DialogDeleteSectionComponent
      isOpened={questionnaireState.isDeleteSectionDialogOpened}
      sectionName={sectionName}
      numberOfEntries={numberOfEntries}
      onClose={handleCloseDeleteSection}
      onDeleteAll={deleteSectionAndQuestions}
      onDeleteWithoutQuestions={deleteSectionWithoutQuestions}
    />
  )
}

export default DialogDeleteSection
