import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { UpdateFieldworkSoftLaunchMutationVariables } from '../../data/gql-gen/fieldwork/graphql'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'

export const UPDATE_FIELDWORK_SOFT_LAUNCH = gql`
  mutation updateFieldworkSoftLaunch(
    $surveyId: ID!
    $softLaunch: SoftLaunchInput
  ) {
    updateFieldwork(
      surveyId: $surveyId
      panelSupplierCode: "cint"
      softLaunch: $softLaunch
    )
  }
`

export const useUpdateFieldworkSoftLaunch = () => {
  const [updateFieldworkSoftLaunch] = useMutation<
    void,
    UpdateFieldworkSoftLaunchMutationVariables
  >(UPDATE_FIELDWORK_SOFT_LAUNCH, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateFieldworkSoftLaunch',
        error
      )
    }
  })
  return { updateFieldworkSoftLaunch }
}
