import { DraftSectionItem } from '../../components/Section/Section.model'
import {
  DraftMaskingRule,
  DraftMatrixRow,
  ForkTag,
  LoopSpecificDisplayLogic
} from '../gql-gen/questionnaire/graphql'
import { ResponseOptionType } from './shared'

export interface MediaEnabledEntry {
  questionMedia?: QuestionMedia
}

export enum TextEntryState {
  TextEntryEnabled = 'TextEntryEnabled',
  TextEntryDisabled = 'TextEntryDisabled'
}

export interface DraftQuestionnaire {
  questionnaireId: string
  entries: DraftQuestionnaireEntry[]
  audienceEntries: DraftQuestionnaireEntry[]
  createdDate: string
  sinceDate: string
  validationErrors: (
    | MatrixValidationErrors
    | QuestionValidationErrors
    | ForkValidationErrors
  )[]
}

export interface QuestionValidationErrors {
  questionLk: string
  errors: QuestionValidationError[]
  responseOptionErrors: ResponseOptionsValidationErrors[]
}

export interface ForkValidationErrors {
  forkId: string
  errors: ForkValidationError[]
}

export enum ForkValidationError {
  ForkBranchesInvalidTotalError = 'ForkBranchesInvalidTotalError'
}

export interface ResponseOptionsValidationErrors {
  responseOptionLk: string
  errors: ResponseOptionValidationError[]
}

export enum ResponseOptionValidationError {
  EmptyResponseOptionValueError = 'EmptyResponseOptionValueError',
  DuplicateResponseOptionValueError = 'DuplicateResponseOptionValueError',
  InvalidRoute = 'InvalidRoute',
  InvalidDisplayLogic = 'InvalidDisplayLogic'
}

export enum QuestionValidationError {
  EmptyQuestionTextError = 'EmptyQuestionTextError',
  ResponseOptionCountLessThan2Error = 'ResponseOptionCountLessThan2Error',
  MatrixRowCountLessThan2Error = 'MatrixRowCountLessThan2Error',
  ResponseRangeMinCharactersExceedError = 'ResponseRangeMinCharactersExceedError',
  QuestionLogicInvalidQuestionError = 'QuestionLogicInvalidQuestionError'
}

export enum SliderValidationError {
  SliderInvalidRangeError = 'SliderInvalidRangeError',
  SliderInvalidStepError = 'SliderInvalidStepError'
}

export interface ValidationError {
  errors?: QuestionValidationError[]
  rowErrors?: MatrixRowValidationErrors[]
  responseOptionErrors?: ResponseOptionsValidationErrors[]
  sliderErrors?: SliderValidationError[]
  forkErrors?: ForkValidationError[]
}

export interface MatrixValidationErrors {
  matrixTitleLk: string
  errors: MatrixValidationError[]
  rowErrors: MatrixRowValidationErrors[]
  columnErrors: MatrixColumnValidationErrors[]
}

export interface MatrixColumnValidationErrors {
  responseOptionLk: string
  errors: MatrixColumnValidationError[]
}
export enum MatrixColumnValidationError {
  EmptyMatrixColumnError = 'EmptyMatrixColumnError',
  DuplicateMatrixColumnError = 'DuplicateMatrixColumnError'
}
export interface MatrixRowValidationErrors {
  questionLk: string
  errors: MatrixRowValidationError[]
}

export enum MatrixRowValidationError {
  EmptyMatrixRowError = 'EmptyMatrixRowError',
  DuplicateMatrixRowError = 'DuplicateMatrixRowError',
  InvalidDisplayLogic = 'InvalidDisplayLogic'
}

export enum MatrixValidationError {
  EmptyMatrixTitleError = 'EmptyMatrixTitleError',
  MatrixColumnCountLessThan2Error = 'MatrixColumnCountLessThan2Error',
  MatrixRowCountLessThan2Error = 'MatrixRowCountLessThan2Error'
}

type EntryTypes = {
  [EntryType.QuestionEntryType]: DraftQuestionItem
  [EntryType.TextCardEntryType]: DraftTextCardItem
  [EntryType.MatrixEntryType]: DraftMatrixItem
  [EntryType.ForkEntryType]: DraftForkItem
  [EntryType.SectionEntryType]: DraftSectionItem
}

export type DraftQuestionnaireEntry<
  Type extends keyof EntryTypes = keyof EntryTypes
> = {
  sectionId: string | undefined
  number: number
  position: number
  entryId: string
  questionKind: QuestionKind
  contextPosition: number
  forks: ForkTag[]
  createdDate: string
  sinceDate: string
  __typename: string
  loopSpecificDisplayLogic: LoopSpecificDisplayLogic | null
} & {
  [K in keyof EntryTypes]: {
    entryType: K
    entryItem: EntryTypes[K]
  }
}[Type]

export enum QuestionKind {
  AudienceKind = 'AudienceKind',
  QuestionnaireKind = 'QuestionnaireKind'
}

export enum EntryType {
  QuestionEntryType = 'QuestionEntryType',
  TextCardEntryType = 'TextCardEntryType',
  MatrixEntryType = 'MatrixEntryType',
  ForkEntryType = 'ForkEntryType',
  SectionEntryType = 'SectionEntryType'
}

export enum QuestionTypeCode {
  Basic = 'basic',
  CustomAudience = 'custom_audience',
  StandardAudience = 'standard_audience',
  FreeText = 'free_text',
  Ranked = 'ranked',
  Matrix = 'matrix',
  Scale = 'scale',
  TextCard = 'text_card',
  Fork = 'fork',
  Section = 'section',
  MaxDiff = 'max_diff'
}

export enum EntryPrefix {
  QuestionnaireKind = 'Q',
  AudienceKind = 'A',
  TextCardEntryType = 'T',
  SectionEntryType = 'S'
}

export interface DraftForkItem {
  fork: Fork
  __typename: string
}

export interface Fork {
  forkId: string
  name: string
  branches: ForkBranch[]
  __typename: string
}

export interface ForkBranch {
  label: string
  branchNumber: number
  percentage: number
  __typename: string
}

export interface DraftQuestionItem extends MediaEnabledEntry {
  questionTypeCode: QuestionTypeCode
  questionLk: string
  question: Question
  questionScale?: QuestionScale
  questionLogic: DraftLogicClauseProposition[][]
  responseOptions: DraftEntryResponseOption[]
  maxDiffSpecification: MaxDiffSpecification | null
  settingValues: EntrySettingValue[]
  __typename: string
}

export interface MaxDiffSpecification {
  mostLabel: MaxDiffLocalisedLabel
  leastLabel: MaxDiffLocalisedLabel
  attributeLabel: MaxDiffLocalisedLabel
  experimentalDesigns: ExperimentalDesign[]
  __typename: string
}

export interface MaxDiffLocalisedLabel {
  text: string
  language: string
  country: string
  __typename: string
}

export interface ExperimentalDesign {
  version: number
  sets: ResponseOptionSet[]
  __typename: string
}

export interface ResponseOptionSet {
  setNumber: number
  responseOptions: number[]
  __typename: string
}

export interface SliderNumbers {
  min: number
  max: number
  step: number
}

export interface ScaleRange extends SliderNumbers {
  startingPosition: number
  createdDate: string
  sinceDate: string
  __typename: string
}

export interface LocalisedScaleLabel {
  text: string
  language: string
  country: string
  createdDate: string
  sinceDate: string
}

export interface QuestionScaleLabel {
  label: LocalisedScaleLabel
  position: number
  __typename: string
}

export interface QuestionScale {
  range: ScaleRange
  labels: QuestionScaleLabel[]
  __typename: string
}

export interface QuestionMedia {
  mediaName: string
  mediaUrl: string
  mediaType: MediaType
  createdDate: string
  sinceDate: string
  renderedMediaUrl: string
  __typename: string
}

export interface ResponseOptionMedia {
  mediaName: string
  mediaUrl: string
  mediaType: MediaType
  renderedMediaUrl: string
  __typename: string
}

export enum MediaType {
  Image = 'Image',
  Video = 'Video'
}

export interface Question {
  questionId: string
  text: string
  textStyling?: string | null
  createdDate: string
  __typename: string
}

export interface DraftEntryResponseOption {
  position: number
  createdDate: string
  sinceDate: string
  responseOptionLk: string
  responseOption: ResponseOption
  qualification: Qualification
  route: Route | null
  pinned: boolean
  exclusive: boolean
  textEntryState: TextEntryState
  maskingRules: DraftMaskingRule[]
  media: ResponseOptionMedia | null
  __typename: string
}

export interface ResponseOption {
  responseOptionId: string
  rotype: ResponseOptionType
  value: string
  createdDate: string
  __typename: string
}

export interface Qualification {
  createdDate: string
  sinceDate: string
  __typename: string
}

export interface EntrySettingValue {
  code: QuestionSettingCode
  value: SettingValue | string
  createdDate: string
  sinceDate: string
  __typename: string
}

export interface Route {
  sinceDate: string
  targetNumber: number
  __typename: string
}

export interface TextCardItem {
  textCard: TextCard
  __typename: string
}

export interface TextCard {
  textCardId: string
  title: string
  titleStyling: string | null
  subtitle: string
  body: string
  createdDate: string
  __typename: string
}

export interface DetachedEntryResponseOption {
  questionnaireId: string
  questionLk: string
  responseOptionLk: string
  detachedDate: string
  __typename: string
}

export interface DetachedQuestionnaireEntry {
  questionnaireId: string
  number: number
  detachedDate: string
  __typename: string
}

export enum QuestionSettingCode {
  BasicChoice = 'basic_choice',
  CharacterLimit = 'character_limit',
  ChoiceLimit = 'choice_limit',
  ChoiceIntervalMin = 'choice_interval_min',
  ChoiceIntervalMax = 'choice_interval_max',
  MatrixChoice = 'matrix_choice',
  DontKnow = 'default_option_dont_know',
  PreferNotToSay = 'default_option_skip',
  NoneOfThese = 'default_option_none',
  NotApplicable = 'default_option_not_applicable',
  RankChoiceRequisite = 'choice_requisite',
  RandomiseOptions = 'randomise_options',
  SectionRandomiseQuestions = 'section_questions_randomisation',
  Looping = 'looping',
  MatrixRandomiseRows = 'matrix_randomise_rows',
  MatrixRandomiseResponseOptions = 'matrix_randomise_options',
  Routing = 'routing',
  FlipOptions = 'flip_options',
  MatrixFlipOptions = 'matrix_flip_options',
  ContentStructure = 'content_structure',
  SliderLabels = 'slider_labels',
  CustomAudienceChoice = 'custom_audience_choice',
  StandardAudienceChoice = 'standard_audience_choice',
  QuestionTimer = 'question_timer',
  SectionsRandomisation = 'sections_randomisation',
  CharacterMinimum = 'character_minimum',
  MatrixMobileViewOnly = 'matrix_mobile_view_only',
  AutoAnswerAll = 'auto_answer_all',
  DisableSourceMedia = 'disable_source_media'
}

export enum SettingValue {
  SingleChoice = 'single_choice',
  MultipleChoice = 'multiple_choice',
  OneHundred = '100',
  TwoHundred = '200',
  EightHundred = '800',
  Enabled = 'enabled',
  Disabled = 'disabled',
  Numeric1 = 'numeric__^-?[0-9]\\d*(\\.\\d+)?$',
  Numeric2 = 'numeric_posint__^\\d+$',
  Numeric3 = 'numeric_int__^-?\\d+$',
  PostCode = 'postcode_uk__^[a-zA-Z]{1,2}\\d[a-zA-Z\\d]?\\s*\\d[a-zA-Z]{2}$',
  Alphanumeric = 'alphanumeric__^[A-Za-z0-9\\s]+$',
  Email = 'email__^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$',
  Unstructured = 'unstructured',
  PostCodeFull = 'postcode_uk__full',
  Two = '2',
  ThreeHundred = '300',
  FiveHundred = '500',
  Category = 'category'
}

export interface SettingInputInput {
  questionSettingCode: QuestionSettingCode
  settingValue: SettingValue | string
}

export interface DraftTextCardItem extends MediaEnabledEntry {
  textCardLk: string
  textCard: TextCard
  questionLogic: DraftLogicClauseProposition[][]
  settingValues: EntrySettingValue[]
  __typename: string
}

export type DraftMatrixItem = {
  matrixTitleLk: string
  matrixTitle: MatrixTitle
  matrixRows: DraftMatrixRow[]
  questionLogic: DraftLogicClauseProposition[][]
  responseOptions: DraftEntryResponseOption[]
  settingValues: EntrySettingValue[]
  matrixQualification: null | MatrixQualification
  __typename: string
}

export interface MatrixTitle {
  matrixTitleId: string
  title: string
  titleStyling: string | null
  __typename: string
}

export type MatrixQualification = {
  qualifyingOptions: string[]
  matrixRowQualifyingCondition: MatrixRowQualifyingCondition
  __typename: string
}

export type MatrixRowQualifyingCondition = {
  numberOfQualifyingRows: number
  operator: 'GreaterThanOrEquals' | 'LessThanOrEquals'
  __typename: string
}

export interface DetachedEntryMatrixRow {
  questionnaireId: string
  matrixTitleLk: string
  questionLk: string
  detachedDate: string
  entry: DraftQuestionnaireEntry<EntryType.MatrixEntryType>
  __typename: string
}

export interface LocalisedQuestion {
  __typename: string
  questionId: string
  questionTypeCode: string
  language: string
  country: string
  text: string
  createdDate: string
  localisedDate: string
}

export interface DetachedEntryMatrixResponseOption {
  questionnaireId: string
  matrixTitleLk: string
  responseOptionLk: string
  detachedDate: string
  __typename: string
}

export interface DraftRoute {
  questionnaireId: string
  questionLk: string
  responseOptionLk: string
  targetNumber: number
  __typename: string
}

export interface PositionTextSelection {
  startPositionSelectedText?: number | null
  endPositionSelectedText?: number | null
}

export enum LogicPropositionType {
  AudienceResponseOptionType = 'AudienceResponseOptionType',
  MatrixResponseOptionCountType = 'MatrixResponseOptionCountType',
  MatrixResponseOptionType = 'MatrixResponseOptionType',
  MatrixRowCountType = 'MatrixRowCountType',
  QuestionResponseOptionCountType = 'QuestionResponseOptionCountType',
  QuestionResponseOptionRankType = 'QuestionResponseOptionRankType',
  QuestionResponseOptionType = 'QuestionResponseOptionType'
}

export interface DraftLogicClauseProposition {
  propositionRef: PropositionRef
  propositionType: LogicPropositionType
  proposition:
    | DraftAudienceReponseOption
    | DraftMatrixResponseOption
    | DraftMatrixResponseOptionCount
    | DraftMatrixRowCount
    | DraftQuestionResponseOption
    | DraftQuestionResponseOptionCount
    | DraftQuestionResponseOptionRank
  negated: boolean
  __typename: string
}

export interface PropositionRef {
  clauseNumber: number
  propositionId: string
  __typename: string
}

export interface DraftAudienceReponseOption {
  surveyId: string
  questionId: string
  responseOptionId: string
  __typename: string
}
export interface DraftMatrixResponseOption {
  matrixTitleLk: string
  questionLk: string
  responseOptionLk: string
  __typename: string
}
export interface DraftMatrixResponseOptionCount {
  matrixTitleLk: string
  questionLk: string
  count: number
  __typename: string
}
export interface DraftMatrixRowCount {
  matrixTitleLk: string
  responseOptionLk: string
  count: number
  __typename: string
}
export interface DraftQuestionResponseOption {
  questionLk: string
  responseOptionLk: string
  __typename: string
}
export interface DraftQuestionResponseOptionCount {
  questionLk: string
  count: number
  __typename: string
}
export interface DraftQuestionResponseOptionRank {
  questionLk: string
  responseOptionLk: string
  ranked: number
  __typename: string
}

export interface DraftQuestionResponseOptionCountInput {
  questionLk: string
  count: number
}
export interface DraftMatrixResponseOptionInput {
  matrixTitleLk: string
  questionLk: string
  responseOptionLk: string
}

export interface DraftMatrixRowCountInput {
  matrixTitleLk: string
  responseOptionLk: string
  count: number
}
export interface DraftMatrixResponseOptionCountInput {
  matrixTitleLk: string
  questionLk: string
  count: number
}
export interface DraftAudienceReponseOptionInput {
  surveyId: string
  questionId: string
  responseOptionId: string
}
export interface DraftQuestionResponseOptionRankInput {
  questionLk: string
  responseOptionLk: string
  ranked: number
}

export interface DraftQuestionResponseOptionInput {
  questionLk: string
  responseOptionLk: string
}
