import { useMutation } from '@apollo/client'
import {
  RemoveResponseOptionMediaMutationVariables,
  SetResponseOptionMediaMutationVariables
} from '../../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import {
  REMOVE_RESPONSE_OPTION_MEDIA,
  SET_RESPONSE_OPTION_MEDIA,
  setResponseOptionMediaData
} from './ResponseOption.mutations'

export const useResponseOptionMediaActions = () => {
  const [setResponseOptionMedia] = useMutation<
    setResponseOptionMediaData,
    SetResponseOptionMediaMutationVariables
  >(SET_RESPONSE_OPTION_MEDIA, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addResponseOptionImage',
        error
      )
    }
  })

  const [removeResponseOptionMedia] = useMutation<
    void,
    RemoveResponseOptionMediaMutationVariables
  >(REMOVE_RESPONSE_OPTION_MEDIA, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeResponseOptionImage',
        error
      )
    }
  })

  return {
    setResponseOptionMedia,
    removeResponseOptionMedia
  }
}
