import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  RemoveAllStandardQualificationMutationVariables,
  RemoveStandardCriterionMutationVariables,
  RemoveStandardMemberSettingValueMutationVariables,
  RemoveStandardQualificationMutationVariables,
  RemoveStandardQuotasMutationVariables,
  SetAllStandardQualificationMutationVariables,
  SetStandardMemberSettingValueMutationVariables,
  SetStandardQualificationMutationVariables,
  SetStandardQuotaMutationVariables,
  SetStandardQuotasMutationVariables
} from '../../../data/gql-gen/fieldwork/graphql'
import {
  FieldworkAudienceCriterionData,
  REMOVE_CRITERION
} from '../../../data/gql/fieldwork/mutations/fieldworkAudienceCriterion'
import {
  QualificationData,
  REMOVE_QUALIFICATION,
  SET_QUALIFICATION
} from '../../../data/gql/fieldwork/mutations/qualification'
import {
  REMOVE_ALL_STANDARD_QUALIFICATION,
  SET_ALL_STANDARD_QUALIFICATION
} from '../../../data/gql/fieldwork/mutations/setAllStandardQualification'
import {
  REMOVE_STANDARD_MEMBER_SETTING_VALUE,
  RemoveStandardMemberSettingValueData,
  SET_STANDARD_MEMBER_SETTING_VALUE,
  SetStandardMemberSettingValueData
} from '../../../data/gql/fieldwork/mutations/standardMemberSettingValue'
import {
  FieldworkQuotaData,
  FieldworkQuotaUpdatData,
  REMOVE_STANDARD_QUOTAS,
  SET_STANDARD_QUOTAS,
  UPDATE_STANDARD_QUOTA
} from '../../../data/gql/fieldwork/mutations/standardQuotas'
import { categoriesRefetchQuery } from '../../../data/gql/fieldwork/queries/categories'
import { fieldworkAudienceRefetchQuery } from '../../../data/gql/fieldwork/queries/fieldworkAudience'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { getFeasibilityEstimateRefetchQuery } from '../../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { LoggerErrorType } from '../../../data/logger'
import {
  DraftStandardAudience,
  FieldworkAudience,
  MemberSettingCode
} from '../../../data/model/audience'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { DEFAULT_LOI } from '../../Survey/SurveyStats/SurveyStats.utils'
import { useUpdateCustomizedCriterionOption } from '../CustomAgeRanges/CustomAgeRanges.hooks'
import { getIsEnabledInSettings } from '../utils'

export const useAudienceQuestionCardMutations = (lengthOfInterview: number) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const defaultMutationOptions = {
    context: { clientName: 'fieldwork' },
    refetchQueries: [
      draftAudienceRefetchQuery(projectId, surveyId),
      getFeasibilityEstimateRefetchQuery(
        projectId,
        surveyId,
        lengthOfInterview
      ),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  }

  const [removeCriterion] = useMutation<
    FieldworkAudienceCriterionData,
    RemoveStandardCriterionMutationVariables
  >(REMOVE_CRITERION, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeCriterion',
        error
      )
    },
    refetchQueries: [
      draftAudienceRefetchQuery(projectId, surveyId),
      categoriesRefetchQuery(surveyId),
      getFeasibilityEstimateRefetchQuery(
        projectId,
        surveyId,
        lengthOfInterview
      ),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  })
  const [enableQuotas] = useMutation<
    FieldworkQuotaData,
    SetStandardQuotasMutationVariables
  >(SET_STANDARD_QUOTAS, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'enableQuotas', error)
    }
  })
  const [disableQuotas] = useMutation<
    FieldworkQuotaData,
    RemoveStandardQuotasMutationVariables
  >(REMOVE_STANDARD_QUOTAS, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'disableQuotas', error)
    }
  })
  const [enableOption] = useMutation<
    QualificationData,
    SetStandardQualificationMutationVariables
  >(SET_QUALIFICATION, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'enableOption', error)
    }
  })
  const [disableOption] = useMutation<
    QualificationData,
    RemoveStandardQualificationMutationVariables
  >(REMOVE_QUALIFICATION, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'disableOption', error)
    }
  })
  const [updateQuota] = useMutation<
    FieldworkQuotaUpdatData,
    SetStandardQuotaMutationVariables
  >(UPDATE_STANDARD_QUOTA, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'updateQuota', error)
    }
  })
  const [batchUpdateQuota] = useMutation<
    FieldworkQuotaUpdatData,
    SetStandardQuotaMutationVariables
  >(UPDATE_STANDARD_QUOTA, {
    context: { clientName: 'fieldwork' },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'updateQuota', error)
    }
  })
  const [setAllStandardQualification] = useMutation<
    void,
    SetAllStandardQualificationMutationVariables
  >(SET_ALL_STANDARD_QUALIFICATION, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setAllStandardQualification',
        error
      )
    }
  })
  const [removeAllStandardQualification] = useMutation<
    void,
    RemoveAllStandardQualificationMutationVariables
  >(REMOVE_ALL_STANDARD_QUALIFICATION, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeAllStandardQualification',
        error
      )
    }
  })

  const [setSettingValue] = useMutation<
    SetStandardMemberSettingValueData,
    SetStandardMemberSettingValueMutationVariables
  >(SET_STANDARD_MEMBER_SETTING_VALUE, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setStandardAudienceSetting',
        error
      )
    }
  })

  const [removeSettingValue] = useMutation<
    RemoveStandardMemberSettingValueData,
    RemoveStandardMemberSettingValueMutationVariables
  >(REMOVE_STANDARD_MEMBER_SETTING_VALUE, {
    ...defaultMutationOptions,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeStandardAudienceSetting',
        error
      )
    }
  })

  return {
    removeCriterion,
    enableQuotas,
    disableQuotas,
    enableOption,
    disableOption,
    updateQuota,
    batchUpdateQuota,
    setAllStandardQualification,
    removeAllStandardQualification,
    setSettingValue,
    removeSettingValue
  }
}

export const useSurveyLengthData = (): {
  totalCompletes: number
  loi: number
} => {
  const [totalCompletes, setTotalCompletes] = useState(0)
  const [loi, setLoi] = useState(DEFAULT_LOI)

  const { loading, error, fieldwork } = useFieldwork()

  useEffect(() => {
    if (!loading && !error && fieldwork) {
      if (fieldwork.samplesNeeded.unadjusted !== totalCompletes) {
        setTotalCompletes(fieldwork.samplesNeeded.unadjusted || 0)
      }
      if (fieldwork.lengthOfInterview.adjusted !== loi) {
        setLoi(fieldwork.lengthOfInterview.adjusted || DEFAULT_LOI)
      }
    }
  }, [totalCompletes, loi, loading, error, fieldwork])

  return { totalCompletes, loi }
}

export const useSumQuotas = (audience: FieldworkAudience, loi: number) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const updateCustomizedCriterionOption = useUpdateCustomizedCriterionOption()
  const memberItem: DraftStandardAudience =
    audience.memberItem as DraftStandardAudience
  const { batchUpdateQuota } = useAudienceQuestionCardMutations(loi)
  const { refetchAudience } = useGetDraftAudience()
  const customizedCriterionOptionsEnabled: boolean = getIsEnabledInSettings(
    memberItem.settingValues,
    MemberSettingCode.CustomizedCriterionOptions
  )
  const criterionOptions = customizedCriterionOptionsEnabled
    ? memberItem.customizedCriterionOptions ?? []
    : memberItem.standardCriterionOptions
  const quotaSum = criterionOptions.reduce<number>(
    (sum, { qualification, quota }) =>
      sum + (qualification && quota ? quota.percent : 0),
    0
  )

  const toDistribute = 1 - quotaSum
  // get all options that are qualifying
  const qualifyingOptions = criterionOptions.filter((co) => co.qualification)
  const valueToAdd = toDistribute / qualifyingOptions.length

  return () =>
    Promise.all(
      qualifyingOptions.map(
        async ({ quota, qualification, criterionOption }) => {
          const { code, responseOption } = criterionOption
          const [min, max] = code.split('_-_')
          const currentPercent = quota?.percent ?? 0
          const quotaPercent =
            toDistribute < 0
              ? currentPercent / quotaSum
              : currentPercent + valueToAdd
          return customizedCriterionOptionsEnabled
            ? updateCustomizedCriterionOption(responseOption.responseOptionId, {
                min,
                max,
                qualified: !!qualification,
                quota: quotaPercent
              })
            : batchUpdateQuota({
                variables: {
                  surveyId,
                  audienceCriterionCode: memberItem.criterion.code,
                  audienceCriterionOptionCode: code,
                  percent: quotaPercent
                }
              })
        }
      )
    ).then(() => {
      refetchAudience()
      getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi)
    })
}
