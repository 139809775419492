import { Checkbox } from '@focaldata/cin-ui-components'
import React, { useContext } from 'react'
import QuestionnaireContext, {
  selectCard,
  unselectCard
} from '../Questionnaire.context'

export const CardSelector = ({ entryNumber }: { entryNumber: number }) => {
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)
  const handleToggleCardSelection = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isCardSelected = e.target.checked

    dispatch(
      isCardSelected ? selectCard(entryNumber) : unselectCard(entryNumber)
    )
  }
  return (
    <Checkbox
      noPadding
      onChange={handleToggleCardSelection}
      checked={questionnaireState.selectedCards.has(entryNumber)}
      color="#72758D"
    />
  )
}
