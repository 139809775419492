import { gql } from '@apollo/client/core'
import { DraftEntryResponseOption } from '../../../model/questionnaire'
import DRAFT_ENTRY_RESPONSE_OPTION from '../fragments/draftEntryResponseOption'

export interface UpdateResponseOptionData {
  updateResponseOption: DraftEntryResponseOption
}

export const UPDATE_RESPONSE_OPTION = gql`
  mutation updateResponseOption(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $responseOptionValue: String!
  ) {
    updateResponseOption(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      responseOption: {
        responseOptionValue: $responseOptionValue
        responseOptionType: RoString
      }
    ) {
      ...DraftEntryResponseOption
    }
  }
  ${DRAFT_ENTRY_RESPONSE_OPTION}
`
