import { gql } from '@apollo/client/core'
import { EntrySettingValue } from '../../../model/questionnaire'
import ENTRY_SETTING_VALUE from '../fragments/entrySettingValue'

export interface RemoveQuestionnaireSettingValueData {
  removeQuestionnaireSettingValue: EntrySettingValue
}

export const REMOVE_QUESTIONNAIRE_SETTING_VALUE = gql`
  mutation removeQuestionnaireSettingValue(
    $questionnaireId: ID!
    $questionLk: ID!
    $questionSettingCode: String!
  ) {
    removeQuestionnaireSettingValue(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      questionSettingCode: $questionSettingCode
    ) {
      ...EntrySettingValue
    }
  }
  ${ENTRY_SETTING_VALUE}
`
