import { useMutation } from '@apollo/client'
import { Alert, AlertType, theme } from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import ConfirmationCostBreakdown from '../../../../controls/ConfirmationCostBreakdown'
import ConfirmationCostBreakdownLoader from '../../../../controls/ConfirmationCostBreakdown/loading'
import ConfirmationProjectOverview from '../../../../controls/ConfirmationProjectOverview'
import ConfirmationProjectOverviewLoader from '../../../../controls/ConfirmationProjectOverview/loading'
import ConfirmationReviewDataQuality from '../../../../controls/ConfirmationReviewDataQuality'
import ConfirmationStartDate from '../../../../controls/ConfirmationStartDate'
import ConfirmationStartDateLoader from '../../../../controls/ConfirmationStartDate/loading'
import SurveyLaunchedPage from '../../../../controls/SurveyLaunchedPage'
import { CreateSurveyOrderMutationVariables } from '../../../../data/gql-gen/dashboard/graphql'
import { UpdateFieldworkMutationVariables } from '../../../../data/gql-gen/fieldwork/graphql'
import { UPDATE_FIELDWORK } from '../../../../data/gql/fieldwork/mutations/updateFieldwork'
import {
  CREATE_SURVEY_ORDER,
  CreateSurveyOrderData
} from '../../../../data/gql/order/mutations/createSurveyOrder'
import { LoggerErrorType } from '../../../../data/logger'
import { Locale } from '../../../../data/model/fieldwork'
import useAudienceQuestionsCounter from '../../../../hooks/audience/useAudienceQuestionsCounter'
import useGetDraftAudience from '../../../../hooks/audience/useGetDraftAudience'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import useCalculateLengthOfInterview from '../../../../hooks/useCalculateLengthOfInterview'
import { useFieldwork } from '../../../../hooks/useFieldwork'
import { useGetFeasibilityEstimateData } from '../../../../hooks/useGetFeasibilityEstimateData'
import usePollCostQuote from '../../../../hooks/usePollCostQuote'
import { useProjectId } from '../../../../hooks/useProjectId'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import ConfirmationLayout from '../../../../layouts/ConfirmationLayout'
import {
  calculateTotalCost,
  captureApolloError,
  getResultsPath,
  handleShowPreview
} from '../../../../utils/HelperFunctions'
import BottomNav from '../BottomNav'
import {
  getStartAndEndDatesAdjusted,
  getStartAndEndDatesFormatted
} from './Confirmation.utils'

const Confirmation: React.FC = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const navigate = useNavigate()
  const [isPurchaseGoLiveClicked, setIsPurchaseGoLiveClicked] =
    useState<boolean>(false)
  const [isOrderPlaced, setIsOrderPlaced] = useState<boolean>(false)
  const [hasCostChanged, setHasCostChanged] = useState<boolean>(false)

  const { loadingCountAudienceQuestions } = useAudienceQuestionsCounter()
  const { hasValidationErrors } = useQuestionnaireValidation()

  const {
    isFeasible,
    isAudienceValid,
    estimatedDeliveryTime: estimatedDeliveryDays,
    feasibility,
    loadingFeasibilityEstimate,
    loadingFieldworkEstimate
  } = useGetFeasibilityEstimateData()

  const { draftQuestionnaire, questionnaireLoading } =
    useGetDraftQuestionnaire()

  const entryQuestionnaireQuestionsCount = draftQuestionnaire?.entries.length

  const isQuestionnairePresent = entryQuestionnaireQuestionsCount
    ? entryQuestionnaireQuestionsCount !== 0
    : false

  const { draftAudience, loading: loadingAudience } = useGetDraftAudience()

  const membersAudienceQuestionsCount = draftAudience?.members.length

  const countTotalQuestions = membersAudienceQuestionsCount
    ? entryQuestionnaireQuestionsCount &&
      entryQuestionnaireQuestionsCount + membersAudienceQuestionsCount
    : entryQuestionnaireQuestionsCount

  const {
    fieldwork,
    loading: loadingFieldwork,
    refetch: refetchFieldwork
  } = useFieldwork({ fetchPolicy: 'no-cache' })

  const calculatedEstimatedLOI = useCalculateLengthOfInterview()

  let estimatedLengthOfInterview = fieldwork?.lengthOfInterview.unadjusted || 5 // 5 minutes is the min default length of interview used for the intial estimation

  const isEstimatedAndCalculatedSame =
    estimatedLengthOfInterview === calculatedEstimatedLOI

  if (calculatedEstimatedLOI && !isEstimatedAndCalculatedSame) {
    estimatedLengthOfInterview = calculatedEstimatedLOI
  }

  const wantedCompletes: number | undefined =
    fieldwork?.samplesNeeded.unadjusted
  const locale: Locale | undefined = fieldwork?.locale
  const fieldworkDataStartDate: string | undefined =
    fieldwork?.duration.startDate

  const [selectedDate, setSelectedDate] = useState<Date>(
    fieldworkDataStartDate ? new Date(fieldworkDataStartDate) : new Date()
  )

  const [updateFieldwork] = useMutation<void, UpdateFieldworkMutationVariables>(
    UPDATE_FIELDWORK,
    {
      context: { clientName: 'fieldwork' },
      onCompleted: () => refetchFieldwork(),
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'updateFieldwork',
          error
        )
      }
    }
  )

  const { getCostQuoteData, loadingCostDetails, refetchGetCostQuote } =
    usePollCostQuote(estimatedLengthOfInterview)
  const { costPerIncidence, baseAmount, roundedCostPerIncidence } =
    getCostQuoteData

  const totalCost =
    roundedCostPerIncidence &&
    wantedCompletes &&
    calculateTotalCost(roundedCostPerIncidence, wantedCompletes)

  const { formattedStartDate, formattedEndDate } = getStartAndEndDatesFormatted(
    selectedDate,
    estimatedDeliveryDays
  )

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedConfirmPage, { surveyId })
  }, [surveyId])

  useEffect(() => {
    if (!fieldworkDataStartDate) {
      return
    }

    // It checks the startDate saved in the DB and compares it to todayDate
    // If the startDate is in the past, it updates UI to always be, at least,
    // today's date and never in the past
    setSelectedDate(
      new Date(
        getStartAndEndDatesAdjusted(
          fieldworkDataStartDate,
          estimatedDeliveryDays
        ).adjustedStartDate
      )
    )
  }, [fieldworkDataStartDate, estimatedDeliveryDays])

  const [createSurveyOrder, { loading: loadingCreateSurveyOrder }] =
    useMutation<CreateSurveyOrderData, CreateSurveyOrderMutationVariables>(
      CREATE_SURVEY_ORDER,
      {
        context: { clientName: 'dashboard' },
        onCompleted: () => {
          setIsOrderPlaced(true)
        },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'createSurveyOrder',
            error
          )
        }
      }
    )

  const toggleDialogOpen: () => void = () => {
    const { adjustedStartDate } = getStartAndEndDatesAdjusted(
      selectedDate,
      estimatedDeliveryDays
    )
    setSelectedDate(new Date(adjustedStartDate))
    setIsPurchaseGoLiveClicked(!isPurchaseGoLiveClicked)
    setHasCostChanged(false)
  }

  const handlePlaceOrder = async () => {
    if (surveyId && feasibility !== undefined && costPerIncidence) {
      // have a final check on the cost quote
      // show an error message when cost has changed silently
      // i.e IR slackbot or via gql playground
      const { data: newCostDetails } = await refetchGetCostQuote({
        projectId,
        surveyId,
        lengthOfInterview: estimatedLengthOfInterview
      })
      const newCostPerIncidence = newCostDetails.getCostQuote?.cpi.amount
      setHasCostChanged(costPerIncidence !== newCostPerIncidence)

      // this is to match the cost details shown to customer
      // with the final check
      if (costPerIncidence === newCostPerIncidence) {
        const { adjustedStartDate, adjustedEndDate } =
          getStartAndEndDatesAdjusted(selectedDate, estimatedDeliveryDays)
        // make sure fieldwork is always updated before placing survey order
        await updateFieldwork({
          variables: {
            surveyId,
            panelSupplierCode: 'cint',
            lengthOfInterview: estimatedLengthOfInterview,
            startDate: adjustedStartDate,
            endDate: adjustedEndDate,
            countryCode: null,
            samplesNeeded: null
          }
        })

        await createSurveyOrder({
          variables: {
            surveyId,
            feasibility,
            customerCPI: costPerIncidence,
            baseCPI: baseAmount,
            panelSupplierCode: 'cint'
          }
        })

        LogAmplitudeEvent(EventType.PurchasedProject, { surveyId })
      }
    }
  }

  const goToLaunchedProject = () => {
    LogAmplitudeEvent(EventType.ClickedToYourProjectLaunch, { surveyId })

    navigate(getResultsPath(surveyId))
  }

  if (isOrderPlaced) {
    return (
      <SurveyLaunchedPage
        onButtonClick={goToLaunchedProject}
        surveyLaunchDate={formattedStartDate}
      />
    )
  }

  const isDateSelectionLoading =
    loadingFeasibilityEstimate ||
    loadingFieldwork ||
    questionnaireLoading ||
    loadingFieldworkEstimate

  const isCostBreakdownLoading =
    loadingFieldwork ||
    loadingCostDetails ||
    loadingFeasibilityEstimate ||
    questionnaireLoading ||
    loadingCountAudienceQuestions

  const isProjectOverviewLoading =
    loadingFieldwork ||
    questionnaireLoading ||
    loadingFieldworkEstimate ||
    loadingAudience

  const rightButtonDisabled = !(
    !hasValidationErrors() &&
    isAudienceValid &&
    isFeasible &&
    isQuestionnairePresent
  )

  const statusComponent = (
    <Alert
      severity={AlertType.ERROR}
      style={{ marginBottom: theme.spacing(2) }}
    >
      There is a change in cost. Please check the final cost below before <br />
      proceeding.
    </Alert>
  )

  return (
    <>
      <ConfirmationLayout
        startDateSelection={
          isDateSelectionLoading ? (
            <ConfirmationStartDateLoader />
          ) : (
            <ConfirmationStartDate
              estimatedTimeToComplete={estimatedDeliveryDays}
              completionDate={formattedEndDate}
              displayedDate={selectedDate}
            />
          )
        }
        reviewDataQuality={<ConfirmationReviewDataQuality />}
        costBreakdown={
          isCostBreakdownLoading ? (
            <ConfirmationCostBreakdownLoader />
          ) : (
            <ConfirmationCostBreakdown
              completes={wantedCompletes}
              costPerComplete={costPerIncidence}
              totalCost={totalCost}
              onPlaceOrder={handlePlaceOrder}
              onShowPreview={() => handleShowPreview(surveyId)}
              isOpen={isPurchaseGoLiveClicked}
              toggleIsOpen={toggleDialogOpen}
              confirmButtonDisabled={rightButtonDisabled}
              completionDate={formattedEndDate}
              surveyLaunchDate={formattedStartDate}
              loadingCreateSurveyOrder={loadingCreateSurveyOrder}
              statusComponent={hasCostChanged ? statusComponent : undefined}
            />
          )
        }
        projectOverview={
          isProjectOverviewLoading ? (
            <ConfirmationProjectOverviewLoader />
          ) : (
            <ConfirmationProjectOverview
              locale={locale}
              completes={wantedCompletes}
              lengthOfInterview={estimatedLengthOfInterview}
              numberOfQuestions={countTotalQuestions}
            />
          )
        }
      />
      <BottomNav
        width={'calc(100% - 56px)'}
        rightButtonText="Confirm"
        onClickRightButton={toggleDialogOpen}
        secondaryRightButtonText="Preview"
        onClickSecondaryRightButton={() => handleShowPreview(surveyId)}
        secondaryRightButtonDisabled={!isQuestionnairePresent}
        rightButtonDisabled={rightButtonDisabled}
      />
    </>
  )
}

export default Confirmation
