import { gql } from '@apollo/client/core'
import { DraftRoute } from '../../../model/questionnaire'
import DRAFT_ROUTE from '../fragments/draftRoute'

export interface SetRouteData {
  setRoute: DraftRoute
}

export const SET_ROUTE = gql`
  mutation setRoute(
    $questionnaireId: ID!
    $questionLk: ID!
    $responseOptionLk: ID!
    $targetNumber: Int!
  ) {
    setRoute(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      responseOptionLk: $responseOptionLk
      targetNumber: $targetNumber
    ) {
      ...DraftRoute
    }
  }
  ${DRAFT_ROUTE}
`
