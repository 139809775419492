import { gql } from '@apollo/client/core'

export const SET_QUESTION_SCALE_LABEL = gql`
  mutation setQuestionScaleLabel(
    $questionnaireId: ID!
    $questionLk: ID!
    $labelText: String!
    $position: Int!
  ) {
    setQuestionScaleLabel(
      questionnaireId: $questionnaireId
      questionLk: $questionLk
      labelText: $labelText
      position: $position
    )
  }
`
