import {
  Spinner,
  SpinnerColor,
  SpinnerSize
} from '@focaldata/cin-ui-components'
import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ErrorPage, { ErrorType } from '../../../controls/ErrorPage'
import { SurveyState } from '../../../data/gql-gen/dashboard/graphql'
import usePrepareApolloMiddleware from '../../../hooks/usePrepareApolloMiddleware'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { AudienceContextProvider } from '../../../modules/Audience/Audience.context'
import { FdChatProgressProvider } from '../../../modules/FdChat/FdChatProgressProvider.context'
import { useProjectData } from '../../../modules/Project/Project.hooks'
import { QuestionnaireContextProvider } from '../../../modules/Questionnaire/Questionnaire.context'
import { Datadog } from '../../../tracking/datadog'
import { FdChatAudience } from './AudiencePage'
import FdChatContainer from './FdChat.container'
import { steps } from './FdChatBreadcrumbs'
import FDChatLaunch from './FdChatLaunch/FDChatLaunch'
import FdChatSetupContainer from './FdChatSetup.container'
import { FdChatObjectives } from './ObjectivesPage'
import FdChatOverview from './OverviewPage/FdChatOverview'
import AskAi from './ResultsPage/AskAi/AskAi'
import AskAiSearch from './ResultsPage/AskAi/Search/Search'
import FdChatResultsContainer from './ResultsPage/FdChatResults.container'
import { FdChatReview } from './ReviewPage'

const FdChat = () => {
  const surveyId = useSurveyId()
  usePrepareApolloMiddleware()

  useEffect(() => {
    Datadog.updateUserSession({ surveyId })
  }, [surveyId])

  return (
    <AudienceContextProvider>
      <QuestionnaireContextProvider>
        <FdChatProgressProvider>
          <Routes>
            <Route path="/" element={<FdChatContainer />}>
              <Route index element={<FDChatRoot />} />
              <Route path="setup" element={<FdChatSetupContainer />}>
                <Route
                  index
                  element={<Navigate to={steps[0].path} replace />}
                />
                <Route path="audience" element={<FdChatAudience />} />
                <Route path="objectives" element={<FdChatObjectives />} />
                <Route path="review" element={<FdChatReview />} />
              </Route>
              <Route path="launch" element={<FDChatLaunch />} />
              <Route path="results" element={<FdChatResultsContainer />}>
                <Route index element={<Navigate to="ask-ai" replace />} />
                <Route path="overview" element={<FdChatOverview />} />
                <Route path="ask-ai">
                  <Route index element={<AskAi />} />
                  <Route path="search" element={<AskAiSearch />} />
                </Route>
              </Route>
              <Route
                path="*"
                element={<ErrorPage errorType={ErrorType.NotFound404} />}
              />
            </Route>
          </Routes>
        </FdChatProgressProvider>
      </QuestionnaireContextProvider>
    </AudienceContextProvider>
  )
}

export default FdChat

const FDChatRoot = () => {
  const surveyId = useSurveyId()
  const { project, loading, error } = useProjectData()

  if (loading)
    return (
      <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        <Spinner size={SpinnerSize.l} color={SpinnerColor.Primary} />
      </div>
    )
  if (error) return <ErrorPage errorType={ErrorType.ServerError503} />

  const projectState = project?.surveys[0]?.state

  if (projectState === SurveyState.Draft)
    return <Navigate to={`/fdchat/${surveyId}/setup`} replace />

  return <Navigate to={`/fdchat/${surveyId}/results`} replace />
}
