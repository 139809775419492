import { Grid, PaperGroupItem } from '@focaldata/cin-ui-components'
import React from 'react'
import { CardBackgroundContainer } from '../../modules/Questionnaire/CardBackground'

interface Props {
  questionCardHeader: JSX.Element
  matrixResponseOptionSwitches: JSX.Element
  matrixQuestionRowsSwitches: JSX.Element
  matrixQuestionRows: JSX.Element
  matrixResponseOptions: JSX.Element
  questionCardFooter: JSX.Element
  defaultOptions: JSX.Element
  entryNumber: number
}

const MatrixQuestionLayout: React.FC<Props> = (props: Props) => {
  const {
    questionCardHeader,
    matrixResponseOptionSwitches,
    matrixQuestionRowsSwitches,
    matrixQuestionRows,
    matrixResponseOptions,
    questionCardFooter,
    defaultOptions,
    entryNumber
  } = props

  return (
    <Grid container>
      <CardBackgroundContainer entryNumber={entryNumber}>
        <PaperGroupItem>{questionCardHeader}</PaperGroupItem>
        <PaperGroupItem paddingTopBottom={2}>
          {matrixQuestionRowsSwitches}
        </PaperGroupItem>
        <PaperGroupItem>{matrixQuestionRows}</PaperGroupItem>
        <PaperGroupItem paddingTopBottom={2}>
          {matrixResponseOptionSwitches}
        </PaperGroupItem>
        <PaperGroupItem>
          {matrixResponseOptions}
          {questionCardFooter}
        </PaperGroupItem>
        <PaperGroupItem paddingTopBottom={2}>{defaultOptions}</PaperGroupItem>
      </CardBackgroundContainer>
    </Grid>
  )
}

export default MatrixQuestionLayout
