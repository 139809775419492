import { useMutation, useReactiveVar } from '@apollo/client'
import {
  ExclusiveButton,
  IconColor,
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption,
  ListItemQuestionnaireResponseOption
} from '@focaldata/cin-ui-components'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'
import { useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { questionBeingEditedNumber } from '../../../../apollo/apolloClient'
import {
  CreateOrUpdateMatrixColumnMaskingRuleMutationVariables,
  DraftMaskingRule
} from '../../../../data/gql-gen/questionnaire/graphql'
import {
  DraftEntryResponseOption,
  EntryType,
  PositionTextSelection,
  QuestionSettingCode
} from '../../../../data/model/questionnaire'
import { SurveyParams } from '../../../../data/model/surveyParams'
import {
  IsNonManualUIChange,
  onChangePasteAcceptingInput
} from '../../../../hooks/copyPaste/useCopyPasteComplete'
import useCopyPasteMatrix from '../../../../hooks/copyPaste/useCopyPasteMatrix'
import {
  ValidationResult,
  isNonEmptyResponseError
} from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import { useDebounceEffect } from '../../../../hooks/useDebounce'
import { useDefaultState } from '../../../../hooks/useDefaultState'
import {
  addMatrixQuestionResponseOptionTransactionDatadog,
  deleteMatrixQuestionResponseOptionTransactionDatadog,
  moveMatrixQuestionResponseOptionTransactionDatadog,
  pasteInMatrixQuestionColumnTransactionDatadog
} from '../../../../tracking/perf/transactions'
import {
  isSettingEnabled,
  newEntryId
} from '../../../../utils/questionnaireUtils'
import {
  MaskingButton,
  MaskingDialogContext
} from '../../Masking/MaskingButton'
import {
  CREATE_OR_UPDATE_MATRIX_COLUMN_MASKING_RULE,
  CreateOrUpdateMaskingRuleData
} from '../../Masking/MaskingButton/MaskingButton.mutations'
import {
  PinResponse,
  PinnedItemType,
  usePinResponseMutations
} from '../../PinResponse'
import { selectSettingsByQuestionId } from '../../Questionnaire.slice'
import { useMatrixEntryContext } from '../MatrixQuestion.container'

interface Props {
  index: number
  matrixColumn: DraftEntryResponseOption
  shouldAutoFocus: boolean
  responseOptionsCount: number
  entryNumber: number
  matrixTitleLk: string
  maskingEnabled: boolean
  onEnter?: (position: number, matrixTitleLk: string) => void
  onChangeMatrixResponseOption: (
    inputValue: string,
    responseOptionLk: string,
    matrixTitleLk: string
  ) => void
  onDeleteMatrixResponseOption: (
    responseOptionLk: string,
    matrixTitleLk: string
  ) => void
  validateMatrixColumnText: (
    entryLk: string | undefined,
    responseOptionLk: string
  ) => ValidationResult
  onSetExclusiveOption?: (
    responseOptionLk: string,
    isExclusive: boolean
  ) => void
}

const MatrixResponseOption: React.FC<Props> = (props: Props) => {
  const {
    index,
    matrixColumn,
    shouldAutoFocus,
    responseOptionsCount,
    entryNumber,
    matrixTitleLk,
    maskingEnabled,
    onEnter,
    onChangeMatrixResponseOption,
    onDeleteMatrixResponseOption,
    validateMatrixColumnText,
    onSetExclusiveOption
  }: Props = props
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const entryItem = useMatrixEntryContext()
  const { setPinnedMatrixResponseOption } = usePinResponseMutations()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const [pendingDeletionPosition, setPendingDeletionPosition] = useState<
    number | undefined
  >(undefined)
  const [matrixColumnText, setMatrixColumnText] = useState<string>(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    matrixColumn.responseOption?.value
  )
  const [positionSelectedText, setPositionSelectedText] = useState<
    PositionTextSelection | undefined
  >(undefined)
  const newlyAddedEntryId = useReactiveVar(newEntryId)
  const [hasBeenFocused, setHasBeenFocused] = useState<boolean>(false)

  const [isExclusiveResponse, setIsExclusiveResponse] =
    useDefaultState<boolean>(
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      matrixColumn.exclusive ?? false
    )

  const { pasteToMatrixColumn } = useCopyPasteMatrix(matrixTitleLk)

  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectSettingsByQuestionId(state, matrixTitleLk)
    ) || []

  const { maskingRules } = matrixColumn

  const isAfterPaste = useReactiveVar<boolean>(IsNonManualUIChange)

  const hasTempId = /^responseOptionLk-\d+$/.test(matrixColumn.responseOptionLk)
  const isEditable = matrixColumn.createdDate !== '' && !hasTempId

  const debounceDelayMs = 400

  const handleOnFocusedWithDelay: (delay?: number) => void = (
    delay = debounceDelayMs
  ) => {
    if (hasBeenFocused) return

    setTimeout(() => {
      setHasBeenFocused(true)
    }, delay)
  }

  const triggerResponseOptionChanged: () => void = () => {
    if (
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !matrixColumn.responseOption || // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      matrixColumnText !== matrixColumn.responseOption?.value
    ) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (entryItem?.matrixTitleLk) {
        onChangeMatrixResponseOption(
          matrixColumnText,
          matrixColumn.responseOptionLk,
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          entryItem?.matrixTitleLk
        )
        handleOnFocusedWithDelay(100)
      }
    }
  }

  const onPasteMatrixResponseOption: (
    text: string,
    position: number,
    positionSelectedText?: PositionTextSelection
  ) => void = useCallback(
    async (text, position, positionSelectedText) => {
      pasteInMatrixQuestionColumnTransactionDatadog.start()
      questionBeingEditedNumber(entryNumber)

      await pasteToMatrixColumn({
        text,
        matrixTitleLk,
        position,
        positionSelectedText
      })
    },
    [entryNumber, pasteToMatrixColumn, matrixTitleLk]
  )

  const [createOrUpdateMatrixColumnMaskingRule] = useMutation<
    CreateOrUpdateMaskingRuleData,
    CreateOrUpdateMatrixColumnMaskingRuleMutationVariables
  >(CREATE_OR_UPDATE_MATRIX_COLUMN_MASKING_RULE, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const handleCreateOrUpdateMaskingRule = async (
    maskingRules: DraftMaskingRule[]
  ) => {
    await createOrUpdateMatrixColumnMaskingRule({
      variables: {
        questionnaireId: surveyId,
        matrixTitleLk: entryItem.matrixTitleLk,
        responseOptionLk: matrixColumn.responseOptionLk,
        maskingRules
      }
    })
  }

  useDebounceEffect<string | undefined>(
    () => {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (matrixColumnText !== null && matrixColumnText !== undefined) {
        triggerResponseOptionChanged()
      }
    },
    matrixColumnText,
    { delay: debounceDelayMs }
  )

  useEffect(() => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isAfterPaste && matrixColumn.responseOption) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      setMatrixColumnText(matrixColumn.responseOption?.value)
    }
  }, [matrixColumn.responseOption, isAfterPaste])

  useEffect(() => {
    setPendingDeletionPosition(undefined)
  }, [responseOptionsCount])

  useMount(() => {
    addMatrixQuestionResponseOptionTransactionDatadog.end()
    pasteInMatrixQuestionColumnTransactionDatadog.end()
    moveMatrixQuestionResponseOptionTransactionDatadog.end()
  })

  useUnmount(() => {
    deleteMatrixQuestionResponseOptionTransactionDatadog.end()
  })

  const { hasError, errorMessage } = validateMatrixColumnText(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem?.matrixTitleLk,
    matrixColumn.responseOptionLk
  )

  const placeHolderTextMap = new Map<number, string>([
    [0, 'Very inspiring'],
    [1, 'Quite inspiring']
  ])

  if (matrixColumn.position === pendingDeletionPosition) {
    return null
  }

  const isRandomiseOn = isSettingEnabled(
    settingValues,
    QuestionSettingCode.MatrixRandomiseResponseOptions
  )

  const handleSelectedMatrixResponseOptionText = (
    selectionStart?: number | null,
    selectionEnd?: number | null
  ) => {
    setPositionSelectedText({
      startPositionSelectedText: selectionStart,
      endPositionSelectedText: selectionEnd
    })
  }

  const handleDelete = () => {
    IsNonManualUIChange(true)
    setPendingDeletionPosition(matrixColumn.position)
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (entryItem?.matrixTitleLk) {
      onDeleteMatrixResponseOption(
        matrixColumn.responseOptionLk,
        entryItem.matrixTitleLk
      )
    }
  }

  const handlePinResponseOption = () => {
    setPinnedMatrixResponseOption(
      matrixTitleLk,
      matrixColumn.responseOptionLk,
      true
    )
  }

  const handleOpenMaskingDialog = () => {
    setIsDialogOpen(true)
  }

  const responseActions: KebabMenuOption[] = [
    {
      id: 2,
      textItem: 'Add display logic',
      iconName: IconName.Visibility,
      disabled:
        // OR if this question is the first one and there are no other questions before it
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !maskingEnabled || (maskingRules && maskingRules.length > 0),
      onClickItem: handleOpenMaskingDialog
    },
    {
      id: 3,
      textItem: 'Pin response',
      disabled: matrixColumn.pinned || !isRandomiseOn,
      iconName: IconName.PushPin,
      onClickItem: handlePinResponseOption
    }
  ]

  // onSetExclusiveOption is available only for matrix and for multi-select
  if (onSetExclusiveOption) {
    responseActions.push({
      id: 4,
      textItem: 'Make exclusive',
      iconName: IconName.Filter1Outlined,
      disabled: isExclusiveResponse,
      onClickItem: () => {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (onSetExclusiveOption) {
          setIsExclusiveResponse(true)
          onSetExclusiveOption(matrixColumn.responseOptionLk, true)

          LogAmplitudeEvent(EventType.MadeResponseExclusive, {
            surveyId,
            responseOptionValue: matrixColumn.responseOption.value
          })
        }
      }
    })
  }

  const handelExclusiveIconClick = onSetExclusiveOption
    ? () => {
        setIsExclusiveResponse(false)
        onSetExclusiveOption(matrixColumn.responseOptionLk, false)
      }
    : undefined

  const isNewlyAddedEntryId = newlyAddedEntryId === matrixTitleLk
  const canShowError =
    hasError &&
    (hasBeenFocused ||
      !isNewlyAddedEntryId ||
      isNonEmptyResponseError(errorMessage))

  return (
    <ListItemQuestionnaireResponseOption
      responseOptionId={matrixColumn.responseOptionLk}
      responseActionsMenu={
        responseActions.length > 0 ? (
          <KebabMenu
            kebabMenuOptions={responseActions}
            horizontal
            iconPosition={KebabMenuIconPosition.Left}
            iconColor={IconColor.Background}
            tooltipText="Configure this response option"
          />
        ) : undefined
      }
      ariaLabel="Matrix response option"
      maskingIconButton={
        <MaskingDialogContext.Provider
          value={{ isDialogOpen, setIsDialogOpen }}
        >
          <MaskingButton
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            questionLk={entryItem?.matrixTitleLk}
            responseOptionLk={matrixColumn.responseOptionLk}
            entryType={EntryType.MatrixEntryType}
            createOrUpdateMaskingRule={handleCreateOrUpdateMaskingRule}
          />
        </MaskingDialogContext.Provider>
      }
      index={matrixColumn.position}
      key={matrixColumn.responseOptionLk}
      isError={canShowError}
      helperText={canShowError ? errorMessage : ''}
      onFocus={() => {
        questionBeingEditedNumber(entryNumber)
      }}
      onBlur={() => {
        setHasBeenFocused(true)
      }}
      onEnter={() => {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (onEnter && entryItem?.matrixTitleLk) {
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          onEnter(matrixColumn.position, entryItem?.matrixTitleLk)
        }
      }}
      pinningIconButton={
        matrixColumn.pinned ? (
          <PinResponse
            matrixTitleLk={matrixTitleLk}
            responseOptionLk={matrixColumn.responseOptionLk}
            itemType={PinnedItemType.MatrixResponseOption}
            isPinned={matrixColumn.pinned}
          />
        ) : undefined
      }
      exclusiveButton={
        isExclusiveResponse ? (
          <ExclusiveButton onExclusiveIconClick={handelExclusiveIconClick} />
        ) : undefined
      }
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={shouldAutoFocus}
      editable={isEditable}
      cannotDelete={responseOptionsCount <= 2}
      onDelete={handleDelete}
      disableGutters
      draggableId={`dr${matrixColumn.responseOptionLk}`}
      canBeReordered
      value={matrixColumnText}
      placeholder={placeHolderTextMap.get(index) || 'Type an option'}
      onChange={(value) => {
        onChangePasteAcceptingInput(() => setMatrixColumnText(value))
      }}
      onPaste={(value) => {
        IsNonManualUIChange(true)
        onPasteMatrixResponseOption(
          value,
          matrixColumn.position,
          positionSelectedText
        )
      }}
      onSelect={handleSelectedMatrixResponseOptionText}
    />
  )
}

export default memo(MatrixResponseOption)
