import { gql } from '@apollo/client'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface SetQuestionnaireEntryForkBranchData {
  setQuestionnaireEntryForkBranch: DraftQuestionnaireEntry
}

const SET_QUESTIONNAIRE_ENTRY_FORK_BRANCH = gql`
  mutation setQuestionnaireEntryForkBranch(
    $questionnaireId: ID!
    $number: Int!
    $forkId: ID!
    $branchNumber: Int!
  ) {
    setQuestionnaireEntryForkBranch(
      questionnaireId: $questionnaireId
      number: $number
      forkId: $forkId
      branchNumber: $branchNumber
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`

export default SET_QUESTIONNAIRE_ENTRY_FORK_BRANCH
