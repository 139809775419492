import { Grid, Text, TextSize, TextWeight } from '@focaldata/cin-ui-components'
import { styled } from '@mui/material/styles'
import ProjectTypeCard from '../ProjectTypeItem'
import { getAvailableProjectTypes } from './ProjectTypesPanel.utils'

const ProjectTypeCardsWrapper = styled(Grid)(({ theme }) => {
  return {
    gridTemplateColumns: 'repeat(3, 1fr)',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr'
    }
  }
})

export const ProjectTypesPanel = () => {
  const projectTypes = getAvailableProjectTypes()
  return (
    <div className="project-types-panel">
      <Text size={TextSize.l} weight={TextWeight.Bold}>
        Create a new project
      </Text>
      <ProjectTypeCardsWrapper
        container
        display="grid"
        gap={4}
        marginTop={1}
        marginBottom={6}
      >
        {projectTypes.map((projectType) => (
          <ProjectTypeCard {...projectType} key={projectType.type} />
        ))}
      </ProjectTypeCardsWrapper>
    </div>
  )
}
