import { gql } from '@apollo/client'
import { DraftForkItem } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface AddForkBranchData {
  addForkBranch: DraftForkItem
}

const ADD_FORK_BRANCH = gql`
  mutation addForkBranch(
    $questionnaireId: ID!
    $forkId: ID!
    $percentage: Float!
  ) {
    addForkBranch(
      questionnaireId: $questionnaireId
      forkId: $forkId
      percentage: $percentage
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`

export default ADD_FORK_BRANCH
