import { gql } from '@apollo/client/core'
import { EntrySettingValue } from '../../../model/questionnaire'
import ENTRY_SETTING_VALUE from '../fragments/entrySettingValue'

export interface RemoveQuestionnaireMatrixSettingValueData {
  removeQuestionnaireMatrixSettingValue: EntrySettingValue
}

export const REMOVE_QUESTIONNAIRE_MATRIX_SETTING_VALUE = gql`
  mutation removeQuestionnaireMatrixSettingValue(
    $questionnaireId: ID!
    $matrixTitleLk: ID!
    $questionSettingCode: String!
  ) {
    removeQuestionnaireMatrixSettingValue(
      questionnaireId: $questionnaireId
      matrixTitleLk: $matrixTitleLk
      questionSettingCode: $questionSettingCode
    ) {
      ...EntrySettingValue
    }
  }
  ${ENTRY_SETTING_VALUE}
`
