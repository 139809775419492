import { useMutation, useQuery } from '@apollo/client'
import { ScrollTo } from '@focaldata/cin-ui-components'
import { useContext, useEffect } from 'react'
import {
  RemoveCustomCriterionMutationVariables,
  SetCustomCriterionMutationVariables
} from '../../../data/gql-gen/fieldwork/graphql'
import {
  REMOVE_CUSTOM_CRITERION,
  SET_CUSTOM_CRITERION,
  SetCustomCriterionData
} from '../../../data/gql/fieldwork/mutations/customCriterion'
import { fieldworkAudienceRefetchQuery } from '../../../data/gql/fieldwork/queries/fieldworkAudience'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import {
  CustomAudienceCriteriaData,
  GET_CUSTOM_CRITERIA
} from '../../../data/gql/questionnaire/queries/customCriteria'
import { LoggerErrorType } from '../../../data/logger'
import {
  DraftCustomCriterion,
  EnabledState
} from '../../../data/model/audience'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { useProjectType } from '../../Project/Project.hooks'
import AudienceContext, { setAudienceMembers } from '../Audience.context'
import { useAddCustomAudience } from '../Audience.hooks'
import { MAX_AUDIENCE_COUNT } from '../AudienceCount/AudienceCount.control'
import { AUDIENCE_SCROLL_CONTAINER_ID, SCROLL_ID_PREFIX } from '../constants'
import { AddCustomAudienceButton } from './AddCustomAudienceButton'
import CustomAudienceListControl from './CustomAudienceList.control'

const CustomAudienceList = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { dispatch } = useContext(AudienceContext)
  const { isFdChat } = useProjectType()
  const { addCustomAudience } = useAddCustomAudience()

  const {
    data,
    error,
    refetch: refetchCustomCriteria
  } = useQuery<CustomAudienceCriteriaData>(GET_CUSTOM_CRITERIA, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId
    },
    fetchPolicy: 'cache-and-network'
  })
  const { draftAudience, refetchAudience } = useGetDraftAudience()

  useEffect(() => {
    if (draftAudience) {
      dispatch(setAudienceMembers(draftAudience.members))
    }
  }, [draftAudience, dispatch])

  const [setCustomCriterion] = useMutation<
    SetCustomCriterionData,
    SetCustomCriterionMutationVariables
  >(SET_CUSTOM_CRITERION, {
    context: { clientName: 'fieldwork' },
    onCompleted: refetchCustomCriteria,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setCustomCriterion',
        error
      )
    },
    refetchQueries: [
      draftAudienceRefetchQuery(projectId, surveyId),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  })
  const [removeCustomCriterion] = useMutation<
    SetCustomCriterionData,
    RemoveCustomCriterionMutationVariables
  >(REMOVE_CUSTOM_CRITERION, {
    context: { clientName: 'fieldwork' },
    onCompleted: async (data: SetCustomCriterionData) => {
      refetchCustomCriteria()
      await refetchAudience()
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (data.setCustomCriterion?.questionLk !== undefined) {
        setTimeout(() => {
          ScrollTo(
            `${SCROLL_ID_PREFIX}${data.setCustomCriterion.questionLk}`,
            AUDIENCE_SCROLL_CONTAINER_ID
          )
        }, 0)
      }
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'removeCustomCriterion',
        error
      )
    },
    refetchQueries: [
      draftAudienceRefetchQuery(projectId, surveyId),
      fieldworkAudienceRefetchQuery(surveyId)
    ]
  })

  const handleToggleCustomCriterion: (
    criterion: DraftCustomCriterion
  ) => void = (criterion) => {
    if (criterion.state === EnabledState.Enabled) {
      removeCustomCriterion({
        variables: { surveyId, questionLk: criterion.questionLk }
      })
    } else {
      setCustomCriterion({
        variables: { surveyId, questionLk: criterion.questionLk }
      })
    }
  }

  if (error) return <div>Error loading custom criteria</div>

  return (
    <>
      <AddCustomAudienceButton
        onAddCustomAudience={addCustomAudience}
        label={isFdChat ? 'Add a new question' : undefined}
        disabled={
          isFdChat &&
          draftAudience &&
          draftAudience.members.length >= MAX_AUDIENCE_COUNT
        }
      />
      {data && (
        <CustomAudienceListControl
          customCriteria={data.customCriteria}
          validationErrors={draftAudience?.validationErrors}
          onCustomAudienceToggleCriterion={handleToggleCustomCriterion}
        />
      )}
    </>
  )
}

export default CustomAudienceList
