import { useMutation } from '@apollo/client'
import { PinOrUnpinResponseOptionMutationVariables } from '../../../data/gql-gen/fieldwork/graphql'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  PIN_CUSTOM_AUDIENCE_RESPONSE_OPTION,
  PinCustomAudienceResponseOptionData
} from './PinCustomAudienceResponse.mutations'

const usePinCustomAudienceResponseMutations = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [pinOrUnpinCustomAudienceResponseOption] = useMutation<
    PinCustomAudienceResponseOptionData,
    PinOrUnpinResponseOptionMutationVariables
  >(PIN_CUSTOM_AUDIENCE_RESPONSE_OPTION, {
    context: { clientName: 'fieldwork' },
    fetchPolicy: 'no-cache',
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
  })

  const setPinnedCustomAudienceResponseOption = (
    questionLk: string,
    responseOptionLk: string,
    isPinned: boolean
  ) => {
    pinOrUnpinCustomAudienceResponseOption({
      variables: {
        surveyId,
        questionLk,
        responseOptionLk,
        pinned: isPinned
      }
    })
  }

  return {
    setPinnedCustomAudienceResponseOption
  }
}

export default usePinCustomAudienceResponseMutations
