import { gql } from '@apollo/client'
import DRAFT_LOOPING_CONFIG from '../../../data/gql/questionnaire/fragments/draftLoopingConfig'

export const SET_LOOPING = gql`
  mutation setLooping(
    $questionnaireId: ID!
    $sectionId: ID!
    $loopingConfig: LoopingConfigInputInput
    $enabled: Boolean
  ) {
    setLooping(
      questionnaireId: $questionnaireId
      sectionId: $sectionId
      loopingConfig: $loopingConfig
      enabled: $enabled
    ) {
      number
      position
      entryType
      questionKind
      entryItem {
        __typename
        ... on DraftSectionItem {
          title
          sectionId
          isLoopingEnabled @client
          settingValues {
            code
            value
          }
          loopingConfig {
            ...DraftLoopingConfig
          }
          __typename
        }
      }
      __typename
    }
  }
  ${DRAFT_LOOPING_CONFIG}
`
