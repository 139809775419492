import { gql } from '@apollo/client/core'
import { FdChatSpecification } from './FdChat.model'

export interface AddFdChatSpecificationData {
  addFDChatSpecification: FdChatSpecification
}

export const ADD_FD_CHAT_SPECIFICATION = gql`
  mutation addFDChatSpecification(
    $surveyId: ID!
    $maxLengthOfInterview: Int!
    $goal: String!
    $questions: [String!]!
    $themes: String
    $context: String
  ) {
    addFDChatSpecification(
      surveyId: $surveyId
      maxLengthOfInterview: $maxLengthOfInterview
      goal: $goal
      questions: $questions
      themes: $themes
      context: $context
      maxNumberQuestions: 9999
    ) {
      ... on AdHocTestingSpecification {
        fdChatSpecificationId
        maxNumberQuestions
        goal
        questions
        themes
        context
        maxLengthOfInterview
      }
    }
  }
`
