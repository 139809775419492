import { ApolloError, useQuery } from '@apollo/client'
import { RoutesQueriesDraftQuestionnaireV2Args } from '../../data/gql-gen/questionnaire/graphql'
import {
  QUESTIONNAIRE,
  QuestionnaireData
} from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export interface CountQuestionnaireQuestions {
  totalNumberQuestionnaireQuestions?: number
  loadingCountQuestionnaireQuestions: boolean
  errorCountQuestionnaireQuestions: ApolloError | undefined
}

const useQuestionnaireQuestionsCounter = (): CountQuestionnaireQuestions => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const {
    data: questionnaireData,
    loading: loadingCountQuestionnaireQuestions,
    error: errorCountQuestionnaireQuestions
  } = useQuery<QuestionnaireData, RoutesQueriesDraftQuestionnaireV2Args>(
    QUESTIONNAIRE,
    {
      context: { clientName: 'questionnaire' },
      variables: {
        projectId,
        surveyId
      },
      skip: !surveyId,
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloQuery,
          'useQuestionnaireQuestionsCounter',
          error
        )
      }
    }
  )

  const totalNumberQuestionnaireQuestions =
    questionnaireData?.draftQuestionnaire.entries.length

  return {
    totalNumberQuestionnaireQuestions,
    loadingCountQuestionnaireQuestions,
    errorCountQuestionnaireQuestions
  }
}

export default useQuestionnaireQuestionsCounter
