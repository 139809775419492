import { gql } from '@apollo/client'
import { AUDIENCE_QUESTION } from './draftAudience'

const STANDARD_AUDIENCE_CRITERION = gql`
  fragment StandardAudienceCriterion on StandardCriterion {
    criterion {
      code
      name
      question {
        ...AudienceQuestion
      }
      createdDate
    }
    state
    createdDate
    sinceDate
  }
  ${AUDIENCE_QUESTION}
`

export const STANDARD_AUDIENCE_CRITERION_WITH_OPTIONS = gql`
  fragment StandardAudienceCriterionWithOptions on StandardCriterionWithOptions {
    criterion {
      code
      name
      question {
        ...AudienceQuestion
      }
      createdDate
    }
    state
    options {
      code
      responseOption {
        responseOptionId
        rotype
        value
        createdDate
      }
      createdDate
      sinceDate
    }
  }
  ${AUDIENCE_QUESTION}
`
export default STANDARD_AUDIENCE_CRITERION
