import { Switch } from '@focaldata/cin-ui-components'
import { ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import {
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import { WithEntry, isSettingEnabled } from '../../../utils/questionnaireUtils'
import {
  selectSettingsByQuestionId,
  setQuestionSetting
} from '../Questionnaire.slice'

export const MobileViewOnlySwitch = ({ entry }: WithEntry) => {
  const { entryId } = entry
  const dispatch = useAppDispatch()
  const setQuestionnaireValue = useSetQuestionnaireSetting(entry)
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) => selectSettingsByQuestionId(state, entryId)) || []

  const isChecked = isSettingEnabled(
    settingValues,
    QuestionSettingCode.MatrixMobileViewOnly
  )

  const handleChangeMobileViewOnly = (e: ChangeEvent<HTMLInputElement>) => {
    const code = QuestionSettingCode.MatrixMobileViewOnly
    const value = e.target.checked
      ? SettingValue.Enabled
      : SettingValue.Disabled
    dispatch(
      setQuestionSetting({
        questionLk: entryId,
        code,
        value
      })
    )

    setQuestionnaireValue(code, value, 'no-cache')
  }

  return (
    <Switch
      label="Mobile view only"
      checked={isChecked}
      onChange={handleChangeMobileViewOnly}
    />
  )
}
