import { gql } from '@apollo/client/core'
import { MemberSettingValue } from '../../../model/audience'
import MEMBER_SETTING_VALUE from '../fragments/memberSettingValue'

export interface SetStandardMemberSettingValueData {
  setStandardMemberSettingValue: MemberSettingValue
}

export const SET_STANDARD_MEMBER_SETTING_VALUE = gql`
  mutation setStandardMemberSettingValue(
    $surveyId: ID!
    $audienceCriterionCode: String!
    $setting: SettingInputInput!
  ) {
    setStandardMemberSettingValue(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
      setting: $setting
    ) {
      ...MemberSettingValue
    }
  }
  ${MEMBER_SETTING_VALUE}
`

export interface RemoveStandardMemberSettingValueData {
  removeStandardMemberSettingValue: MemberSettingValue
}

export const REMOVE_STANDARD_MEMBER_SETTING_VALUE = gql`
  mutation removeStandardMemberSettingValue(
    $surveyId: ID!
    $audienceCriterionCode: String!
    $memberSettingCode: String!
  ) {
    removeStandardMemberSettingValue(
      surveyId: $surveyId
      audienceCriterionCode: $audienceCriterionCode
      memberSettingCode: $memberSettingCode
    ) {
      ...MemberSettingValue
    }
  }
  ${MEMBER_SETTING_VALUE}
`
