import {
  Checkbox,
  Dialog,
  DialogMaxWidth,
  Grid,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import useStyles from './DialogAddDisplayLogic.styles'
import useLoopSourceOptions from './useLoopSourceOptions'
import useLoopSpecificDisplayLogicData from './useLoopSpecificDisplayLogicData'
import useLoopingConfigData from './useLoopingConfigData'

interface Props {
  sectionId: string | undefined
  entryNumber: number
  contextPosition: number
  open: boolean
  isSaving: boolean
  onSave: (entryNumber: number, sourceIds: string[]) => void
  onClose: () => void
}
const DialogAddLoopSpecificDisplayLogic: React.FC<Props> = (props: Props) => {
  const {
    sectionId,
    entryNumber,
    contextPosition,
    open,
    isSaving,
    onSave,
    onClose
  } = props

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const loopSpecificQuestionLogic = useLoopSpecificDisplayLogicData(entryNumber)

  const [selectedSourceIds, setSelectedSourceIds] = useState(
    loopSpecificQuestionLogic?.sourceIds.map((x) => x as string) ?? []
  )

  const { loopingConfig } = useLoopingConfigData(sectionId) ?? {}

  const loopSourceOptions = useLoopSourceOptions(
    loopingConfig?.sourceEntryNumber
  )

  const handleSave = () => {
    onSave(entryNumber, selectedSourceIds)
    onClose()
  }

  const handleToggleChange = (checked: boolean, value: string) => {
    const newSelectedSourceIds = checked
      ? [...selectedSourceIds, value]
      : selectedSourceIds.filter((id) => id !== value)
    setSelectedSourceIds(newSelectedSourceIds)
  }

  return (
    <Dialog
      title="Loop-based display logic"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={DialogMaxWidth.Md}
      primaryButtonDisabled={selectedSourceIds.length === 0}
      primaryButtonText="Save"
      primaryButtonLoading={isSaving}
      primaryButtonClick={handleSave}
    >
      <Grid container spacing={1}>
        <Grid item>
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeM,
              textClasses.highlightBackground,
              classes.subtitleContainer
            )}
          >
            Only show{' '}
            <strong>{`Q${contextPosition ? contextPosition + 1 : 1}
          `}</strong>{' '}
            within the following loops:
          </p>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {loopSourceOptions?.map(({ id, title }) => {
              return (
                <Checkbox
                  key={id}
                  label={title}
                  ariaLabel={`Display logic loop option - ${id}`}
                  checked={selectedSourceIds.includes(id as string)}
                  onChange={(event) =>
                    handleToggleChange(event.target.checked, id as string)
                  }
                />
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DialogAddLoopSpecificDisplayLogic
