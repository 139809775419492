import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { DraftEntryResponseOption } from '../../../../data/model/questionnaire'
import { useLoopingSourceResponseOptions } from '../Looping.hooks'
import { LoopingResponseOption } from '../Looping.model'
import {
  priorityToggled,
  selectPrioritizedResponseOptionIds
} from '../Looping.slice'
import LoopingResponseOptionsComponent from './LoopingResponseOptions.component'

const LoopingResponseOptions = () => {
  const dispatch = useAppDispatch()
  const prioritizedResponseOptionIds = useAppSelector(
    selectPrioritizedResponseOptionIds
  )
  const loopingSourceResponseOptions = useLoopingSourceResponseOptions()

  const loopingResponseOptions: LoopingResponseOption[] =
    loopingSourceResponseOptions.map((ro) => {
      return {
        ...ro,
        priorityEnabled: prioritizedResponseOptionIds.includes(
          ro.responseOptionLk
        )
      }
    })

  const handleTogglePriority = useCallback(
    (id: DraftEntryResponseOption['responseOptionLk']) =>
      dispatch(priorityToggled(id)),
    [dispatch]
  )

  return (
    <LoopingResponseOptionsComponent
      loopingResponseOptions={loopingResponseOptions}
      onTogglePriority={handleTogglePriority}
    />
  )
}

export default LoopingResponseOptions
