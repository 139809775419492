import { useApolloClient } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { writeAudienceChangedSinceLastSlackIRSNotification } from '../../apollo/localState/writeAudienceChangedSinceLastSlackIRSNotification'
import BottomNav from '../../containers/Page/Survey/BottomNav'
import useIRSlackNotification from '../../hooks/useIRSlackNotification'
import { useProjectId } from '../../hooks/useProjectId'
import { useSurveyId } from '../../hooks/useSurveyId'
import { AudiencePageLayout } from '../../layouts/AudiencePageLayout'
import AudienceContext, { setNewAudienceQuestionLk } from './Audience.context'
import { AudiencePresetPickerContainer } from './AudiencePresetPicker'
import { AudienceSummaryContainer } from './AudienceSummary'
import { CountryPickerContainer } from './CountryPicker'
import { CustomizeAudienceCriteria } from './CustomizeAudienceCriteria'

const Audience = ({
  setShowErrorPage
}: {
  setShowErrorPage: (show: true) => void
}) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const navigate = useNavigate()
  const { postIRSlackNotification } = useIRSlackNotification()
  const [isCustomisingAudienceCriteria, setCustomisingAudienceCriteria] =
    useState(false)
  const { cache } = useApolloClient()

  const { dispatch } = useContext(AudienceContext)

  const toggleDialogOpen: () => void = () => {
    if (!isCustomisingAudienceCriteria) {
      LogAmplitudeEvent(EventType.OpenedAudienceBuilder, { surveyId })
    } else {
      postIRSlackNotification()
    }
    setCustomisingAudienceCriteria(!isCustomisingAudienceCriteria)
    dispatch(setNewAudienceQuestionLk(undefined))
  }

  const routeToSurvey: () => void = () => {
    navigate(`/project/${projectId}/survey/${surveyId}/questionnaire`)
  }

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedAudiencePage, { surveyId })
  }, [surveyId])

  useEffect(() => {
    cache.evict({ fieldName: 'draftAudience' })
    cache.gc()

    return () => {
      writeAudienceChangedSinceLastSlackIRSNotification(cache, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId])

  return (
    <>
      <AudiencePageLayout
        countryPicker={
          <CountryPickerContainer setShowErrorPage={setShowErrorPage} />
        }
        showAudienceDetails
        customiseAudience={
          <CustomizeAudienceCriteria
            isOpen={isCustomisingAudienceCriteria}
            toggleIsOpen={toggleDialogOpen}
            showButton
          />
        }
        audiencePresetPicker={
          <AudiencePresetPickerContainer
            onChoseCustomPreset={toggleDialogOpen}
          />
        }
        audienceSummary={<AudienceSummaryContainer showTitle />}
      />
      <BottomNav
        width={'calc(100% - 56px)'}
        rightButtonText="To survey"
        onClickRightButton={routeToSurvey}
      />
    </>
  )
}

export default Audience
