import { useMutation } from '@apollo/client'
import React, { useCallback } from 'react'
import { UpdateFieldworkMutationVariables } from '../../../data/gql-gen/fieldwork/graphql'
import { UPDATE_FIELDWORK } from '../../../data/gql/fieldwork/mutations/updateFieldwork'
import { draftQuestionnaireRefetchQuery } from '../../../data/gql/questionnaire/queries'
import { fieldworkRefetchQuery } from '../../../data/gql/questionnaire/queries/fieldwork'
import { getFeasibilityEstimateRefetchQuery } from '../../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { LoggerErrorType } from '../../../data/logger'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  captureApolloError,
  checkIsResultsPage
} from '../../../utils/HelperFunctions'
import CompletesInputControl from './CompletesInput.control'
import CompletesInputLoading from './CompletesInputLoading'

interface Props {
  inputLabel?: string
  fullWidth?: boolean
}

const CompletesInput: React.FC<Props> = (props: Props) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { inputLabel } = props

  const { fieldwork, loading: loadingFieldwork } = useFieldwork({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const defaultCompletes = fieldwork?.samplesNeeded.unadjusted
  const loi = fieldwork?.lengthOfInterview.adjusted || 2

  const [updateFieldwork] = useMutation<void, UpdateFieldworkMutationVariables>(
    UPDATE_FIELDWORK,
    {
      context: { clientName: 'fieldwork' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'updateFieldwork',
          error
        )
      },
      refetchQueries: [
        fieldworkRefetchQuery(surveyId),
        draftQuestionnaireRefetchQuery(projectId, surveyId),
        getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi)
      ]
    }
  )

  const handleSaveCompletes = useCallback(
    (completes: number | undefined) => {
      if (
        completes !== undefined &&
        completes !== defaultCompletes &&
        completes > 0
      ) {
        updateFieldwork({
          variables: {
            surveyId,
            panelSupplierCode: 'cint',
            lengthOfInterview: null,
            samplesNeeded: completes,
            startDate: null,
            endDate: null,
            countryCode: null
          }
        })
      }
    },
    [defaultCompletes, surveyId, updateFieldwork]
  )

  if (loadingFieldwork) {
    return <CompletesInputLoading />
  }

  const isInputDisabled = checkIsResultsPage()

  return (
    <CompletesInputControl
      disabled={isInputDisabled}
      defaultCompletes={defaultCompletes}
      inputLabel={inputLabel}
      saveCompletes={handleSaveCompletes}
    />
  )
}

export default CompletesInput
