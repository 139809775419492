import { gql } from '@apollo/client'

const DRAFT_QUALIFICATION = gql`
  fragment DraftQualification on DraftQualification {
    surveyId
    questionLk
    responseOptionLk
    sinceDate
  }
`

export default DRAFT_QUALIFICATION
