import { useApolloClient } from '@apollo/client'
import React, { useCallback } from 'react'
import {
  RootState,
  useAppDispatch,
  useAppSelector
} from '../../../../App.store'
import {
  DraftQuestionItem,
  QuestionSettingCode,
  SettingValue
} from '../../../../data/model/questionnaire'
import useDraftQuestionnaireIdCache from '../../../../hooks/localState/useDraftQuestionnaireIdCache'
import useHandleMultiSelectLimits from '../../../../hooks/questionnaire/useHandleIntervalSettingsChange'
import {
  WithEntry,
  checkIfRankedChoice,
  getQuestionSettings
} from '../../../../utils/questionnaireUtils'
import {
  responseOptionAdded,
  selectResponseOptionsByQuestion,
  selectSettingsByQuestionId
} from '../../Questionnaire.slice'
import { addResponseScale } from '../../Questionnaire.thunks'
import { useResponseOptionActions } from '../ResponseOptions/ResponseOptions.hooks'
import MultiSelectChoiceLimit from './MultiSelectChoiceLimit'
import QuestionCardFooter from './QuestionCardFooter'
import RankChoiceRequisite from './RankChoiceRequisite'

const QuestionCardFooterContainer: React.FC<WithEntry> = (props: WithEntry) => {
  const { entry } = props
  const entryItem: DraftQuestionItem = entry.entryItem as DraftQuestionItem
  const { questionLk } = entryItem

  const dispatch = useAppDispatch()
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const selectById = useCallback(
    (state: RootState) => selectSettingsByQuestionId(state, questionLk),
    [questionLk]
  )
  const settings = useAppSelector(selectById)

  const questionnaireId = useDraftQuestionnaireIdCache()

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const responseOptionsCount = (responseOptionsByQuestion[questionLk] ?? [])
    .length

  const { addResponseOption } = useResponseOptionActions(entry)

  const { handleIntervalSettingsChange, handleQuestionLimitChange } =
    useHandleMultiSelectLimits(entry)
  const handleClickAddResponseOption: () => void = useCallback(() => {
    const newEntryPosition = responseOptionsCount
    dispatch(responseOptionAdded({ questionLk, position: newEntryPosition }))
    addResponseOption(newEntryPosition)
  }, [addResponseOption, dispatch, questionLk, responseOptionsCount])

  const handleChangeRankChoiceRequisite: (settingValue: number) => void =
    useCallback(
      (settingValue) =>
        handleQuestionLimitChange(
          QuestionSettingCode.RankChoiceRequisite,
          settingValue
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [questionLk, questionnaireId]
    )

  const apolloClient = useApolloClient()
  const handleAddScale = useCallback(async () => {
    await dispatch(
      addResponseScale({
        questionLk,
        questionnaireId,
        entryType: entry.entryType,
        scale: 'Agree1To5',
        apolloClient
      })
    )
  }, [dispatch, entry.entryType, questionLk, questionnaireId, apolloClient])

  const handleChangeChoiceLimit: (settingValue: number) => void = useCallback(
    (settingValue) =>
      handleQuestionLimitChange(QuestionSettingCode.ChoiceLimit, settingValue),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionLk, questionnaireId]
  )

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const questionSettings = getQuestionSettings(settings || [])

  const isMultipleChoice =
    questionSettings.get(QuestionSettingCode.BasicChoice) ===
    SettingValue.MultipleChoice

  const isSingleChoice =
    questionSettings.get(QuestionSettingCode.BasicChoice) ===
    SettingValue.SingleChoice

  const isRankedChoice = checkIfRankedChoice(entryItem)

  const choiceLimitValue = questionSettings.get(QuestionSettingCode.ChoiceLimit)
  const choiceLimit = choiceLimitValue && parseInt(choiceLimitValue, 10)

  const choiceIntervalMinValue = questionSettings.get(
    QuestionSettingCode.ChoiceIntervalMin
  )
  const choiceIntervalMin =
    choiceIntervalMinValue && parseInt(choiceIntervalMinValue, 10)

  const choiceIntervalMaxValue = questionSettings.get(
    QuestionSettingCode.ChoiceIntervalMax
  )
  const choiceIntervalMax =
    choiceIntervalMaxValue && parseInt(choiceIntervalMaxValue, 10)

  const rankChoiceRequisite = parseInt(
    questionSettings.get(QuestionSettingCode.RankChoiceRequisite) || '0',
    10
  )

  return (
    <QuestionCardFooter
      entry={entry}
      onClickAddResponseOption={handleClickAddResponseOption}
      showAddScale={isSingleChoice}
      rankChoiceRequisite={
        isRankedChoice ? (
          <RankChoiceRequisite
            rankChoiceRequisite={rankChoiceRequisite}
            responseOptionsCount={responseOptionsCount}
            onChangeRankChoiceRequisite={handleChangeRankChoiceRequisite}
          />
        ) : undefined
      }
      multiSelectChoiceLimit={
        isMultipleChoice ? (
          <MultiSelectChoiceLimit
            responseOptionsCount={responseOptionsCount}
            choiceLimit={choiceLimit}
            choiceIntervalMin={choiceIntervalMin}
            choiceIntervalMax={choiceIntervalMax}
            onChangeChoiceLimit={handleChangeChoiceLimit}
            onChangeIntervalRange={handleIntervalSettingsChange}
          />
        ) : undefined
      }
      onAddScale={handleAddScale}
    />
  )
}

export default QuestionCardFooterContainer
