import { gql } from '@apollo/client'
import { DraftQuestionnaireEntry } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface UpdateForkData {
  updateFork: DraftQuestionnaireEntry
}

const UPDATE_FORK = gql`
  mutation updateFork($questionnaireId: ID!, $forkId: ID!, $name: String!) {
    updateFork(
      questionnaireId: $questionnaireId
      forkId: $forkId
      name: $name
    ) {
      ...DraftQuestionnaireEntry
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`

export default UPDATE_FORK
