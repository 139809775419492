import {
  Grid,
  Skeleton,
  Text,
  TextDisplay,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { useNavigate } from 'react-router-dom'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { useGetFdChatSpecification } from '../FdChat.hooks'
import { ReviewSection } from './FdChatReview'

const FdChatReviewObjectives = ({
  showEditLink
}: {
  showEditLink?: boolean
}) => {
  const navigate = useNavigate()
  const surveyId = useSurveyId()
  const { fdChatSpecification, loadingFdChatSpecification } =
    useGetFdChatSpecification()

  if (loadingFdChatSpecification)
    return <Skeleton variant="rectangular" height={350} />

  return (
    <ReviewSection
      title="Research objectives"
      sectionLinkText={showEditLink ? 'Edit objectives' : undefined}
      onSectionLinkClick={
        showEditLink
          ? () => navigate(`/fdchat/${surveyId}/setup/objectives`)
          : undefined
      }
    >
      <Grid container direction={'column'} gap={'20px'}>
        <div>
          <Text size={TextSize.m} weight={TextWeight.Bold}>
            Research goal
          </Text>
          <Text>{fdChatSpecification?.goal}</Text>
        </div>
        <div>
          <Text size={TextSize.m} weight={TextWeight.Bold}>
            Research questions
          </Text>
          <Grid
            container
            direction={'column'}
            component={'ol'}
            style={{
              listStylePosition: 'inside',
              padding: 0,
              margin: 0
            }}
            rowGap={'5px'}
          >
            {fdChatSpecification?.questions.map((question, i) => (
              <li key={i}>
                <Text display={TextDisplay.Inline}>{question}</Text>
              </li>
            ))}
          </Grid>
        </div>
        <div>
          <Text size={TextSize.m} weight={TextWeight.Bold}>
            Further context
          </Text>
          <Text
            highlight={
              fdChatSpecification?.context
                ? TextHighlight.Standard
                : TextHighlight.Background
            }
          >
            {fdChatSpecification?.context
              ? fdChatSpecification.context
              : 'No information provided'}
          </Text>
        </div>
      </Grid>
    </ReviewSection>
  )
}

export default FdChatReviewObjectives
