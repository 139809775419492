/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  InfoPanel,
  InfoPanelItem,
  InfoPanelVariant
} from '../../../components/InfoPanel'
import { TextHighlight } from '../../../constants/text'
import { EntryType } from '../../../data/model/questionnaire'
import { SurveyParams } from '../../../data/model/surveyParams'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { scrollToNewInBetweenQuestionCard } from '../../Questionnaire/AddQuestionMenu/AddQuestionMenu.utils'
import useStyles from './SurveyStats.styles'

interface Props {
  text: string
}

interface ErrorText {
  paragraph1: string
  paragraph2: string
}

const invalidQuestionnaireText: ErrorText = {
  paragraph1:
    'Sorry, your survey cannot be launched due to one or more errors in your question cards.',
  paragraph2: 'If you need additional support'
}

const QuestionnaireErrorPanel: React.FC<Props> = (props: Props) => {
  const { classes, cx: classNames } = useStyles()
  const { text } = props
  const { surveyId } = useParams<keyof SurveyParams>()

  const { entryHasValidationErrors } = useQuestionnaireValidation()
  const { flattenedEntries } = useGetDraftQuestionnaire()
  const entriesWithError = flattenedEntries
    .filter((entry) => entryHasValidationErrors(entry.number))
    .map((entry) => {
      return {
        entryNumber: entry.number,
        entryText: `${
          entry.entryType === EntryType.ForkEntryType ? 'Fork ' : 'Q'
        }${entry.contextPosition + 1}`
      }
    })

  const errorText = invalidQuestionnaireText

  const suggestions =
    entriesWithError.length > 0 ? (
      <>
        Please check the following question cards for errors: <br />
        <>
          {entriesWithError.map(({ entryNumber, entryText }, i) => (
            <span
              key={`suggestion-error-span-${entryNumber}`}
              style={{ cursor: 'pointer', color: '#ff0505' }}
              role="button"
              tabIndex={-1}
              onClick={() => {
                LogAmplitudeEvent(
                  EventType.ClickedQuestionLinkFromInvalidQuestionnaireBadge,
                  { surveyId }
                )
                scrollToNewInBetweenQuestionCard(entryNumber)
              }}
            >
              {entryText}
              {i === entriesWithError.length - 1 ? '' : ', '}
            </span>
          ))}
        </>
      </>
    ) : (
      'A few suggestions:'
    )

  const [isPanelOpen, setIsPanelOpen] = useState(false)

  const handleOnClick = () => {
    if (!isPanelOpen)
      LogAmplitudeEvent(EventType.OpenedInvalidQuestionnaireBadge, { surveyId })

    setIsPanelOpen(!isPanelOpen)
  }

  return (
    <div
      className={classNames(
        'fd-grid fd-container fd-row fd-align-items-center',
        classes.rightChild
      )}
    >
      <div>
        <InfoPanel
          title={text}
          variant={InfoPanelVariant.Error}
          onClick={handleOnClick}
        >
          <InfoPanelItem>
            <div
              className={classNames(
                'fd-grid fd-container',
                classes.feasibilityInfoPanel
              )}
            >
              <p
                className={classes.paddingBottom}
                style={{ color: TextHighlight.Background }}
              >
                {errorText.paragraph1}
              </p>
              <p
                className={classes.paddingBottom}
                style={{ color: TextHighlight.Background }}
              >
                {suggestions}
              </p>
              <p style={{ color: TextHighlight.Background }}>
                {errorText.paragraph2}, please email{' '}
                <span className={classes.inlineSemiBold}>
                  support@focaldata.com
                </span>{' '}
                and we&apos;ll get back to you shortly.
              </p>
            </div>
          </InfoPanelItem>
        </InfoPanel>
      </div>
    </div>
  )
}

export default QuestionnaireErrorPanel
