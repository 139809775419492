import { ApolloCache } from '@apollo/client'
import { produce } from 'immer'
import {
  DetachCustomCriterionOptionData,
  DetachedCustomCriterionOption
} from '../../../data/gql/fieldwork/mutations/detachCustomCriterionOption'
import { DraftAudience } from '../../../data/gql/questionnaire/queries/audiences'
import {
  DraftCustomAudience,
  FieldworkAudienceType
} from '../../../data/model/audience'

export const detachCustomResponseOption: (
  cache: ApolloCache<DetachCustomCriterionOptionData>,
  detachedCustomCriterionOption: DetachedCustomCriterionOption
) => void = (cache, detachedCustomCriterionOption) => {
  cache.modify({
    fields: {
      draftAudience(existing) {
        const draftAudienceCache = existing as DraftAudience
        const criterionIndex = draftAudienceCache.members.findIndex(
          (audience) =>
            audience.memberType === FieldworkAudienceType.Custom &&
            (audience.memberItem as DraftCustomAudience).questionLk ===
              detachedCustomCriterionOption.questionLk
        )

        if (criterionIndex === -1) {
          return draftAudienceCache
        }

        const criterion = draftAudienceCache.members[criterionIndex]
        const member = criterion.memberItem as DraftCustomAudience
        const optionIndex = member.criterionOptions.findIndex(
          (option) =>
            option.responseOptionLk ===
            detachedCustomCriterionOption.responseOptionLk
        )

        if (optionIndex === -1) {
          return draftAudienceCache
        }

        return produce(draftAudienceCache, (draft) => {
          member.criterionOptions.splice(optionIndex, 1)
          draft.members.splice(criterionIndex, 1, criterion)
        })
      }
    }
  })
}
