import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  useMutation,
  useQuery
} from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { SnackbarVariant, useSnackbar } from '@focaldata/cin-ui-components'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { CreateSurveyOrderMutationVariables } from '../../../data/gql-gen/dashboard/graphql'
import { UpdateFieldworkMutationVariables } from '../../../data/gql-gen/fieldwork/graphql'
import {
  AddFdChatSpecificationMutationVariables,
  QuestionnaireQueryVariables
} from '../../../data/gql-gen/questionnaire/graphql'
import {
  CREATE_SURVEY_ORDER,
  CreateSurveyOrderData
} from '../../../data/gql/order/mutations/createSurveyOrder'
import { LoggerErrorType } from '../../../data/logger'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useUpdateFieldwork } from '../../../hooks/audience/useUpdateFieldwork'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { useFieldworkData } from '../../../modules/Audience/Audience.hooks'
import { useProjectData } from '../../../modules/Project/Project.hooks'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { downloadFile } from '../../../utils/projectUtils'
import { convertAudienceType } from './AudiencePage/FdChatAudience'
import { ContextType } from './FdChat.container'
import {
  FdChatSpecification,
  ResultsType,
  TranscriptsFileFormatType
} from './FdChat.model'
import {
  ADD_FD_CHAT_SPECIFICATION,
  AddFdChatSpecificationData
} from './FdChat.mutations'
import { FD_CHAT_SPECIFICATION, FdChatSpecificationData } from './FdChat.query'
import { generateTranscriptsFilename } from './FdChat.utils'

export const useGetFdChatSpecification = (): {
  fdChatSpecification?: FdChatSpecification | null
  refetchFdChatSpecification: (
    variables?: Partial<QuestionnaireQueryVariables> | undefined
  ) => Promise<ApolloQueryResult<FdChatSpecificationData>>
  loadingFdChatSpecification: boolean
  error?: ApolloError
} => {
  const surveyId = useSurveyId()
  const { data, refetch, loading, error } = useQuery<
    FdChatSpecificationData,
    QuestionnaireQueryVariables
  >(FD_CHAT_SPECIFICATION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'cache-first',
    variables: {
      surveyId
    }
  })

  return {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    fdChatSpecification: data?.questionnaire?.fdChatSpecification,
    refetchFdChatSpecification: refetch,
    loadingFdChatSpecification: loading,
    error
  }
}

export const useAddFdChatSpecification = () => {
  const [addFdChatSpecification] = useMutation<
    AddFdChatSpecificationData,
    AddFdChatSpecificationMutationVariables
  >(ADD_FD_CHAT_SPECIFICATION, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addFdChatSpecification',
        error
      )
    }
  })

  return { addFdChatSpecification }
}

export const usePlaceOrder = () => {
  const surveyId = useSurveyId()
  const { total, costPerInterview } = useFdChatPrice()
  const { fieldworkData } = useFieldworkData()
  const { updateFieldwork, updateFieldworkLoading } = useUpdateFieldwork()

  const [createSurveyOrder, { loading: placingOrderLoading }] = useMutation<
    CreateSurveyOrderData,
    CreateSurveyOrderMutationVariables
  >(CREATE_SURVEY_ORDER, {
    context: { clientName: 'dashboard' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'createSurveyOrder',
        error
      )
    }
  })

  const placeOrder = async () => {
    if (!fieldworkData) return

    const startDate = dayjs().toISOString()
    const endDate = dayjs().add(7, 'days').toISOString()
    const panelSupplierCode = convertAudienceType(
      fieldworkData.audience[0].__typename
    )

    await updateFieldwork({
      variables: {
        surveyId,
        panelSupplierCode,
        startDate,
        endDate
      }
    })

    await createSurveyOrder({
      variables: {
        surveyId,
        panelSupplierCode: panelSupplierCode,
        customerCPI: costPerInterview,
        baseCPI: total,
        feasibility: 1.0
      }
    })
  }

  return {
    totalCost: total,
    placingOrderLoading: placingOrderLoading || updateFieldworkLoading,
    placeOrder
  }
}

export const useFdChatResults = () => {
  const surveyId = useSurveyId()
  const [results, setResults] = useState<ResultsType>()
  const [loading, setLoading] = useState(true)

  const fetchResults = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FD_CHAT_ANALYSIS_BUCKET_URL}/${surveyId}.json`
      )
      const json = await response.json()
      setLoading(false)
      setResults(json)
    } catch (error) {
      setLoading(false)
      setResults(undefined)
    }
  }, [surveyId])

  useEffect(() => {
    fetchResults()
  }, [fetchResults])

  return {
    results,
    loading,
    hasFindings: !!(results?.research_goal && results.research_questions),
    refetch: fetchResults
  }
}

export const useFdChatValidation = () => {
  const { fieldworkData } = useFieldworkData()
  const { fdChatSpecification } = useGetFdChatSpecification()
  const { draftAudience } = useGetDraftAudience()

  const { isAudienceInvalid } = useAudienceValidation({
    validationErrors: draftAudience?.validationErrors
  })
  const { hasValidationErrors } = useQuestionnaireValidation()

  const audienceValid =
    !!fieldworkData?.samplesNeeded.unadjusted &&
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !!fieldworkData?.lengthOfInterview.unadjusted &&
    !isAudienceInvalid

  if (!fdChatSpecification)
    return {
      objectivesValid: false,
      audienceValid
    }

  const objectivesValid =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    fdChatSpecification?.goal?.length > 0 && // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    fdChatSpecification?.questions?.[0]?.length > 0

  return {
    objectivesValid,
    audienceValid,
    questionnaireValid: !hasValidationErrors()
  }
}

export const useUpdateFdChatFieldwork = () => {
  const surveyId = useSurveyId()
  const { updateFieldwork } = useUpdateFieldwork()
  const { fieldworkData } = useFieldworkData()

  const updateFdChatFieldwork = useCallback(
    (variables: Partial<UpdateFieldworkMutationVariables>) => {
      if (!fieldworkData) return
      const audience = fieldworkData.audience[0]
      updateFieldwork({
        variables: {
          surveyId,
          panelSupplierCode: convertAudienceType(audience.__typename),
          countryCode: fieldworkData.locale.country,
          countryLanguage: fieldworkData.locale.language,
          audienceDescription:
            audience.__typename !== 'CintAudience'
              ? audience.description
              : undefined,
          ...variables
        },
        refetchQueries: ['fieldworkV2']
      })
    },
    [fieldworkData, surveyId, updateFieldwork]
  )

  const debouncedUpdateFdChatFieldwork = useMemo(
    () =>
      debounce((variables: Partial<UpdateFieldworkMutationVariables>) => {
        updateFdChatFieldwork(variables)
      }, 1000),
    [updateFdChatFieldwork]
  )

  return { updateFdChatFieldwork, debouncedUpdateFdChatFieldwork }
}

export const useFdChatPrice = () => {
  const { fieldwork, isCustomAudience } = useFdChatFieldwork()

  const numberOfInterviews = fieldwork?.samplesNeeded.unadjusted || 2000

  const respondentFee = isCustomAudience ? 0 : 15
  const dataProcessingPerInterview = isCustomAudience ? 3 : 1

  const costPerInterview = respondentFee + dataProcessingPerInterview
  const total = costPerInterview * numberOfInterviews

  return {
    respondentFee,
    dataProcessingPerInterview,
    total,
    costPerInterview
  }
}

export const fdChatSpecificationDebounceDelay = 1000
export const useUpdateFdChatSpecification = () => {
  const surveyId = useSurveyId()
  const client = useApolloClient()
  const { addFdChatSpecification } = useAddFdChatSpecification()

  type NewSpecificationType = Omit<FdChatSpecification, 'fdChatSpecificationId'>

  const updateFdChatSpecificationCache = (
    fdChatSpecification: NewSpecificationType
  ) => {
    client.cache.writeQuery({
      query: FD_CHAT_SPECIFICATION,
      variables: { surveyId },
      data: {
        questionnaire: {
          fdChatSpecification
        }
      }
    })
  }

  const updateFdChatSpecification = useCallback(
    (
      newSpecification: NewSpecificationType,
      updateCacheOnComplete: boolean = true
    ) => {
      addFdChatSpecification({
        variables: {
          surveyId,
          ...newSpecification
        },
        update: (cache, { data }) => {
          if (updateCacheOnComplete) {
            cache.writeQuery({
              query: FD_CHAT_SPECIFICATION,
              variables: { surveyId },
              data: {
                questionnaire: {
                  fdChatSpecification: {
                    ...data?.addFDChatSpecification
                  }
                }
              }
            })
          }
        }
      })
    },
    [addFdChatSpecification, surveyId]
  )

  const debouncedUpdateFdChatSpecification = useMemo(
    () =>
      debounce((newSpecification) => {
        updateFdChatSpecification(newSpecification, false)
      }, fdChatSpecificationDebounceDelay),
    [updateFdChatSpecification]
  )

  const updateFdChatSpecificationCacheAndDebounce = (
    newSpecification: NewSpecificationType
  ) => {
    updateFdChatSpecificationCache(newSpecification)
    debouncedUpdateFdChatSpecification(newSpecification)
  }

  return {
    updateFdChatSpecification,
    updateFdChatSpecificationCache,
    updateFdChatSpecificationCacheAndDebounce
  }
}

export const useFdChatFieldwork = () => {
  const { fieldworkData, loading: fieldworkLoading } = useFieldworkData()

  const isCustomAudience =
    fieldworkData?.audience[0].__typename === 'CustomAudience'

  return {
    fieldwork: fieldworkData,
    fieldworkLoading,
    isCustomAudience
  }
}

const FD_CHAT_ANALYTICS_URL = `${
  process.env.REACT_APP_URL_FD_CHAT_ANALYTICS ||
  process.env.REACT_APP_REST_DOMAIN
}/v1/analytics`

export const useExportFdChatTranscripts = () => {
  const surveyId = useSurveyId()
  const { project } = useProjectData()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const exportTranscripts = async ({
    fileFormat
  }: {
    fileFormat: TranscriptsFileFormatType
  }) => {
    setLoading(true)

    try {
      const url = `${FD_CHAT_ANALYTICS_URL}/transcripts/${surveyId}?project_name=${project?.name}&file_format=${fileFormat}`
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error('Error from fdchat analytics service')
      }

      const data = await response.blob()
      if (data.size <= 1) {
        throw new Error('No data in fdchat transcript file')
      }

      const dataUrl = window.URL.createObjectURL(data)
      const fileName = generateTranscriptsFilename(
        project?.name || surveyId,
        fileFormat
      )

      downloadFile({ dataUrl, fileName })
      window.URL.revokeObjectURL(dataUrl)

      enqueueSnackbar(
        'Downloading transcripts... Please check your downloads folder shortly.',
        {
          variant: SnackbarVariant.Info
        }
      )
    } catch (error: any) {
      datadogLogs.logger.error(
        `[FDChat Export Transcripts] ${error.message}`,
        {},
        error
      )
      enqueueSnackbar('Something went wrong. Please try again later.', {
        variant: SnackbarVariant.Error
      })
    }

    setLoading(false)
  }

  return { exportTranscripts, loading }
}

export const useFdChatContainerContent = () => {
  return useOutletContext<ContextType>()
}

export const useInterviewLink = ({
  preview = false
}: {
  preview?: boolean
} = {}) => {
  const surveyId = useSurveyId()

  return `${
    process.env.REACT_APP_RESPONDENT_DOMAIN
  }?sid=${surveyId}&ps=fd&type=fdchat&preview=${preview}&collect=${!preview}`
}
