import { gql } from '@apollo/client/core'
import { FieldworkAudience } from '../../../model/audience'
import DRAFT_AUDIENCE_MEMBER from '../fragments/draftAudienceMember'

export interface PasteInCriterionOptionsData {
  pasteInCriterionOptions: FieldworkAudience
}

export const PASTE_IN_CRITERION_OPTIONS = gql`
  mutation pasteInCriterionOptions(
    $surveyId: ID!
    $questionLk: ID!
    $responseOptions: [String!]!
    $startPosition: Int!
  ) {
    pasteInCriterionOptions(
      surveyId: $surveyId
      questionLk: $questionLk
      responseOptions: $responseOptions
      startPosition: $startPosition
    ) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`
