import { DropResult, List, RoutingMenuItem } from '@focaldata/cin-ui-components'
import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  DraftEntryResponseOption,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  TextEntryState
} from '../../../../data/model/questionnaire'
import useQuestionnaireValidation from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import {
  WithEntry,
  checkIfMultipleChoice,
  getRoutingBadgeDisplayString,
  isLastEntry,
  propsAreEqual
} from '../../../../utils/questionnaireUtils'
import ResponseOption from './ResponseOption'

interface Props extends WithEntry {
  entries: DraftQuestionnaireEntry[] | undefined
  responseOptions: DraftEntryResponseOption[]
  ariaLabel: string
  filteredRoutingMenuItems: RoutingMenuItem[] | undefined
  newlyAddedResponseOptionLk: string | undefined
  maskingEnabled: boolean
  onEnter: (position: number) => void
  onFocus: () => void
  onChangeResponseOption: (inputValue: string, responseOptionLk: string) => void
  onClickDeleteResponseOption: (
    questionLk: string,
    responseOptionLk: string
  ) => void
  onReorderedItems: (destinationIndex: number, sourceIndex: number) => void
  onRoutingMenuItemClick: (
    targetNumber: number | null,
    responseOptionLk: string
  ) => void
  onSetExclusiveOption?: (
    responseOptionLk: string,
    isExclusive: boolean
  ) => void
  onUpdateTextEntryState?: (
    responseOptionLk: string,
    newTextEntryState: TextEntryState
  ) => void
}

// This number is used by the respondent app to route the user to the end of the survey
export const ROUTE_TO_END_SURVEY_NUMBER = 100000

const ResponseOptionsList: React.FC<Props> = (props: Props) => {
  const {
    maskingEnabled = false,
    entries: flatEntries,
    responseOptions,
    ariaLabel,
    entry,
    filteredRoutingMenuItems,
    newlyAddedResponseOptionLk,
    onChangeResponseOption,
    onClickDeleteResponseOption,
    onEnter,
    onFocus,
    onReorderedItems,
    onRoutingMenuItemClick,
    onSetExclusiveOption,
    onUpdateTextEntryState
  } = props

  const entryItem = entry.entryItem as DraftQuestionItem
  const { validateBasicResponseOption } = useQuestionnaireValidation()

  const responseOptionCount = responseOptions.length

  const [pendingDeletionPosition, setPendingDeletionPosition] = useState<
    number | undefined
  >(undefined)

  const isMultipleChoice = checkIfMultipleChoice(entryItem.settingValues)

  const handleReorderedItems = (event: DropResult): void => {
    if (event.destination) {
      onReorderedItems(event.destination.index, event.source.index)
    }
  }

  useEffect(() => {
    setPendingDeletionPosition(undefined)
  }, [responseOptionCount])

  const routeThatIsSet = responseOptions.find((ro) => ro.route)
  // if it's multiple choice, add a state for the chosen routing
  // if that chosen routing is set, when filtering the routing items, only show that one
  const routingMenuItems = useMemo(
    () =>
      isMultipleChoice && routeThatIsSet
        ? filteredRoutingMenuItems?.filter(
            (menuItem) => menuItem.number === routeThatIsSet.route?.targetNumber
          )
        : filteredRoutingMenuItems,
    [filteredRoutingMenuItems, isMultipleChoice, routeThatIsSet]
  )

  const shouldShowEndOfSurveyForMultipleChoice = !routeThatIsSet
    ? undefined
    : routeThatIsSet.route?.targetNumber === ROUTE_TO_END_SURVEY_NUMBER

  const showEndOfSurveyRouting = !isMultipleChoice
    ? true
    : shouldShowEndOfSurveyForMultipleChoice

  const routingDisabled = isLastEntry(flatEntries, entry.number)

  const getResponseOptionsComponent: (
    responseOptions: DraftEntryResponseOption[]
  ) => (JSX.Element | null)[] = (responseOptions) => {
    return responseOptions.map((responseOption) => {
      if (responseOption.position === pendingDeletionPosition) {
        return null
      }
      const shouldAutoFocus =
        newlyAddedResponseOptionLk === responseOption.responseOptionLk

      const { errorMessage } = validateBasicResponseOption(
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        entryItem?.questionLk,
        responseOption.responseOptionLk
      )

      const routingBadgeDisplayString = getRoutingBadgeDisplayString(
        responseOption,
        flatEntries,
        ROUTE_TO_END_SURVEY_NUMBER
      )

      // TODO: this should also be able to render a lilght version of the response option control
      return (
        <ResponseOption
          key={responseOption.responseOptionLk + responseOption.position}
          ariaLabel={ariaLabel}
          errorText={errorMessage ?? ''}
          maskingEnabled={maskingEnabled}
          responseOption={responseOption}
          questionLk={entryItem.questionLk}
          entryNumber={entry.number}
          routingDisabled={routingDisabled}
          showEndOfSurveyRouting={showEndOfSurveyRouting}
          shouldAutoFocus={shouldAutoFocus}
          cannotDelete={responseOptions.length <= 2}
          entries={flatEntries}
          routingText={routingBadgeDisplayString}
          filteredRoutingMenuItems={routingMenuItems}
          onClickDeleteResponseOption={onClickDeleteResponseOption}
          onChangeResponseOption={onChangeResponseOption}
          onEnter={onEnter}
          onFocus={onFocus}
          onRoutingMenuItemClick={onRoutingMenuItemClick}
          onSetExclusiveOption={onSetExclusiveOption}
          onUpdateTextEntryState={onUpdateTextEntryState}
        />
      )
    })
  }

  const responseOptionsComponent = getResponseOptionsComponent(responseOptions)

  return (
    <List
      canReorderItems
      droppableId="drop-basicQuestion-responseOptions"
      onReorderedItems={handleReorderedItems}
    >
      {responseOptionsComponent}
    </List>
  )
}

export default memo(ResponseOptionsList, propsAreEqual)
