import { gql } from '@apollo/client/core'

export interface FieldworkAudienceData {
  fieldworkAudience: {
    surveyId: string
    entries: { number: number }[]
  }
}

export const SIDEBAR_AUDIENCE_QUESTION_COUNT = gql`
  query sidebarAudienceQuestionCount($surveyId: ID!) {
    fieldworkAudience: fieldworkAudience(surveyId: $surveyId) {
      surveyId
      entries {
        number
        __typename
      }
      __typename
    }
  }
`
