import { useCallback, useState } from 'react'
import { RootState, useAppDispatch, useAppSelector } from '../../App.store'
import { emptyArr } from '../../constants/misc'
import { PositionTextSelection } from '../../data/model/questionnaire'
import {
  responseOptionPasted,
  selectResponseOptionsByQuestionId
} from '../../modules/Questionnaire/Questionnaire.slice'
import { useDraftMatrixItem } from '../questionnaire/useDraftMatrixItem'
import { usePasteMutations } from '../questionnaire/usePasteMutations'
import { replacingTextAt, splitTextIntoLinesAndCleanup } from './utils'

interface UseCopyPasteCompleteData {
  // This one takes a text and formats and adds response options. Also cleans up
  pasteToMatrixRow: (
    text: string,
    matrixTitleLk: string,
    position: number,
    positionSelectedText?: PositionTextSelection
  ) => Promise<void>
  pasteToMatrixColumn: (params: {
    text: string
    matrixTitleLk: string
    position: number
    positionSelectedText?: PositionTextSelection
  }) => Promise<void>
  loading: boolean
}

const useCopyPasteMatrix: (
  matrixTitleLk: string
) => UseCopyPasteCompleteData = (matrixTitleLk) => {
  const dispatch = useAppDispatch()
  const selectColumnById = useCallback(
    (state: RootState) =>
      selectResponseOptionsByQuestionId(state, matrixTitleLk),
    [matrixTitleLk]
  )
  const responseOptions = useAppSelector(selectColumnById)
  const matrixItem = useDraftMatrixItem(matrixTitleLk)
  const matrixRows = matrixItem?.matrixRows ?? emptyArr
  const [loading, setLoading] = useState<boolean>(false)

  const { pasteInMatrixResponsesMutation, pasteInMatrixRowsMutation } =
    usePasteMutations()

  const pasteToMatrixRow: (
    text: string,
    matrixTitleLk: string,
    position: number,
    positionSelectedText?: PositionTextSelection
  ) => Promise<void> = async (
    text,
    matrixTitleLk,
    position,
    positionSelectedText
  ) => {
    setLoading(true)
    const pastedRows = splitTextIntoLinesAndCleanup(
      text,
      !!positionSelectedText?.startPositionSelectedText
    )

    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!pastedRows || pastedRows.length < 1) {
      // TODO: show some error message here?
      return
    }

    let updatedRow = matrixRows[position].question?.text
    if (
      positionSelectedText &&
      typeof positionSelectedText.startPositionSelectedText === 'number' &&
      typeof positionSelectedText.endPositionSelectedText === 'number'
    ) {
      updatedRow = replacingTextAt(
        matrixRows[position].question?.text ?? '',
        pastedRows[0],
        positionSelectedText.startPositionSelectedText,
        positionSelectedText.endPositionSelectedText
      )
    }

    pastedRows.splice(0, 1, updatedRow ?? '')
    await pasteInMatrixRowsMutation(matrixTitleLk, pastedRows, position)

    setLoading(false)
  }

  const pasteToMatrixColumn = async ({
    text,
    matrixTitleLk,
    position,
    positionSelectedText
  }: {
    text: string
    matrixTitleLk: string
    position: number
    positionSelectedText?: PositionTextSelection
  }): Promise<void> => {
    setLoading(true)
    const responseOptionTexts = splitTextIntoLinesAndCleanup(
      text,
      !!positionSelectedText?.startPositionSelectedText
    )

    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!responseOptionTexts || responseOptionTexts.length < 1) {
      // TODO: show some error message here?
      return
    }

    let updatedResponseOptionAtPosition =
      responseOptions[position].responseOption.value

    if (
      positionSelectedText &&
      typeof positionSelectedText.startPositionSelectedText === 'number' &&
      typeof positionSelectedText.endPositionSelectedText === 'number'
    ) {
      updatedResponseOptionAtPosition = replacingTextAt(
        responseOptions[position].responseOption.value,
        responseOptionTexts[0],
        positionSelectedText.startPositionSelectedText,
        positionSelectedText.endPositionSelectedText
      )
    }

    // update UI with pasted response options before actual update on backend
    dispatch(
      responseOptionPasted({
        questionLk: matrixTitleLk,
        position,
        responseOptionTexts,
        positionSelectedText
      })
    )

    responseOptionTexts.splice(0, 1, updatedResponseOptionAtPosition)
    await pasteInMatrixResponsesMutation(
      matrixTitleLk,
      responseOptionTexts,
      position
    )

    setLoading(false)
  }

  return { pasteToMatrixRow, pasteToMatrixColumn, loading }
}

export default useCopyPasteMatrix
