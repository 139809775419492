import { gql } from '@apollo/client/core'
import DRAFT_CUSTOM_CRITERION_OPTION from './draftCustomCriterionOption'
import DRAFT_STANDARD_CRITERION_OPTION from './draftStandardCriterionOption'

const DRAFT_AUDIENCE_MEMBER = gql`
  fragment DraftAudienceMember on DraftFieldworkAudienceMember {
    number
    position
    contextPosition
    memberType
    isWeighted
    memberItem {
      __typename
      ... on DraftStandardAudienceItem {
        criterion {
          code
          name
          question {
            questionId
            text
            createdDate
          }
          createdDate
        }
        settingValues {
          code
          value
          createdDate
          sinceDate
        }
        standardCriterionOptions {
          ...DraftStandardCriterionOption
        }
        customizedCriterionOptions {
          ...DraftStandardCriterionOption
        }
      }
      ...DraftCustomAudienceItem
    }
  }
  ${DRAFT_CUSTOM_CRITERION_OPTION}
  ${DRAFT_STANDARD_CRITERION_OPTION}
`

export default DRAFT_AUDIENCE_MEMBER
