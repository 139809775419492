import { gql } from '@apollo/client/core'
import { Fieldwork } from '../../../model/fieldwork'

export interface AddNewFieldworkData {
  addFieldwork: Fieldwork
}

export const ADD_FIELDWORK = gql`
  mutation addFieldwork(
    $surveyId: ID!
    $countryCode: String!
    $countryLanguage: String
    $samplesNeeded: Int!
    $accountMarkup: Float
    $panelSupplierCode: String!
    $audienceDescription: String
  ) {
    addFieldwork(
      surveyId: $surveyId
      panelSupplierCode: $panelSupplierCode
      countryCode: $countryCode
      countryLanguage: $countryLanguage
      samplesNeeded: $samplesNeeded
      accountMarkup: $accountMarkup
      audienceDescription: $audienceDescription
    ) {
      surveyId
    }
  }
`
