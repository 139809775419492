import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../App.store'
import { DraftSectionItem } from '../../../components/Section/Section.model'
import { emptyArr } from '../../../constants/misc'
import {
  DraftEntryResponseOption,
  DraftQuestionnaireEntry
} from '../../../data/model/questionnaire'
import {
  LoopBasis,
  LoopingConfig,
  LoopingSourceMatrixRowQuotas
} from './Looping.model'
import { entryHasStaticLooping } from './Looping.utils'

const initialState: {
  loopingConfig: LoopingConfig
  currentLoopingSectionNumber: number | null
  initialSourceEntryNumber: number | null
  staticLooping: boolean
} = {
  loopingConfig: {
    maxIterations: 0,
    loopBasis: 'Random',
    sourceEntryNumber: -1,
    loopRandomisationEnabled: true,
    prioritizedResponseOptionIds: null,
    matrixLoopingConfig: undefined,
    __typename: 'LoopingConfig'
  },
  staticLooping: false,
  initialSourceEntryNumber: null,
  currentLoopingSectionNumber: null
}

const loopingSlice = createSlice({
  name: 'looping',
  initialState,
  reducers: {
    maxIterationsSet(state, { payload }: PayloadAction<number>) {
      state.loopingConfig.maxIterations = payload
    },
    loopBasisSet(state, { payload }: PayloadAction<LoopBasis>) {
      state.loopingConfig.loopBasis = payload
    },
    setSourceEntry(
      state,
      { payload }: PayloadAction<DraftQuestionnaireEntry | null>
    ) {
      state.loopingConfig.prioritizedResponseOptionIds = null
      state.loopingConfig.sourceEntryNumber = payload?.number ?? -1
      /**
       * We can't have static looping on if multiple loops refer to the same
       * question, but we can't check that here – so it's just easier to always
       * turn it off when switching question
       */
      state.staticLooping = false
    },
    priorityToggled(
      state,
      { payload }: PayloadAction<DraftEntryResponseOption['responseOptionLk']>
    ) {
      const { prioritizedResponseOptionIds } = state.loopingConfig

      if (!prioritizedResponseOptionIds) {
        state.loopingConfig.prioritizedResponseOptionIds = [payload]
        return
      }

      const prioritizedResponseIndex = prioritizedResponseOptionIds.findIndex(
        (id) => id === payload
      )

      if (prioritizedResponseIndex === -1) {
        prioritizedResponseOptionIds.push(payload)
      } else {
        prioritizedResponseOptionIds.splice(prioritizedResponseIndex, 1)
      }
    },
    qualifyingResponseOptionIdAdded(state, { payload }: PayloadAction<string>) {
      if (!state.loopingConfig.matrixLoopingConfig) {
        state.loopingConfig.matrixLoopingConfig = {
          qualifyingResponseOptionIds: [],
          sourceMatrixRowQuotas: [],
          __typename: 'MatrixLoopingConfig'
        }
      }
      state.loopingConfig.matrixLoopingConfig.qualifyingResponseOptionIds.push(
        payload
      )
    },
    qualifyingResponseOptionIdRemoved(
      state,
      { payload }: PayloadAction<string>
    ) {
      const { matrixLoopingConfig } = state.loopingConfig
      if (!matrixLoopingConfig?.qualifyingResponseOptionIds) {
        return
      }
      const { qualifyingResponseOptionIds } = matrixLoopingConfig
      matrixLoopingConfig.qualifyingResponseOptionIds =
        qualifyingResponseOptionIds.filter((roId) => roId !== payload)
    },
    sourceMatrixQuotaTargetSet(
      state,
      {
        payload
      }: PayloadAction<
        Partial<LoopingSourceMatrixRowQuotas> &
          Pick<LoopingSourceMatrixRowQuotas, 'questionId'>
      >
    ) {
      if (!state.loopingConfig.matrixLoopingConfig) {
        state.loopingConfig.matrixLoopingConfig = {
          qualifyingResponseOptionIds: [],
          sourceMatrixRowQuotas: [],
          __typename: 'MatrixLoopingConfig'
        }
      }

      const { sourceMatrixRowQuotas } = state.loopingConfig.matrixLoopingConfig

      const existingRowQuota = sourceMatrixRowQuotas.find(
        ({ questionId }) => questionId === payload.questionId
      )

      if (existingRowQuota && payload.quotaTarget !== undefined) {
        existingRowQuota.quotaTarget = payload.quotaTarget
      } else if (existingRowQuota) {
        state.loopingConfig.matrixLoopingConfig.sourceMatrixRowQuotas =
          sourceMatrixRowQuotas.filter(
            ({ questionId }) => questionId !== payload.questionId
          )
      } else {
        sourceMatrixRowQuotas.push({
          ...payload,
          __typename: 'SourceMatrixRowQuota'
        })
      }
    },
    loopRandomisationEnabledSet(state, { payload }: PayloadAction<boolean>) {
      state.loopingConfig.loopRandomisationEnabled = payload
    },
    openLoopingDialog(
      state,
      {
        payload: { sectionEntryNumber, sectionEntryItem, sourceEntry }
      }: PayloadAction<{
        sectionEntryNumber: number
        sectionEntryItem: DraftSectionItem | null
        sourceEntry: DraftQuestionnaireEntry | null
      }>
    ) {
      state.currentLoopingSectionNumber = sectionEntryNumber
      state.loopingConfig =
        sectionEntryItem?.loopingConfig ?? initialState.loopingConfig
      state.staticLooping = sourceEntry
        ? entryHasStaticLooping(sourceEntry)
        : false
      state.initialSourceEntryNumber = sourceEntry?.number ?? null
    },
    closeLoopingDialog() {
      return initialState
    },
    toggleStaticLoopingForCurrentSourceEntry(state) {
      if (state.loopingConfig.sourceEntryNumber < 0) {
        throw new Error(
          'You must select a source entry to toggle static looping'
        )
      }

      state.staticLooping = !state.staticLooping

      if (
        state.staticLooping &&
        state.loopingConfig.loopBasis === 'LeastFilled'
      ) {
        state.loopingConfig.loopBasis = 'Random'
      }
    }
  }
})

export const selectMaxIterations = (state: RootState): number => {
  return state.looping.loopingConfig.maxIterations
}

export const selectLoopBasis = (state: RootState): LoopBasis => {
  return state.looping.loopingConfig.loopBasis
}

export const selectSourceEntryNumber = (state: RootState): number => {
  return state.looping.loopingConfig.sourceEntryNumber
}

export const selectSourceChosen = createSelector(
  selectSourceEntryNumber,
  (number) => number > -1
)

export const selectInitialSourceEntryNumber = (state: RootState) =>
  state.looping.initialSourceEntryNumber

export const selectLoopRandomisationEnabled = (state: RootState) => {
  return state.looping.loopingConfig.loopRandomisationEnabled
}

export const selectPrioritizedResponseOptionIds = (state: RootState) => {
  return state.looping.loopingConfig.prioritizedResponseOptionIds ?? emptyArr
}

const selectQualifyingResponseOptionIds = (state: RootState) =>
  state.looping.loopingConfig.matrixLoopingConfig?.qualifyingResponseOptionIds

export const selectQualifyingResponseOptionIdSet = createSelector(
  selectQualifyingResponseOptionIds,
  (ids) => new Set(ids)
)

export const selectMatrixLoopingConfig = (state: RootState) => {
  return state.looping.loopingConfig.matrixLoopingConfig
}

const selectSourceMatrixRowQuotas = (state: RootState) =>
  state.looping.loopingConfig.matrixLoopingConfig?.sourceMatrixRowQuotas ??
  emptyArr

export const selectSourceMatrixQuotaByRow = createSelector(
  selectSourceMatrixRowQuotas,
  (quotas) =>
    new Map(
      quotas.map(
        ({ questionId, quotaTarget }) => [questionId, quotaTarget] as const
      )
    )
)

export const selectCurrentLoopingSectionNumberRequired = (state: RootState) => {
  const number = state.looping.currentLoopingSectionNumber

  if (number === null) {
    throw new Error('Expected looping section number to be set')
  }

  return number
}

export const selectLoopingModalOpen = (state: RootState) => {
  return state.looping.currentLoopingSectionNumber !== null
}

export const selectStaticLoopingEnabled = (state: RootState) => {
  return selectSourceChosen(state) && state.looping.staticLooping
}

export const {
  maxIterationsSet,
  loopBasisSet,
  setSourceEntry,
  priorityToggled,
  qualifyingResponseOptionIdAdded,
  qualifyingResponseOptionIdRemoved,
  sourceMatrixQuotaTargetSet,
  loopRandomisationEnabledSet,
  openLoopingDialog,
  closeLoopingDialog,
  toggleStaticLoopingForCurrentSourceEntry
} = loopingSlice.actions

export default loopingSlice.reducer
