import { useMutation } from '@apollo/client'
import { produce } from 'immer'
import isEmpty from 'lodash/isEmpty'
import { useContext } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { AddCustomCriterionMutationVariables } from '../../data/gql-gen/fieldwork/graphql'
import { FieldworkV2Query } from '../../data/gql-gen/questionnaire/graphql'
import {
  ADD_CUSTOM_CRITERION,
  AddCustomCriterionData
} from '../../data/gql/fieldwork/mutations/addCustomCriterion'
import {
  AudienceData,
  GET_AUDIENCES
} from '../../data/gql/questionnaire/queries/audiences'
import {
  CustomAudienceCriteriaData,
  GET_CUSTOM_CRITERIA
} from '../../data/gql/questionnaire/queries/customCriteria'
import { LoggerErrorType } from '../../data/logger'
import {
  DraftCustomAudience,
  FieldworkAudience,
  MemberSettingCode,
  SettingValue
} from '../../data/model/audience'
import { useAudienceCountries } from '../../hooks/audience/useAudienceCountries'
import useGetDraftAudience from '../../hooks/audience/useGetDraftAudience'
import { useFieldwork } from '../../hooks/useFieldwork'
import { useProjectId } from '../../hooks/useProjectId'
import { useSurveyId } from '../../hooks/useSurveyId'
import { captureApolloError } from '../../utils/HelperFunctions'
import AudienceContext, {
  setAudienceMembers,
  setNewAudienceQuestionLk
} from './Audience.context'
import {
  createEmptyCustomAudience,
  createEmptyCustomCriterion
} from './CustomAudienceList/CustomAudienceList.utils'

export const useFieldworkData = (): {
  fieldworkData?: FieldworkV2Query['fieldwork']
  loading: boolean
} => {
  const { fieldwork, loading } = useFieldwork()

  return { fieldworkData: fieldwork, loading }
}

export const useCountryName = () => {
  const { fieldworkData } = useFieldworkData()

  const { data } = useAudienceCountries()

  const country = data?.countries.find(
    ({ countryCode }) => countryCode === fieldworkData?.locale.country
  )

  return country?.name ?? 'UK'
}

export const useAddCustomAudience = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { dispatch } = useContext(AudienceContext)
  const { draftAudience } = useGetDraftAudience()

  const [addCustomCriterion] = useMutation<
    AddCustomCriterionData,
    AddCustomCriterionMutationVariables
  >(ADD_CUSTOM_CRITERION, {
    context: { clientName: 'fieldwork' },
    onCompleted: async (data: AddCustomCriterionData) => {
      const newAud = data.addCustomCriterion.memberItem as DraftCustomAudience
      dispatch(setNewAudienceQuestionLk(newAud.questionLk))
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'addCustomCriterion',
        error
      )
    }
  })

  const addCustomAudience: (isMulti: boolean) => void = (isMulti) => {
    const audienceQuestionsNr = draftAudience?.members.length
    if (audienceQuestionsNr !== undefined) {
      if (draftAudience) {
        dispatch(
          setAudienceMembers([
            ...draftAudience.members,
            createEmptyCustomAudience(isMulti)
          ])
        )
        dispatch(setNewAudienceQuestionLk('questionLk'))
      }

      addCustomCriterion({
        variables: {
          surveyId,
          position: audienceQuestionsNr,
          settings: [
            {
              memberSettingCode: MemberSettingCode.CustomAudienceChoice,
              settingValue: isMulti
                ? SettingValue.MultipleChoice
                : SettingValue.SingleChoice
            }
          ],
          defaultOptionsCount: 2,
          skipIRSNotificationRecording: true
        },
        optimisticResponse: {
          addCustomCriterion: {
            ...createEmptyCustomAudience(isMulti),
            __typename: 'DraftFieldworkAudienceMember'
          }
        },
        update: (cache, { data: newAudienceData }) => {
          const newAudience =
            newAudienceData?.addCustomCriterion as FieldworkAudience
          cache.updateQuery(
            {
              query: GET_CUSTOM_CRITERIA,
              variables: {
                projectId,
                surveyId
              }
            },
            (customAudienceCriteriaData: CustomAudienceCriteriaData | null) => {
              if (!customAudienceCriteriaData) {
                return undefined
              }
              return produce(customAudienceCriteriaData, (draft) => {
                draft.customCriteria.push(createEmptyCustomCriterion())
              })
            }
          )

          if (isEmpty(newAudience)) {
            return
          }

          cache.updateQuery(
            {
              query: GET_AUDIENCES,
              variables: {
                projectId,
                surveyId
              }
            },
            (audienceData: AudienceData | null) => {
              return produce(audienceData, (draft) => {
                draft?.draftAudience.members.push(newAudience)
              })
            }
          )
        }
      })

      LogAmplitudeEvent(EventType.AddedScreeningQuestion, { surveyId })
    }
  }

  return { addCustomAudience }
}
