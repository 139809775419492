import { gql } from '@apollo/client/core'

export const MOVE_SECTION_ENTRY = gql`
  mutation moveSectionEntry(
    $questionnaireId: ID!
    $number: Int!
    $toPosition: Int!
  ) {
    moveSectionEntry(
      questionnaireId: $questionnaireId
      number: $number
      toPosition: $toPosition
    ) {
      number
      position
      entryType
      questionKind
      entryItem {
        __typename
        ... on DraftQuestionItem {
          questionTypeCode
          questionLk
          question {
            __typename
            questionId
            text
            createdDate
          }
          __typename
        }
      }
      __typename
    }
  }
`
